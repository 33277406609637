import React, { useState, useEffect } from "react";
import AuthLayout from "../../components/AuthLayout";
import { Helmet } from "react-helmet";
import GeneralShiftDetails from "./SubComponents/GeneralShiftDetails/GeneralShiftDetails";
import LoadingScreen from "../../components/LoadingScreen";
import { toast } from "react-toastify";
import ShiftHeader from "./SubComponents/ShiftPlanExpand/ShiftHeader";
import EquipmentPlan from "./SubComponents/ShiftPlanExpand/EquipmentPlan/EquipmentPlan";
import ShiftPlanSubHeaderBar from "./SubComponents/ShiftPlanSubHeaderBar/ShiftPlanSubHeaderBar";
import { ShiftStatus } from "./utils/contants";
import { formateDataForView, updateTimes } from "./utils/helper";
import { checkForTruckAssignedOrNot, formatFormData, validateShiftDetailsObject } from "./utils/helpers/PublishShift";
import { getShiftUpdates } from "./utils/helpers/FormUpdates";
import moment from "moment";
import { useAddDailyPlan } from "./Context/context";
import { calculateRemovedEquipmentsUpdates, calculateShiftPlanUpdates, generateErrorMessage, handleApiResponse } from "./utils/helpers/PublishShift";
import { checkForEquipmentOverlaps, checkForOperatorOverlaps, checkForShiftPlanOverlaps } from "./utils/helpers/checkOverlaps";
import { useNavigate } from 'react-router-dom';
import { useDefaultBlocker } from "./hooks/useDefaultBrowser";

const AddDailyPlan = () => {
  const navigate = useNavigate();
  const [shiftDataSuccessfullySaved, setShiftDataSuccessfullySaved] = useState(false);

  const {
    removedEquipments,
    isGeneralShiftFlyerOpen,
    originalShiftData,
    originalData,
    search,
    setSearch,
    shiftStatus,
    loading,
    setLoading,
    assignedExcavatorDetailsFormData,
    removedEquipmentFlyerOpen,
    isTruckShiftDetailsFlyerOpen,
    isExcavatorShiftDetailsFlyerOpen,
    generalShiftPlanDetailsFormData,
    canEditShift,
    mySession,
    setOverlapFailures,
    editSession
  } = useAddDailyPlan();

  const publishHandler = async (saveAndPublish) => {
    setLoading(true);
    setOverlapFailures([]);

    const { createdAt, ...shiftData } = generalShiftPlanDetailsFormData;
    const shiftUpdates = getShiftUpdates(originalShiftData, shiftData, shiftStatus);

    const updatedAssignedExcavatorDetailsFormData = calculateShiftPlanUpdates(originalData, assignedExcavatorDetailsFormData);
    const updatedRemovedEquipments = calculateRemovedEquipmentsUpdates(removedEquipments, originalShiftData);

    let formattedFormData = await formatFormData({ ...shiftData, updates: shiftUpdates }, updatedAssignedExcavatorDetailsFormData, updatedRemovedEquipments);
    formattedFormData = updateTimes(formattedFormData);

    if (saveAndPublish) {
      // Full validation
      const validation = validateShiftDetailsObject(formattedFormData, shiftStatus);

      if (!validation.valid) {
        const message = generateErrorMessage(validation.errors);
        toast.error(message, {
          position: "bottom-right",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setLoading(false);
        return;
      }

      const noExcavatorisAsssignedToShift = formattedFormData?.assignedExcavators?.length === 0;

      if (noExcavatorisAsssignedToShift && (shiftStatus === ShiftStatus.NEW || shiftStatus === ShiftStatus.DRAFT)) {
        toast.error("Please assign at least one Excavator to the Shift.", {
          position: "bottom-right",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setLoading(false);
        return;
      }

      if (moment(formattedFormData.endTime).isSameOrBefore(moment())) {
        toast.error("Shift Timings are Invalid", {
          position: "bottom-right",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setLoading(false);
        return;
      }

      if (await checkForTruckAssignedOrNot(formattedFormData)) {
        toast.error("Some trucks have been added but are not assigned to any shift plans. Please remove them from the excavators.", {
          position: "bottom-right",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setLoading(false);
        return;
      }
    }

    // Always check for shift plan overlaps
    const shiftPlanOverlapDetails = await checkForShiftPlanOverlaps(formattedFormData);
    if (shiftPlanOverlapDetails?.hasOverlap) {
      setOverlapFailures(shiftPlanOverlapDetails.allOverlaps);
      toast.error("Start-End Timing conflict found in Excavator Shift Plans. Please make adjustments", {
        position: "bottom-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setLoading(false);
      return;
    }

    if (saveAndPublish) {
      // Additional checks if saveAndPublish is true
      const equipmentOverlapDetails = await checkForEquipmentOverlaps(formattedFormData);
      if (equipmentOverlapDetails?.hasOverlap) {
        setOverlapFailures(equipmentOverlapDetails.allOverlaps);
        toast.error((equipmentOverlapDetails.allOverlaps?.length === 1 ? "An equipment is" : "Some equipments are") + " Assigned to other shifts. Please make changes", {
          position: "bottom-right",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setLoading(false);
        return;
      }

      const operatorOverlapDetails = await checkForOperatorOverlaps(formattedFormData);
      if (operatorOverlapDetails?.hasOverlap) {
        setOverlapFailures(operatorOverlapDetails.allOverlaps);
        toast.error((operatorOverlapDetails.allOverlaps?.length === 1 ? "An operator is" : "Some operators are") + " Assigned to other shift. Please make changes", {
          position: "bottom-right",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setLoading(false);
        return;
      }
    }

    // Handle the API response regardless of saveAndPublish flag
    const isSuccess = await handleApiResponse(formattedFormData, saveAndPublish);
    if (isSuccess) {
      navigate("/shift-management/shift-planning", { replace: true });
    }

    setLoading(false);
  };

  //?We can create custom Hook for Search
  const handleOnSearch = (val) => {
    setSearch({ ...search, query: val })
  }

  const handleSearchCount = (count) => {
    setSearch((prev) => ({ ...prev, count: count }))
  }

  const handleSearchCount2 = (count) => {
    setSearch((prev) => ({ ...prev, count2: count }))
  }

  const handleOnClear = () => {
    //Clear handler for the search in equipments table
    setSearch({
      query: "",
      count: 0,
    });
  };

  useDefaultBlocker(
    'Are you sure you want to leave this page?',
    (shiftStatus == ShiftStatus.NEW && shiftDataSuccessfullySaved) || (!editSession.by && editSession.willEndAt)
  );

  return (
    <AuthLayout pageName={""}>
      <Helmet>
        <title>Add Daily Shift | iMOS</title>
      </Helmet>
      {loading && <LoadingScreen />}
      <div className="right_sidebar_body" style={{ padding: "0px 2rem", height: 'calc(100vh - 5.5em)', display: 'flex', flexDirection: 'column' }}>
        <ShiftPlanSubHeaderBar
          isSavingDisabled={(function () {
            const isNewShiftAndNotSaved =
              !shiftDataSuccessfullySaved && shiftStatus === ShiftStatus.NEW;

            const isAnyFlyerOpen =
              isGeneralShiftFlyerOpen || removedEquipmentFlyerOpen !== -1 || isTruckShiftDetailsFlyerOpen || isExcavatorShiftDetailsFlyerOpen !== -1;

            return !canEditShift || isAnyFlyerOpen || isNewShiftAndNotSaved || (!mySession && ShiftStatus.NEW != shiftStatus)
          })()}
          onClickPublish={() => publishHandler(true)}
          onClickSaveDraft={() => publishHandler(false)}
          handleOnSearch={handleOnSearch}
          handleOnClear={handleOnClear} />
        <div
          className={"shift_details active"}
          style={{
            border: "1px solid #E1E8F4",
            marginBottom: "1.5rem",
            borderRadius: "10px",
            fontFamily: "Open Sans",
          }}
        >
          <ShiftHeader
            searchQuery={search.query}
            handleSearchCount={handleSearchCount2}
            viewDataOfGeneralShiftDetails={formateDataForView(generalShiftPlanDetailsFormData)}
          />
        </div>
        <EquipmentPlan
          handleSearchCount={handleSearchCount}
        />
      </div>
      <>
        {isGeneralShiftFlyerOpen && (
          <GeneralShiftDetails
            setShiftDataSuccessfullySaved={setShiftDataSuccessfullySaved}
          />
        )}
      </>
    </AuthLayout>
  );

};

export default AddDailyPlan;

