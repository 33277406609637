import { useEffect, useState } from "react";
import styles from "./ShiftSummaryReport.module.css";
import DelayAndActivitySection from "./DelayActivitySection/Delay-Activity-Section";
import OperatorSection from "./OperatorSection/OperatorSection";
import ExcavatorSection from "./ExcavatorSection/ExcavatorSection";
import TableSection from "./TableSection/TableSection";
import SupervisorSection from "./SupervisorSection/SupervisorSection";
import HeaderSection from "./HeaderSection/HeaderSection";
import close from "../../../../assets/svgs/roundClose.svg";
import download from "../../../../assets/svgs/roundDownload.svg";
import { Riple } from "react-loading-indicators";
import {
  showToast,
  transformActivityInformation,
  transformLoadData,
  transformTotals,
  transformWorkingInformation,
} from "pages/ShiftHistory/utils/helper";
import { generateShiftSummaryPdf, SignShiftSummary } from "pages/ShiftHistory/utils/apiService";
import { useShiftHistoryContext } from "pages/ShiftHistory/Context/context";

const ShiftSummaryReport = ({
  show,
  isTruck,
  closeModal,
  data,
  selectedEquipmentId,
}) => {
  const { loading } = useShiftHistoryContext();

  const { selectedDate, shiftType } = useShiftHistoryContext();
  const [pdfLoading, setPdfLoading] = useState(false);
  const [signatureData, setSignatureData] = useState({
    signedName: "",
    comments: "",
    signature: "",
    file: "",
    shiftId: "",
    equipmentId: "",
  });

  useEffect(() => {
    if (data?.comment) {
      setSignatureData({
        signedName: data?.comment?.signedName,
        comments: data?.comment?.comments,
        signature: data?.comment?.signature,
        file: data?.comment?.signature,
        shiftId: data?.shiftId,
        equipmentId: selectedEquipmentId,
      });
    }
    else {
      setSignatureData({
        signedName: "",
        comments: "",
        signature: "",
        file: "",
        shiftId: "",
        equipmentId: "",
      });
    }
  }, [data]);


  const overburdenHeaders = [
    { label: "Load", key: "load" },
    { label: "Time", key: "time" },
    { label: "Excavator", key: "excavator" },
    { label: "Source", key: "source" },
    { label: "Dump", key: "dump" },
    { label: "Material", key: "material" },
    { label: "Operator", key: "operator" },
  ];

  //Transform load data converting key into header keys
  const overburdenLoadData = transformLoadData(data?.dailyLoadSheet);

  const totalHeaders = [
    { label: "Loading Machine", key: "excavator" },
    { label: "Material", key: "material" },
    { label: "Source", key: "source" },
    { label: "Dump", key: "dump" },
    { label: "Total Loads", key: "quantity" },
  ];

  //Transform totals
  const totalsData = transformTotals(data?.totals);

  const calculateTotalLoads = data?.totals?.reduce(
    (acc, item) => acc + item.totalLoads,
    0
  );

  const workingHeaders = [
    { label: "Haul Truck (Make/Model)", key: "model" },
    { label: "Plant/Machine No.", key: "machine" },
    { label: "Start Hour Meter", key: "startHour" },
    { label: "Finish Hour Meter", key: "finishHour" },
    { label: "Total Machine Hour", key: "totalHour" },
  ];

  //Transform working information
  const workingInformation = transformWorkingInformation(
    data?.workingInformation
  );

  //Transform activity information
  const activityInformation = transformActivityInformation(
    data?.activitiesInformation
  );

  const generateShiftSummaryReportPdf = async () => {
    setPdfLoading(true);
    try {
      const pdfResponse = await generateShiftSummaryPdf(
        selectedDate,
        shiftType,
        data?.summary?.plantId?.replace(/\s+/g, ""),
        data?.summary?.reportNo
      );
      if (pdfResponse) {
        closeModal();
        showToast(true, "Pdf generated successfully..!");
      }
    } catch (error) {
      showToast(false, error.message);
    }
    setPdfLoading(false);
  }
  const handleSignShiftSummary = async () => {
    const formData = new FormData();
    formData.append("signedName", signatureData.signedName);
    formData.append("shiftId", data?.shiftId);
    formData.append("equipmentId", selectedEquipmentId);
    if (typeof signatureData?.file == "string") {
      formData.append("signatureFileUrl", signatureData?.file);
    } else {
      formData.append("files", signatureData?.file);
    }

    formData.append("comments", signatureData.comments);

    try {
      const isSigned = await SignShiftSummary(formData);

      if (isSigned) {
        showToast(true, "Shift Summary signed successfully ");
      }
    } catch (error) {
      showToast(false, "Error while shift summary signed.");
    }
  };

  if (!show) return null;
  return (
    <>
      {pdfLoading ? (
        <div
          className={`d-flex align-items-center justify-content-center ${styles.ripple}`}
        >
          <Riple color="#317bcc" size="medium" text="" textColor="" />
        </div>
      ) : (
        <div>
          <div id="reportIcon" className={styles.iconContainer}>
            <div
              className={styles.iconButton}
              onClick={generateShiftSummaryReportPdf}
            >
              <img
                className={styles.reportTopIcon}
                src={download}
                alt="download"
              />
            </div>
            <div className={styles.iconButton} onClick={closeModal}>
              <img className={styles.reportTopIcon} src={close} alt="preview" />
            </div>
          </div>

          <div
            id="summary-report"
            className={`modal fade show ${styles.modalTop}`}
            tabIndex="-1"
            role="dialog"
          >
            <div
              id="modalDialogTop"
              className={`modal-dialog modal-dialog-centered ${styles.modalTopArea}`}
              role="document"
            >
              <div className={`modal-content px-5 ${styles.modalContent}`}>
                <div
                  className={`modal-header d-flex flex-column ${styles.modalHeader}`}
                >
                  <HeaderSection materialType={data?.workingInformation?.materialType} summary={data?.summary} isTruck={isTruck} />
                </div>
                <div className={`modal-body p-0 pt-3 pb-5 ${styles.modalBody}`}>
                  {data?.operatorInformation && (
                    <OperatorSection operators={data?.operatorInformation} />
                  )}
                  <SupervisorSection
                    signatureData={signatureData}
                    setSignatureData={setSignatureData}
                    handelSaveAndSign={handleSignShiftSummary}
                    commentData={data?.comment}
                  />

                  {isTruck && (
                    <TableSection
                      title={"Working"}
                      headers={workingHeaders}
                      data={workingInformation}
                    />
                  )}

                  <DelayAndActivitySection
                    data={data?.delaysInformation}
                    header="Delays"
                    containerClass={"mb-4"}
                  />

                  {!isTruck && (
                    <ExcavatorSection data={data?.workingInformation} />
                  )}

                  <DelayAndActivitySection
                    data={activityInformation}
                    header="Activities"
                  />

                  {isTruck && (
                    <TableSection
                      title={"Overburden Daily Load Sheet"}
                      headers={overburdenHeaders}
                      data={overburdenLoadData}
                    />
                  )}

                  {isTruck && (
                    <TableSection
                      title={"Totals"}
                      headers={totalHeaders}
                      data={totalsData}
                      titleClass={styles.totals}
                      paddingClass={"py-3"}
                    />
                  )}

                  {isTruck && (
                    <div className="d-flex align-items-center justify-content-between px-3 py-4">
                      <div className={styles.totalLoads}>Total Loads</div>
                      <div className={styles.totalLoads}>
                        {calculateTotalLoads} Loads
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ShiftSummaryReport;
