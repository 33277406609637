import MTDTotals from "../MTDTotals/MTDTotals"

const MTDCoals = ({ coalData }) => {

  const getProductCoalData = () => {
    return {
      actual: coalData?.productCoalMTDActual,
      target: coalData?.productCoalMTDTarget,
    }
  }

  const getROMCoalData = () => {
    return {
      actual: coalData?.romCoalMTDActual,
      target: coalData?.romCoalMTDTarget,
    }
  }


  return (
    <>
      <MTDTotals title={"Product Coal"} data={getProductCoalData()} />
      <MTDTotals title={"ROM Coal"} data={getROMCoalData()} />
    </>
  )
}

export default MTDCoals
