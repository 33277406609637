import moment from "moment";
import { toast } from "react-toastify";
import { EquipmentType } from "./constant";


//Transform equipment data for processed data.
export const transformEquipmentData = (historyData) => {
  return historyData?.reduce(
    (result, item) => {
      if (item.type === EquipmentType.EXCAVATOR) {
        result.excavators.push(item);
      } else if (item.type === EquipmentType.TRUCK) {
        result.trucks.push(item);
      }
      return result;
    },
    { excavators: [], trucks: [] }
  );
};

export const dateFormatted = (utcDate) => {
  if (!utcDate) {
    return "00:00";
  }
  return moment(utcDate).tz("Pacific/Auckland").format("hh:mm A");
};

export const convertSecondsToTime = (seconds) => {
  if (!seconds) return "-";

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  let timeString = "";
  if (hours > 0) {
    timeString += `${hours}h `;
  }
  if (minutes > 0 || hours > 0) {
    timeString += `${minutes}m`;
  }

  return timeString;
};

export const getElementDimensions = (element) => {
  return [
    element.getBoundingClientRect().width,
    element.getBoundingClientRect().height,
  ];
};

export const TimeFormatter = (startTime, endTime) => {
  if (!startTime || !endTime) {
    return "Invalid time range";
  }

  const start = moment(startTime).isValid()
    ? moment(startTime).format("hh:mm A")
    : "-";
  const end = moment(endTime).isValid()
    ? moment(endTime).format("hh:mm A")
    : "-";

  const duration = moment.duration(moment(endTime).diff(moment(startTime)));
  const hours = String(duration.hours()).padStart(2, "0");
  const minutes = String(duration.minutes()).padStart(2, "0");

  return `${start} - ${end} (${hours}h ${minutes}m)`;
};

export const showToast = (isSuccess, msg) => {
  const options = {
    position: "bottom-right",
    autoClose: 6000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  };

  if (isSuccess) {
    toast.success(msg, options);
  } else {
    toast.error(msg, options);
  }
};

//Transform working information
export const transformWorkingInformation = (workingInformation) => {
  const startHour = parseFloat(workingInformation?.startHour) || 0;
  const finishHour = parseFloat(workingInformation?.endHour) || 0;
  const totalHour = Math.max(finishHour - startHour, 0); // Ensure no negative values

  return [
    {
      model: workingInformation?.modelNo,
      machine: workingInformation?.plantId,
      startHour: workingInformation?.startHour || 0,
      finishHour: workingInformation?.endHour || 0,
      totalHour: totalHour,
    },
  ];
};

export const transformActivityInformation = (activitiesInformation) => {
  return activitiesInformation?.map((activity) => ({
    ...activity,
    subType: activity?.type,
    type: undefined,
  }));
};

export const transformLoadData = (loadData) => {
  return loadData?.map((item, index) => {
    const time = moment.unix(item.timestamp).format("hh:mm A");
    return {
      load: index + 1,
      time: time,
      excavator: item?.excavatorName,
      source: item?.sourceArea,
      dump: item?.dumpArea,
      material: item?.material,
      operator: item?.operator,
    };
  });
};

export const transformTotals = (data) => {
  return data?.map((item) => {
    return {
      excavator: item?.excavatorName,
      material: item?.material,
      source: item?.sourceArea,
      dump: item?.dumpArea,
      quantity: item?.totalLoads,
    };
  });
};
