import { CallWithAuth } from "action/apiActions";
import axios from "axios";
import useAuth from "hooks/useAuth";
import moment from "moment";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import { KPIDashboardData } from "pages/KPIDashboard/DummyData";
import { KPI_DASHBOARD_DATA } from "action/apiPath";

// import { fetchEquipments } from "../utils/apiService";
// import { GET_SHIFT_HISTORY } from "action/apiPath";

const KPIDashboardContext = createContext();

export const KPIDashboardProvider = ({ children }) => {

  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const [selectedDate, setSelectedDate] = useState(
    queryParams.get("date")
      ? moment
        .tz(queryParams.get("date"), "YYYY-MM-DD", "Pacific/Auckland")
        .toDate()
      : new Date()
  );

  const [loading, setLoading] = useState(true);
  const { auth } = useAuth();
  const timeoutRef = useRef(null);

  const [dashboardData, setDashboardData] = useState({});
  const [refreshPage, setRefreshPage] = useState(false);

  useEffect(() => {
    const dateFromQuery = queryParams.get("date");
    const formattedSelectedDate = moment(selectedDate).format("YYYY-MM-DD");

    if (dateFromQuery && formattedSelectedDate !== dateFromQuery) {
      const newSelectedDate = moment
        .tz(dateFromQuery, "YYYY-MM-DD", "Pacific/Auckland")
        .toDate();
      setSelectedDate(newSelectedDate);
    }
  }, [location.search]);

  const navigateWithDate = () => {
    queryParams.set("date", moment(selectedDate).format("YYYY-MM-DD"));
    navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true });
  };

  useEffect(() => {
    setLoading(true);
    navigateWithDate();
    const source = axios.CancelToken.source();

    //Helper function to fetch data
    const fetchData = async (dontLoad = false) => {
      if (!dontLoad) setLoading(true);
      try {
        const response = await CallWithAuth(
          "GET",
          KPI_DASHBOARD_DATA(moment(selectedDate).format("YYYY-MM-DD")),
          {},
          source.token
        );


        const data = response?.res?.data?.data;

        if (!dontLoad && data) setLoading(false);
        if (data && response?.res.status && response.status) {
          setDashboardData(data);
        }

        setAutoRefreshTimeout();
        // If successful, start the timeout for auto-refresh
      } catch (error) {
        handleError(error, dontLoad);
      }
      setLoading(false);
    };

    //Helper function for error handling and retry logic
    const handleError = (error, dontLoad) => {
      if (!dontLoad) setLoading(false);
      console.error("Error fetching performance data:", error);

      // Set retry with timeout on error
      setAutoRefreshTimeout();
    };

    //Helper function to manage auto-refresh
    const setAutoRefreshTimeout = () => {
      timeoutRef.current = setTimeout(() => {
        fetchData(true);
      }, 5000);
    };

    // Initial Navigation
    if (selectedDate) {
      navigate(`${location.pathname}?${queryParams.toString()}`, {
        replace: true,
      });
    }

    // Initial Data Fetch
    if (selectedDate) fetchData();
    else {
      setDashboardData([]);
    }

    // Cleanup on unmount
    return () => {
      source.cancel("Operation canceled by the user.");
      clearTimeout(timeoutRef.current);
    };
  }, [selectedDate, refreshPage]);

  return (
    <KPIDashboardContext.Provider
      value={{
        loading,
        dashboardData,
        setLoading,
        selectedDate,
        setSelectedDate,
        setRefreshPage,
        refreshPage,
      }}
    >
      {children}
    </KPIDashboardContext.Provider>
  );
};

export const useKPIDashboardContext = () => {
  return useContext(KPIDashboardContext);
};
