import { getDefaultNewShiftTimings } from "./helper";



export const ShiftStatus = {
  CATA: "cat a failure",
  CATB: "cat b failure",
  CATC: "cat c failure",
  PASSED: "passed",
  INCOMPLETE: "incomplete",
};

export const DateTimeFormats = {
  DATE_TIME_U: "DD/MM/YYYY, LT",
};

export const SelectAssetTypes = {
  MISC: 0,
  DOZER: 1,
  EXCAVATORS: 2,
  GRADER: 3,
  HVM: 4,
  DD: 5,
  DT: 6,
  FEL: 7,
  CD: 8,
  DMU: 9
};

export const AssetCategories = [
  { name: "Excavator", type: SelectAssetTypes.EXCAVATORS },
  { name: "Misc", type: SelectAssetTypes.MISC },
  { name: "Truck", type: SelectAssetTypes.DT },

];

export const HistoryTypes = {
  UPDATED: "Edited",
  ADDED: "Added",
};

export const shifts = {
  DAY: "Day",
  NIGHT: "Night",
};
export const shiftTypes = {
  DAY: "Day",
  NIGHT: "Night",
};

export const shiftOptions = [
  { name: "Show All", shift: '' },
  { name: "Day", shift: shifts.DAY },
  { name: "Night", shift: shifts.NIGHT },
];

export const shiftStatusOptions = [
  { name: "Cat A Failures", status: ShiftStatus.CATA, color: "#F44242" },
  { name: "Cat B Failures", status: ShiftStatus.CATB, color: "#F8A200" },
  { name: "Cat C Failures", status: ShiftStatus.CATC, color: "#4C1A90" },
  { name: "Passed", status: ShiftStatus.PASSED, color: "#3EA884" },
  { name: "Incomplete", status: ShiftStatus.INCOMPLETE, color: "#6F7070" },
];

export const InitialFilters = {
  date: "",
  equipmentType: [],
  status: [],
  query: "",
};
// initialStates.js