import React from "react";
import styles from "../../../EquimentPlan.module.css";
import Kebab from "../../../../../../../../assets/images/kebab.png";
import { IoCloseSharp } from "react-icons/io5";
import { ShiftStatus } from "../../../../../../utils/contants";
import { joinWithAnd } from "../../../../../../../../utils/helper";
import AddEquipmentButton from "../../../components/AddEquipmentButton/AddEquipmentButton";
import ActionMenu from "../../../../../../components/ActionMenu/ActionMenu";
import { getShiftStatus } from "../../../../../../utils/helper";
import './style.css'
import Tooltip from "../../../../../../components/Tooltip/Tooltip";
import { useAddDailyPlan } from "../../../../../../Context/context";

const RowActions = ({
    equipmentViewOnlyData,
    index,
    rowIndex,
    isExcavator,
    handleRemoveAsset,
    setShowError,
    setShowConfirmRemove,
    handleClickViewDetails,
    setShowConfirmReassignTrucks,
    onTruckAddClick,
}) => {
    const {
        shiftStatus,
        assignedExcavatorDetailsFormData,
        canEditShift,
    } = useAddDailyPlan()
    const isShiftEnded = shiftStatus === ShiftStatus.ENDED;

    const handleClickRemove = () => {
        if (isExcavator && assignedExcavatorDetailsFormData[index]?.excavatorHaulTrucks?.length > 0) {
            setShowError({
                show: true,
                title: 'Error Removing Excavator',
                message: 'The excavator you are trying to remove contains trucks assigned to it, please remove all the trucks before removing the excavator',
            });
        } else {
            if (shiftStatus === ShiftStatus.NEW) {
                handleRemoveAsset(rowIndex);
            } else {
                setShowConfirmRemove({
                    show: true,
                    title: `Remove ${equipmentViewOnlyData?.plantId}`,
                    message: `Are you sure you want to remove this ${isExcavator ? "excavator" : "truck"} from the ${getShiftStatus(shiftStatus)} shift?`,
                    truckID: isExcavator ? "" : rowIndex,
                });
            }
        }
    };

    const handleClickReassignTrucks = () => {
        setShowConfirmReassignTrucks({
            show: true,
            title: `${isExcavator ? equipmentViewOnlyData.plantId : assignedExcavatorDetailsFormData[index].excavatorHaulTrucks[rowIndex].label} - Reassign ${isExcavator ? 'Trucks' : 'Truck'}`,
            message: <>
                Are you sure you want to reassign{" "}
                {isExcavator ? (
                    <>
                        trucks{" "}
                        <span style={{ fontWeight: "600" }}>
                            {joinWithAnd(
                                assignedExcavatorDetailsFormData[index].excavatorHaulTrucks.map(
                                    (ht) => ht.label
                                )
                            )}
                        </span>
                    </>
                ) : (
                    "this truck"
                )}{" "}
                to a different excavator?
            </>,
            truck: isExcavator ? '' : assignedExcavatorDetailsFormData[index].excavatorHaulTrucks[rowIndex],
        });
    };

    const getOptions = () => {
        const options = [];
        options.push({ name: 'View Details', onClick: handleClickViewDetails })
        if (isExcavator) {
            if (canEditShift && assignedExcavatorDetailsFormData[index].excavatorHaulTrucks?.length > 1) {
                options.push({ name: 'Reassign Trucks', onClick: handleClickReassignTrucks })
            }
        } else {
            if (canEditShift) {
                options.push({ name: 'Reassign Truck', onClick: handleClickReassignTrucks })
            }
        }
        options.push({ name: 'Download PDF', onClick: () => { }, disabled: true })
        options.push({ name: 'Download CSV', onClick: () => { }, disabled: true })
        return options;
    }

    return (
        <div className={styles.actions}>
            {canEditShift && (
                <Tooltip
                    tooltipContent={"Remove"}
                    toolTipStyle={{ width: '60px' }}
                    alwaysShowOnHover={true}
                    child={<div
                        style={{ cursor: "pointer", userSelect: "none", height: 18, width: 18, borderRadius: '100%' }}
                        className="remove_equipment_table_remove d-flex align-items-center justify-content-center"
                        onClick={(e) => {
                            e.stopPropagation()
                            handleClickRemove(e)
                        }}
                    >
                        <IoCloseSharp size={15} />
                    </div>} />
            )}
            <ActionMenu
                options={getOptions()}
            />
            {isExcavator && canEditShift && <AddEquipmentButton isDisabled={!equipmentViewOnlyData?.material} onClick={() => onTruckAddClick(index)} label={"Trucks"} />}
        </div>
    );
};

export default RowActions;
