import { FunctionComponent } from 'react';
import { IconProps } from '../../helper/types';
const ReportPassedIcon: FunctionComponent<IconProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={props.size ?? '44.499'}
      height={props.size ?? '50.227'}
      viewBox="0 0 36.6 40.573"
    >
      <defs>
        <filter
          id="Icon_metro-security"
          x="0"
          y="0"
          width="36.6"
          height="40.573"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Group_51386"
        data-name="Group 51386"
        transform="translate(-903.501 -6082.596)"
      >
        <g
          transform="matrix(1, 0, 0, 1, 904.5, 6084.1)"
          filter="url(#Icon_metro-security)"
        >
          <path
            id="Icon_metro-security-2"
            data-name="Icon metro-security"
            d="M15.227,3.856l-8.8,3.911v5.867c0,5.432,3.75,10.5,8.8,11.733,5.05-1.237,8.8-6.3,8.8-11.733V7.767Z"
            transform="translate(3.07 3.69)"
            fill="#fff"
            stroke="#2a7b39"
            strokeWidth="1"
          />
        </g>
        <g id="Group_51230" data-name="Group 51230">
          <line
            id="Line_5988"
            data-name="Line 5988"
            x2="3"
            y2="3"
            transform="translate(919.219 6103.643)"
            fill="none"
            stroke="#2a7b39"
            strokeLinecap="round"
            strokeWidth="1.4"
          />
          <line
            id="Line_5989"
            data-name="Line 5989"
            x1="4"
            y2="9"
            transform="translate(922.219 6097.643)"
            fill="none"
            stroke="#2a7b39"
            strokeLinecap="round"
            strokeWidth="1.4"
          />
        </g>
      </g>
    </svg>
  );
};

export default ReportPassedIcon;
