import React, { useEffect } from "react";
import { shiftOptions, shiftStatusOptions, AssetCategories } from "./utils/contants";
import DynamicSelectField from "../../../ShiftPlanning/components/DynamicSelectField/DynamicSelectField";
import DatePickerField from "../../../ShiftPlanning/components/GeneralFields/DateTimeSelect/DateTimeSelect";
import { MdCircle } from "react-icons/md";

const Filter = ({
    filters,
    onChange,
}) => {
    return (
        <div>
            <DatePickerField
                label={"Date"}
                dateOnly={true}
                onChange={(val) => onChange("date", new Date(val).toISOString())}
                value={filters.date ? new Date(filters.date) : new Date()}
            />

            <DynamicSelectField
                label={"Asset Category"}
                placeholder={"Select Asset Category"}
                options={AssetCategories?.map((department) => {
                    return { value: department.type, label: department.name };
                })}
                value={filters.department}
                onChange={(val) => onChange("department", val)}
                isMulti={true}
                selectAll={true}
            />

            <DynamicSelectField
                label={"Status"}
                placeholder={"Select Status"}
                options={shiftStatusOptions?.map((status) => {
                    return { value: status.status, label: status.name, icon: <MdCircle style={{ marginLeft: 8 }} size={12.5} color={status.color} /> };
                })}
                value={filters.status}
                onChange={(val) => onChange("status", val)}
                isMulti={true}
                selectAll={true}
            />
        </div>
    );
};

export default Filter;
