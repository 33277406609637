// WeatherCard.js
import { useState } from 'react';
import Draggable from 'react-draggable';
import Styles from '../WeatherCard/Weather.module.css';
import PartialCloudy from '../../../../../assets/images/Dashboard/Weather/partialCloudy.png'
import WeatherReport from '../WeatherReport/WeatherReport';
import ActionMenu from '../../../../../assets/images/Dashboard/ActionMenu.png';

import CloudyNightLight from "../../../../../assets/images/Dashboard/Weather/cloudyNightLight.png";
import HalfMoonLight from "../../../../../assets/images/Dashboard/Weather/halfMoonLight.png";
import HeavyRainLight from "../../../../../assets/images/Dashboard/Weather/heavyRainLight.png";
import MostlyCloudyLight from "../../../../../assets/images/Dashboard/Weather/mostlyCloudyLight.png";
import SunLight from "../../../../../assets/images/Dashboard/Weather/sunLight.png";

const Weather = ({ handleClose, weatherData }) => {

  const [weatherModalShow, setWeatherModalShow] = useState(false);
  const [isDragging, setIsDragging] = useState(false); // State to track drag

  const fahrenheitToCelsius = (fahrenheit) => {
    return Math.round(((fahrenheit - 32) * 5) / 9);
  }

  // Handle opening the modal only if no drag happened
  const handleWeatherCardClick = () => {
    if (!isDragging) {
      setWeatherModalShow(true); // Open the modal on valid click
    }
  };

  const handleWeatherCardClose = () => {
    setWeatherModalShow(false); // Close the modal
  };

  // Track drag start
  const handleDragStart = () => {
    setIsDragging(false); // Reset drag state on start
  };

  // Set drag state when movement happens
  const handleDrag = () => {
    setIsDragging(true); // Set to true when the card is dragged
  };

  // Ensure drag state is false after a drag ends
  const handleDragStop = () => {
    setTimeout(() => setIsDragging(false), 100); // Small timeout to allow state reset
  };

  const currentForeCast = {
    cloudCover: [weatherData?.dailyForecastData?.cloudCover[0]], // Extract the first value in an array
    temperature: [weatherData?.dailyForecastData?.temperature[0]] // Extract the first value in an array
  };

  const getIconForData = (t, forecast, iconSize) => {
    const timeC = new Date();
    timeC?.setHours(new Date()?.getHours() + t);
    const time = timeC?.getHours();
    console?.log(time, forecast?.cloudCover?.length);
    if (time >= forecast?.cloudCover?.length || !forecast?.cloudCover)
      return <img draggable={false} height={iconSize} src={MostlyCloudyLight} alt="full Cloudy" />;
    else if (forecast?.cloudCover[time] <= 20) {
      if (time > 7 && time < 19)
        return <img draggable={false} height={iconSize} src={SunLight} alt="sun" />;
      else return <img draggable={false} height={iconSize} src={HalfMoonLight} alt="moon" />;
    } else if (forecast?.cloudCover[time] <= 60) {
      if (time > 7 && time < 19)
        return <img draggable={false} height={iconSize} src={MostlyCloudyLight} alt="sunny Cloudy" />;
      else return <img draggable={false} height={iconSize} src={CloudyNightLight} alt="night Cloudy" />;
    } else if (forecast?.cloudCover[time] <= 90) {
      if (time > 7 && time < 19)
        return <img draggable={false} height={iconSize} src={MostlyCloudyLight} alt="cloudy Day" />;
      else return <img draggable={false} height={iconSize} src={CloudyNightLight} alt="cloudy Night" />;
    } else
      return <img draggable={false} height={iconSize} src={MostlyCloudyLight} alt="fully Cloudy" />;
  };

  return (
    < >
      <Draggable
        // bounds="parent"
        onStart={handleDragStart}
        onDrag={handleDrag}
        onStop={handleDragStop}
      >
        <div
          className={Styles.weatherCard}
          onClick={handleWeatherCardClick} // Open modal only if not dragged
        >
          <span onClick={handleClose} className={Styles.weatherClose}>&times;</span>
          <div className="d-flex align-items-center">
            <img draggable={false} height={20} src={ActionMenu} alt='ActionMenu' />
            <div className={Styles.weatherIcon}>
              {/* <img draggable={false} draggable={false} height={80} src={PartialCloudy} alt="PartialCloudy" /> */}
              {getIconForData(0, currentForeCast, 80)}
            </div>
            <div className="d-flex flex-column" style={{ marginLeft: '30px' }}>
              <div className="d-flex flex-column" style={{ paddingBottom: '15px' }}>
                <span className={Styles.weatherDescription}>Cloudy</span>
                <span className={Styles.weatherTemp}>{weatherData?.temp}°</span>
              </div>
              <div className="d-flex flex-column justify-content-between">
                <div className="d-flex justify-content-between">
                  <span className={Styles.weatherLabel}>Rainfall</span>
                  <span className={Styles.weatherVal}>{(weatherData?.rain)?.toFixed(1)} mm</span>
                </div>
                <div className="d-flex justify-content-between">
                  <span className={Styles.weatherLabel}>Dust</span>
                  <span className={Styles.weatherVal}>{weatherData?.dust} ppm</span>
                </div>
                <div className="d-flex justify-content-between">
                  <span className={Styles.weatherLabel}>Wind</span>
                  <span className={Styles.weatherVal}>{weatherData?.wind} km NE</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Draggable>

      {/* Weather Popup - Open when the card is clicked */}
      {weatherModalShow && <WeatherReport onClose={handleWeatherCardClose} weatherData={weatherData} />}
    </>
  );

};

export default Weather;
