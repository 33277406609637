import React, { useState, useEffect, useRef } from "react";
import Select, { components } from "react-select";
import { GoPlus } from "react-icons/go";
import { IoChevronDown } from "react-icons/io5";
import { CiSearch } from "react-icons/ci";
import styles from "./DynamicSelectField.module.css";
import { MdDone } from "react-icons/md";

export default function DynamicSelectField({
  isMulti,
  viewOnly,
  required,
  label,
  value,
  isDisabled,
  error,
  errorMessage,
  className = "",
  onChange,
  options,
  isSearchable,
  placeholder,
  selectAll,
  wrapperClassName = "",
  wrapperStyle = {},
  valueStyle,
  labelStyle
}) {
  const [searchQuery, setSearchQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const Control = (props) => {
    return <div></div>;
  };

  const Option = (props) => {
    return (
      <components.Option {...props}>
        <div className="d-flex align-items-center justify-content-between">
          <div className={styles.optionContainer}>
            {isMulti && (
              <input
                type={"checkbox"}
                style={{ borderRadius: 0 }}
                checked={props.isSelected}
                onChange={() => null}
              />
            )}{" "}
            {props?.data?.icon ?? ""}
            <span className={styles.optionLabel}>{props.label}</span>
          </div>
          {!isMulti && props.isSelected && <MdDone />}
        </div>
      </components.Option>
    );
  };

  useEffect(() => {
    // Function to close dropdown when clicking outside
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div style={{ minHeight: "3.4rem", height: "max-content", marginBottom: "1rem", ...wrapperStyle }} className={"row " + wrapperClassName} >
      {label && <div className="col-md-4 d-flex align-items-center">
        <label
          style={{
            fontSize: "1.25rem",
            fontWeight: '500',
            margin: 0,
            color: "#4B515A",
            ...labelStyle,
          }}
        >
          {label}{required && <span style={{ color: "#6A7C9A" }}>*</span>}
        </label>
      </div>}
      <div className={`col-md-${label ? 8 : 12} ` + className}>
        <div
          ref={dropdownRef}
          style={!viewOnly ? { cursor: "pointer", } : {}}
          className={styles.dropdownContainer}
        >
          {/* Field */}
          <div
            style={{
              border:
                isDisabled ? "none" : ("1px solid " + (error ? "#BA3938" : isOpen ? "#186FD9" : "#E1E8F4")),
              ...(viewOnly ? { color: "#1A1A1A", fontWeight: "600" } : {}),
              minHeight: "3.25rem",
              fontWeight: "600",
              fontSize: "1.3rem",
              height: "max-content",
              color: !viewOnly && isDisabled ? "#B3BBC4" : "#1A1A1A",
              backgroundColor: !viewOnly && isDisabled ? "rgb(240,241,244)" : "transparent",
              ...valueStyle,
            }}
            className={
              "d-flex justify-content-between align-items-center py-1 " +
              (viewOnly ? "border-0 pl-1" : "rounded px-3")
            }
            onClick={() => !viewOnly && setIsOpen(!isOpen)}
          >
            {
              !value && viewOnly ? "-" : (
                isMulti ? (
                  value?.length ? (
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {value === "-" ? "-" : (
                        selectAll && options?.every(op => value?.some(v => v.value === op.value))
                          ? "All"
                          : value?.map((v, index) => (
                            <span className="pr-1 d-flex align-items-center" style={valueStyle} key={index}>
                              {v?.icon && <span className="mr-1" style={{ paddingBottom: 5 }}>{v.icon}</span>}
                              <span>{v.label}{index !== value.length - 1 && ","}</span>
                            </span>
                          ))
                      )}
                    </div>
                  ) : <span>{placeholder || " "}</span>
                ) : (
                  value ? (
                    <span className="d-flex align-items-center" style={valueStyle}>
                      {value?.icon && <span className="mr-1">{value.icon}</span>}
                      {value.label}
                    </span>
                  ) : <span>{placeholder || " "}</span>
                )
              )
            }
            <div style={{ minWidth: 20 }}>
              {!viewOnly && !isDisabled &&
                (isMulti ? <GoPlus size={20} /> : <IoChevronDown />)}
            </div>
          </div>
          {/* CustomMenu */}
          {isOpen && !isDisabled && (
            <div className={styles.menu}>
              {isSearchable && (
                <div
                  className="form-group d-flex align-items-center mb-0 pl-4 pr-3"
                  style={{
                    borderTopLeftRadius: 4,
                    borderTopRightRadius: 4,
                    width: "100%",
                    backgroundColor: "white",
                    cursor: "auto",
                    borderBottom: "1px solid #E1E8F4",
                    height: 40,
                    fontSize: 12.5,
                    borderRadius: 0,
                  }}
                >
                  <CiSearch size={15} />
                  <input
                    placeholder="Search"
                    className="pl-2"
                    style={{
                      height: "100%",
                      fontSize: 12.5,
                      borderRadius: 0,
                      borderTopLeftRadius: 4,
                      borderTopRightRadius: 4,
                      border: 0,
                    }}
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target?.value)}
                    disabled={isDisabled}
                  />
                </div>
              )}
              {selectAll && !searchQuery && isMulti && <div className="d-flex align-items-center mb-0 pr-3"
                style={{
                  borderTopLeftRadius: 4,
                  borderTopRightRadius: 4,
                  width: "100%",
                  backgroundColor: "white",
                  cursor: "default",
                  height: 30,
                  paddingTop: 10,
                  paddingLeft: "1.25rem",
                  fontSize: 12.5,
                  borderRadius: 0,
                }}>
                <input
                  type={"checkbox"}
                  style={{ borderRadius: 0, marginRight: ".75rem" }}
                  checked={(options?.length > 0 && value && options.length === value?.length) ? options.every(op => new Set(value.map(v => v.value)).has(op.value)) : false}
                  onChange={() => {
                    if ((options?.length > 0 && value && options.length === value?.length) ? options.every(op => new Set(value.map(v => v.value)).has(op.value)) : false) {
                      onChange([])
                    } else
                      onChange(options)
                  }}
                />
                <div
                  onClick={() => {
                    if ((options?.length > 0 && value && options.length === value?.length) ? options.every(op => new Set(value.map(v => v.value)).has(op.value)) : false) {
                      onChange([])
                    } else
                      onChange(options)
                  }}
                  style={{
                    fontWeight: '600',
                    fontSize: 14,
                    color: "#6A7C9A"
                  }}>Select All</div>
              </div>}
              <Select
                menuIsOpen
                isMulti={isMulti}
                isDisabled={isDisabled}
                isClearable={false}
                styles={{
                  menuList: (baseStyles) => ({
                    ...baseStyles,
                    maxHeight: 200,
                    overflowY: "scroll",
                  }),
                  menu: (baseStyles) => ({
                    ...baseStyles,
                    marginTop: 0,
                    borderRadius: 0,
                    borderBottomLeftRadius: 4,
                    borderBottomRightRadius: 4,
                    border: "1px solid #E1E8F4",
                    borderTop: 0,
                    width: "calc(100% + 2px)",
                    boxShadow: "1px 10px 8px #80808029",
                    left: -1,
                  }),
                  container: (baseStyles) => ({
                    ...baseStyles,
                    width: "100%",
                  }),
                  option: (baseStyles, state) => ({
                    ...baseStyles,
                    padding: "0px 15px ",
                    backgroundColor: state.isFocused ? "#E1E8F4" : "white",
                    color: "#1A1A1A",
                  }),
                }}
                controlShouldRenderValue={false}
                hideSelectedOptions={false}
                tabSelectsValue={false}
                closeMenuOnSelect={false}
                value={value}
                isSearchable={true}
                onKeyDown={(e) => e.stopPropagation()}
                onChange={(v) => {
                  if (isMulti) {
                    // setSelectedValues(v);
                    onChange(v);
                  } else {
                    // setSingleSelectedValue(v);
                    onChange(v);
                    setIsOpen(false);
                  }
                }}
                components={{
                  Control: Control,
                  IndicatorSeparator: null,
                  DropdownIndicator: GoPlus,
                  Option: Option,
                }}
                options={
                  options?.filter((option) => option?.label?.toString()?.toLowerCase().includes(searchQuery?.toLowerCase()))
                }
              />
            </div>
          )}
        </div>
        <div
          className="d-flex align-items-center pt-1"
          style={{ fontSize: 12, color: "#BA3938", height: error ? 15 : 0 }}
        >
          {error ? errorMessage : ""}
        </div>
      </div>
    </div >
  );
}
