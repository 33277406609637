import moment from "moment";
import { DateTimeFormats } from "../../../utils/contants";
import { CallWithAuth } from "../../../../../action/apiActions";
import { GET_OPERATOR_SHIFT_ASSIGNMENTS } from "../../../../../action/apiPath";
import { toast } from "react-toastify";


export const detectOverlaps = (shiftPlans) => {
    if (!shiftPlans || shiftPlans.length <= 1) return false;
    shiftPlans.sort((a, b) => moment(a.startTime, DateTimeFormats.DATE_TIME_U) - moment(b.startTime, DateTimeFormats.DATE_TIME_U));
    return shiftPlans.some((plan, i) => moment(plan.startTime, DateTimeFormats.DATE_TIME_U).isBefore(moment(shiftPlans[i - 1]?.endTime, DateTimeFormats.DATE_TIME_U)));
};

export const getInitialPlanData = (planIndex, excavator, fetchedData) => {
    return ({
        plan: excavator?.shiftPlans[planIndex]?.plan ?? String.fromCharCode(65 + planIndex),
        plantId: excavator?.plantId,
        category: excavator?.category,
        bcmPerHour: excavator?.bcmPerHour,
        bcmPerCoal: excavator?.bcmPerCoal,
        operator: excavator?.shiftPlans[planIndex]?.operator ?? null,
        assistantOperator: excavator?.shiftPlans[planIndex]?.assistantOperator ?? null,
        trainee: excavator?.shiftPlans[planIndex]?.trainee ?? null,
        material: excavator?.shiftPlans[planIndex]?.material ?? null,
        sourceArea: excavator?.shiftPlans[planIndex]?.sourceArea ?? null,
        dumpArea: excavator?.shiftPlans[planIndex]?.dumpArea ?? [],
        haulTrucks: excavator?.shiftPlans[planIndex]?.haulTrucks?.map((ht) => ({ value: ht?.equipment, label: ht.plantId })) ?? excavator?.excavatorHaulTrucks?.map((ht) => ({ value: ht?.value, label: ht.label })),
        targetBCMs: excavator?.shiftPlans[planIndex]?.actualBCMs ?? 0,
        targetBCMs: excavator?.shiftPlans[planIndex]?.targetBCMs ?? excavator?.shiftPlans[planIndex - 1]?.targetBCMs,
        startTime: excavator?.shiftPlans[planIndex]?.startTime ?? excavator?.shiftPlans[planIndex - 1]?.startTime,
        endTime: excavator?.shiftPlans[planIndex]?.endTime ?? excavator?.shiftPlans[planIndex - 1]?.endTime,
        shiftDuration: excavator?.shiftPlans[planIndex]?.shiftDuration ?? null,
        productiveHours: excavator?.shiftPlans[planIndex]?.productiveHours ?? null,
        activityDescription: excavator?.shiftPlans[planIndex]?.activityDescription || {
            label: "E1-Dig and load trucks (default code)", value: fetchedData?.activityDescriptions?.filter(ad => ad?.equipType == "Excavator")?.find((ad) => ad.activityCode == "E1")?._id
        },
        knownHazards: excavator?.shiftPlans[planIndex]?.knownHazards ?? null,
        startOfShiftNoteToOperator: excavator?.shiftPlans[planIndex]?.startOfShiftNoteToOperator ?? null,
    })
};

export const validateFormData = (data) => {
    if (!data?.plantId || !data?.category || !data?.operator || !data?.material || !data?.sourceArea || !data?.dumpArea?.length || !data?.startTime || !data?.endTime || !data?.productiveHours || !data?.activityDescription) {
        return false;
    }
    const startTime = moment(data?.startTime, DateTimeFormats.DATE_TIME_U);
    const endTime = moment(data?.endTime, DateTimeFormats.DATE_TIME_U);
    return endTime.diff(startTime, 'hour') >= data.productiveHours.value;
};

export const fetchOperatorShiftAssignments = async (formData, generalShiftPlanDetailsFormData) => {
    try {
        const response = await CallWithAuth(
            "GET",
            `${GET_OPERATOR_SHIFT_ASSIGNMENTS}/${formData?.operator?.value}?startTime=${moment(formData?.startTime, DateTimeFormats.DATE_TIME_U).toISOString()}&endTime=${moment(formData?.endTime, DateTimeFormats.DATE_TIME_U).toISOString()}`
        );
        const { status, data } = response.res.data;
        if (status === 200) {
            if (data?.length && data?.[0]?.shift != generalShiftPlanDetailsFormData?.newShiftId) {
                toast.error("Operator already assigned to a different shift", { position: "bottom-right", autoClose: 3000 });
                return false;
            }
            return true;
        }
        throw new Error(`Error fetching operator's other shift details: ${data.message}`);
    } catch (error) {
        throw new Error(`Error fetching operator's other shift details: ${error.message}`);
    }
};

export const findOperatorInAssignments = (assignments, operatorId, startTime, endTime, newLocation, type) => {
    if (!operatorId) {
        return { found: false, locations: [] };
    }
    const locations = [];

    assignments.forEach((assignment, excavatorIndex) => {
        assignment.shiftPlans.forEach((shiftPlan) => {
            const isOperatorInShiftPlan = shiftPlan?.[type]?.value === operatorId &&
                moment(shiftPlan?.startTime, DateTimeFormats.DATE_TIME_U) <= moment(endTime, DateTimeFormats.DATE_TIME_U) &&
                moment(startTime, DateTimeFormats.DATE_TIME_U) <= moment(shiftPlan?.endTime, DateTimeFormats.DATE_TIME_U) && moment() < moment(shiftPlan.endTime, DateTimeFormats.DATE_TIME_U);
            if (isOperatorInShiftPlan) {
                if (newLocation.exIdx != excavatorIndex)
                    locations.push({ type: 'excavator', exPlantId: assignment.plantId, plan: shiftPlan.plan });
            }

            shiftPlan.haulTrucks.forEach((truck) => {

                const isOperatorInTruck = truck?.[type]?.value === operatorId &&
                    moment(truck?.startTime, DateTimeFormats.DATE_TIME_U) <= moment(endTime, DateTimeFormats.DATE_TIME_U) &&
                    moment(startTime, DateTimeFormats.DATE_TIME_U) <= moment(truck?.endTime, DateTimeFormats.DATE_TIME_U) && moment() < moment(truck?.endTime, DateTimeFormats.DATE_TIME_U);
                if (isOperatorInTruck) {
                    locations.push({ type: 'truck', exPlantId: assignment.plantId, plan: shiftPlan.plan, truckPlantId: truck?.plantId });
                }
            });
        });
    });

    return { found: locations.length > 0, locations };
};
