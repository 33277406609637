import { useEffect, useRef, useState } from "react";
import Excavators from "./ExcavatorsSummary/Excavators";
import Performance from "./PerformanceSummary/Performance";
import { Riple } from "react-loading-indicators";
import { Status } from "pages/Dashboard/constant";
import { FaCircleXmark } from "react-icons/fa6";
import { MdDoNotDisturbOn } from "react-icons/md";


function Summary({ isExpanded, tabIdx, isLoading, summaryData, excavatorData, exHourlyResultChange }) {
  const contentRef = useRef(null); // Reference to the collapsible content
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    // Trigger exHourlyResultChange for the default selected button
    if (excavatorData?.length > 0 && !selected) {
      setSelected(0);
      exHourlyResultChange(excavatorData?.[0]?.excavatorName);
    }
  }, [excavatorData]);

  const handleClick = (index, excavatorName) => {
    setSelected(index); // Update the selected button's index
    exHourlyResultChange(excavatorName); // Call the provided function
  };

  if (isLoading)
    return <div style={{ height: '100%' }} className="d-flex align-items-center justify-content-center pb-4">
      {/* <Riple color="#317bcc" size="medium" text="" textColor="" /> */}
    </div>
  return (
    <div
      ref={contentRef} // Reference the content for height calculation
      className="d-flex align-items-center"
      style={{
        overflow: isExpanded ? 'visible' : 'hidden',
        paddingBottom: isExpanded ? 0 : 20,
        transition: "height 0.3s ease,padding 0.3s ease,overflow 0.35 ease"
      }}
    >
      {tabIdx === 1 && (
        <div
          style={{
            height: isExpanded ? 300 : 0,
            transition: "height 0.3s ease,padding 0.3s ease,overflow 0.35 ease",
            overflow: !isExpanded ? "hidden" : 'visible',
            paddingBottom: isExpanded ? 12 : 0
          }}
          className="d-flex justify-content-between align-items-center px-5 w-100"
        >
          <Performance data={summaryData} />
          <Excavators headerLabel={"Excavators"} summaryData={summaryData?.excavatorStatus} />
          <Excavators headerLabel={"Trucks"} summaryData={summaryData?.truckStatus} />
        </div>)}

      {tabIdx === 2 && (
        <div
          style={{ height: isExpanded ? 80 : 0, transition: "height 0.3s ease", overflow: "hidden", }}
          className="d-flex justify-content-between align-items-center px-5 w-100"
        >
          <div className="d-flex justify-content-between align-items-center">

            {excavatorData?.map((ex, index) => (
              <div key={index} className="mr-2">
                <div
                  className="btn px-4 py-2"
                  style={{
                    backgroundColor: selected === index ? "#E8F2FD" : "transparent",
                    border: selected === index ? "1px solid #E8F2FD" : "1px solid #EBEBEB",
                    borderRadius: "15px",
                    font: "normal normal bold 12px/17px Open Sans",
                    color: "#006BED",
                    transition: "background-color 0.3s ease, color 0.3s ease"
                  }}
                  onClick={() => {
                    handleClick(index, ex?.excavatorName)
                  }}
                >{ex?.excavatorName}
                  {ex?.status === Status.SHIFTENDED && (<FaCircleXmark className="ml-2 mb-1" color="#000000" size={12} />)}
                  {ex?.status === Status.NOTOPERATING && (<MdDoNotDisturbOn className="ml-2 mb-1" color="red" size={12} />)}  </div>
              </div>

            ))}
          </div>

        </div>)}
    </div>
  );
}

export default Summary;
