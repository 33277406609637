import React from 'react'

const SHIFT_TYPES = [
    { name: "Day", value: 'day' },
    { name: "Night", value: 'night' },
];

const DayNightSwitch = ({ value, onChange }) => {
    return (
        <div
            className="d-flex"
            style={{
                backgroundColor: "rgb(240,244,251)",
                borderRadius: "45%",
            }}
        >
            {
                SHIFT_TYPES.map((e, index) => (
                    <div
                        style={{
                            backgroundColor:
                                value === e.value
                                    ? "black"
                                    : "rgb(240,244,251)",
                            color: value === e.value ? "white" : "#96a4bb",
                            borderWidth: "0px",
                            cursor: "pointer",
                            fontSize: "1rem",
                            fontWeight: "600",
                            padding: "0px 1.3rem",
                            borderRadius: "15px",
                        }}
                        key={index}
                        name="radio"
                        value={e.value}
                        onClick={() => {
                            onChange(e.value);
                        }}
                    >
                        {e.name}
                    </div>
                ))
            }
        </div>
    )
}

export default DayNightSwitch