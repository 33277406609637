export const Status = {
  REASSIGNED: "REASSIGNED",
  REMOVED: "REMOVED",
  ASSIGNED: "ASSIGNED",
  UNASSIGNED: "UNASSIGNED",
  UNSCHEDULED: "UNSCHEDULED",
  NOTOPERATING: "NOTOPERATING",
  SHIFTENDED: "SHIFTENDED",
};


export const ShiftStatusEnum = {
    ONGOING: 'O',
    PUBLISHED: 'P',
    ENDED: 'E',
  };