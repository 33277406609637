import React, { useEffect } from "react";
import moment from "moment";
import {
  clipString,
  convertHoursToHHMM,
  getDurationBetweenMomentsInHHMM,
  highlightText,
  searchExist,
} from "../../utils/helper";
import { DateTimeFormats, ShiftStatus } from "../../utils/contants";
import Tooltip from "../../components/Tooltip/Tooltip";
import ActionMenu from "../../components/ActionMenu/ActionMenu";
import { useAddDailyPlan } from "../../Context/context";

const ShiftHeader = ({
  viewDataOfGeneralShiftDetails,
  searchQuery,
  handleSearchCount,
}) => {

  const {
    isGeneralShiftFlyerOpen,
    openFlyer,
    loading
  } = useAddDailyPlan();


  useEffect(() => {
    const countMatches = (data, query) => {
      let count = 0;
      query = query.toLowerCase();

      const checkMatch = (value) => {
        if (value && query) {
          value = value.toLowerCase();
          const regex = new RegExp(query, 'g');
          const matches = value.match(regex);
          if (matches) {
            count = count + matches.length;
          }
        }
      };

      checkMatch(data?.annualLeave);
      checkMatch(data?.sickLeave);
      checkMatch(data?.absent);

      return count;
    };
    handleSearchCount(countMatches(viewDataOfGeneralShiftDetails, searchQuery))
  }, [searchQuery]);


  const renderDetail = (label, value) => (
    <div className="d-flex ">
      <div style={{ fontSize: "12px", width: 100 }}>
        <span>{label}: </span>
      </div>
      <div style={{ width: "calc(100% - 100px)" }}>
        <span>
          <b>{value || "-"}</b>
        </span>
      </div>
    </div>
  );

  const getOptions = () => {
    const options = [];
    if (viewDataOfGeneralShiftDetails?.status === ShiftStatus.NEW) {
      options.push({ name: 'View Details', onClick: () => openFlyer('general', true) })
    } else {
      options.push({ name: 'View Details', onClick: () => openFlyer('general', true) })
      options.push({ name: 'Download PDF', onClick: () => { }, disabled: true })
      options.push({ name: 'Download CSV', onClick: () => { }, disabled: true })
    }
    return options;
  }

  return (
    <div className="col-12" style={{ fontSize: 12, border: "1px solid " + (isGeneralShiftFlyerOpen ? "#186FD9" : "#E1E8F4"), borderRadius: 10 }}>
      <div className="row">
        <div
          className="col-lg-12 col-md-12 col-sm-12"
          style={{
            borderBottom: "solid 0.1rem #e1e8f4", background: isGeneralShiftFlyerOpen ? "#E2ECFC" : "#f2f7ff", float: "left", fontSize: "16px", fontWeight: "bold", padding: "1rem 1.4rem",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <b>Shift Details</b>
          <ActionMenu options={loading ? [] : getOptions()} />
        </div>
      </div>
      <div className="row" onClick={() => !loading && openFlyer('general', true)}>
        <div
          className="col-lg-1 col-md-4 col-sm-6"
          style={{ borderRight: "solid 0.1rem #e1e8f4", float: "left", minWidth: "max-content" }}
        >
          {renderDetail("Ref No", viewDataOfGeneralShiftDetails?.reportNo)}
          {renderDetail("Mine", viewDataOfGeneralShiftDetails?.mine)}
          {renderDetail("Shift", viewDataOfGeneralShiftDetails?.shiftType)}
          {renderDetail("Material", viewDataOfGeneralShiftDetails?.materialType)}
        </div>
        <div
          className="col-lg-1 col-md-4 col-sm-6"
          style={{ borderRight: "solid 0.1rem #e1e8f4", float: "left", minWidth: "max-content" }}
        >
          {renderDetail("Department", viewDataOfGeneralShiftDetails?.department)}
          {renderDetail("Crew", viewDataOfGeneralShiftDetails?.crew)}
          {renderDetail("Shift Start", viewDataOfGeneralShiftDetails?.startTime)}
        </div>
        <div
          className="col-lg-1 col-md-4 col-sm-6"
          style={{ borderRight: "solid 0.1rem #e1e8f4", float: "left", minWidth: "max-content" }}
        >
          {renderDetail("Shift End", viewDataOfGeneralShiftDetails?.endTime)}
          {renderDetail("Shift Duration", getDurationBetweenMomentsInHHMM(moment(viewDataOfGeneralShiftDetails?.startTime, DateTimeFormats.DATE_TIME_U), moment(viewDataOfGeneralShiftDetails?.endTime, DateTimeFormats.DATE_TIME_U)))}
          {renderDetail("Productive hrs", convertHoursToHHMM(viewDataOfGeneralShiftDetails?.productiveHours) + " hrs")}
        </div>
        <div
          className="col-lg-2 col-md-4 col-sm-6"
          style={{ borderRight: "solid 0.1rem #e1e8f4", float: "left", minWidth: "max-content" }}
        >
          {renderDetail("Shift Supervisor", viewDataOfGeneralShiftDetails?.shiftSupervisor)}
          {renderDetail("Leading Hand", viewDataOfGeneralShiftDetails?.leadingHand)}
          {renderDetail("Created By", viewDataOfGeneralShiftDetails?.createdAt
            ? `${viewDataOfGeneralShiftDetails?.createdBy} (${viewDataOfGeneralShiftDetails?.createdAt})`
            : viewDataOfGeneralShiftDetails?.createdBy)}
        </div>
        <div style={{ minWidth: "max-content" }} className="col-lg-3 col-md-4 col-sm-6">
          {renderDetail(
            "Annual Leave",
            <Tooltip

              tooltipContent={highlightText(viewDataOfGeneralShiftDetails?.annualLeave, searchQuery)}
              child={
                highlightText(clipString(viewDataOfGeneralShiftDetails?.annualLeave, 50), searchExist(viewDataOfGeneralShiftDetails?.annualLeave, searchQuery, 50) ? clipString(viewDataOfGeneralShiftDetails?.annualLeave, 50) : searchQuery)
              }

            />
          )}
          {renderDetail(
            "Sick Leave",
            <Tooltip
              tooltipContent={highlightText(viewDataOfGeneralShiftDetails?.sickLeave, searchQuery)}
              child={
                highlightText(clipString(viewDataOfGeneralShiftDetails?.sickLeave, 50), searchExist(viewDataOfGeneralShiftDetails?.sickLeave, searchQuery, 50) ? clipString(viewDataOfGeneralShiftDetails?.sickLeave, 50) : searchQuery)
              }

            />
          )}
          {renderDetail(
            "Absent",
            <Tooltip
              tooltipContent={highlightText(viewDataOfGeneralShiftDetails?.absent, searchQuery)}
              child={
                highlightText(clipString(viewDataOfGeneralShiftDetails?.absent, 50), searchExist(viewDataOfGeneralShiftDetails?.absent, searchQuery, 50) ? clipString(viewDataOfGeneralShiftDetails?.absent, 50) : searchQuery)
              }
            />,
            true
          )}
        </div>
      </div>
    </div>
  );
};

export default ShiftHeader;
