import { useContext, useEffect, useState, useRef } from "react";
import { Form, Image, Dropdown } from "react-bootstrap";
import { FiDownload } from "react-icons/fi";
import DashboardContext from "../Context/DashboardContext";
// import { generateShiftHistoryPDF } from './download';
import { formatDate } from "./helper";
import Modal from "./components/Modal";
import { CSVLink } from "react-csv";
import Button from "../../../components/Buttons";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import TrendArrow from "../../../components/TrendArrow";
import LoadingScreen from "../../../components/LoadingScreen";

function getHourMinuteDifference(start, end) {
  const diffInMs = Math.abs(end - start);
  const hours = Math.floor(diffInMs / (1000 * 60 * 60));
  const minutes = Math.floor((diffInMs / (1000 * 60)) % 60);
  const formattedTime = `${hours.toString().padStart(2, "0")}: ${minutes
    .toString()
    .padStart(2, "0")} hrs`;
  return formattedTime;
}

export default function DumpCycleTracker() {
  const {
    dumpCycleTrackerData,
    shiftChoiceForDumpTracker,
    setShiftChoiceForDumpTracker,
    dateForDumpTracker,
    fallingBehind,
    dateForDumpTrackerRef,
    setDateForDumpTracker,
    setDumpCycleTrackerData,
    dumpTrackerLoading
  } = useContext(DashboardContext);
  const [searchQuery, setSearchQuery] = useState();
  const [selectedExcavator, setSelectedExcavator] = useState(0);
  const [selectedTruck, setSelectedTruck] = useState(0);
  const [selectedUnit, setSelectedUnit] = useState(undefined);
  const mainContainer = useRef();

  const removeSpaces = (val) => {
    if (val) {
      return val.replaceAll(/\s/g, '');
    } else {
      return '';
    }
  };

  const getCSVData = (intervals) =>
    intervals
      ? intervals?.flatMap((interval) =>
        interval?.loads?.map((load) => ({
          Load: load.name,
          "Cycle Duration": `${formatDate(
            load?.sourceArivalTime
          )} - ${formatDate(load?.sourceReturningTime, "00:00 AM")} (${load?.sourceArivalTime && load?.sourceReturningTime
            ? getHourMinuteDifference(
              new Date(load?.sourceArivalTime),
              new Date(load?.sourceReturningTime)
            )
            : "00:00 hrs"
            })`,
          Source: load.sourceAtStart,
          Excavator: load.contactTitle,
          Material: load.material,
          Dump: load.dumpPoint,
          Operator: load.operator,
        }))
      )
      : [];

  const generatePDF = () => {
    const input = mainContainer.current;
    html2canvas(input).then((canvas) => {
      const pdf = new jsPDF({
        unit: "px",
        format: [canvas.width, Math.max(canvas.height, 1800)],
      });
      const imgData = canvas.toDataURL("image/png");

      pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);
      pdf.save(
        "Dump_Cycle_Tracker_Data " +
        dumpCycleTrackerData?.[shiftChoiceForDumpTracker]?.excavators[
          selectedExcavator
        ]?.excavatorName +
        "_" +
        dumpCycleTrackerData?.[shiftChoiceForDumpTracker]?.excavators[
          selectedExcavator
        ]?.trucks[selectedTruck]?.truckName +
        ".pdf"
      );
    });
  };

  useEffect(() => {
    setSelectedUnit(undefined);
  }, [selectedExcavator, selectedTruck, shiftChoiceForDumpTracker]);
  useEffect(() => {
    setSelectedExcavator(0);
    setSelectedTruck(0);
  }, [shiftChoiceForDumpTracker]);

  return (
    <>
      {dumpTrackerLoading && <LoadingScreen />}
      <section
        style={{
          backgroundColor: "#FFF",
          height: "11rem",
          padding: "0 2rem 0 2rem",
          marginTop: "8px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex" }}>
            {dumpCycleTrackerData?.[shiftChoiceForDumpTracker]?.excavators?.map(
              (excavator, i) => (
                <div key={i}>
                  <Button
                    onClick={() => setSelectedExcavator(i)}
                    label={removeSpaces(excavator?.excavatorName)}
                    hold={
                      shiftChoiceForDumpTracker == 0 && excavator?.breakdown
                    }
                    alert={
                      shiftChoiceForDumpTracker == 0 &&
                      fallingBehind?.excavators?.includes(
                        excavator?.excavatorName
                      )
                    }
                    style={{
                      margin: "0 4px",
                      border:
                        "1px solid " +
                        (selectedExcavator == i ? "#7da4db" : "#C1D3EF"),
                      backgroundColor:
                        selectedExcavator == i ? "#E2ECFC" : "transparent",
                    }}
                  />
                </div>
              )
            )}
          </div>
          <div className="my-2 d-flex">
            <div className="mx-2">
              <CSVLink
                data={
                  dumpCycleTrackerData
                    ? getCSVData(
                      dumpCycleTrackerData?.[shiftChoiceForDumpTracker]
                        ?.excavators[selectedExcavator]?.trucks[selectedTruck]
                        ?.intervals
                    )
                    : []
                }
                filename={
                  "Dump_Cycle_Tracker_Data " +
                  dumpCycleTrackerData?.[shiftChoiceForDumpTracker]?.excavators[
                    selectedExcavator
                  ]?.excavatorName +
                  "_" +
                  dumpCycleTrackerData?.[shiftChoiceForDumpTracker]?.excavators[
                    selectedExcavator
                  ]?.trucks[selectedTruck]?.truckName
                }
                target="_blank"
              >
                <FiDownload
                  onClick={generatePDF}
                  style={{
                    color: "black",
                    cursor: "pointer",
                    backgroundColor: "#E2ECFC",
                    borderRadius: "10%",
                    padding: ".25rem",
                    width: "2.3rem",
                    position: "relative",
                    right: "0px",
                    height: "2.4rem",
                    border: "1.5px solid rgb(225,232,244)",
                  }}
                />
              </CSVLink>
            </div>
            <div>
              <Dropdown className="d-flex">
                <Dropdown.Toggle
                  style={{ height: "2.5rem", width: "6rem" }}
                  bsPrefix="custom-toggle"
                  id=""
                >
                  Legend
                </Dropdown.Toggle>

                <Dropdown.Menu bsPrefix="dropdown-menu custom-menu2">
                  {/* <Dropdown.Item
                    className="d-flex align-items-center justify-content-start"
                    disabled
                  >
                    <div
                      className="d-flex justify-content-center"
                      style={{ width: "18px", marginRight: 7 }}
                    >
                      <div
                        style={{
                          width: "0px",
                          height: "15px",
                          border: " 1.6px solid #000000",
                          borderRadius: "7px",
                        }}
                      />
                    </div>
                    <div className="dashboard__header__bar__label">Target</div>
                  </Dropdown.Item> */}
                  <Dropdown.Item
                    className="d-flex align-items-center justify-content-start"
                    disabled
                  >
                    <div
                      style={{
                        background: "#95C365 0% 0% no-repeat padding-box",
                      }}
                      className="dashboard__header__bar"
                    />
                    <div className="dashboard__header__bar__label">
                      Target Achieved
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="d-flex align-items-center justify-content-start"
                    disabled
                  >
                    <div
                      style={{
                        background: "#ED7C7F 0% 0% no-repeat padding-box",
                      }}
                      className="dashboard__header__bar"
                    />
                    <div className="dashboard__header__bar__label">
                      Below Target
                    </div>
                  </Dropdown.Item>
                  {/* <Dropdown.Item
                    className="d-flex align-items-center justify-content-start"
                    disabled
                  >
                    <div
                      style={{
                        background: "#707070 0% 0% no-repeat padding-box",
                      }}
                      className="dashboard__header__bar"
                    />
                    <div className="dashboard__header__bar__label">
                      Unsheduled
                    </div>
                  </Dropdown.Item> */}
                  <Dropdown.Item
                    className="d-flex align-items-center justify-content-start"
                    disabled
                  >
                    <Image
                      src={require("../../../assets/images/not-available.png")}
                      height={18}
                      width={18}
                      style={{ marginRight: 7 }}
                    />
                    <div className="dashboard__header__bar__label">
                      {/* Not operating */}
                      Breakdown
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="d-flex align-items-center justify-content-start"
                    disabled
                  >
                    <Image
                      src={require("../../../assets/images/alert.png")}
                      height={16}
                      width={18}
                      style={{ marginRight: 7 }}
                    />
                    <div className="dashboard__header__bar__label">
                      Falling behind
                    </div>
                  </Dropdown.Item>
                  {/* <Dropdown.Item
                    className="d-flex align-items-center justify-content-start"
                    disabled
                  >
                    <div
                      className="d-flex justify-content-center"
                      style={{ width: "18px", marginRight: 7 }}
                    >
                      <TrendArrow backward={true} />
                    </div>
                    <div className="dashboard__header__bar__label">
                      Backward trend
                    </div>
                  </Dropdown.Item> */}
                  {/* <Dropdown.Item
                    className="d-flex align-items-center justify-content-start"
                    disabled
                  >
                    <div
                      className="d-flex justify-content-center"
                      style={{ width: "18px", marginRight: 7 }}
                    >
                      <TrendArrow backward={false} />
                    </div>
                    <div className="dashboard__header__bar__label">
                      Upward trend
                    </div>
                  </Dropdown.Item> */}
                  <Dropdown.Item
                    className="d-flex align-items-center justify-content-start"
                    disabled
                  >
                    <div
                      style={{
                        background: "#BFD4F9 0% 0% no-repeat padding-box",
                      }}
                      className="dashboard__header__bar"
                    />
                    <div className="dashboard__header__bar__label">
                      Ongoing cycle{" "}
                      <span style={{ fontSize: "10px" }}>
                        (on hourly results)
                      </span>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>

        <div
          style={{
            overflowX: "auto",
            display: "flex",
            justifyContent: "space-between",
            marginTop: "15px",
          }}
        >
          <div style={{ overflowX: "auto", display: "flex" }}>
            {dumpCycleTrackerData?.[shiftChoiceForDumpTracker]?.excavators[
              selectedExcavator
            ]?.trucks?.map((truck, i) => (
              <Button
                key={i}
                onClick={() => setSelectedTruck(i)}
                label={removeSpaces(truck?.truckName)}
                alert={
                  shiftChoiceForDumpTracker == 0 &&
                  fallingBehind?.trucks?.includes(truck?.truckName)
                }
                hold={shiftChoiceForDumpTracker == 0 && truck?.breakdown}
                style={{
                  fontSize: "1.25rem",
                  width: "10rem",
                  height: "2.5rem",
                  margin: "0 4px",
                  fontSize: "1rem",
                  color: selectedTruck == i ? "#1A1A1A" : "#576B8D",
                  border:
                    "1px solid " + (selectedTruck == i ? "#7da4db" : "#C1D3EF"),
                  backgroundColor:
                    selectedTruck == i ? "#E2ECFC" : "transparent",
                }}
              />
            ))}
          </div>
          <ul className="my-2 shift-history-toolbar">
            <li>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: "1px solid #dde5f3",
                  padding: "4px 12px",
                  borderRadius: "40px",
                  width: "15rem",
                  height: "2.5rem",

                  backgroundColor: "white",
                }}
              >
                <input
                  type="search"
                  className="shadow-none"
                  value={searchQuery}
                  placeholder="Search"
                  style={{
                    border: "none",
                    font: "normal normal normal 1rem Open Sans",
                    width: "125px",
                  }}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <i
                  style={{ fontSize: "13px" }}
                  className="fa-regular fa-magnifying-glass"
                ></i>
              </div>
            </li>
            <li
              style={{
                // position: "absolute",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={{ width: "15rem" }}>
                <input
                  type="date"
                  className="form-control date-picker"
                  value={dateForDumpTracker}
                  style={{
                    borderRadius: "50%",
                  }}
                  placeholder="DD/MM/YYYY"
                  onChange={(e) => {
                    setDumpCycleTrackerData([])
                    setDateForDumpTracker(e.target.value);
                    dateForDumpTrackerRef.current = e.target.value;
                  }}
                />
              </div>
            </li>
            <li>
              <div
                className="m-3 d-flex"
                style={{
                  backgroundColor: "rgb(240,244,251)",
                  borderRadius: "45%",
                }}
              >
                <div
                  style={{
                    backgroundColor:
                      shiftChoiceForDumpTracker == 0
                        ? "black"
                        : "rgb(240,244,251)",
                    color: shiftChoiceForDumpTracker == 0 ? "white" : "#96a4bb",
                    borderWidth: "0px",
                    cursor: "pointer",
                    fontSize: "1rem",
                    fontWeight: "600",
                    padding: "0px 1.3rem",
                    borderRadius: "15px",
                  }}
                  key={0}
                  name="radio"
                  value={0}
                  onClick={(e) => {
                    setShiftChoiceForDumpTracker(0);
                  }}
                >
                  Day
                </div>
                <div
                  style={{
                    backgroundColor:
                      shiftChoiceForDumpTracker == 1
                        ? "rgb(20,36,62)"
                        : "rgb(241,244,251)",
                    color: shiftChoiceForDumpTracker == 1 ? "white" : "#96a4bb",
                    borderWidth: "0px",
                    cursor: "pointer",
                    fontSize: "1rem",
                    fontWeight: "600",
                    padding: "0px 1rem",
                    borderRadius: "15px",
                  }}
                  key={1}
                  name="radio"
                  value={1}
                  onClick={(e) => {
                    setShiftChoiceForDumpTracker(1);
                  }}
                >
                  Night
                </div>
              </div>
            </li>
            {/* <li style={{ display: 'flex', alignItems: 'center', marginLeft: 15 }}>
           
            </li> */}
          </ul>
        </div>


      </section>
      <main
        ref={mainContainer}
        style={{
          position: "relative",
        }}
      >
        <table
          style={{ backgroundColor: "#fff" }}
          className="shift-history-table"
        >
          <thead>
            <tr style={{ fontWeight: "600" }}>
              <th style={{ width: "7.5%" }}>Load</th>
              <th style={{ width: "18%" }}>Cycle Duration</th>
              <th style={{ width: "8.5%" }}>Source</th>
              <th style={{ width: "7%" }}>Excavator</th>
              <th style={{ width: "8.5%" }}>Material</th>
              <th style={{ width: "9%" }}>Dump</th>
              <th style={{ width: "10%" }}>Operator</th>
              {/* <th /> */}
            </tr>
          </thead>
        </table>

        {selectedUnit && (
          <Modal
            selectedUnit={selectedUnit}
            setSelectedUnit={setSelectedUnit}
          />
        )}
      </main>
      <main
        ref={mainContainer}
        style={{
          position: "relative",
          maxHeight: "calc(100% - 17.5rem)",
          overflowY: "scroll",
        }}
      >
        <table
          style={{ backgroundColor: "#fff" }}
          className="shift-history-table"
        ><thead>
            <tr style={{ fontWeight: "600" }}>
              <th style={{ width: "7.5%" }}></th>
              <th style={{ width: "18%" }}> </th>
              <th style={{ width: "8.5%" }}></th>
              <th style={{ width: "7%" }}></th>
              <th style={{ width: "8.5%" }}></th>
              <th style={{ width: "9%" }}></th>
              <th style={{ width: "10%" }}></th>
              {/* <th /> */}
            </tr>
          </thead>
          <tbody>
            {/* {dumpCycleTrackerData?.[shiftChoiceForDumpTracker]?.excavators[
              selectedExcavator
            ]?.trucks[selectedTruck]?.intervals
              ?.filter(
                (interval) =>
                  interval?.loads?.length > 0 &&
                  (interval?.loads.find((load) =>
                    load?.operator
                      ?.toLowerCase()
                      ?.includes(searchQuery?.toLowerCase())
                  ) ||
                    !searchQuery)
              )
              .map((interval, i) => ( */}
            {dumpCycleTrackerData?.[shiftChoiceForDumpTracker]?.excavators[
              selectedExcavator
            ]?.trucks[selectedTruck]?.intervals
              .map((interval, i) => (
                <>
                  <tr style={{ borderBottom: "none" }} key={`first_${i}`}>
                    <th colSpan={8} style={{ padding: "1.5rem 0 1.2rem 4rem" }}>
                      <span
                        className="px-4 py-2"
                        style={{
                          background: "#707070",
                          width: "max-content",
                          padding: "2px 3px",
                          borderRadius: "1.25rem",
                          backgroundColor: "#00214d",
                          color: "white",
                          font: "normal normal bold 12px/17px Open Sans",
                          fontSize: "1rem",
                        }}
                      >
                        {interval?.formatedInterval}
                      </span>
                      <span
                        className="px-4 py-2"
                        style={{
                          background: "#707070",
                          width: "max-content",
                          padding: "2px 3px",
                          borderRadius: "1.25rem",
                          marginLeft: "15px",
                          backgroundColor:
                            interval.loads.length >= interval.target
                              ? "#ADE5C0"
                              : "#F2B5B5",
                          color: "black",
                          font: "normal normal bold 12px/17px Open Sans",
                          fontSize: "1rem",
                        }}
                      >
                        {interval.loads.length} / {interval.target}
                      </span>
                    </th>
                  </tr>

                  {interval.loads
                    ?.filter(
                      (load) =>
                        load?.operator
                          ?.toLowerCase()
                          ?.includes(searchQuery?.toLowerCase()) || !searchQuery
                    )
                    .map((load, j) => (
                      <tr
                        hover={1}
                        key={`${i}_${j}`}
                        onClick={() => setSelectedUnit(load)}
                        style={{ fontWeight: "400", fontSize: "1.1rem" }}
                      >
                        <th>{load?.name}</th>
                        <td>
                          {formatDate(load?.sourceArivalTime)} -{" "}
                          {formatDate(load?.sourceReturningTime, "00:00 AM")} (
                          {load?.sourceArivalTime && load?.sourceReturningTime
                            ? getHourMinuteDifference(
                              new Date(load?.sourceArivalTime),
                              new Date(load?.sourceReturningTime)
                            )
                            : "00:00 hrs"}
                          )
                        </td>
                        <td>{load.sourceAtStart ? `Area ${load.sourceAtStart}` : '-'}</td>
                        <td>{load.contactTitle}</td>
                        <td>{load.material || "-"}</td>
                        <td>{load.dumpPoint || '-'}</td>
                        <td>{load.operator || '-'}</td>
                        {/* <td
                          onClick={(event) => {
                            event.stopPropagation();
                            s(excavator);
                          }}
                          style={{ float: "right" }}
                        >
                          <CSVLink
                      data={getSingleCSVData(excavator)}
                      filename={excavator.name}
                      target="_blank"
                    >
                          <FiDownload
                            className="shift-history-hover"
                            style={{
                              color: "black",
                              width: "1.8rem",
                              height: "1.8rem",
                            }}
                          />
                          </CSVLink>
                        </td> */}
                      </tr>
                    ))}
                  {
                    interval.loads
                      ?.filter(
                        (load) =>
                          load?.operator
                            ?.toLowerCase()
                            ?.includes(searchQuery?.toLowerCase()) || !searchQuery
                      ).length == 0 ?
                      <tr
                        hover={1}
                        key={`last_${i}`}
                        style={{ fontWeight: "400", fontSize: "1.1rem" }}
                      ><td></td></tr> : ''
                  }
                </>
              ))}
          </tbody>
        </table>

        {selectedUnit && (
          <Modal
            selectedUnit={selectedUnit}
            setSelectedUnit={setSelectedUnit}
          />
        )}
      </main>
    </>
  );
}
