import DynamicSelectField from "pages/DelayAndActivity/Component/GeneralFields/DynamicSelectField/DynamicSelectField";
import FromToTimeSpan from "pages/DelayAndActivity/Component/GeneralFields/FromToTimeSpan/FromToTimeSpan";
import { useActivityContext } from "../context/context";

const EditActivityComponent = ({ showError,formData, setFormData }) => {
  const {
    activityTypeList,
  } = useActivityContext();

  const onChange = (field, val) => {
    setFormData((prev) => ({ ...prev, [field]: val }))
  }

  return (
    <div className="px-5" >

      <DynamicSelectField
        label={"Activity Type"}
        placeholder={"Select Activity Type"}
        options={activityTypeList}
        value={formData?.activityType}
        onChange={(val) => {
          onChange("activityType", val)
        }}
        required
        error={showError && !formData?.activityType}
        errorMessage={"Please select a activity type"}

        style={{
          background: "#FFFFFF",
          border: "1px solid #E1E8F4",
          borderRadius: "4px",
          marginTop: "10px",
          font: "normal normal 600 14px/19px Open Sans"
        }}
        labelStyle={{
          font: "normal normal normal 14px/17px Open Sans",
          color: "#1A1A1A",
        }}
        valueStyle={{
          font: "normal normal 600 14px/19px Open Sans",
          color: "#1A1A1A"
        }}
      />

      <FromToTimeSpan
        label={"Activity Duration"}
        onChange={(key, val) => {
          onChange(key, val)
        }}
        labelStyle={{
          font: "normal normal normal 14px/17px Open Sans",
          color: "#1A1A1A",
          marginTop: "10px",
        }}
        valueStyle={{
          font: "normal normal 600 14px/19px Open Sans",
          color: "#1A1A1A"
        }}
        startTime={formData?.startTime}
        endTime={formData?.endTime}
        required
        error={showError && (!formData?.startTime ||!formData?.endTime ) }
        errorMessage={"Please select a activity duration"}
      />
    </div>);
}

export default EditActivityComponent