const AssetBadge = ({ value, color, bgColor, font, containerStyle = {}, className = "" }) => {
  return (
    <div className={className || "px-3 py-2"}
      style={{
        textAlign: "center",
        font: font,
        color: color,
        backgroundColor: bgColor ?? "#D8E5FF",
        borderRadius: "15px",
        display: "inline-block",
        border: "1px solid #EBEBEB",
        userSelect: 'none',
        ...containerStyle,
      }}
    >
      {value}
    </div>
  );
}
export default AssetBadge