import React from "react";
import ReactECharts from "echarts-for-react";

const BarChart = ({
  chartData,
  customColor,
  targetValue = 0,
  verticalGrid = false,
  horizontalGrid = true,
  showTargetLine = true,
  gradient,
  yAxisLabel,
  maxBufferConstant = 5,

}) => {

  const xAxisData = chartData.map((item) => item.xAxisData); // Extract x-axis labels
  const yAxisData = chartData.map((item) => item.yAxisData); // Extract y-axis values
  const yAxisMax = Math.max(...yAxisData, targetValue ?? 0) + (targetValue ? (Math.round(targetValue / 50) * 10) || (Math.round(targetValue / 5) || 1) : 0);

  const option = {
    xAxis: {
      type: "category",
      data: xAxisData, // Extract x-axis labels

      axisLine: {
        show: true,
        lineStyle: {
          type: "dashed", // Dashed line for the x-axis
          color: "#ccc",
        },
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        show: true,
        font: "normal normal normal 12px/8px Open Sans",
        color: "#808080",
        opacity: 1,
      },
      splitLine: { show: verticalGrid }, // No vertical dashed grid lines
    },
    yAxis: {
      type: "value",
      min: 0,
      max: yAxisMax,
      // max: 5,
      interval: (targetValue || yAxisMax) ? (Math.round((targetValue || yAxisMax) / 50) * 10) || (Math.round((targetValue || yAxisMax) / 5) || 1) : 1,
      name: yAxisLabel ?? "BCM", // Add 'BCM' label on y-axis
      nameLocation: "middle", // Position the label in the middle of the axis
      nameTextStyle: {
        font: "normal normal normal 10px/8px Open Sans",
        color: "#728BB5",
        opacity: 1,
        padding: [0, 0, 40, 0], // Adjust spacing from the axis
      },
      splitLine: {
        show: horizontalGrid, // Only horizontal dashed lines
        lineStyle: {
          type: "dashed",
          color: "#ccc",
        },
      },
      minorSplitLine: {
        show: true, // Show minor dashed lines
        lineStyle: { type: "dashed", color: "#aaa" },
      },
      minorTick: {
        show: false,
        splitNumber: 2, // 4 minor ticks between each interval (100, 200, 300, 400 between 0–500)
      },
      axisLabel: {
        show: true,
        font: "normal normal normal 12px/8px Open Sans",
        color: "#576B8D",
        opacity: 1,
      },
    },
    series: [
      {
        name: "Value",
        type: "bar",
        data: chartData.map((item) => ({
          value: item.yAxisData, // Use the yAxisData
          highlight: item.customColor, // Use customColor to indicate if it should be highlighted
        })),
        label: {
          show: true, // Show label on top of bars
          position: "top", // Position labels at the top
          font: "normal normal normal 12px/8px Open Sans",
          color: "#585858",
          formatter: "{c}", // Show the value of the bar
        },
        itemStyle: {
          color: (params) => {
            const value = params.data.value; // Access the current bar's value
            const highlight = params.data.highlight; // Check if it's the current hour
            return highlight
              ? {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  { offset: 0, color: customColor[0] },
                  { offset: 1, color: customColor[1] },
                ],
              }
              : gradient
                ? {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    { offset: 0, color: gradient[0] },
                    { offset: 1, color: gradient[1] },
                  ],
                }
                : value >= targetValue
                  ? {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      { offset: 0, color: "#B7E761" },
                      { offset: 1, color: "#86D812" },
                    ],
                  }
                  : {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      { offset: 0, color: "#FF9393" },
                      { offset: 1, color: "#FF5353" },
                    ],
                  };
          },
          borderRadius: [20, 20, 0, 0], // Rounded corners on top only
        },
        barWidth: "10px", // Adjust bar width
        markLine: showTargetLine
          ? {
            symbol: ["none", "none"],
            lineStyle: {
              type: "solid",
              color: "#186FD9",
              width: 1,
            },
            label: {
              show: true,
              formatter: "Target", // Set custom label
              position: "end", // Label position at the end of the line
              color: "#186FD9", // Customize label color if needed
              fontWeight: "bold",
            },
            data: [
              {
                yAxis: targetValue,
              },
            ],
          }
          : undefined, // Hide markLine if the flag is false
      },
    ],
  };


  return (
    <ReactECharts option={option} style={{ height: "350px", width: "100%", paddingTop: 0 }} />
  );
};

export default BarChart;
