import moment from "moment-timezone";

export const formatDate = (timestamp, defaultValue = "00:00 AM") => {
  if (isNaN(Date.parse(timestamp))) return defaultValue;

  const date = new Date(timestamp);
  return date.toLocaleTimeString("en-US", {
    timeZone: "Pacific/Auckland",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
};

export const getIntervals = (data, excavator) => {
  if (!data || !excavator) return [];

  const { shiftStartTime, shiftEndTime } = data;
  const { hourlyTarget, loads } = excavator;

  const intervals = [];

  let currentIntervalStart = moment(shiftStartTime);
  let index = 1;

  while (currentIntervalStart.isBefore(shiftEndTime)) {
    const currentIntervalEnd = moment(currentIntervalStart).add(1, "hour");

    const formattedInterval = `${moment
      .tz(currentIntervalStart, "Pacific/Auckland")
      .format("hh:mm A")} - ${moment
      .tz(currentIntervalEnd, "Pacific/Auckland")
      .format("hh:mm A")}`;

    const loadsInInterval = loads.filter((load) =>
      moment(load.endTime).isBetween(
        currentIntervalStart,
        currentIntervalEnd,
        "minute",
        "[)"
      )
    ).map(load => ({ ...load, id: index++ }));

    intervals.push({
      formattedInterval,
      target: hourlyTarget,
      loads: loadsInInterval,
    });

    currentIntervalStart = currentIntervalEnd;
  }

  return intervals;
};

export const getFilteredLoads = (loads, search = "") => {
    return (loads || [])
    .filter((load) => {
      const lowerCaseSearch = search?.toLowerCase().trim() || "";
      return (
        load.operatorName?.toLowerCase().includes(lowerCaseSearch) ||
        load.sourceArea?.toLowerCase().includes(lowerCaseSearch) ||
        load.dumpArea?.toLowerCase().includes(lowerCaseSearch) ||
        load.material?.toLowerCase().includes(lowerCaseSearch) ||
        load.truckName?.toLowerCase().includes(lowerCaseSearch)
      );
    })
    .reduce((prev, curr) => prev + (curr.actualBCM || 0), 0);
};
