import Legend from "pages/Dashboard/components/Legend";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { IoBanSharp, IoCloseSharp } from "react-icons/io5";
import { PiAsteriskSimpleBold } from "react-icons/pi";
import { FaCircleXmark } from "react-icons/fa6";
import { MdDoNotDisturbOn } from "react-icons/md";
const LegendsModal = ({ show, handleClose }) => {
  if (!show) return null; // Don't render the modal if it's not visible

  const styles = {
    'close': {
      borderRadius: "100%",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "1px",
    }
  }

  return (
    <div
      className="modal fade show"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div
          className="modal-content px-5 "
          style={{
            // width: "435px",
            // height: "490px",
            background: "#FFFFFF",
            BoxShadow: "0px 0px 45px #A3A3A329",
            borderRadius: "15px",
          }}
        >
          <div
            className="modal-header"
            style={{
              padding: "30px 0 17px 0",
              borderBottom: "1px solid #f2f2f2",
            }}
          >
            <div
              className="modal-title"
              style={{
                font: "normal normal 600 14px/14px Open Sans",
                color: "#000000",
              }}
            >
              Legend
            </div>

            <div onClick={handleClose} style={styles.close} >
              <IoCloseSharp color="black" fontSize={17.5} />
            </div>
          </div>
          <div className="modal-body p-0 pt-3 pb-5 ">
            <div className="legend-items">
              <Legend
                background={"#7BCB87"}
                shadow={"0px 0px 20px #7CCB8740"}
                borderRadius={"40px"}
                label="Target Achieved"
              />
              <Legend
                background={"#EB5F5F"}
                shadow={"0px 0px 20px #EB5F5F40"}
                borderRadius={"40px"}
                label="Below Target"
              />
              <Legend
                background={"#587CDD"}
                shadow={"0px 0px 20px #587CDD40"}
                borderRadius={"40px"}
                label="Unscheduled Load"
                info="These loads come from unscheduled or unassigned trucks."
              />
              <Legend
                background={
                  "transparent linear-gradient(270deg, #69B4FF 0%, #D6E7FF 100%) 0% 0% no-repeat padding-box"
                }
                borderRadius={"15px"}
                label="Ongoing Load (in Hourly Results)"
              />

              <Legend
                background={
                  "transparent linear-gradient(270deg, #F5B4FD 0%, #7453FF 100%) 0% 0% no-repeat padding-box"
                }
                borderRadius={"15px"}
                label="Overtime Load"
                info="Total overtime loads/BCM completed beyond the formal end of the shift."
              />

              <Legend
                background={"rgb(224, 224, 224)"}
                shadow={"0px 0px 45px #A3A3A329"}
                borderRadius={"40px"}
                label="No Data / Pending Load"
              />

              <Legend
                icon={<MdDoNotDisturbOn color="red" />}
                label="Not Operating"
              />

              <Legend
                icon={<IoBanSharp />}
                label="Unscheduled Truck"
                info="This truck has not been scheduled into any of the shifts."
              />

              <Legend
                icon={<PiAsteriskSimpleBold />}
                label="Unassigned Truck"
                info="This truck has been assigned to another excavator in the shift."
              />

              <Legend
                icon={<BsArrowLeft />}
                label="Backward Trend"
              />

              <Legend
                icon={<BsArrowRight />}
                label="Upward Trend"
              />

              <Legend
                icon={<FaCircleXmark />}
                label="Ended Shift"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LegendsModal;
