import React, { useEffect } from "react";
import { PieChart } from "react-minimal-pie-chart";
import "../CircularPieChart/CircularPieChart.css";

const CircularPieChart = ({
  pieChartData = [],
  gradients = [
    { id: "grayGradient", colors: ["#EFEFEF", "#C2C0B9"] },
  ],
  lineWidth = 20,
  width = 140,
  height = 200,
  centerText = "",
  tooltipText = "",
  containerStyle,
  symbol = "",
}) => {

  // Determine gradients and handle edge cases for pieChartData
  const computedGradients =
    Array.isArray(pieChartData) && (pieChartData.length === 0 || pieChartData.every((val) => val === 0))
      ? [{ id: "grayGradient", colors: ["#EFEFEF", "#C2C0B9"] }]
      : gradients;

  // Prepare data for the PieChart
  const data =
    pieChartData.length === 0 || pieChartData.every((val) => val === 0)
      ? [{ value: 1, color: `url(#grayGradient)` }] // Default data for empty or zero values
      : pieChartData.map((value, index) => ({
          value,
          color: `url(#${computedGradients[index]?.id || "grayGradient"})`,
        }));

  useEffect(() => {
    // Initialize tooltips
    const tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    );
    tooltipTriggerList.forEach((tooltipTriggerEl) => {
      new window.bootstrap.Tooltip(tooltipTriggerEl);
    });
  }, []);

  return (
    <>
      <div
        style={{
          position: "relative",
          width: `${width}px`,
          height: `${height}px`,
          ...containerStyle,
        }}
      >
        {/* Gradients Definition */}
        <svg width="0" height="0">
          <defs>
            {computedGradients.map((gradient) => (
              <linearGradient
                key={gradient.id}
                id={gradient.id}
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop offset="0%" stopColor={gradient.colors[0]} />
                <stop offset="100%" stopColor={gradient.colors[1]} />
              </linearGradient>
            ))}
          </defs>
        </svg>

        {/* Pie Chart */}
        <PieChart
          data={data}
          lineWidth={lineWidth}
          radius={50}
          startAngle={-90}
        />
        <div
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title={tooltipText}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            fontSize: "18px",
            fontWeight: "bold",
            color: "#000",
            cursor: "pointer",
          }}
        >
          {centerText}
        </div>
        <div
          style={{
            position: "absolute",
            top: "60%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            font: "normal normal normal 10px/14px Open Sans",
            color: "#6F7070",
          }}
        >
          {symbol}
        </div>
      </div>
    </>
  );
};

export default CircularPieChart;
