import { useKPIDashboardContext } from "pages/KPIDashboard/Context/Context";
import Style from "../Dashboard/Dashboard.module.css";
import Delays from "../Delays/Delays";
import MTDAchieved from "../MTDAchieved/MTDAchieved";
import MTDCoals from "../MTDCoal/MTDCoal";
import MTDTotals from "../MTDTotals/MTDTotals";
import ShiftTotals from "../ShiftTotals/ShiftTotals";
import MTDGraphs from "../MTDGraphs/MTDGraphs";
import { Riple } from "react-loading-indicators";

const Dashboard = () => {
  const {
    loading,

    delayData,
    dashboardData
  } = useKPIDashboardContext();

  const getBCMData = () => {
    return {
      actual: dashboardData?.overburnedData?.mtdActual,
      target: dashboardData?.overburnedData?.mtdTarget,
    }
  }

  return (
    <>
      {loading ? <div
        style={{ height: "100%", width: "100%", position: 'fixed', left: 0, opacity: .25, backgroundColor: "black", zIndex: 1000 }}
        className="d-flex align-items-center justify-content-center"
      >
        <Riple color="#317bcc" size="medium" text="" textColor="" />
      </div>
        :
        <div className="mb-3">

          <div className="px-5 d-flex align-items-center justify-content-between">
            <ShiftTotals unit={"BCM"} title={"Overburden BCM"} shiftTotalData={dashboardData?.overburnedData} />
            <div
              className={`d-flex align-items center justify-content-between ${Style.mtdContainer}`}
            >
              <MTDTotals title={"OB-BCM"} data={getBCMData()} />
            </div>
            <MTDGraphs unit={"BCM"} title={"Overburden BCM"} style={{ width: 'calc(100% - 1025px)' }} data={dashboardData?.overburnedData?.monthlyData} />
            <MTDAchieved coalData={dashboardData?.coalData} overburnedData={dashboardData?.overburnedData} />
          </div>

          <div className="mt-3 px-5 d-flex align-items-center justify-content-between">
            <ShiftTotals unit={"Tonnes"} title={"ROM Coal"} shiftTotalData={dashboardData?.coalData} />
            <div
              className={`d-flex align-items center justify-content-between ${Style.mtdContainer}`}
            >
              <MTDCoals coalData={dashboardData?.coalData} />
            </div>
            <MTDGraphs unit={"Tonnes"} title={"Coal Tonnes"} style={{ width: 'calc(100% - 1100px)' }} data={dashboardData?.coalData?.romCoalMonthlyData} />
          </div>
          <div className="px-5  my-4">
            <Delays delayData={delayData} />
          </div>
        </div>
      }
    </>
  );
};

export default Dashboard;
