import Flyer from "pages/DelayAndActivity/Component/Flyers/Flyer";
import EditDelayComponent from "./EditDelayComponent";
import { useEffect, useRef, useState } from "react";
import moment from "moment-timezone";
import { CallWithAuth } from "action/apiActions";
import { toast } from "react-toastify";
import {
  convertSecToISO,
  formateDataForSaving,
} from "pages/DelayAndActivity/utils/helper";
import { useDelayContext } from "../context/context";
import { fetchDelaySubTypes } from "../utils/apiService";

const EditDelay = ({ data: originalData }) => {
  const { selectedDate, shiftType, delayTypeList, openFlyer, setOpenFlyer,refreshPage, setRefreshPage } =
    useDelayContext();

  const [formData, setFormData] = useState({
    delayType: "",
    delaySubType: "",
    startTime: "",
    endTime: "",
  });
  const initialFormData = useRef({
    delayType: "",
    delaySubType: "",
    startTime: "",
    endTime: "",
  });

  const [showError, setShowError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const delaySubTypeList = await fetchDelaySubTypes(
          delayTypeList?.find((v) => originalData.delayType === v.label)?.value
        );
        initialFormData.current = {
          delayType: originalData.delayType
            ? {
                value: delayTypeList?.find(
                  (v) => originalData.delayType === v.label
                )?.value,
                label: originalData.delayType,
              }
            : "",
          delaySubType: originalData.delaySubType
            ? {
                value: delaySubTypeList?.find(
                  (v) => originalData.delaySubType === v.label
                )?.value,
                label: originalData.delaySubType,
              }
            : "",
          startTime: originalData.startTime
            ? {
                value: moment(originalData?.startTime)
                  .tz("Pacific/Auckland")
                  .diff(
                    moment(originalData?.startTime)
                      .tz("Pacific/Auckland")
                      .startOf("day"),
                    "seconds"
                  ),
                label: moment(originalData?.startTime)
                  .tz("Pacific/Auckland")
                  .format("hh:mm a"),
              }
            : "",
          endTime: originalData.endTime
            ? {
                value: moment(originalData?.endTime)
                  .tz("Pacific/Auckland")
                  .diff(
                    moment(originalData?.endTime)
                      .tz("Pacific/Auckland")
                      .startOf("day"),
                    "seconds"
                  ),
                label: moment(originalData?.endTime)
                  .tz("Pacific/Auckland")
                  .format("hh:mm a"),
              }
            : "",
        };
        setFormData(initialFormData.current);
      } catch (error) {
        console.error(
          "Error fetching delay sub types or processing data:",
          error
        );
      }
    };

    fetchData();
  }, []);


  const isFormValid = () => {
    
    const isDelayValid = (
        formData.delaySubType &&
        formData.delayType &&
        formData.startTime &&
        formData.endTime
      ) ? true : false;
      

    if (!isDelayValid) {
      setShowError(true);
      return false;
    }
    setShowError(false);
    return true;
  };

  const saveChanges = async () => {
    //Validate form data.
    if (!isFormValid()) return;

    // Validate delays first
    const formattedDelay = formateDataForSaving(formData);
    const startTime = convertSecToISO(
      formattedDelay.startTime,
      shiftType,
      selectedDate
    );
    const endTime = convertSecToISO(
      formattedDelay.endTime,
      shiftType,
      selectedDate
    );

    // Check if startTime is greater than endTime
    if (new Date(startTime) > new Date(endTime)) {
      toast.error("Error: Start time cannot be greater than end time.");
      return null; // Mark invalid delays as null
    }

    const validatedData = {
      ...formattedDelay,
      startTime,
      endTime,
    };

    const reqBody = {
      delayId: originalData.delayId,
    };

    // Check for each field before adding to reqBody
    if (
      validatedData.delayType &&
      validatedData.delayType !== initialFormData.current.delayType.value
    ) {
      reqBody.delayType = validatedData.delayType;
    }

    if (
      validatedData.delaySubType &&
      validatedData.delaySubType !== initialFormData.current.delaySubType.value
    ) {
      reqBody.delaySubType = validatedData.delaySubType;
    }

    if (
      validatedData.startTime &&
      new Date(validatedData.startTime).getTime() !==
        new Date(originalData.startTime).getTime()
    ) {
      reqBody.startTime = validatedData.startTime;
    }

    if (
      validatedData.endTime &&
      new Date(validatedData.endTime).getTime() !==
        new Date(originalData.endTime).getTime()
    ) {
      reqBody.endTime = validatedData.endTime;
    }

    // Check if reqBody has any changes to send
    if (Object.keys(reqBody).length === 1) {
      // Only contains delayId
      toast.error("No changes detected to save.");
      return; // Exit if no changes
    }

    try {
      const response = await CallWithAuth("PUT", "/api/delay", reqBody);
      if (response?.res?.status === 202) {
        toast.success("Delay edited successfully!");
        setOpenFlyer("");
        setRefreshPage(!refreshPage)
      } else {
        throw new Error("Failed to edit delay");
      }
    } catch (error) {
      toast.error("An error occurred while adding delays. Please try again.");
    }
  };

  return (
    <>
      {openFlyer == "DELAY_EDIT" && (
        <Flyer
          isOpen={openFlyer == "DELAY_EDIT"}
          onClose={() => setOpenFlyer("")}
          title={"Edit Delay"}
          containerStyle={{
            zIndex: "100",
            right: 0,
            bottom: 0,
            top: "unset",
            height: "calc(100vh - 15.4rem)",
          }}
          headerComponent={true}
          tabs={[
            {
              title: "",
              rightActionButtons: [
                {
                  label: "Save Changes",
                  onClick: async () => {
                    await saveChanges();
                  },
                },
              ],

              component: (
                <EditDelayComponent
                showError={showError}
                  setFormData={setFormData}
                  formData={formData}
                />
              ),
            },
          ]}
        />
      )}
    </>
  );
};

export default EditDelay;
