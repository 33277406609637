import moment from "moment-timezone";
import { toast } from "react-toastify";

export function formatTimeRange(item) {
    const formatTime = (time) => moment(time).tz("Pacific/Auckland").format("hh:mm A");

    if (!item?.startTime) return "Invalid start time";

    const startTimeFormatted = formatTime(item.startTime);

    if (item.endTime) {
        const endTimeFormatted = formatTime(item.endTime);
        const duration = moment
            .utc(moment(item.endTime).diff(moment(item.startTime)))
            .format("HH:mm");

        return `${startTimeFormatted} - ${endTimeFormatted} (${duration} hrs)`;
    } else {
        return `${startTimeFormatted} - In Progress`;
    }
}

export function totalDelayDuration(rows) {
    const totalDurationMs = rows?.reduce((sum, row) => {
        if (!row.endTime)
            return sum
        const duration = moment(row?.endTime).diff(moment(row?.startTime)); // Duration in milliseconds
        return duration > 0 ? sum + duration : sum;
    }, 0);

    const duration = moment.duration(totalDurationMs);
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();

    return `${hours}:${minutes.toString().padStart(2, "0")} hr`;
}


export const showToast = (isSuccess, msg) => {
    const options = {
        position: "bottom-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
    };

    if (isSuccess) {
        toast.success(msg, options);
    } else {
        toast.error(msg, options);
    }
};

export const formateDataForSaving = (data) => {
    const formattedData = {};
    Object.keys(data).forEach((key) => {
        if (Array.isArray(data[key])) {
            formattedData[key] = data[key].length
                ? data[key].map((item) => {
                    if (typeof item === "object" && item !== null && item.value) {
                        return item.value;
                    }
                    return item;
                })
                : [];
        } else if (typeof data[key] === "object" && data[key] !== null) {
            formattedData[key] = data[key].value;
        } else {
            formattedData[key] = data[key];
        }
    });
    return formattedData;
};


export const convertSecToISO = (seconds, type, date) => {
    const obj = moment.tz(moment(date).tz("Pacific/Auckland"), "Pacific/Auckland").startOf('day');

    if (seconds < 43200 && type !== 'day') {
        obj.add(1, 'day');
    }

    return obj.add(seconds, 'seconds').toISOString();
};