import React, { useEffect, useRef, useState } from 'react'
import styles from './ActionMenu.module.css';
import { GoKebabHorizontal } from "react-icons/go";

const ActionMenu = ({ isOpen = false, onClick, className, optionsContainerClass, icon, iconContainerStyle, options, optionStyle, optionClass, optionsContainerStyle = {}, }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(isOpen);
    const menuRef = useRef(null);

    useEffect(() => {
        setIsMenuOpen(isOpen);
    }, [isOpen]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuRef]);

    return (
        <div className={`${styles.mainContainer} ${className}`} ref={menuRef}>
            <div
                onClick={(e) => {
                    e.stopPropagation();
                    if (onClick) {
                        onClick(e);
                    }
                    setIsMenuOpen(!isMenuOpen);
                }}
                className={styles.iconContainer}
                style={{ height: icon ? 'fit-content' : '22px', backgroundColor: isMenuOpen ? '#B6C7E2' : '', ...iconContainerStyle }}
            >
                {icon ? icon : <GoKebabHorizontal size={16} color='black' style={{ transform: "rotate(90deg)" }} />}
            </div>

            {isMenuOpen && <div style={optionsContainerStyle} className={`${styles.optionsContainer} d-flex flex-column align-items-start bg-white ${optionsContainerClass}`}>
                {options && options.map((option, index) => {
                    return <div key={index} className={`${styles.option} ${optionClass} ${option.disabled ? styles.disabled : ''}`} style={optionStyle} onClick={(e) => {
                        e.stopPropagation()
                        setIsMenuOpen(false);
                        option.onClick(e)
                    }}>
                        {option.name}
                    </div>
                })}
            </div>}
        </div>
    )
}

export default ActionMenu