import React from 'react'
import { CSVLink } from "react-csv";
import { FiDownload } from "react-icons/fi";

const ExportButton = ({ onClick, data, fileName }) => {
    return (
        <div className="mx-2">
            <CSVLink
                data={data}
                filename={fileName}
                target="_blank"
            >
                <FiDownload
                    onClick={onClick}
                    style={{
                        color: "black",
                        cursor: "pointer",
                        backgroundColor: "#E2ECFC",
                        borderRadius: "10%",
                        padding: ".25rem",
                        width: "2.3rem",
                        position: "relative",
                        right: "0px",
                        height: "2.4rem",
                        border: "1.5px solid rgb(225,232,244)",
                    }}
                />
            </CSVLink>
        </div>
    )
}

export default ExportButton