import styles from "./ShiftHistoryModal.module.css"
import { useEffect, useRef, useState } from "react";
import { Dot, ShiftHistoryTimeline } from "./ShiftHistoryTimeline";
import { FiDownload } from 'react-icons/fi';
import { IoMdClose } from 'react-icons/io';
import { Dropdown, Image } from 'react-bootstrap';
import clsx from "clsx";
import moment from 'moment';
import { getElementDimensions } from "pages/ShiftHistory/utils/helper";
import { generateUnitHistoryModalPDF } from "pages/ShiftHistory/Component/Download/download";
import jsPDF from 'jspdf';


const ShiftHistoryModal = ({ selectedUnit, setSelectedUnit }) => {
  const [currentOperator, setCurrentOperator] = useState();
  const [currentOperatorLoad, setCurrentOperatorLoad] = useState(null)

  useEffect(() => {
    if (!selectedUnit) {
      setCurrentOperator(undefined);
      setCurrentOperatorLoad(null)
    } else {
      setCurrentOperator(selectedUnit.operatorLoads[0]?._id);
      setCurrentOperatorLoad(selectedUnit.operatorLoads[0])
    }
  }, [selectedUnit]);

  const operatorCountRef = useRef(0);
  const modalRef = useRef();
  const timelineRef = useRef()
  const [pdf, setPdf] = useState();
  useEffect(() => {
    if (!pdf) {
      modalRef.current.style.height = '60%'
      timelineRef.current.style.overflow = "auto"
      return;
    }

    // modalRef.current.style.height = modalRef.current.style.height + 50 + 'px';
    generateUnitHistoryModalPDF(
      pdf,
      modalRef.current,
      Boolean(!operatorCountRef.current),
      timelineRef.current
    ).then(() => {
      operatorCountRef.current += 1;
      if (operatorCountRef.current === selectedUnit.operatorLoads.length) {
        const currentTime = new Date().toLocaleString();
        pdf.save(`${selectedUnit.name}-${currentTime}.pdf`);

        setPdf(undefined);
        operatorCountRef.current = 0;
        setCurrentOperator(selectedUnit.operatorLoads[0]?._id);
      } else {
        setCurrentOperator(
          selectedUnit.operatorLoads[operatorCountRef.current]?._id
        );
      }
    });
  }, [currentOperator, pdf]);

  return (
    <aside className={styles.shiftHistoryModal} ref={modalRef}>
      <header
        style={{
          alignItems: "center",
          display: "inline-flex",
          flexDirection: "row",
          gap: "10px",
          padding: "0 3rem 0 3rem",
          width: "100%",
        }}
      >
        <h3 style={{ fontWeight: "bold", flexGrow: 1 }}>{selectedUnit.name}</h3>
        <FiDownload
          onClick={() => {
            modalRef.current.style.height = "max-content";
            timelineRef.current.style.overflow = "visible";
            setPdf(
              new jsPDF({
                unit: "px",
                format: getElementDimensions(modalRef.current),
              })
            );
          }}
          size={16}
          style={{ cursor: "pointer" }}
        />
        <IoMdClose
          onClick={() => setSelectedUnit(undefined)}
          size={16}
          style={{ cursor: "pointer" }}
        />
      </header>

      <nav style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
        <ul
          className={styles.shiftHistoryMenu}
          style={{ padding: "1rem 3rem 0 3rem" }}
        >
          {selectedUnit.operatorLoads.map((operatorLoad, index) => (
            <li
              key={index}
              className={
                styles.shiftHistoryItem
                + " " + (operatorLoad?._id === currentOperator ? styles.active : "")
              }
              onClick={() => {
                setCurrentOperator(operatorLoad?._id)
                setCurrentOperatorLoad(operatorLoad)
              }}
              style={{ cursor: "pointer" }}
            >
              {operatorLoad.operatorName}
              {index === 0 ? "*" : ""}
            </li>
          ))}
        </ul>
        <hr style={{ marginTop: 0 }} />
      </nav>

      <div
        style={{ justifyContent: "space-between", marginRight: 25, display: "flex" }}
      >
        {currentOperatorLoad?.startTime ? <span
          className="px-3 d-flex align-items-center ml-5"
          style={{
            background: '#707070',
            width: 'max-content',
            borderRadius: '15px',
            backgroundColor: '#00214d',
            color: 'white',
            font: 'normal normal bold 12px/17px Open Sans',
          }}
        >
          {`${currentOperatorLoad?.loadingMachine || ""}${currentOperatorLoad?.loadingMachine ? " _ " : ""}${moment(currentOperatorLoad?.startTime).format("hh:mm a")} - ${moment(currentOperatorLoad?.endTime).format("hh:mm a")}`}
        </span> : <span></span>}
        <Dropdown className="d-inline">
          <Dropdown.Toggle
            style={{ height: "2.5rem", width: "6rem" }}
            bsPrefix="custom-toggle"
            id=""
          >
            Legend
          </Dropdown.Toggle>

          <Dropdown.Menu
            style={{ width: "250px" }}
            bsPrefix="dropdown-menu custom-menu2"
          >
            <Dropdown.Item
              className="d-flex align-items-center justify-content-start"
              disabled
            >
              <div style={{ fontWeight: "700" }}>Table View</div>
            </Dropdown.Item>
            <Dropdown.Item
              className="d-flex align-items-center justify-content-start"
              disabled
            >
              <Image
                src={require("../../../../assets/images/delay.png")}
                height={15}
                width={15}
                style={{ marginRight: "6px", marginBottom: "3px" }}
              />
              <div className={styles.headerLabel}>Ongoing Delay</div>
            </Dropdown.Item>
            <Dropdown.Item
              className="d-flex align-items-center justify-content-start"
              disabled
            >
              <Image
                src={require("../../../../assets/images/not-available.png")}
                height={25}
                width={25}
                style={{ marginLeft: "-6px", marginBottom: "3px" }}
              />
              <div className={styles.headerLabel}>
                Current Breakdown
              </div>
            </Dropdown.Item>
            <Dropdown.Item
              className="d-flex align-items-center justify-content-start"
              disabled
            >
              <div style={{ fontWeight: "700" }}>Modal View</div>
            </Dropdown.Item>
            <Dropdown.Item
              className="d-flex align-items-center justify-content-start"
              disabled
            >
              <Dot
                DotStyles={{ marginLeft: -4, marginRight: "8px" }}
                active={false}
                type={"delay"}
                status={false}
              />
              <div className={styles.headerLabel}>
                Resolved Delay
              </div>
            </Dropdown.Item>
            <Dropdown.Item
              className="d-flex align-items-center justify-content-start"
              disabled
            >
              <Dot
                DotStyles={{ marginLeft: -4, marginRight: "8px" }}
                active={false}
                type={"breakdown"}
                status={false}
              />
              <div className={styles.headerLabel}>
                Resolved Breakdown
              </div>
            </Dropdown.Item>
            <Dropdown.Item
              className="d-flex align-items-center justify-content-start"
              disabled
            >
              <Dot
                DotStyles={{ marginLeft: -4, marginRight: "8px" }}
                active={true}
                type={"basic"}
                status={false}
              />
              <div
                style={{ width: "180px", whiteSpace: "normal" }}
                className={styles.headerLabel}
              >
                Completed (shift start login, first load, last load, shift end
                logout)
              </div>
            </Dropdown.Item>
            <Dropdown.Item
              className="d-flex align-items-center justify-content-start"
              disabled
            >
              <Dot
                DotStyles={{ marginLeft: -4, marginRight: "8px" }}
                active={false}
                type={"basic"}
                status={false}
              />
              <div
                style={{ width: "180px", whiteSpace: "normal" }}
                className={styles.headerLabel}
              >
                Incomplete (shift start login, first load, last load, shift end
                logout)
              </div>
            </Dropdown.Item>
            <Dropdown.Item
              className="d-flex align-items-center justify-content-start"
              disabled
            >
              <Dot
                DotStyles={{ marginLeft: -6.5, marginRight: "8px" }}
                active={true}
                type={"breakdown"}
                status={true}
              />
              <div className={styles.headerLabel}>
                Current Breakdown
              </div>
            </Dropdown.Item>
            <Dropdown.Item
              className="d-flex align-items-center justify-content-start"
              disabled
            >
              <Dot
                DotStyles={{ marginLeft: -6.5, marginRight: "8px" }}
                active={true}
                type={"delay"}
                status={true}
              />
              <div className={styles.headerLabel}>Ongoing Delay</div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <section
        ref={timelineRef}
        style={{ height: "calc(100% - 100px)", overflow: "auto" }}
      >
        {selectedUnit.operatorLoads
          .filter(({ _id }) => _id === currentOperator)
          .map((operatorLoad) => (
            <ShiftHistoryTimeline operatorLoad={operatorLoad} />
          ))}
      </section>
    </aside>
  )
}

export default ShiftHistoryModal