import DateTimeSelect from "pages/KPIDashboard/Component/GeneralFields/DateTimeSelect/DateTimeSelect";
import styles from "../Header/Header.module.css";
import { useKPIDashboardContext } from "pages/KPIDashboard/Context/Context";
const Header = () => {
  const { selectedDate,setSelectedDate } = useKPIDashboardContext();
  return (
    <div className=" pt-5 pb-3">
      <DateTimeSelect
        value={selectedDate}
        onDateChange={(date) => {
          setSelectedDate(date);
        }}
        className={`${styles.dateTimeSelect} ml-5`}
        iconColor="#6B7B8E"
        containerStyle={{
          border: "1px solid #E1E8F4",
        }}
      />
    </div>
  );
};

export default Header;
