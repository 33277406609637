import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { getElementDimensions } from 'pages/ShiftHistory/utils/helper';


export const generateShiftHistoryPDF = (title, mainElementId) => {
  const element = document.getElementsByClassName(mainElementId)[0];

  if (element && element.style.display !== 'none') {
    html2canvas(element).then((canvas) => {
      const pdf = new jsPDF({
        unit: 'px',
        format: [element.clientHeight * 4, element.clientWidth],
      });
      pdf.addImage(
        canvas.toDataURL('image/png'),
        'PNG',
        0,
        0,
        canvas.width,
        canvas.height
      );

      const currentTime = new Date().toLocaleString();
      pdf.save(`${title}-${currentTime}.pdf`);
    });
  } else {
    throw new Error('Element not found or not visible');
  }
};

export const generateUnitHistoryModalPDF = async (pdf, element, isFirst, scrollableElement) => {

  await html2canvas(element).then((canvas) => {
    if (!isFirst) {
      pdf.addPage(scrollableElement.scrollHeight > scrollableElement.clientHeight
        ? [canvas.width, canvas.height]
        : getElementDimensions(element), 'p');
    }

    pdf.addImage(
      canvas.toDataURL('image/png'),
      'PNG',
      0,
      0,
      canvas.width, canvas.height
    );
  });
};
