import DynamicSelectField from "pages/DelayAndActivity/Component/GeneralFields/DynamicSelectField/DynamicSelectField";
import FromToTimeSpan from "pages/DelayAndActivity/Component/GeneralFields/FromToTimeSpan/FromToTimeSpan";
import { useActivityContext } from "../context/context";

const FilterComponent = ({ filters, onChange }) => {
  const {
    equipmentList,
    activityTypeList
  } = useActivityContext()

  const commonFieldStyles = {
    background: "#FFFFFF",
    border: "1px solid #E1E8F4",
    borderRadius: "4px",
    marginTop: "10px",
  };
  const labelStyle = {
    font: "normal normal normal 14px/17px Open Sans",
    color: "#1A1A1A",
  };
  const valueStyle = {
    font: "normal normal 600 14px/19px Open Sans",
    color: "#1A1A1A",
  };

  return (
    <div className="px-5">
      <FromToTimeSpan
        label={"Time Span"}
        onChange={(key, val) => {
          onChange(key, val)
        }}
        startTime={filters.startTime}
        endTime={filters.endTime}
        style={commonFieldStyles}
        labelStyle={{ ...labelStyle, marginTop: "10px" }}
        valueStyle={valueStyle}
      />

      <DynamicSelectField
        isSearchable={true}
        isMulti={true}
        label={"Select Assets"}
        placeholder={"Select Assets"}
        options={equipmentList}
        value={filters.equipments}
        onChange={(val) => onChange("equipments", val)}
        style={commonFieldStyles}
        labelStyle={labelStyle}
        valueStyle={valueStyle}

      />

      <DynamicSelectField
        // isSearchable={true}
        label={"Activity Type"}
        placeholder={"Activity Type"}
        options={activityTypeList}
        isMulti={true}
        value={filters.activityTypes}
        onChange={(val) => onChange("activityTypes", val)}
        style={commonFieldStyles}
        labelStyle={labelStyle}
        valueStyle={valueStyle}

      />
    </div>
  );
};

export default FilterComponent;
