import Header from "./Header/Header";
import ActivityTable from "./ActivityTable/ActivityTable";

const Activity = () => {
    return (
        <>
            <Header />
            <ActivityTable />
        </>
    );
};

export default Activity;
