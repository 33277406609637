import styles from "../ShiftTotals/ShiftTotals.module.css";
import CircularPieChart from "pages/KPIDashboard/Component/CircularPieChart/CircularPieChart";
import ColorDot from "pages/KPIDashboard/Component/ColorDot/ColorDot";
import TotalSection from "./TotalSection/TotalSection";
import Badge from "pages/KPIDashboard/Component/Badge/Badge";
import Excavator from "../../../../assets/svgs/kpi-dashboard/Excavator.svg";
import {
  subtractAbsolute,
  calculatePercentageDifference,
  getSign,
  calculatePercentage
} from "pages/KPIDashboard/Utils/helper";
import Breakdown from "../BreakDown/BreakDown";

const ShiftTotals = ({ title, shiftTotalData, unit }) => {

  const shortFall = () => {
    return Math.max(shiftTotalData?.target - shiftTotalData?.actual, 0);
  };

  const above = () => {
    return Math.max(shiftTotalData?.actual - shiftTotalData?.target, 0);
  };

  const pieChartData = (() => {
    const fall = shortFall();
    const over = above();

    if (over > fall) {
      return [over, Math.max(shiftTotalData?.target - over, 0)];
    } else if (fall > over) {
      return [shiftTotalData?.target - fall, fall];
    } else {
      return [shiftTotalData?.actual];
    }
  })();


  const gradient = (() => {
    const fall = shortFall();
    const over = above();
    if (over > fall) {
      return [
        {
          id: "above",
          colors: ["#BEDDFF", "#89C3FF"]
        },
        { id: "actual", colors: ["#C6EB78", "#85D813"] },
      ]
    }
    if (fall > over) {
      return [
        { id: "actual", colors: ["#C6EB78", "#85D813"] },
        {
          id: "short",
          colors: ["#FF8B4E", "#FF3E2B"]
        },

      ]
    }
    if (fall === over) {
      return [
        { id: "actual", colors: ["#C6EB78", "#85D813"] },
      ]
    }
  })();

  return (
    <div
      className={`d-flex p-4 align-items center justify-content-between ${styles.shiftTotalCard}`}
    >
      <div className="d-flex flex-column pr-4">
        <div className={`pb-3`}>
          <span className={styles.cardTitle}>
            <span>
              <img
                style={{ height: "1.5rem", width: "1.5rem" }}
                src={Excavator}
                alt="sun"
              />
            </span>{" "}
            {title}
          </span>
          <span className={styles.shiftTotalLabel}> (Shift Totals)</span>
        </div>
        <div className={`pb-3 ${styles.activityCard}`}>
          <div className={`px-4 pt-4 ${styles.activityLabel}`}>
            Shift Totals
          </div>
          <div className="px-4 d-flex align-items-center justify-content-between">
            <div>
              <span className={styles.activityTotal}>
                {shiftTotalData?.actual || '-'} /{" "}
              </span>
              <span className={styles.activityTarget}>
                {shiftTotalData?.target || '-'}
              </span>
            </div>
            <div>
              {shiftTotalData?.actual !=
                shiftTotalData?.target && <Badge
                  value={`${getSign(
                    shiftTotalData?.actual,
                    shiftTotalData?.target
                  )} ${subtractAbsolute(
                    shiftTotalData?.actual,
                    shiftTotalData?.target
                  ) || "-"}`}
                  containerStyle={{
                    font: "normal normal 600 13px/24px Open Sans",
                    color: "#FFFFFF",
                    background:
                      shiftTotalData?.actual < shiftTotalData?.target
                        ? "#F03E2B"
                        : "#3B6EFB",
                  }}
                />}
            </div>
          </div>
          <div
            className="px-4 d-flex align-items-baseline"
          // style={{ width: "80%" }}
          >

            <Badge
              value={`${getSign(
                shiftTotalData?.actual,
                shiftTotalData?.target
              )} ${shiftTotalData?.actual !=
                shiftTotalData?.target ? calculatePercentageDifference(
                  shiftTotalData?.actual,
                  shiftTotalData?.target
                ) : "-"}`}
              containerStyle={{
                font: "normal normal 600 10px/22px Open Sans",
                color:
                  shiftTotalData?.actual < shiftTotalData?.target
                    ? "#FF3E2B"
                    : shiftTotalData?.actual > shiftTotalData?.target ? "#3B6EFB" : "black",
                background:
                  shiftTotalData?.actual < shiftTotalData?.target
                    ? "#FCE5E9"
                    : shiftTotalData?.actual > shiftTotalData?.target ? "#E5EFFA" : "#DEDCDA",
                width: "55px",
                height: "19px",
                borderRadius: "20px",
              }}
            />

            <div className={styles.activityStatusLabel + " ml-2"}>
              {shiftTotalData?.actual < shiftTotalData?.target
                ? "Less activity than usual"
                : shiftTotalData?.actual > shiftTotalData?.target ? "More activity than usual" : "N/A"}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div className="mr-2">
            <CircularPieChart
              pieChartData={pieChartData}
              gradients={gradient}
              width={110}
              centerText={`${calculatePercentage(shiftTotalData?.actual, shiftTotalData?.target)} `}
              tooltipText={calculatePercentage(shiftTotalData?.actual, shiftTotalData?.target) >= 100 ? "Percentage achieved" : "Percentage not achieved"} symbol={"%"} />
          </div>
          <div className={`d-flex flex-column ${styles.breakDownContainer}`}>
            <div className={`pb-3 ${styles.breakDownLabel}`}>{unit || "BCM"} Breakdown</div>

            <Breakdown
              actual={shiftTotalData?.actual}
              shortFall={shortFall()}
              above={above()}
              labelStyle={{
                font: "normal normal 600 11px/15px Open Sans",
                color: "#000000",
              }}
            />
          </div>
        </div>
      </div>

      <div className="d-flex flex-column ">
        <div className={`px-4 py-4 ${styles.rightContainer}`}>
          <TotalSection isDayTotal={true} shiftTotalData={shiftTotalData} />
          <div
            className="my-3"
            style={{ border: "1px solid #ebebeb", opacity: "0.4" }}
          ></div>
          <TotalSection isDayTotal={false} shiftTotalData={shiftTotalData} />
        </div>
      </div>
    </div>
  );
};

export default ShiftTotals;
