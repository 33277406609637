import React, { useState } from "react";
import DynamicSelectField from "pages/DelayAndActivity/Component/GeneralFields/DynamicSelectField/DynamicSelectField";
import FromToTimeSpan from "pages/DelayAndActivity/Component/GeneralFields/FromToTimeSpan/FromToTimeSpan";
import { FaPlus } from "react-icons/fa";
import Styles from "./AddActivity.module.css";
import { useActivityContext } from "../context/context";

const commonFieldStyles = {
  background: "#FFFFFF",
  border: "1px solid #E1E8F4",
  borderRadius: "4px",
  marginTop: "10px",
};
const labelStyle = {
  font: "normal normal normal 14px/17px Open Sans",
  color: "#1A1A1A",
};
const valueStyle = {
  font: "normal normal 600 14px/19px Open Sans",
  color: "#1A1A1A",
};

const ActivitySection = ({
  index,
  onChange,
  isSelected,
  toggleSelection,
  activityTypeList = [],
  activityData,
  showError
}) => {
  return (
    <div >
      <div className="pt-3 pb-4 d-flex align-items-center">
        <input
          className={Styles.activityCheckbox}
          type="checkbox"
          checked={isSelected}
          onChange={toggleSelection}
        />
        <div className="d-flex align-items-center" style={{ width: "100%" }}>
          <div className={`pl-3 ${Styles.checkLabel}`}>
            {`Activity ${String(index + 1).padStart(2, "0")}`}
          </div>
          <div
            style={{
              flexGrow: 1,
              borderBottom: "1px solid #DEE5F2",
              marginLeft: "5px",
              paddingBottom: "11px",
              opacity: 0.5,
            }}
          />
        </div>
      </div>

      <DynamicSelectField
        label="Activity Type"
        placeholder="Select Activity Type"
        options={activityTypeList}
        value={activityData.activityType}
        onChange={async (val) => {
          onChange("activityType", val)
        }}
        style={commonFieldStyles}
        labelStyle={labelStyle}
        valueStyle={valueStyle}
        required
        error={showError && !activityData?.activityType}
        errorMessage={"Please select a activity type"}
      />

      <FromToTimeSpan
        label="Activity Duration"
        onChange={(key, val) => {
          onChange(key, val)
          // onChange("endTime", endTime)
        }}
        labelStyle={{ ...labelStyle, marginTop: "10px" }}
        valueStyle={valueStyle}
        startTime={activityData.startTime}
        endTime={activityData.endTime}
        required
        error={showError && (!activityData?.startTime ||!activityData?.endTime ) }
        errorMessage={"Please select a activity duration"}
      />
    </div >)
}


const AddActivityComponent = ({showError, setFormData, formData }) => {
  const [selectedActivitys, setSelectedActivitys] = useState([]);
  const {
    equipmentList,
    activityTypeList,
  } = useActivityContext()


  const handleAddActivity = () => {
    setFormData((prev) => ({
      ...prev,
      activities: [
        ...prev.activities,
        {
          activityType: "",
          startTime: "",
          endTime: ""
        },
      ],
    }));
  };

  const handleDeleteSelected = () => {
    if (formData.activities.length > 1) {
      setSelectedActivitys([]);
      setFormData((prev) => ({
        ...prev,
        activities: prev.activities.filter((_, index) => !selectedActivitys.includes(index)),
      }));
    }
  };

  const handleChange = (field, value, index) => {
    setFormData((prev) => {
      const updatedActivitys = prev.activities.map((activity, idx) =>
        idx === index ? { ...activity, [field]: value } : activity
      );
      return { ...prev, activities: updatedActivitys };
    });
  };

  const toggleSelection = (index) => {
    setSelectedActivitys((prev) =>
      prev.includes(index) ? prev.filter((idx) => idx !== index) : [...prev, index]
    );
  };

  const actionButtonStyle = {
    background: "#FFFFFF",
    border: "1px solid #E1E8F4",
    borderRadius: "15px",
    font: "normal normal bold 12px/17px Open Sans",
    color: "#186FD9",
    cursor: "pointer",
  };

  return (
    <div style={{ minHeight: "100%" }} className="d-flex flex-column">
      <div style={{ borderBottom: "1px solid #e1e8f4" }} className="px-5">

        <DynamicSelectField
          isSearchable={true}
          isMulti={true}
          label="Select Assets"
          placeholder="Select Assets"
          options={equipmentList}
          value={formData?.equipments}
          required
          onChange={(val) =>
            setFormData((prev) => ({
              ...prev,
              equipments: val,
            }))
          }
          className="mb-3"
          labelStyle={labelStyle}
          valueStyle={valueStyle}
          error={showError && !formData?.equipments}
          errorMessage={"Please select a asset"}

        />

      </div>
      <div style={{ background: "#FAFBFD", flexGrow: 1 }} className="px-5">
        <div className="d-flex justify-content-between align-items-center pb-5 pt-3">
          <div onClick={handleAddActivity} style={actionButtonStyle} className="px-3 py-2">
            Add Activity <FaPlus color="#186FD9" />
          </div>
          <div onClick={handleDeleteSelected} style={actionButtonStyle} className="px-3 py-2">
            Delete
          </div>
        </div>

        {formData.activities.map((activity, index) => (
          <ActivitySection
            key={index}
            index={index}
            activityTypeList={activityTypeList}
            activityData={formData.activities[index]}
            onChange={(field, value) => handleChange(field, value, index)}
            isSelected={selectedActivitys.includes(index)}
            toggleSelection={() => toggleSelection(index)}
            showError={showError}
          />
        ))}
      </div>
    </div>
  );
};

export default AddActivityComponent;
