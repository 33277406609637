import Styles from "../WeatherReport/WeatherReport.module.css";
import PartialCloudy from "../../../../../assets/images/Dashboard/Weather/partialCloudy.png";
import AssetBadge from "pages/Dashboard/components/AssetBadge";
import CloudyNightLight from "../../../../../assets/images/Dashboard/Weather/cloudyNightLight.png";
import HalfMoonLight from "../../../../../assets/images/Dashboard/Weather/halfMoonLight.png";
import HeavyRainLight from "../../../../../assets/images/Dashboard/Weather/heavyRainLight.png";
import MostlyCloudyLight from "../../../../../assets/images/Dashboard/Weather/mostlyCloudyLight.png";
import SunLight from "../../../../../assets/images/Dashboard/Weather/sunLight.png";
import moment from "moment";

const WeatherReport = ({ onClose, weatherData }) => {
  const forecastToday = weatherData?.dailyForecastData;
  const foreCastWeekly = weatherData?.weeklyForecastData;

  const currentForeCast = {
    cloudCover: [weatherData?.dailyForecastData?.cloudCover[0]], // Extract the first value in an array
    temperature: [weatherData?.dailyForecastData?.temperature[0]] // Extract the first value in an array
  };

  const fahrenheitToCelsius = (fahrenheit) => {
    return Math.round(((fahrenheit - 32) * 5) / 9);
  };

  const getIconForData = (t, forecast, iconSize) => {
    const timeC = new Date();
    timeC?.setHours(new Date()?.getHours() + t);
    const time = timeC?.getHours();
    if (time >= forecast?.cloudCover?.length || !forecast?.cloudCover)
      return <img draggable={false} style={{ userSelect: 'none' }} height={iconSize} src={MostlyCloudyLight} alt="full Cloudy" />;
    else if (forecast?.cloudCover[time] <= 20) {
      if (time > 7 && time < 19)
        return <img draggable={false} style={{ userSelect: 'none' }} height={iconSize} src={SunLight} alt="sun" />;
      else return <img draggable={false} style={{ userSelect: 'none' }} height={iconSize} src={HalfMoonLight} alt="moon" />;
    } else if (forecast?.cloudCover[time] <= 60) {
      if (time > 7 && time < 19)
        return <img draggable={false} style={{ userSelect: 'none' }} height={iconSize} src={MostlyCloudyLight} alt="sunny Cloudy" />;
      else return <img draggable={false} style={{ userSelect: 'none' }} height={iconSize} src={CloudyNightLight} alt="night Cloudy" />;
    } else if (forecast?.cloudCover[time] <= 90) {
      if (time > 7 && time < 19)
        return <img draggable={false} style={{ userSelect: 'none' }} height={iconSize} src={MostlyCloudyLight} alt="cloudy Day" />;
      else return <img draggable={false} style={{ userSelect: 'none' }} height={iconSize} src={CloudyNightLight} alt="cloudy Night" />;
    } else
      return <img draggable={false} style={{ userSelect: 'none' }} height={iconSize} src={MostlyCloudyLight} alt="fully Cloudy" />;
  };

  return (
    <>
      <div
        className="modal fade show"
        tabIndex="-1"
        role="dialog"
        style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      >
        <div
          className=""
          style={{
            width: "auto",
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "50%",
            zIndex: 1100,
          }}
          role="document"
        >
          <div
            className="modal-content px-5 "
            style={{
              background:
                "transparent linear-gradient(180deg, #006BED 0%, #96BEFF 100%)",
              borderRadius: "15px",
              opacity: "1",
            }}
          >
            <div
              className="modal-header"
              style={{
                padding: "30px 0 17px 0",
                borderBottom: "None",
              }}
            >
              <div
                className="modal-title"
                style={{
                  font: "normal normal 600 20px/27px Open Sans",
                  color: "#FFFFFF",
                  opacity: "1",
                }}
              >
                Christchurch Central -{" "}
                {moment.tz(new Date(), "Pacific/Auckland").format("D MMMM")}
              </div>

              <div onClick={onClose}>
                <span className={Styles.weatherReportClose}>&times;</span>
              </div>
            </div>
            <div className="modal-body p-0 pt-3 pb-5 ">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="pr-5">
                    {/* <img height={100} src={PartialCloudy} alt="PartialCloudy" /> */}

                    {getIconForData(0, currentForeCast, 100)}
                  </div>
                  <div className="d-flex flex-column pt-2">
                    <span className={Styles.cloudy}>Cloudy</span>
                    <span className={`${Styles.temp} pb-3`}>
                      {weatherData?.temp}°
                    </span>
                    <span className={Styles.tempSM}>8° / 22°</span>
                  </div>
                </div>

                <div
                  className={` px-4 py-3 d-flex flex-column justify-content-between ${Styles.weatherDetail}`}
                >
                  <div className="d-flex justify-content-between align-items-center pb-1">
                    <div className={Styles.commonWeather}>Rainfall</div>
                    <div className={Styles.commonVal}>
                      {weatherData?.rain} mmph
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center pb-1">
                    <div className={Styles.commonWeather}>Wind</div>
                    <div className={Styles.commonVal}>
                      {weatherData?.wind} km/hr
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center pb-1">
                    <div className={Styles.commonWeather}>Pressure</div>
                    <div className={Styles.commonVal}>
                      {weatherData?.pressure} hPa
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center pb-1">
                    <div className={Styles.commonWeather}>Dust</div>
                    <div className={Styles.commonVal}>
                      {weatherData?.dust} μg/m<sup>3</sup>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`px-4 py-4  ${Styles.weatherToday}`}>
                <div
                  className={`d-flex  align-items-center ${Styles.weatherTodayCloud} pt-5`}
                >
                  {[...Array(23)].map((_, i) => {
                    return (
                      <div
                        className="d-flex flex-column"
                        key={i}
                        style={{
                          minWidth: "55px",
                          flex: "0 0 auto",
                          textAlign: "center",
                          margin: "7px",
                        }}
                      >
                        <span className={`pb-3 ${Styles.weatherCloudFont} `}>
                          {(() => {
                            const time = moment
                              .tz("Pacific/Auckland")
                              .add(i, "hours");
                            return time.format("HH:00");
                          })()}
                        </span>
                        <span className="pb-3">
                          {getIconForData(i, forecastToday, 32)}
                        </span>
                        <span className={`${Styles.weatherCloudTemp}`}>
                          {forecastToday?.temperature
                            ? forecastToday?.temperature[i]
                              ? (
                                forecastToday?.temperature[i] - 273.15
                              ).toFixed(1)
                              : ""
                            : "-"}{" "}
                          °C{" "}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div
                className={`px-4 py-3 d-flex flex-column  ${Styles.DayForCast}`}
              >
                <div className="pt-2 pb-2">
                  <AssetBadge
                    value={"7-Day Forecast"}
                    color={"#FFFFFF"}
                    bgColor={"#5B9EFF"}
                    font={"normal normal 600 14px/19px Open Sans"}
                    className="border-0 px-3 py-2"
                    containerStyle={{
                      opacity: "0.8",
                    }}
                  />
                </div>
                <div className={`d-flex align-items-center`}>
                  {[...Array(7)].map((_, index) => {
                    return (
                      <div
                        className="d-flex flex-column"
                        key={index}
                        style={{
                          minWidth: "100px",
                          flex: "0 0 auto",
                          textAlign: "center",
                          margin: "7px",
                        }}
                      >
                        <span className={`pb-3 ${Styles.weatherCloudFont} `}>
                          {(() => {
                            const time = new Date();
                            time?.setHours(new Date()?.getHours() + index + 1);
                            return time?.getHours();
                          })() + ":00"}
                        </span>
                        <span className="pb-3">
                          {getIconForData(index + 1, foreCastWeekly, 32)}
                        </span>
                        <span className={`${Styles.weatherCloudTemp}`}>
                          {foreCastWeekly?.temperature?.[index] !== undefined
                            ? `${(foreCastWeekly.temperature[index] - 273.15).toFixed(1)} °C`
                            : "-"}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WeatherReport;
