import BarChart from "pages/Dashboard/components/BarChart/BarChart";
import Styles from "../ExcavatorsHourlyResult/ExcavatorsHourlyResult.module.css";
import AssetDot from "pages/Dashboard/components/AssetDot";
import moment from "moment";
import { Status } from "pages/Dashboard/constant";
import { ShiftStatusEnum } from "pages/Dashboard/constant";
import { FaCircleXmark } from "react-icons/fa6";
import AssetBadge from "pages/Dashboard/components/AssetBadge";
import { dayDefaultHouryData, nightDefaultHouryData } from "../constant";
import { MdDoNotDisturbOn } from "react-icons/md";


const ExcavatorsHourlyResult = ({ exHourlyResultData, shiftType, materialType }) => {

  const exHourlyResult = exHourlyResultData?.exHourlyResult[0]
  const exHourlyChartData = Object.entries({ ...(shiftType == 'day' ? dayDefaultHouryData : nightDefaultHouryData), ...exHourlyResult?.hourlyData }).map(([hour, value]) => ({
    xAxisData: hour, // Convert hour to "H:00" format
    yAxisData: value,
    customColor: (exHourlyResultData.status === ShiftStatusEnum.ONGOING) && (hour === moment.tz("Pacific/Auckland").format("HH:00"))
  }));


  const firstLoad = (date) => {
    return date ? moment(date)
      .tz("Pacific/Auckland") // Set the timezone
      .format("HH:mm") : '-';
  };

  const calculateAverage = (shiftStatus, shiftStartTime, shiftEndTime, actual) => {

    if (shiftStatus === ShiftStatusEnum.PUBLISHED) {
      return 0;
    }

    const start = moment(shiftStartTime).tz("Pacific/Auckland");
    let end = moment().tz("Pacific/Auckland");
    if (shiftStatus === ShiftStatusEnum.ENDED) {
      // eslint-disable-next-line no-const-assign
      end = moment(shiftEndTime).tz("Pacific/Auckland")
    }
    // Calculate the difference in hours
    const timeElapsed = end.diff(start, "hours", true);
    return Math.round(actual / timeElapsed);
  };

  const statusAppearance = (status) => {
    const result = {};
    switch (status) {
      case Status.UNASSIGNED:
        result.icon = <span
          style={{
            font: "normal normal 600 12px/24px Open Sans"
          }}>*</span>
        break;

      case Status.SHIFTENDED:
        result.exBgColor = "#F0F0F0";
        result.icon = <FaCircleXmark className="ml-1 mb-1" size={12} />;
        break;

      case Status.REASSIGNED:
        result.label = 'Reassigned'
        result.bgColor = '#D8E5FF'
        result.color = '#000000'
        result.icon = ''
        break;

      case Status.REMOVED:
        result.label = 'Removed'
        result.bgColor = '#D8D8D8'
        result.color = '#2B2B2B'
        result.exBgColor = '#F0F0F0'
        break;

      case Status.NOTOPERATING:
        result.icon = <MdDoNotDisturbOn className="ml-1 mb-1" color="red" size={12} />
        break;

      default:
        result.label = status;
        result.icon = "";
    }

    return result;
  };

  return (
    <div
      className={Styles.hourlyCard}
      style={{ background: statusAppearance(exHourlyResult?.status).exBgColor }}
    >
      <div
        className={`pl-3 d-flex justify-content-between align-items-center ${Styles.hourlyCardHeader}`}
      >
        <div style={{ font: "normal normal 600 14px/19px Open Sans", color: "#000000" }}>
          Excavator
        </div>
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center mr-3">
            <AssetDot bgColor={"#94DC28"} />
            <span style={{ font: "normal normal normal 12px/17px Open Sans" }}>
              Meeting Target
            </span>
          </div>
          <div className="d-flex align-items-center">
            <AssetDot
              className="d-flex align-items-center"
              bgColor={"#FF5858"}
            />
            <span style={{ font: "normal normal normal 12px/17px Open Sans" }}>
              Falling Behind
            </span>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center pl-5 ">
        <div className={Styles.leftSection}>
          <div className={Styles.equipmentInfo}>
            {exHourlyResult?.excavatorName}<span>{statusAppearance(exHourlyResult?.status).icon}</span>
            <div className={`pt-1 ${Styles.operatorInfo}`}>O: {exHourlyResult?.operatorName ? exHourlyResult?.operatorName : "Unknown Operator"}</div>
            <div> {(exHourlyResult?.status === Status.REMOVED || exHourlyResult?.status === Status.REASSIGNED) && (
              <AssetBadge
                className="px-3 mt-3"
                containerStyle={{ border: 'none', padding: "3.5px 0px" }}
                value={statusAppearance(exHourlyResult?.status).label}
                color={statusAppearance(exHourlyResult?.status).color}
                bgColor={statusAppearance(exHourlyResult?.status).bgColor}
                font={"normal normal normal 9px/12px Open Sans"}
              />
            )}</div>
          </div>

          <div className={Styles.details}>
            <div className={Styles.detailItem}>
              {materialType == "Coal" ? "Tonnes" : "BCM"}:{" "}
              <span className={Styles.detailValue}>
                <span style={{ fontWeight: '700' }}>{exHourlyResult?.actualBCMs}</span> / {exHourlyResult?.targetBCMs ?? '-'}
              </span>
            </div>
            <div className={Styles.detailItem}>
              Average/hr: <span className={Styles.detailValue}>{calculateAverage(exHourlyResultData?.shiftStatus, exHourlyResultData?.shiftStartTime, exHourlyResultData?.shiftEndTime, exHourlyResult?.actualBCMs)} {materialType == "Coal" ? "Tonnes" : "BCM"}</span>
            </div>
            <div className={Styles.detailItem}>
              Hourly Target:{" "} <span className={Styles.detailValue}>

                {exHourlyResult?.targetBCMs ? (Math.round(exHourlyResult?.targetBCMs / exHourlyResult?.productiveHours) + (materialType == "Coal" ? " Tonnes" : " BCM")) : "-"}

              </span>
            </div>
            <div className={Styles.detailItem}>
              First Load:{" "}
              <span className={Styles.detailValue}>
                {firstLoad(exHourlyResult.firstLoad)}
              </span>
            </div>
          </div>
        </div>

        <div className={Styles.chartContainer}>
          <BarChart
            maxBufferConstant={
              exHourlyResult?.status === Status.UNASSIGNED ||
                exHourlyResult?.status === Status.UNSCHEDULED
                ? 0
                : Math.round(
                  exHourlyResult?.targetBCMs / (exHourlyResult?.productiveHours * 4)
                )
            }
            chartData={exHourlyChartData}
            customColor={["#D6E7FF", "#69B4FF"]}
            showTargetLine={
              !(
                exHourlyResult?.status === Status.UNASSIGNED ||
                exHourlyResult?.status === Status.UNSCHEDULED
              )
            }
            gradient={
              exHourlyResult?.status === Status.UNASSIGNED ||
                exHourlyResult?.status === Status.UNSCHEDULED
                ? ["#AAB7DB", "#7A88C6"]
                : undefined
            }
            targetValue={
              exHourlyResult?.status === Status.UNASSIGNED ||
                exHourlyResult?.status === Status.UNSCHEDULED
                ? 0
                : Math.round(exHourlyResult?.targetBCMs / exHourlyResult?.productiveHours)
            }

          />
        </div>
      </div>
    </div>
  );
};
export default ExcavatorsHourlyResult;
