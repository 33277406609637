import React, { useState } from "react";
import DynamicSelectField from "pages/DelayAndActivity/Component/GeneralFields/DynamicSelectField/DynamicSelectField";
import FromToTimeSpan from "pages/DelayAndActivity/Component/GeneralFields/FromToTimeSpan/FromToTimeSpan";
import { FaPlus } from "react-icons/fa";
import Styles from "./AddDelay.module.css";
import { fetchDelaySubTypes } from "../utils/apiService";
import { showToast } from "pages/DelayAndActivity/utils/helper";
import { useDelayContext } from "../context/context";

const commonFieldStyles = {
  background: "#FFFFFF",
  border: "1px solid #E1E8F4",
  borderRadius: "4px",
  marginTop: "10px",
};
const labelStyle = {
  font: "normal normal normal 14px/17px Open Sans",
  color: "#1A1A1A",
};
const valueStyle = {
  font: "normal normal 600 14px/19px Open Sans",
  color: "#1A1A1A",
};

const DelaySection = ({
  index,
  onChange,
  isSelected,
  toggleSelection,
  delayTypeList = [],
  delayData,
  showError
}) => {
  const [delaySubTypeList, setDelaySubTypeList] = useState([])
  return (
    <div >
      <div className="pt-3 pb-4 d-flex align-items-center">
        <input
          className={Styles.delayCheckbox}
          type="checkbox"
          checked={isSelected}
          onChange={toggleSelection}
        />
        <div className="d-flex align-items-center" style={{ width: "100%" }}>
          <div className={`pl-3 ${Styles.checkLabel}`}>
            {`Delay ${String(index + 1).padStart(2, "0")}`}
          </div>
          <div
            style={{
              flexGrow: 1,
              borderBottom: "1px solid #DEE5F2",
              marginLeft: "5px",
              paddingBottom: "11px",
              opacity: 0.5,
            }}
          />
        </div>
      </div>

      <DynamicSelectField
        label="Delay Type"
        placeholder="Select Delay Type"
        required
        options={delayTypeList}
        value={delayData.delayType}
        onChange={async (val) => {
          onChange("delayType", val)
          onChange("delaySubType", "")
          try {
            const data = await fetchDelaySubTypes(val.value)
            setDelaySubTypeList(data)
          } catch (error) {
            showToast(false, error.message)
          }
        }}
        style={commonFieldStyles}
        labelStyle={labelStyle}
        valueStyle={valueStyle}
        error={showError && !delayData?.delayType}
        errorMessage={"Please select a delay type"}
      />

      <DynamicSelectField
        label="Delay Sub-Type"
        placeholder="Select Delay Sub-Type"
        required
        options={delaySubTypeList}
        value={delayData.delaySubType}
        onChange={(val) => onChange("delaySubType", val)}
        style={commonFieldStyles}
        labelStyle={labelStyle}
        valueStyle={valueStyle}
        error={showError && !delayData?.delaySubType}
        errorMessage={"Please select a delay sub type"}
      />

      <FromToTimeSpan
        label="Delay Duration"
        required
        onChange={(key, val) => {
          onChange(key, val)
          // onChange("endTime", endTime)
        }}
        labelStyle={{ ...labelStyle, marginTop: "10px" }}
        valueStyle={valueStyle}
        startTime={delayData.startTime}
        endTime={delayData.endTime}
        error={showError && (!delayData?.startTime || !delayData?.endTime)  }
        errorMessage={"Please select a delay duration"}
      />
    </div >)
}


const AddDelayComponent = ({ showError,setFormData, formData }) => {
  const [selectedDelays, setSelectedDelays] = useState([]);
  const {
    equipmentList,
    delayTypeList,
  } = useDelayContext()


  const handleAddDelay = () => {
    setFormData((prev) => ({
      ...prev,
      delays: [
        ...prev.delays,
        {
          delayType: "",
          delaySubType: "",
          startTime: "",
          endTime: ""
        },
      ],
    }));
  };

  const handleDeleteSelected = () => {
    if (formData.delays.length > 1) {
      setSelectedDelays([]);
      setFormData((prev) => ({
        ...prev,
        delays: prev.delays.filter((_, index) => !selectedDelays.includes(index)),
      }));
    }
  };

  const handleChange = (field, value, index) => {
    setFormData((prev) => {
      const updatedDelays = prev.delays.map((delay, idx) =>
        idx === index ? { ...delay, [field]: value } : delay
      );
      return { ...prev, delays: updatedDelays };
    });
  };

  const toggleSelection = (index) => {
    setSelectedDelays((prev) =>
      prev.includes(index) ? prev.filter((idx) => idx !== index) : [...prev, index]
    );
  };

  const actionButtonStyle = {
    background: "#FFFFFF",
    border: "1px solid #E1E8F4",
    borderRadius: "15px",
    font: "normal normal bold 12px/17px Open Sans",
    color: "#186FD9",
    cursor: "pointer",
  };

  return (
    <div style={{ minHeight: "100%" }} className="d-flex flex-column">
      <div style={{ borderBottom: "1px solid #e1e8f4" }} className="px-5">

        <DynamicSelectField
          isSearchable={true}
          isMulti={true}
          label="Select Assets"
          placeholder="Select Assets"
          options={equipmentList}
          value={formData?.equipments}
          required
          onChange={(val) =>
            setFormData((prev) => ({
              ...prev,
              equipments: val,
            }))
          }
          className="mb-3"
          labelStyle={labelStyle}
          valueStyle={valueStyle}
          error={showError && !formData?.equipments}
          errorMessage={"Please select a asset"}

        />

      </div>
      <div style={{ background: "#FAFBFD", flexGrow: 1 }} className="px-5">
        <div className="d-flex justify-content-between align-items-center pb-5 pt-3">
          <div onClick={handleAddDelay} style={actionButtonStyle} className="px-3 py-2">
            Add Delay <FaPlus color="#186FD9" />
          </div>
          <div onClick={handleDeleteSelected} style={actionButtonStyle} className="px-3 py-2">
            Delete
          </div>
        </div>

        {formData.delays.map((delay, index) => (
          <DelaySection
            key={index}
            index={index}
            delayTypeList={delayTypeList}
            delayData={formData.delays[index]}
            onChange={(field, value) => handleChange(field, value, index)}
            isSelected={selectedDelays.includes(index)}
            toggleSelection={() => toggleSelection(index)}
            showError={showError}
          />
        ))}
      </div>
    </div>
  );
};

export default AddDelayComponent;
