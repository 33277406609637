export const dummy_Licences = [
    {
        "_id": "6491213291c6cf03df3c0b77",
        "name": "Excavator",
        "code": 2,
        "__v": 0,
        "departmentCode": 800
    },
    {
        "_id": "64d462eefba89e600cd101b3",
        "name": "Misc",
        "code": 0,
        "__v": 0,
        "departmentCode": 600
    },
    {
        "_id": "668b172735e826e4871b9422",
        "name": "Truck",
        "code": 6,
        "__v": 0,
        "departmentCode": 600
    },
]


export const base_data = [
    {
        "truck": {
            "_id": "65021fd8590047d7404d8e42",
            "name": "CB144",
            "type": 5
        },
        "operator": {
            "_id": "65274a4e8cdfbbdd9c81ed26",
            "first_name": "Kevin",
            "last_name": "Bacon",
            "signature": "https://nz-steel-mine.s3.ap-southeast-2.amazonaws.com/1722916337188_signature.png"
        },
        "_id": "66b19df1cbd29ba037a6f703",
        "status": "cat c failure",
        "loginTime": "2024-08-06T03:51:12.091Z",
        "engineHours": 2999.1,
        "odometers": 7228,
        "checkpoints": [
            {
                "checkpoints": [
                    {
                        "question": "Isolation lock in place and working",
                        "answer": "n/a",
                        "imagePaths": [],
                        "_id": "66b19df1cbd29ba037a6f705"
                    },
                    {
                        "question": "Lift, tilt blade cylinders",
                        "answer": "ok",
                        "imagePaths": [],
                        "_id": "66b19df1cbd29ba037a6f706"
                    },
                    {
                        "question": "Warning system functioning",
                        "answer": "ok",
                        "imagePaths": [],
                        "_id": "66b19df1cbd29ba037a6f707"
                    },
                    {
                        "question": "Fire extinguisher (Certified)",
                        "answer": "ok",
                        "imagePaths": [],
                        "_id": "66b19df1cbd29ba037a6f708"
                    },
                    {
                        "question": "Fire suppression (if fitted)",
                        "answer": "ok",
                        "imagePaths": [],
                        "_id": "66b19df1cbd29ba037a6f709"
                    },
                    {
                        "question": "Pins & retainers",
                        "answer": "ok",
                        "imagePaths": [],
                        "_id": "66b19df1cbd29ba037a6f70a"
                    },
                    {
                        "question": "Park brake/trans lock",
                        "answer": "ok",
                        "imagePaths": [],
                        "_id": "66b19df1cbd29ba037a6f70b"
                    },
                    {
                        "question": "Hydraulic lock if fitted",
                        "answer": "ok",
                        "imagePaths": [],
                        "_id": "66b19df1cbd29ba037a6f70c"
                    },
                    {
                        "question": "ROPS and/or FOPS",
                        "answer": "ok",
                        "imagePaths": [],
                        "_id": "66b19df1cbd29ba037a6f70d"
                    },
                    {
                        "question": "Horn & reverse alarm",
                        "answer": "ok",
                        "imagePaths": [],
                        "_id": "66b19df1cbd29ba037a6f70e"
                    },
                    {
                        "question": "Seat belt & Seat (Safe)",
                        "answer": "ok",
                        "imagePaths": [],
                        "_id": "66b19df1cbd29ba037a6f70f"
                    },
                    {
                        "question": "Lights (flashing)",
                        "answer": "ok",
                        "imagePaths": [],
                        "_id": "66b19df1cbd29ba037a6f710"
                    },
                    {
                        "question": "Clear birds’ nests",
                        "answer": "ok",
                        "imagePaths": [],
                        "_id": "66b19df1cbd29ba037a6f711"
                    }
                ],
                "category": "Category A",
                "_id": "66b19df1cbd29ba037a6f704"
            },
            {
                "checkpoints": [
                    {
                        "question": "Two-way radio available",
                        "answer": "ok",
                        "imagePaths": [],
                        "_id": "66b19df1cbd29ba037a6f713"
                    },
                    {
                        "question": "Fuel leaks",
                        "answer": "ok",
                        "imagePaths": [],
                        "_id": "66b19df1cbd29ba037a6f714"
                    },
                    {
                        "question": "Main machine access, ladders, rails",
                        "answer": "ok",
                        "imagePaths": [],
                        "_id": "66b19df1cbd29ba037a6f715"
                    },
                    {
                        "question": "Grease lines",
                        "answer": "ok",
                        "imagePaths": [],
                        "_id": "66b19df1cbd29ba037a6f716"
                    },
                    {
                        "question": "New damage",
                        "answer": "ok",
                        "imagePaths": [],
                        "_id": "66b19df1cbd29ba037a6f717"
                    },
                    {
                        "question": "Debris build up",
                        "answer": "ok",
                        "imagePaths": [],
                        "_id": "66b19df1cbd29ba037a6f718"
                    },
                    {
                        "question": "Mirrors & windows",
                        "answer": "ok",
                        "imagePaths": [],
                        "_id": "66b19df1cbd29ba037a6f719"
                    },
                    {
                        "question": "Coolant leaks",
                        "answer": "ok",
                        "imagePaths": [],
                        "_id": "66b19df1cbd29ba037a6f71a"
                    },
                    {
                        "question": "Blade, G.E.T & Ripper boots",
                        "answer": "ok",
                        "imagePaths": [],
                        "_id": "66b19df1cbd29ba037a6f71b"
                    },
                    {
                        "question": "Major oil leaks",
                        "answer": "ok",
                        "imagePaths": [],
                        "_id": "66b19df1cbd29ba037a6f71c"
                    },
                    {
                        "question": "Camera if fitted",
                        "answer": "ok",
                        "imagePaths": [],
                        "_id": "66b19df1cbd29ba037a6f71d"
                    },
                    {
                        "question": "Hydraulic hose leaks",
                        "answer": "ok",
                        "imagePaths": [],
                        "_id": "66b19df1cbd29ba037a6f71e"
                    },
                    {
                        "question": "Grouser plates, Ripper cylinders",
                        "answer": "ok",
                        "imagePaths": [],
                        "_id": "66b19df1cbd29ba037a6f71f"
                    },
                    {
                        "question": "Sprockets",
                        "answer": "ok",
                        "imagePaths": [],
                        "_id": "66b19df1cbd29ba037a6f720"
                    },
                    {
                        "question": "Grease tank level",
                        "answer": "ok",
                        "imagePaths": [],
                        "_id": "66b19df1cbd29ba037a6f721"
                    },
                    {
                        "question": "Carrier/load rollers/idlers",
                        "answer": "ok",
                        "imagePaths": [],
                        "_id": "66b19df1cbd29ba037a6f722"
                    },
                    {
                        "question": "Fluid levels (Eng, Hyd, Trans, Coolant)",
                        "answer": "ok",
                        "imagePaths": [],
                        "_id": "66b19df1cbd29ba037a6f723"
                    }
                ],
                "category": "Category B",
                "_id": "66b19df1cbd29ba037a6f712"
            },
            {
                "checkpoints": [
                    {
                        "question": "Minor oil leaks",
                        "answer": "ok",
                        "imagePaths": [],
                        "_id": "66b19df1cbd29ba037a6f725"
                    },
                    {
                        "question": "Wipers/Washers",
                        "answer": "n/a",
                        "imagePaths": [],
                        "_id": "66b19df1cbd29ba037a6f726"
                    },
                    {
                        "question": "Existing damage",
                        "answer": "Defect found",
                        "imagePaths": [],
                        "_id": "66b19df1cbd29ba037a6f727"
                    },
                    {
                        "question": "Fuel level",
                        "answer": "Defect found",
                        "imagePaths": [],
                        "_id": "66b19df1cbd29ba037a6f728"
                    },
                    {
                        "question": "Radiator free from dust build up",
                        "answer": "Defect found",
                        "imagePaths": [],
                        "_id": "66b19df1cbd29ba037a6f729"
                    },
                    {
                        "question": "Air conditioner",
                        "answer": "ok",
                        "imagePaths": [],
                        "_id": "66b19df1cbd29ba037a6f72a"
                    }
                ],
                "category": "Category C",
                "_id": "66b19df1cbd29ba037a6f724"
            }
        ],
        "checkTime": "2024-08-06T03:52:17.182Z",
        "refNo": "PS412",
        "__v": 0
    }
]