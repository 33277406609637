import { forwardRef } from 'react';
import { Dropdown } from 'react-bootstrap';
import ActionIcon from '../../../../assets/images/Actions.png';
import Delete from '../../../../assets/svgs/Delete.svg';
import Edit from '../../../../assets/svgs/edit.svg';


const CustomToggle = forwardRef(({ onClick }, ref) => (
  <img
    className="image-click"
    alt="action button"
    src={ActionIcon}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  />
));

export const dropdownOptions = {
  view: 'view',
  edit: 'edit',
  delete: 'delete'
};

export const SignatureAction = ({
  selectedSign,
  handleDropdownOptions,
}) => {
  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} />
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={() =>
            handleDropdownOptions(dropdownOptions.edit, selectedSign)
          }
        >
          <div className="d-flex">
            <img src={Edit} className="mr-2" alt='edit' />
            <span className="dropdown-text">Edit</span>
          </div>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            handleDropdownOptions(dropdownOptions.delete, selectedSign);
          }}
        >
          <div className="d-flex">
            <img src={Delete} className="mr-2" alt='delete' />
            <span className="dropdown-text">Delete</span>
          </div>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
