import React from "react";

const LinearPieChart = ({
  data = [
    {
      title: "No Data",
      value: 10,
      style: {
        background: "transparent linear-gradient(270deg, #EDEBEA 0%, #DDDBD9 100%)",
        boxShadow: "0px 0px 45px #E4E2E040",
      },
    },
  ]
}) => {

  const total = data.reduce((acc, item) => acc + item.value, 0);

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "12px",
        borderRadius: "15px",
        overflow: "hidden",
        background: "#f8f9fa",
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
      }}
    >
      {data.map((segment, index) => (
        <div
          key={index}
          style={{
            width: `${(segment.value / total) * 100}%`,
            ...segment.style, // Apply dynamic gradient and shadow
            transition: "width 0.3s ease",
          }}
        ></div>
      ))}
    </div>
  );
};

export default LinearPieChart;
