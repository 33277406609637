import moment from "moment";
import { DateTimeFormats, ShiftStatus } from "./contants";

export const getDurationBetweenMomentsInHHMM = (startTime, endTime) => {
  const duration = moment.duration(moment(endTime).diff(moment(startTime)));
  return `${Math.floor(
    duration.asHours()).toString().padStart(2, "0")
    }:${duration.minutes().toString().padStart(2, "0")} hrs`;
};

export const formateDataForView = (data) => {
  const formattedData = {};
  Object.keys(data).forEach((key) => {
    if (Array.isArray(data[key])) {
      formattedData[key] = data[key].length
        ? data[key]
          .map((item) => {
            if (typeof item === "object" && item !== null) {
              return item.label;
            }
            return item;
          })
          .join(", ")
        : "";
    } else if (typeof data[key] === "object" && data[key] !== null) {
      formattedData[key] = data[key].label;
    } else if (data[key] == null) {
      formattedData[key] = "";
    } else {
      formattedData[key] = data[key];
    }
  });
  return formattedData;
};

export const clipString = (string, characters) => {
  if (string?.length > characters + 3) {
    return string.substring(0, characters) + "...";
  }
  return string;
};

export const highlightText = (textInp, highlight) => {
  if (!highlight || !textInp) return textInp;
  const text = textInp.toString();

  const parts = text.split(new RegExp(`(${highlight})`, "gi"));
  return parts.map((part, index) =>
    part.toLowerCase() === highlight.toLowerCase() ? (
      <span key={index} style={{ backgroundColor: "yellow" }}>
        {part}
      </span>
    ) : (
      part
    )
  );
};

export const searchExist = (text, search, clipLength) => {
  let exist = false;
  if (!search || !text) {
    return exist;
  }
  if (text?.length <= clipLength) {
    return exist;
  }
  return text.substr(clipLength)?.toLowerCase()?.includes(search.toLowerCase())
};

export const getDefaultNewShiftTimings = () => {
  const currentMoment = moment();
  const prevNightStart = currentMoment
    .clone()
    .subtract(1, "day")
    .set({ hour: 17, minute: 0 });
  const prevNightEnd = currentMoment.clone().set({ hour: 4, minute: 0 });
  const dayStart = currentMoment.clone().set({ hour: 6, minute: 0 });
  const dayEnd = currentMoment.clone().set({ hour: 17, minute: 0 });
  const nightStart = currentMoment.clone().set({ hour: 17, minute: 0 });
  const nightEnd = currentMoment
    .clone()
    .add(1, "day")
    .set({ hour: 4, minute: 0 });

  const isLastNightShift = currentMoment.isBetween(
    prevNightStart,
    prevNightEnd
  );
  const isDayShift = currentMoment.isBetween(prevNightEnd, nightStart);

  const startTime = isLastNightShift
    ? prevNightStart.format(DateTimeFormats.DATE_TIME_U)
    : isDayShift
      ? dayStart.format(DateTimeFormats.DATE_TIME_U)
      : nightStart.format(DateTimeFormats.DATE_TIME_U);
  const endTime = isLastNightShift
    ? prevNightEnd.format(DateTimeFormats.DATE_TIME_U)
    : isDayShift
      ? dayEnd.format(DateTimeFormats.DATE_TIME_U)
      : nightEnd.format(DateTimeFormats.DATE_TIME_U);

  return { isDayShift, startTime, endTime };
};

/**
 * Function to update all 'startTime' and 'endTime' strings in an object to ISO format.
 * It recursively traverses the object and formats any 'startTime' and 'endTime' strings.
 *
 * @param {Object} obj - The input object containing date strings to be formatted.
 * @returns {Object} - The new object with formatted date strings.
 */
export const updateTimes = (obj) => {
  // Recursive function to traverse and update the object
  const recursiveUpdate = (item) => {
    if (typeof item === "object" && item !== null) {
      for (let key in item) {
        if (key === "startTime" || key === "endTime") {
          item[key] = moment(item[key], "DD/MM/YYYY, LT").toISOString();
        } else if (typeof item[key] === "object") {
          recursiveUpdate(item[key]);
        }
      }
    }
  };

  const newObj = JSON.parse(JSON.stringify(obj));
  recursiveUpdate(newObj);
  return newObj;
};

export const getShiftStatus = (status) => {
  if (status === ShiftStatus.NEW) {
    return "New";
  } else if (status === ShiftStatus.DRAFT) {
    return "Draft";
  } else if (status === ShiftStatus.PUSBLISHED) {
    return "Published";
  } else if (status === ShiftStatus.ONGOING) {
    return "Ongoing";
  } else {
    return "Ended";
  }
};

export const getTargetLoads = (excavator, haultTrucks) => {
  return Math.round(
    Math.max(
      (excavator?.targetBCMs - (excavator?.actualBCMs || 0)) /
      (haultTrucks?.reduce((prev, curr) => prev + curr?.bcmPerLoad, 0) /
        haultTrucks?.length) /
      haultTrucks?.length
      , 0)
  );
};
export const convertHoursToHHMM = (hours) => {
  hours = Number(hours);
  const totalMinutes = Math.floor(hours * 60);  // Convert hours to total minutes
  const hh = Math.floor(totalMinutes / 60);     // Extract hours
  const mm = totalMinutes % 60;                 // Extract remaining minutes

  return moment().startOf('day').add(hh, 'hours').add(mm, 'minutes').format('HH:mm');
}



