import React from "react";
import Header from "./Header/Header";
import LoadTrackerLayout from "./LoadTrackerLayout/LoadTrackerLayout";
import { useExcavatorLoadTrackerContext } from "./context/context";
import { Riple } from "react-loading-indicators";

const ExcavatorLoadTracker = () => {
  const { loading } = useExcavatorLoadTrackerContext();

  return (
    <div
      style={{
        overflowY: "hidden",
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Header />
      {loading ? (
        <div
          style={{ height: "100%" }}
          className="d-flex align-items-center justify-content-center"
        >
          <Riple color="#317bcc" size="medium" text="" textColor="" />
        </div>
      ) : (
        <LoadTrackerLayout />
      )}
    </div>
  );
};

export default ExcavatorLoadTracker;
