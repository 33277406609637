import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendarAlt } from 'react-icons/fa';
import styles from './DateTimeSelect.module.css';
import { IoIosArrowDown } from 'react-icons/io';

const DateTimeSelect = ({
    label,
    value,
    placeholder = "DD/MM/YYYY",
    dateFormat = "yyyy/MM/dd",
    onDateChange,
    containerStyle = {},
    className = "",
    iconColor = "#1a73e8",
}) => {

    const handleChange = (date) => {
        if (onDateChange) {
            onDateChange(new Date(date));
        }
    };

    return (
        <div className='d-flex align-items-center '>
            {label && <div>{label}</div>}
            <div className={styles.datePickerContainer + ` pl-3 ${className}`} style={containerStyle}>
                <DatePicker
                    selected={value}
                    onChange={handleChange}
                    dateFormat={dateFormat}
                    placeholderText={placeholder}
                    className={styles.customInput}
                />
                <FaCalendarAlt className={styles.calendarIcon} style={{ color: iconColor }} />
            </div>
        </div>
    );
};

export default DateTimeSelect;
