import ColorDot from "pages/KPIDashboard/Component/ColorDot/ColorDot"
import styles from "../BreakDown/BreakDown.module.css"

const Breakdown = ({ actual = 0, shortFall, above, labelStyle, target }) => {


  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <div className={styles.label} style={labelStyle}>
          <span>
            <ColorDot
              bgColor={"#88D918"}
              containerStyle={{ opacity: "0.8" }}
            />
          </span>{" "}
          Actual
        </div>
        <div className={styles.value}>{target ? actual : (actual || '-')}</div>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <div className={styles.label} style={labelStyle}>
          <span>
            <ColorDot
              bgColor={"#FF3E2B"}
              containerStyle={{ opacity: "0.8" }}
            />
          </span>{" "}
          Shortfall
        </div>
        <div className={styles.value}>{shortFall || '-'}</div>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <div className={styles.label} style={labelStyle}>
          <span>
            <ColorDot
              bgColor={"#87C2FF"}
              containerStyle={{ opacity: "0.8" }}
            />
          </span>{" "}
          Above Target
        </div>
        <div className={styles.value}>{above || '-'}</div>
      </div>
    </>
  )
}

export default Breakdown
