import moment from 'moment-timezone';

// Helper function to check if filters are empty
export const isEmptyFilter = (equipments, delayTypes, startTime, endTime) => {
    return (
        equipments.length === 0 &&
        delayTypes.length === 0 &&
        !startTime &&
        !endTime
    );
};

// Filter equipment based on the provided filters
export const filterEquipments = (items, equipmentFilter, delayTypeFilter, startTime, endTime) => {
    return items
        ?.filter(item =>
            equipmentFilter.length === 0 ||
            equipmentFilter.some(eq => item.name === eq.label)
        )
        .map(item => {
            const filteredDelays = filterDelays(item.delays, delayTypeFilter, startTime, endTime);
            return {
                ...item,
                delays: filteredDelays,
            };
        })
        .filter(eq => eq.delays && eq.delays.length > 0);
};

// Filter delays based on the provided filters
export const filterDelays = (delays, delayTypeFilter, startTime, endTime) => {
    return delays?.filter(delay => {
        const delayMatches =
            delayTypeFilter.length === 0 ||
            delayTypeFilter.some(d => delay.delayType === d.label);

        const startSec = getSecondsFromStartOfDay(delay.startTime);
        const endSec = delay.endTime ? getSecondsFromStartOfDay(delay.endTime) : undefined;

        const startTimeMatches = startSec >= (startTime.value || 0) && startSec <= (endTime.value || 86400);
        const endTimeMatches = !endSec || (endSec >= (startTime.value || 0) && endSec <= (endTime.value || 86400));

        return delayMatches && startTimeMatches && endTimeMatches;
    });
};

// Get seconds from the start of the day for a given time
export const getSecondsFromStartOfDay = (time) => {
    return moment(time).tz("Pacific/Auckland").diff(moment(time).tz("Pacific/Auckland").startOf("day"), 'seconds');
};
