import { Riple } from "react-loading-indicators";
import ExcavatorsHourlyResult from "./ExcavatorsHourlyResult/ExcavatorsHourlyResult";
import ExcavatorTruckHourlyResult from "./ExcavatorTruckHourlyResult/ExcavatorTruckHourlyResult";
import { Status } from "pages/Dashboard/constant";
import { sortTruckAssignments } from "./helper";

const HourlyResult = ({ isLoading, exHourlyResultData, shiftType, materialType }) => {
   if (isLoading)
      return (
         <div
            style={{ height: "100%" }}
            className="d-flex align-items-center justify-content-center"
         >
            <Riple color="#317bcc" size="medium" text="" textColor="" />
         </div>
      );
   if (
      !exHourlyResultData.exHourlyResult?.[0]?.truckAssignments ||
      !exHourlyResultData
   ) {
      return (
         <div
            style={{ color: "#99A0AC", flexGrow: 1 }}
            className="d-flex px-5 w-100 align-items-center justify-content-center"
         >
            <span className="mb-3">No records to display</span>
         </div>
      );
   }
   return (
      <div className="px-5">
         <ExcavatorsHourlyResult
            materialType={materialType}
            shiftType={shiftType}
            exHourlyResultData={exHourlyResultData}
         />
         <ExcavatorTruckHourlyResult
            shiftType={shiftType}
            shiftStatus={exHourlyResultData?.shiftStatus}
            productiveHours={
               exHourlyResultData?.exHourlyResult?.[0]?.productiveHours
            }
            exHourlyTruckResult={sortTruckAssignments(exHourlyResultData.exHourlyResult?.[0]?.truckAssignments)}
         />
      </div>
   );
};

export default HourlyResult;
