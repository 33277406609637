import React, { useEffect, useState } from "react";
// import Search from "../../components/GeneralFields/Search";
import Flyer from "../../../ShiftPlanning/components/Flyers/Flyer";
import Filter from "./Filter";
import { InitialFilters } from "./utils/contants";
import filter from "../../../../assets/images/filter.png";
import styles from '../../../ShiftPlanning/SubComponents/ShiftPlanFilter/Filter.module.css';

const PreStartFilter = ({
    onApply,
    onClear,
    filterObj,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [filters, setFilters] = useState({ ...filterObj });

    const handleOnChange = (key, val) => {
        const tempFilters = { ...filters };
        tempFilters[key] = val;
        setFilters(tempFilters);
    };

    return (
        <div className="d-flex">
            {/* <Search onChange={onSearch} query={query} /> */}
            <div style={isOpen ? { backgroundColor: "#E2ECFC" } : {}} className={styles.filterbtn} onClick={() => setIsOpen(!isOpen)}>
                <img src={filter} />
                <span>Filter</span>
            </div>
            {isOpen && (
                <Flyer
                    isOpen={isOpen}
                    onClose={() => {
                        // setFilters({ ...filterObj });
                        setIsOpen(false);
                    }}
                    title={"Filters"}
                    containerStyle={{
                        zIndex: '100', right: 0, bottom: 0, top: 'unset',
                        height: 'calc(100vh - 14.8rem)'
                    }}
                    // style={{ height: 'calc(100vh - 11.3rem)' }}
                    tabs={[
                        {
                            title: "",
                            rightActionButtons: [
                                {
                                    label: "Clear All",
                                    onClick: () => {
                                        setFilters(InitialFilters);
                                        onClear();
                                        setIsOpen(false);
                                    },
                                    outline: true,
                                },
                            ],
                            leftActionButtons: [
                                {
                                    label: "Apply",
                                    onClick: () => {
                                        onApply(filters);
                                        setIsOpen(false);
                                    },
                                },
                            ],
                            component: (
                                <Filter
                                    filters={filters}
                                    onChange={handleOnChange}

                                />
                            ),
                        },
                    ]}
                />
            )}
        </div>
    );
};

export default PreStartFilter;
