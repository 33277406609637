import ImageUploader from "pages/ShiftHistory/Component/ImageUploader/ImageUploader";
import React, { useState, useEffect } from "react";
import styles from "../AddSign/AddSign.module.css"

const AddSign = ({
  closeModal = () => {},
  onChange = () => {},
  props = {},
}) => {
  const [data, setData] = useState({
    signedName: props?.signedName,
    file: props?.signature,
    f: "",
  });
  const [file, setFile] = useState("");
  const handleCancelClick = () => {
    closeModal(false);
  };

  const handleSaveClick = () => {
    onChange({ ...data, f: file });
    closeModal(false);
  };

  return (
    <div
      style={{
        marginTop: "50px",
        borderRadius: "10px",
        width: "365px",
        boxShadow: "0 0 10px rgba(0,0,0,0.15)",
      }}
      className="pdf-view d-flex justify-content-center p-4 align-items-start flex-column overflow-hidden "
    >
      <div className=" d-flex w-100 justify-content-start align-items-start flex-column">
        <div className="mb-3 ">
          <strong>Add a Signature</strong>
        </div>
        <div
          style={{
            font: "normal normal normal 12px/17px Open Sans",
            color: "#1A1A1A",
          }}
          className="mb-2"
        >
          Signee’s name*
        </div>
        <input
          type="text"
          className="w-100 px-3 rounded"
          style={{ border: "1px solid #EBEBEB" }}
          value={data?.signedName}
          onChange={(e) => {
            setData({ ...data, signedName: e.target.value });
          }}
        />
      </div>
      <div className="w-100">
        <ImageUploader
          selectedImage={data?.file}
          setSelectedImage={(imageData) =>
            setData({ ...data, file: imageData })
          }
          setFile={(file) => {
            setFile(file);
          }}
        />
      </div>
      <div className="mt-3 d-flex w-100 justify-content-between align-items-center ">
        <div
          name="Cancel"
          className={`mr-3 ${styles.cancel}`}
          onClick={handleCancelClick}
        >
          Cancel
        </div>
        <div
          name="Save"
          className={`${styles.save} ${!(data?.signedName && data?.file) ? styles.disabled : ''}`}
          style={{
         
           
          }}
          onClick={handleSaveClick}
        >
          Save
        </div>
      </div>
    </div>
  );
};

export default AddSign;
