import Ajv from "ajv";
import { AJVSchema } from '../Schema'
import { toast } from 'react-toastify';
import { getExShiftPlanUpdates, getTruckUpdates } from './FormUpdates';
import { CallWithAuth } from '../../../../action/apiActions';
import { ShiftStatus } from "../contants";
const ajv = new Ajv();

export function validateShiftDetailsObject(obj, shiftStatus) {
    const validate = ajv.compile(AJVSchema(shiftStatus));
    const valid = validate(obj);
    return { valid, errors: validate.errors };
}

export function checkForTruckAssignedOrNot(obj) {
    return obj?.assignedExcavators?.some(excavator => {
        const unassignedHaulTrucks = excavator?.excavatorHaulTrucks?.filter(exht =>
            !excavator?.shiftPlans?.some(shiftPlan =>
                shiftPlan?.haulTrucks?.some(ht => ht.equipment === exht)
            )
        );

        return unassignedHaulTrucks.length > 0;
    });
}

export const formateDataForSaving = (data) => {
    const formattedData = {};
    Object.keys(data).forEach((key) => {
        if (Array.isArray(data[key])) {
            formattedData[key] = data[key].length
                ? data[key].map((item) => {
                    if (typeof item === "object" && item !== null && item.value) {
                        return item.value;
                    }
                    return item;
                })
                : [];
        } else if (typeof data[key] === "object" && data[key] !== null) {
            formattedData[key] = data[key].value;
        } else {
            formattedData[key] = data[key];
        }
    });
    return formattedData;
};

export async function formatFormData(generalShiftPlanDetailsFormData, assignedExcavatorDetailsFormData, removedEquipments) {
    const formatHaulTrucks = async (haulTrucks) => {
        return Promise.all(haulTrucks?.map(ht => formateDataForSaving(ht)));
    };

    const formatShiftPlans = async (shiftPlans) => {
        return Promise.all(shiftPlans.map(async shiftPlan => formateDataForSaving({
            ...shiftPlan,
            haulTrucks: await formatHaulTrucks(shiftPlan.haulTrucks || []),
            removedHaulTrucks: await formatHaulTrucks(shiftPlan.removedHaulTrucks || [])
        })));
    };

    const formatExcavators = async (excavators) => {
        return Promise.all(excavators.map(async ex => formateDataForSaving({
            ...ex,
            haulTrucks: await formatHaulTrucks(ex.haulTrucks || []),
            removedHaulTrucks: await formatHaulTrucks(ex.removedHaulTrucks || []),
            shiftPlans: await formatShiftPlans(ex.shiftPlans || [])
        })));
    };

    const formatRemovedEquipments = async (equipments) => {
        return Promise.all(equipments?.map(eq => formateDataForSaving(eq)));
    };

    const formattedData = {
        ...formateDataForSaving(generalShiftPlanDetailsFormData),
        assignedExcavators: await formatExcavators(assignedExcavatorDetailsFormData || []),
        removedEquipments: await formatRemovedEquipments(removedEquipments || []),
        status: generalShiftPlanDetailsFormData.status === ShiftStatus.NEW ? "P" : generalShiftPlanDetailsFormData.status
    };

    return formattedData;
}


// Generate error messages based on validation errors
export const generateErrorMessage = (validationErrors, shiftStatus) => {
    console.log(validationErrors);
    return validationErrors?.map(error => {
        const field = error?.instancePath?.split('/').pop() ?? error?.dataPath?.split('/').pop();
        switch (error.keyword) {
            case 'required':
                return `The field "${error.params.missingProperty}" is required.`;
            case 'type':
                return `The field "${field}" is invalid or empty.`;
            case 'minLength':
                return field.includes("haulTrucks")
                    ? `Please add "${field}" to Trucks.`
                    : field.includes("assignedExcavator")
                        ? `Please add "${field}" to Excavators.`
                        : `Please add "${field}" to the Shift.`;
            case 'minItems':
                return field.includes("excavatorHaulTrucks")
                    ? `Please assign at least one Truck to every Excavator.`
                    : field.includes("haulTrucks")
                        ? `Please assign at least one Truck to every Excavator Plan.`
                        : `Please assign at least one Excavator to the Shift.`;
            default:
                return `Error in the field "${field}": ${error.message}`;
        }
    }).join('\n');
}

// Handle API response and display messages
export const handleApiResponse = async (data, saveAndPublish) => {
    try {

        const response = await CallWithAuth("POST", "api/shift-planning/shift-details/save", { ...data, status: saveAndPublish ? (data?.status === "D" ? "P" : data?.status) : "D" });
        if (response?.res?.data?.status === 200) {
            toast.success("Shift details added successfully.", {
                position: "bottom-right",
                autoClose: 6000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            return true;
        } else {
            toast.error(response?.res?.data?.errors || "Error occurred while adding shift.", {
                position: "bottom-right",
                autoClose: 6000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            return false;
        }
    } catch (error) {
        toast.error("Error occurred while adding shift.", {
            position: "bottom-right",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
        return false;
    }
}

// Calculate shift plan updates
export const calculateShiftPlanUpdates = (originalData, assignedData) => {
    return assignedData?.map((ex, exIdx) => ({
        ...ex,
        shiftPlans: ex?.shiftPlans?.map((shiftPlan, shiftPlanIdx) => {
            const exType = !shiftPlan?.assignmentId ? "added" : "edit";
            const orEx = originalData?.find(e => e.plantId === ex?.plantId);

            return {
                ...shiftPlan,
                haulTrucks: shiftPlan?.haulTrucks?.map((ht) => ({
                    ...ht,
                    updates: exType === "added" ? [{
                        key: "Asset Added",
                        to: ht?.plantId
                    }] : getTruckUpdates(orEx?.shiftPlans?.[shiftPlanIdx]?.haulTrucks, ht)
                })),
                removedHaulTrucks: shiftPlan?.removedHaulTrucks?.map((ht) => ({
                    ...ht,
                    updates: !orEx?.shiftPlans?.[shiftPlanIdx]?.removedHaulTrucks?.find(ht => ht?.plantId === ht?.plantId) ? [{
                        key: "Asset Removed",
                        to: ht?.plantId
                    }] : []
                })),
                updates: exType === "added" ? [{
                    key: "Asset Added",
                    to: ex?.plantId
                }] : orEx ? getExShiftPlanUpdates(orEx?.shiftPlans?.[shiftPlanIdx], shiftPlan) : []
            };
        })
    }));
};

// Calculate removed equipment updates
export const calculateRemovedEquipmentsUpdates = (removedEquipments, originalShiftData) => {
    return removedEquipments?.map((re) => {
        const isNewlyRemoved = !originalShiftData?.removedEquipments || originalShiftData?.removedEquipments?.findIndex(ore => ore?.plantId === re?.plantId) === -1;
        return {
            ...re,
            shiftPlans: re?.shiftPlans?.map((shiftPlan) => ({
                ...shiftPlan,
                updates: !shiftPlan?.removedFromPlan && isNewlyRemoved ? [{
                    key: "Asset Removed",
                    to: re?.plantId
                }] : []
            }))
        };
    });
};

