import { convertSecondsToTime } from "pages/ShiftHistory/utils/helper";
import styles from "../ShiftSummaryReport.module.css";

const DelayAndActivitySection = ({ data, header, containerClass }) => {
  return (
    <div className={containerClass} id={header.toLowerCase()}>
    <div className={`py-4 ${styles.singleHeader}`}>{header}</div>
    <div>
      {data?.map((delay, index) => {
        const isLeft = index % 2 === 0; 
        const nextDelay = data[index + 1]; 
  
       
        const getSubTypeParts = (subType) => {
          const parts = subType?.split("-").map((part) => part.trim());
          if (parts.length === 1) {
            parts[0] = "-";
            parts[1] = subType;
          }
          return parts;
        };
  
        if (isLeft) {
          const leftSubType = getSubTypeParts(delay?.subType);
          const rightSubType = nextDelay ? getSubTypeParts(nextDelay?.subType) : null;
  
          return (
            <div
              key={index}
              className="d-flex align-items-center justify-content-between"
            >
              {/* Left item */}
              <div
                className={`px-3 py-4 d-flex align-items-center justify-content-between ${styles.dualHeaderLeft}`}
              >
                <div className={styles.dualType}>{leftSubType[0]}</div>
                <div className={styles.dualData}>{leftSubType[1]}</div>
                <div className={styles.dualTime}>
                  {convertSecondsToTime(delay?.totalTime)}
                </div>
              </div>
  
              {/* Right item (if available) */}
              {nextDelay && (
                <div
                  className={`px-3 py-4 d-flex align-items-center justify-content-between ${styles.dualHeaderRight}`}
                >
                  <div className={styles.dualType}>{rightSubType[0]}</div>
                  <div className={styles.dualData}>{rightSubType[1]}</div>
                  <div className={styles.dualTime}>
                    {convertSecondsToTime(nextDelay?.totalTime)}
                  </div>
                </div>
              )}
            </div>
          );
        }
  
        return null; 
      })}
    </div>
  </div>
  
  );
};

export default DelayAndActivitySection;
