import moment from 'moment-timezone';

// Helper function to check if filters are empty
export const isEmptyFilter = (equipments, activityTypes, startTime, endTime) => {
    return (
        equipments.length === 0 &&
        activityTypes.length === 0 &&
        !startTime &&
        !endTime
    );
};

// Filter equipment based on the provided filters
export const filterEquipments = (items, equipmentFilter, activityTypeFilter, startTime, endTime) => {
    return items
        ?.filter(item =>
            equipmentFilter.length === 0 ||
            equipmentFilter.some(eq => item.name === eq.label)
        )
        .map(item => {
            const filteredActivities = filterActivities(item.activities, activityTypeFilter, startTime, endTime);
            return {
                ...item,
                activities: filteredActivities,
            };
        })
        .filter(eq => eq.activities && eq.activities.length > 0);
};

// Filter activities based on the provided filters
export const filterActivities = (activities, activityTypeFilter, startTime, endTime) => {
    return activities?.filter(activity => {
        const activityMatches =
            activityTypeFilter.length === 0 ||
            activityTypeFilter.some(d => activity.activityType === d.label);

        const startSec = getSecondsFromStartOfDay(activity.startTime);
        const endSec = activity.endTime ? getSecondsFromStartOfDay(activity.endTime) : undefined;

        const startTimeMatches = startSec >= (startTime.value || 0) && startSec <= (endTime.value || 86400);
        const endTimeMatches = !endSec || (endSec >= (startTime.value || 0) && endSec <= (endTime.value || 86400));

        return activityMatches && startTimeMatches && endTimeMatches;
    });
};

// Get seconds from the start of the day for a given time
export const getSecondsFromStartOfDay = (time) => {
    return moment(time).tz("Pacific/Auckland").diff(moment(time).tz("Pacific/Auckland").startOf("day"), 'seconds');
};
