import React, { useEffect, useRef, useState } from 'react'
import styles from '../AssetActionMenu/AssetActionMenu.module.css';
// import Kebab from '../../../../assets/images/kebab.png';
import ActionMenuOpen from '../../../../assets/images/Dashboard/ActionMenuOpen.png';
import ActionMenu from '../../../../assets/images/Dashboard/ActionMenu.png';



const AssetActionMenu = ({ isOpen = false, onClick, className, optionsContainerClass, menuIcon, iconContainerStyle, options, optionStyle, optionClass }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(isOpen);
    const menuRef = useRef(null);

    useEffect(() => {
        setIsMenuOpen(isOpen);
    }, [isOpen]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuRef]);

    return (
        <div className={`${styles.mainContainer} ${className}`} ref={menuRef}>
            <div
                onClick={(e) => {
                    e.stopPropagation();
                    if (onClick) {
                        onClick(e);
                    }
                    setIsMenuOpen(!isMenuOpen);
                }}
                className={styles.iconContainer}
                style={{ height: menuIcon ? 'fit-content' : '21px', ...iconContainerStyle }}
            >
                {menuIcon ? menuIcon : isMenuOpen ? <img height={20} src={ActionMenuOpen} alt='ActionMenuOpen' /> : <img height={20} src={ActionMenu} alt='ActionMenu' />}
            </div>

            {isMenuOpen && <div className={`${styles.optionsContainer} d-flex flex-column align-items-start bg-white mt-1 ${optionsContainerClass}`}>
                {options && options.map((option, index) => {
                    return <div key={index} className={`${styles.option} ${optionClass} ${option.disabled ? styles.disabled : ''} pb-2`} style={optionStyle} onClick={(e) => {
                        e.stopPropagation()
                        setIsMenuOpen(false);
                        option.onClick(e)
                    }}>
                        <span className='pr-3'>{option.icon}</span>
                        {option.name}
                    </div>
                })}
            </div>}
        </div>
    )
}

export default AssetActionMenu