import moment from "moment";
import styles from "../ShiftSummaryReport.module.css";

const ExcavatorSection = ({ data }) => {
  return (
    <div id="excavators">
      <div className={`py-4 ${styles.singleHeader}`}>Excavator</div>
      <div id="excavator-data" className="px-3">
        <div
          id="header"
          className="d-flex align-items-center justify-content-between py-3"
          style={{ borderBottom: "1px solid #E1E8F4" }}
        >
          <div className={styles.assetHeader}>Machine Ex</div>
          <div className={styles.assetHeader}>Hour Meter Start</div>
          <div className={styles.assetHeader}>Hour Meter End</div>
          <div className={styles.assetHeader}>Hours</div>
          <div className={styles.assetHeader}>Material</div>
        </div>
        <div
          id="data"
          className="d-flex align-items-center justify-content-between py-4"
          style={{ borderBottom: "1px solid #E1E8F4" }}
        >
          <div className={styles.assetData}>{data?.modelNo}</div>
          <div className={styles.assetData}>{data?.startHour || 0}</div>
          <div className={styles.assetData}>{data?.endHour || 0}</div>
          <div className={styles.assetData}>{(data?.endHour - data?.startHour)}</div>
          <div className={styles.assetData}>{data?.materialType}</div>
        </div>
        <div
          id="header"
          className="d-flex align-items-center justify-content-between py-3"
          style={{ borderBottom: "1px solid #E1E8F4" }}
        >
          <div className={styles.assetHeader}>Location</div>
          <div className={styles.assetHeader}>First Load</div>
          <div className={styles.assetHeader}>Last Load</div>
          <div style={{ width: "20%" }}></div>
          <div style={{ width: "20%" }}></div>
        </div>
        <div
          id="data"
          className="d-flex align-items-center justify-content-between py-4"
          style={{ borderBottom: "1px solid #E1E8F4" }}
        >
          <div className={styles.assetData}>{data?.location}</div>
          <div className={styles.assetData}>{data?.firstLoad ? moment(data?.firstLoad).format('hh:mm A') : '-'}</div>
          <div className={styles.assetData}>{data?.lastLoad ? moment(data?.lastLoad).format('hh:mm A') : '-'}</div>
          <div style={{ width: "20%" }}></div>
          <div style={{ width: "20%" }}></div>
        </div>
      </div>
    </div>
  );
};

export default ExcavatorSection;
