import Styles from "../Header/Header.module.css";
import DateTimeSelect from "pages/ShiftNotes/Component/GeneralFields/DateTimeSelect/DateTimeSelect";
import ToggleButtons from "pages/ShiftNotes/Component/ToggleButton/ToggleButton";
import SelectField from "pages/ShiftNotes/Component/GeneralFields/SelectField/SelectField";
import Search from "pages/ShiftNotes/Component/GeneralFields/Search/Search";
import AddShiftNotes from "../../../ShiftNotes/SubComponent/AddShiftNotes/AddShiftNotes"

import { useShiftNotesContext } from ".././../Context/context";
import filter from "../../../../assets/images/filter.png";
import Filter from "pages/ShiftNotes/SubComponent/Filter/Filter";
import ActionMenu from "pages/ShiftNotes/Component/ActionMenu/ActionMenu";
import History from "../../SubComponent/History/History";
import { useState } from "react";

const SHIFT_TYPES = [
  { name: "Day", value: 1 },
  { name: "Night", value: 2 },
];



const Header = () => {
  const {
    setOpenFlyer,
    openFlyer,
    selectedDate,
    setSelectedDate,
    shiftType,
    setShiftType,
    shiftOptions,
    setSelectedShift,
    searchQuery,
    setSearchQuery,
    filters,
    loading,
    shiftNotesData,
    onApplyFilter
  } = useShiftNotesContext();


  const [historyData, setHistoryData] = useState([]);

  const optionMenu = [
    {
      name: "History",
      disabled:true,
      icon: "",
      onClick: () =>handleHistory( shiftNotesData?.history?.map((hist) => ({
        updatedAt: hist.actionDate,
        updatedBy: hist.actionBy,
        changes: [
          {
            key: 'Note Type',
            to: hist.noteType,
          }
        ],
      })))
     
    },
    {
      name: "Download",
      icon: "",
      disabled: true,
      onClick: () => {},
    },
  ]
  
  const handleHistory = (data) => {
    setHistoryData(data)
    setOpenFlyer("SHIFT_NOTE_HISTORY");
  };
  


  return (
    <div
      // style={{ paddingTop: 30 }}
      className={`d-flex justify-content-between align-items-center pt-5 mx-5 pb-3 ${Styles.header} border-bottom`}
    >
      <div className="d-flex justify-content-between align-items-center">
        <div
          className={`px-3 py-2 ${Styles.addNotesBtn} ${!shiftOptions?.[shiftType]?.length || loading || openFlyer
            ? Styles.disabled
            : ""
            }`}
          onClick={() => setOpenFlyer("ADD_NOTES")}
        >
          Add Notes
        </div>
        {/*  */}
        {openFlyer === "ADD_NOTES" && <AddShiftNotes />}

        <DateTimeSelect
          value={selectedDate}
          onDateChange={(date) => {
            setSelectedDate(date);
            setOpenFlyer("");
          }}
          className={`${Styles.dateTimeSelect} ml-5`}
          iconColor="#6B7B8E"
          containerStyle={{
            border: "1px solid #E1E8F4",
          }}
        />

        <ToggleButtons
          selectedValue={shiftType === "day" ? 1 : 2}
          onOptionClick={(v) => {
            setShiftType(v === 1 ? "day" : "night")
            setOpenFlyer("");
          }}
          style={{ height: 27.5 }}
          options={SHIFT_TYPES}
        />

        <SelectField
          noPlaceHolder={true}
          options={shiftOptions?.[shiftType]}
          value="1"
          className="ml-3"
          isDisabled={!shiftOptions?.[shiftType]?.length || loading}
          style={getSelectFieldStyle(loading, shiftOptions?.[shiftType])}
          onChange={(v) => {
            setSelectedShift((prev) => ({ ...prev, [shiftType]: v }))
            setOpenFlyer("");
          }}
          mainContainerStyle={{ marginBottom: "0px" }}
        />
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <Search query={searchQuery} onChange={(value) => {
          setSearchQuery(value)
          onApplyFilter(filters, value, shiftNotesData)
        }} />

        <div
          style={{
            userSelect: "none",
            backgroundColor:
              openFlyer === "NOTES_FILTER" ||
                filters.noteType ||
                filters.equipments.length > 0 ||
                filters.startTime ||
                filters.endTime
                ? "#E2ECFC"
                : "transparent",
          }}
          className={Styles.filterBtn}
          onClick={() => setOpenFlyer("NOTES_FILTER")}
        >
          <img src={filter} alt="filter" />
          <span>Filter</span>
        </div>
      
        {openFlyer === "NOTES_FILTER" && <Filter />}

        <ActionMenu
                          options={optionMenu}
                          optionsContainerStyle={{
                            font: "normal normal normal 12px/18px Open Sans",
                            color: "#1A1A1A",
                            right: "102.5%",
                          }}
                          className="ml-3"
                        />
        {openFlyer==="SHIFT_NOTE_HISTORY" && <History   handleOnClose={() => setOpenFlyer("")}
          historyData={historyData}/>}  
      </div>
    </div>
  );
};

const getSelectFieldStyle = (isLoading, options) => ({
  height: "30px",
  width: "160px",
  marginTop: 2.5,
  border: "1px solid #F1F1F1",
  borderRadius: "15px",
  paddingLeft: "10px",
  font: "normal normal 600 11px/14px Open Sans",
  color: "#000000",
  cursor: !options?.length || isLoading ? "not-allowed" : "pointer",
});

export default Header;
