import styles from "../ShiftSummaryReport.module.css";

const TableSection = ({ title, headers, data, titleClass, paddingClass }) => {
  return (
    <>
      <div>
        <div
          id={title.toLowerCase()}
          className={` ${paddingClass ? paddingClass :"py-4"} ${titleClass ? titleClass : styles.singleHeader}`}
        >
          {title}
        </div>
        <div >
          <div
            id="header"
            className="d-flex align-items-center justify-content-between px-3 py-3 "
            style={{ borderBottom: "1px solid #E1E8F4" }}
          >
            {headers?.map((header, index) => (
              <div key={index} className={styles.assetHeader}>
                {header.label}
              </div>
            ))}
          </div>
          <div id="excavator-data" className="px-3">
            {data?.map((row, index) => (
              <div
                key={index}
                id="data"
                className="d-flex align-items-center justify-content-between py-4"
                style={{ borderBottom: "1px solid #E1E8F4" }}
              >
                {headers?.map((header, idx) => (
                  <div key={idx} className={styles.assetData}>
                    {row[header.key] ? row[header.key]:"-"
                    } 
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default TableSection;
