import { statusPriority } from "./constant";

export function sortTruckAssignments(truckAssignments) {
    if (!Array.isArray(truckAssignments)) {
        throw new Error("Invalid input: truckAssignments must be an array");
    }

    return truckAssignments.sort((a, b) => {
        // Sort by status priority
        const statusDiff = statusPriority[a.status] - statusPriority[b.status];
        if (statusDiff !== 0) return statusDiff;

        // Secondary sorting within ASSIGNED status: actualLoads vs. targetLoads
        if (statusPriority[a.status] === 1) {
            const aIsUnderLoaded = a.actualLoads < a.targetLoads;
            const bIsUnderLoaded = b.actualLoads < b.targetLoads;
            if (aIsUnderLoaded !== bIsUnderLoaded) {
                return aIsUnderLoaded ? -1 : 1;
            }
        }

        return 0;
    });
}
