import Flyer from "pages/DelayAndActivity/Component/Flyers/Flyer";
import { useState } from "react";
import AddActivityComponent from "./AddActivityComponent";
import Style from "./AddActivity.module.css";
import {
  convertSecToISO,
  formateDataForSaving,
} from "pages/DelayAndActivity/utils/helper";
import { CallWithAuth } from "action/apiActions";
import { toast } from "react-toastify";
import { useActivityContext } from "../context/context";

const AddActivity = () => {
  const initialFormData = {
    equipments: undefined,
    activities: [
      {
        activityType: "",
        startTime: "",
        endTime: "",
      },
    ],
  };
  const { setOpenFlyer, selectedShift, openFlyer, selectedDate, shiftType,refreshPage, setRefreshPage } =
    useActivityContext();
  const [formData, setFormData] = useState(initialFormData);
  const [showError, setShowError] = useState(false);

  const isFormValid = () => {
    if (!formData.equipments?.length) {
      setShowError(true);
      return false;
    }
    const isActivityValid = formData.activities?.every((activity) => {
      return activity.activityType && activity.startTime && activity.endTime;
    });

    if (!isActivityValid) {
      setShowError(true);
      return false;
    }

    setShowError(false);
    return true;
  };

  const saveChanges = async () => {
    //Form validation
    if (!isFormValid()) return;

    // Validate activities first
    const validatedActivities = formData.activities?.map((activity) => {
      const formattedActivity = formateDataForSaving(activity);
      const startTime = convertSecToISO(
        formattedActivity.startTime,
        shiftType,
        selectedDate
      );
      const endTime = convertSecToISO(
        formattedActivity.endTime,
        shiftType,
        selectedDate
      );

      // Check if startTime is greater than endTime
      if (new Date(startTime) > new Date(endTime)) {
        toast.error("Error: Start time cannot be greater than end time.");
        return null; // Mark invalid activities as null
      }

      return {
        ...formattedActivity,
        startTime,
        endTime,
      };
    });

    // If any activity is invalid, stop the function
    if (validatedActivities?.includes(null)) {
      return; // Prevent API call
    }

    // If all activities are valid, proceed with formatting the data
    const formattedData = {
      ...formateDataForSaving({ ...formData, shift: selectedShift[shiftType] }),
      activities: validatedActivities,
    };

    try {
      const reqBody = formattedData;
      const response = await CallWithAuth("POST", "/api/activity", reqBody);

      if (response?.res?.status === 201) {
        toast.success("Activities added successfully!");
        setFormData(initialFormData);
        setOpenFlyer("");
        setRefreshPage(!refreshPage)
      } else {
        throw new Error("Failed to add activities");
      }
    } catch (error) {
      toast.error(
        "An error occurred while adding activities. Please try again."
      );
    }
  };

  return (
    <>
      {openFlyer == "ADD_ACTIVITY" && (
        <Flyer
          isOpen={openFlyer == "ADD_ACTIVITY"}
          onClose={() => {
            setOpenFlyer("");
          }}
          title={"Add Activity"}
          containerStyle={{
            zIndex: "100",
            right: 0,
            bottom: 0,
            top: "unset",
            height: "calc(100vh - 15.4rem)",
          }}
          tabOptionsClassName={Style.flyer}
          tabs={[
            {
              title: "",
              rightActionButtons: [
                {
                  label: "Save",
                  onClick: async () => {
                    await saveChanges();
                  },
                },
              ],
              component: (
                <AddActivityComponent
                  showError={showError}
                  formData={formData}
                  setFormData={setFormData}
                />
              ),
            },
          ]}
        />
      )}
    </>
  );
};

export default AddActivity;
