import AuthLayout from "../../components/AuthLayout";
import { ShiftNotesProvider } from "./Context/context";
import Notes from "./SubComponent";




const ShiftNotes = () => {

  return (
    <AuthLayout pageName="Shift Notes">
      <div
        className={`right_sidebar_body d-flex flex-column`}
        id="main"
        style={{backgroundColor: "#FFFFFF"}}
      >
      
          <ShiftNotesProvider>
            <Notes />
          </ShiftNotesProvider>
      </div>
    </AuthLayout>
  );
};

export default ShiftNotes;
