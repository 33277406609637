import React, { useContext, useEffect, useState } from 'react';
import * as toast from '../../NZMine/components/toast';
import AddSign from '../AddSign';
import BasicButton from '../BasicButton';
import { SignatureActionDropdown } from '../../NZMine/materialMovements/components/SignatureActionDropdown';
import CloseIcon from '../../assets/icons/cross.svg';
import DownloadIcon from '../../assets/icons/download.svg';
import { PreCheckAnswer } from '../../NZMine/utils/model';
import {
  countDefectsInCategory,
  getCategoryDefectDescription,
  isCategoryValid,
} from '../../NZMine/utils/helper';
import { forEach } from 'lodash';
import Logo from '../../assets/favicon.png'
import DisplayFaultyChecks from './helper/diplayFaultyChecks';
import { dummy_Licences } from 'pages/preStartChecks/helper/DummyData';
import { CallWithAuthFormData } from 'action/apiActions';
import { tempPreProcessing } from 'pages/preStartChecks/helper';
import LoadingScreen from '../../../../components/LoadingScreen'
import moment from 'moment-timezone'
const color = {
  passed: '#319844',
  failed: '#E3402F',
  incomplete: '#325D88',
};

const generateCategoryHtml = (category, checkpoints) => {
  let categoryHtml = '';
  if (category) {
    const categoryText = getCategoryDefectDescription(category);
    const numberOfDefects = countDefectsInCategory(checkpoints);
    categoryHtml = `<div class="d-flex category-title mb-2 font-weight-bold text-uppercase bg-light p-1">${categoryText} ${isCategoryValid(category)
      ? `(<div class="text-danger font-weight-bold">${numberOfDefects}</div>/${checkpoints.length})`
      : ''
      } </div>`;
  }
  return categoryHtml;
};

const generateCheckpointHtml = (checkpoint, currentHeight) => {
  const { checkList, answer, comments, media, key } = checkpoint;
  let temp = '';
  let tempHeight = 0;
  let temp2Height = currentHeight;

  temp += `<div class="d-flex flex-column mb-4">`;
  currentHeight += 26;
  tempHeight += 26;

  temp += `<div class="d-flex" >`;
  temp += ` <div class="d-flex font-weight-bold col-6 p-0">`;
  if (key) temp += `<div class="col-1 mr-2 p-0">${key}</div>`;
  if (checkList) temp += `<div class="col-11 pl-0">${checkList}</div>`;
  temp += `</div>`;

  const containerWidth = 122; // in pixels 142
  const fontSize = 12; // in pixels
  const charsPerLine = Math.floor(containerWidth / fontSize);
  const numLines = Math.ceil(checkList?.length / charsPerLine);
  currentHeight += 13 * numLines;
  tempHeight += 13 * numLines;
  if (answer === PreCheckAnswer.DEFECT_FOUND) {
    temp += `<div class="col-6" style="color:#AA7827"><strong>${PreCheckAnswer.DEFECT_FOUND}</strong></div>`;
  } else if (answer === PreCheckAnswer.OK) {
    temp += `<div class="col-12" style="color:#2A7B39"><strong>OK</strong></div>`;
  } else {
    temp += `<div class="col-12" style="color:#00005B"><strong>${answer}</strong></div>`;
  }

  temp += '</div>';

  temp += `<div class="d-flex">`;
  if (comments?.length > 0) {
    temp += `<div style="color:#707070" class="col-6 mt-2">Comments</div>`;
    temp += `<div class="col-6 mt-2">${comments}</div>`;
    const containerWidth = 151; // in pixels
    const fontSize = 11; // in pixels
    const charsPerLine = Math.floor(containerWidth / fontSize);
    const numLines = Math.ceil(comments?.length / charsPerLine);
    currentHeight += 11 * numLines;
    tempHeight += 11 * numLines;
  }

  temp += '</div>';

  if (media?.length > 0) {
    media.map((mediaInst, index) => {
      temp += `<div style="margin-top:10px" class="d-flex ">`;
      temp += `<div style="color:#707070" class="col-6 p-0">${index == 0 ? 'Media' : ''
        }</div>`;
      temp += `<div class="col-6"><img src="${mediaInst}" alt="Image" style="max-height: 60px; height: 60px;cursor:pointer" class="modal-trigger" data-media="${mediaInst}"></div>`;
      currentHeight += 70;
      tempHeight += 70;
      temp += '</div>';
    });
  }

  temp += '</div>';
  return {
    temp,
    temp2Height,
    tempHeight,
    currentCheckpointheight: currentHeight,
  };
};

const generateTable = (data, maxHeight, maxWidth, index) => {
  let tableHtml =
    '<div class="w-100" style="font-size: 12px; font-weight: 400">';
  let currentHeight = 0;
  let remainingData = [];

  try {
    forEach(data, (entry) => {
      const { category, checkpoints } = entry;
      const categoryHtml = generateCategoryHtml(category, checkpoints);
      if (currentHeight + 20 <= maxHeight) {
        // Check if there's enough space for the category title
        if (currentHeight + 26 + 20 <= maxHeight) {
          tableHtml += categoryHtml;
          currentHeight += 26;
        } else {
          remainingData.push({ category, checkpoints });
        }

        forEach(checkpoints, (checkpoint, checkpointIndex) => {
          const { temp, temp2Height, tempHeight, currentCheckpointheight } =
            generateCheckpointHtml(checkpoint, currentHeight);
          currentHeight = currentCheckpointheight;
          if (tempHeight <= maxHeight - temp2Height) {
            tableHtml += temp;
          } else {
            remainingData.push({
              checkpoints: checkpoints.slice(checkpointIndex),
            });
            return false;
          }
        });
      } else {
        remainingData.push(entry);
      }
    });
  } catch (error) {
    console.log(error);
  }

  tableHtml += '</div>';
  return { tableHtml, remaining: remainingData };
};

const Header = ({ data }) => {
  const formattedCheckTime = moment(data?.checkTime)
    .tz("Pacific/Auckland")
    .format("DD/MM/YYYY");

  const currentFormattedDate = moment()
    .tz("Pacific/Auckland")
    .format("DD/MM/YYYY");

  const currentFormattedTime = moment()
    .tz("Pacific/Auckland")
    .format("hh:mm a");
  return (
    <div className="d-flex pady-2 padx-2 justify-content-between">
      <div className="col-8 d-flex align-items-end ">
        <div style={{ height: '50px' }} className="report-logo">
          <img style={{ width: 100 }} src={Logo} alt='iMOS' />
        </div>
        <div className="d-flex ml-3">
          <div className="pr-2">
            <strong>ASSET ID - {data?.assetID}</strong>
          </div>
          <div style={{ padding: '0 0.5rem' }}>Pre-Start Check Results</div>
        </div>
      </div>
      <div className="col-4 d-flex justify-content-end align-items-end">
        {formattedCheckTime === currentFormattedDate ? (
          <>
            {formattedCheckTime} <span style={{ fontStyle: 'italic', marginLeft: 5 }}>(until {currentFormattedTime})</span>
          </>
        ) : (
          formattedCheckTime
        )}
      </div>

    </div>
  );
};
const getCategoryName = (truckType, licences) => {
  const license = licences.find((license) => license.code === truckType);

  if (license) {
    return license.name;
  } else {
    return 'cat_not_found';
  }
};

const getDeptname = (truckType, departments, licences) => {
  const license = licences.find((license) => license.code === truckType);
  const dept = departments.find((dept) => license.departmentCode === dept.code);
  if (dept) {
    return dept.name;
  } else {
    return 'dept_not_found';
  }
};

const Report1 = ({ id, date, fetchParent }) => {
  const departments = []
  const licenses = dummy_Licences

  async function openModal(imageUrl) {
    const modal = document.getElementById('imageModal');
    const modalImg = document.getElementById('modalImage');
    const downloadImg = document.getElementById('downloadModal'); // Get the download image element

    modal.style.display = 'block';
    modalImg.src = imageUrl;

    // Handle click event for downloading the image
    downloadImg.onclick = async function () {
      try {
        if (imageUrl) {
          try {
            setLoading(true);
            const response = await CallWithAuthFormData(
              "PUT",
              'api/prestart/image',
              {
                url: imageUrl,
              },
              '',
              { blobType: true },
            );

            const url = window.URL.createObjectURL(new Blob([response?.res?.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'image.png');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setLoading(false);
          } catch (error) {
            console.log(error);
            toast.error(error?.message);
            setLoading(false);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    const closeModal = document.getElementById('closeModal');
    closeModal.onclick = function () {
      modal.style.display = 'none';
    };
  }

  const search = new URLSearchParams(document.location.search);

  const [showModal, setShowModal] = useState(false);
  const [prestartcheck, setPrestartcheck] = useState({});
  const [loading, setLoading] = useState(false);
  const [signatureData, setSignatureData] = useState({
    signedName: '',
    comments: '',
    signature: '',
    file: '',
  });
  const [tables, setTables] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const fetch = async () => {
    try {
      setIsLoading(true);
      const response = await CallWithAuthFormData("POST", '/api/prestart/', {
        date: date
      });
      const tempData = await Promise.all(
        response?.res.data?.map(d => tempPreProcessing(d))
      );

      const ID = search.get('id') ? search.get('id') : id;
      const d = tempData.find((obj) => obj._id === ID);
      let t_summary = [0, 0, 0];
      let catData = [];

      let fault = 0;
      function getStatusIndex(status) {
        return ['passed', 'failed', 'incomplete'].indexOf(status);
      }
      for (const status of ['failed', 'passed', 'incomplete']) {
        if (d?.status === status) {
          t_summary[getStatusIndex(status)]++;

          // Check if the category exists in catData, otherwise add it
          const categoryName = getCategoryName(d?.truck?.type, licenses);
          const categoryIndex = catData.findIndex(
            (cat) => cat.name === categoryName,
          );

          if (categoryIndex === -1) {
            catData.push({ name: categoryName, data: [0, 0, 0] });
          }

          // Update data in catData
          const updatedCategoryIndex = catData.findIndex(
            (cat) => cat.name === categoryName,
          );
          catData[updatedCategoryIndex].data[getStatusIndex(status)]++;
        }
      }
      d?.checkpoints?.map((c) => {
        if (c.answer == 'faulty') fault++;
      });
      let tempor = {
        ...d,
        assetID: d?.truck?.name,
        failedChecks: `${fault}/${d?.checkpoints?.length}`,
        supervisorSignature: d?.supervisor?.signedName,
        department: getDeptname(d?.truck?.type, departments, licenses),
        category: getCategoryName(d?.truck?.type, licenses),
      };
      setPrestartcheck(tempor);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      toast.error(err?.message);
    }
  };

  const fetchCheckpoints = async () => {
    try {
      const result = prestartcheck?.checkpoints?.map(
        ({ checkpoints, category }) => {
          return {
            category,
            checkpoints: checkpoints.map(
              (matchingCheckpoint, checkpointIndex) => ({
                key: checkpointIndex + 1,
                checkList: matchingCheckpoint.question,
                answer: matchingCheckpoint.answer,
                media: matchingCheckpoint.imagePaths,
                comments: matchingCheckpoint.note || '',
              }),
            ),
          };
        },
      );

      // add the extranote in the result array so it can be displayed.
      const extraNote = prestartcheck?.extraNote;

      if (extraNote) {
        const { question, answer, imagePaths, note } = extraNote;
        result.push({
          category: question || '',
          checkpoints: [
            {
              checkList: '',
              answer: answer || '',
              media: imagePaths || [],
              comments: note || '',
            },
          ],
        });
      }

      const temp = [];
      let remainingData = result;
      while (remainingData?.length > 0) {
        const { tableHtml, remaining } = generateTable(
          remainingData,
          temp?.length > 1 ? 925 : 600,
          200,
          temp?.length,
        );
        temp.push(tableHtml);
        setTables(temp);
        remainingData = remaining;
      }
    } catch (err) {
      console.log(err);

      toast.error(err?.message);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if (prestartcheck?._id) {
      setIsLoading(true);
      fetchCheckpoints();
      setSignatureData({
        signedName: prestartcheck?.supervisor?.signedName,
        comments: prestartcheck?.supervisor?.comments,
        signature: prestartcheck?.supervisor?.signature,
        file: prestartcheck?.supervisor?.signature,
      });
      prestartcheck?.supervisor?._id ? setIsEdit(true) : console.log('');
      setIsLoading(false);
    }
  }, [prestartcheck]);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const formatDate = (timestamp, defaultValue = '00:00 AM') => {
    if (isNaN(Date.parse(timestamp))) return defaultValue;
    const date = new Date(timestamp);
    return date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  };

  const getLocalTime = (time) => {
    return formatDate(time);
  };

  const handleSaveAndSign = async () => {
    const formData = new FormData();
    formData.append('signedName', signatureData.signedName);
    formData.append('prestartId', prestartcheck._id);
    if (typeof signatureData?.file == 'string') {
      formData.append('signatureFileUrl', signatureData?.file);
    } else {
      formData.append('files', signatureData?.file);
    }

    formData.append('comments', signatureData.comments);

    try {
      const response = await CallWithAuthFormData(
        "POST",
        "api/prestart/sign",
        formData,
      );

      if (response?.res?.status == 200) {
        toast.success('Added');
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.message);
    }

    fetchParent();
  };

  const handleDropdownOptions = async (action, sign) => {
    console.log({ action, sign });
    if (action === 'edit') {
      setShowModal(true);
    }
    if (action === 'delete') {
      setSignatureData({
        ...signatureData,
        signedName: '',
        signature: '',
        file: '',
      });
    }
    // if (action === dropdownOptions.delete) {
    //   setModalType("");
    //   if (user.status === UserStatus.invited) {
    //     await cancelInvitation(user._id);
    //   } else {
    //     await deactivateDelete("Deleted", user._id);
    //   }
    // }
  };

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <div

          onClick={() => {
            const images = document.querySelectorAll('.modal-trigger');
            images.forEach((img) => {
              img.addEventListener('click', () => {
                const mediaUrl = img.getAttribute('data-media');
                openModal(mediaUrl);
              });
            });
          }}
          id="print-report"
          className="report-loaded"
        >
          <div id="imageModal" className="modal">
            <div className="download-icon-report gap-3">
              {loading ? (
                <LoadingScreen />
              ) : (
                <span className="download" id="downloadModal">
                  <div style={{ cursor: 'pointer' }} className="cursor-pointer text-white">
                    <img style={{ opacity: .5 }} src={DownloadIcon} alt="download" />
                  </div>
                </span>
              )}
              <span id="closeModal">
                <div style={{ cursor: 'pointer' }} className="cursor-pointer text-white">
                  <img src={CloseIcon} alt="close" style={{ padding: '0 .3rem' }} />
                </div>
              </span>
            </div>
            <img
              style={{
                margin: 'auto',
                marginTop: '10vh',
                height: '70vh',
                width: 'max-content',
              }}
              className="modal-content"
              id="modalImage"
              src=""
            />
            <div id="caption"></div>
          </div>

          <div style={{ lineHeight: '1.8rem' }}>
            <div className="report-view-pdf  p-0">
              <Header data={prestartcheck} />
              <div className="report-section-2 mb-3">
                <div className="d-flex py-2 padx-2 justify-content-between">
                  <div>
                    {' '}
                    Status:{' '}
                    <strong style={{ color: color[prestartcheck.status] }}>
                      {' '}
                      {prestartcheck.status?.charAt(0)?.toUpperCase() +
                        prestartcheck.status?.slice(1)}
                    </strong>
                  </div>

                  <div> Ref No.: {prestartcheck?.refNo} </div>
                </div>

                <div className="d-flex py-2 padx-2 border-y ">
                  <div className=" col-5 d-flex flex-column">
                    {/* <div className=" d-flex">
                    <div className="col-6">Department:</div>
                    <div className="col-6">
                      <strong>{prestartcheck?.department}</strong>
                    </div>
                  </div> */}
                    <div className=" d-flex">
                      <div className="col-6 p-0">Asset category: </div>
                      <div className="col-6 p-0">
                        <strong>{prestartcheck?.category}</strong>
                      </div>
                    </div>
                    <div className=" d-flex">
                      <div className="col-6 p-0">Engine Hours:</div>
                      <div className="col-6 p-0">
                        <strong>{prestartcheck?.engineHours}</strong>
                        {prestartcheck?._databaseEngineHours
                          ? prestartcheck?.engineHours !=
                            prestartcheck?._databaseEngineHours
                            ? ' (Manual)'
                            : ' (Automatic)'
                          : ' (Automatic)'}
                      </div>
                    </div>
                    {/* <div className=" d-flex">
                      <div className="col-6 p-0">Odometer:</div>
                      <div className="col-6 p-0">
                        <strong className="font-weight-bold ">
                          {prestartcheck?.odometers}
                        </strong>
                        {prestartcheck?._databaseOdometers
                          ? prestartcheck?.odometers !=
                            prestartcheck?._databaseOdometers
                            ? ' (Manual)'
                            : ' (Automatic)'
                          : ' (Automatic)'}
                      </div>
                    </div> */}
                  </div>
                  <div className=" col-5 d-flex flex-column">
                    <div className=" d-flex">
                      <div className="col-6 p-0">Faulty Checks</div>
                      <div className="col-6 p-0">
                        <DisplayFaultyChecks
                          checkpoints={prestartcheck.checkpoints}
                        />
                      </div>
                    </div>
                    <div className=" d-flex">
                      <div className="col-6 p-0">Operator</div>
                      <div className="col-6 p-0">
                        <strong>
                          {(prestartcheck?.operator?.first_name + " " + prestartcheck.operator?.last_name).length > 15 ? ((prestartcheck?.operator?.first_name + " " + prestartcheck.operator?.last_name).substring(0, 15) + "...") : (prestartcheck?.operator?.first_name + " " + prestartcheck.operator?.last_name)}
                        </strong>
                      </div>
                    </div>
                    <div className=" d-flex">
                      <div className="col-6 p-0">Login Time</div>
                      <div className="col-6 p-0">
                        <strong>{getLocalTime(prestartcheck?.loginTime)}</strong>
                      </div>
                    </div>
                  </div>
                  <div className="col-3 d-flex flex-column">
                    <div>Operator's Signature</div>
                    <div>
                      {prestartcheck?.operator?.signature && (
                        <embed
                          style={{ height: '30px' }}
                          src={prestartcheck?.operator?.signature}
                          alt="sign"
                        />
                      )}
                    </div>
                    <div style={{ textAlign: 'right', paddingRight: 40 }}>{getLocalTime(prestartcheck?.checkTime)}</div>
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-center ">
                  <div style={{ lineHeight: '2rem' }} className="col-8 py-3 padx-2 d-flex flex-column">
                    <div>Supervisor's use</div>
                    <div>
                      <strong>Comments</strong>
                    </div>
                    <textarea
                      placeholder="Write a comment..."
                      value={signatureData?.comments}
                      onChange={(e) => {
                        setSignatureData({
                          ...signatureData,
                          comments: e.target.value,
                        });
                      }}
                      style={{
                        height: '6rem',
                        maxHeight: '6rem',
                        width: '100%',
                        backgroundColor: 'white',
                        borderRadius: '0.33rem',
                        border: '1px solid #EBEBEB',
                        padding: '0.85rem',
                        marginBottom: '0.85rem',
                        resize: 'vertical',
                      }}
                    />

                    <div className="d-flex justify-content-end">
                      <BasicButton
                        name="Save"
                        style={{ backgroundColor: 'black', color: 'white' }}
                        className="col-6 "
                        onClick={() => handleSaveAndSign()}
                        disabled={!signatureData?.signature}
                      >
                        Save
                      </BasicButton>
                    </div>
                  </div>
                  <div className="col-4 py-3 padx-2 ">
                    <div className="d-flex justify-content-between align-items-start mb-2 ">
                      <div>
                        <strong>Signature</strong>
                      </div>
                      {!signatureData?.signature && (
                        <BasicButton
                          name="add"
                          style={{ backgroundColor: 'white', color: 'black' }}
                          onClick={handleShowModal}
                        >
                          Add
                        </BasicButton>
                      )}
                      {showModal && (
                        <>
                          <div
                            style={{
                              position: 'fixed',
                              top: 290,
                              left: 100,
                              right: 0,
                              bottom: 0,
                              backgroundColor: 'rgba(0,0,0,0)',
                              zIndex: 1000,
                            }}
                          >
                            <AddSign
                              closeModal={() => {
                                setShowModal(false);
                              }}
                              onChange={(v) => {
                                let temp = {
                                  ...signatureData,
                                  signature: v.file,
                                  signedName: v.signedName,
                                };

                                if (v.f) {
                                  temp['file'] = v.f;
                                }
                                setSignatureData(temp);
                              }}
                              props={{
                                signedName: signatureData?.signedName,
                                signature: signatureData?.signature,
                              }}
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <div className="d-flex w-100 border-y py-2 ">
                      {signatureData?.signature ? (
                        <div className="d-flex w-100 justify-content-between">
                          <div>
                            <embed
                              style={{ maxHeight: '40px', maxWidth: '170px' }}
                              // src={dataObj?.supervisor?.signature}
                              src={signatureData?.signature}
                              alt="sign"
                            />
                            <br />
                            <i className="">{signatureData?.signedName}</i>
                          </div>

                          <SignatureActionDropdown
                            handleDropdownOptions={handleDropdownOptions}
                          />
                        </div>
                      ) : (
                        <i className="py-1" style={{ color: '#707070' }}>
                          No signature saved
                        </i>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex">
                <div className="col-6 d-flex border-el pady-2 padx-2">
                  <div className="col-6">
                    <strong>Checklist</strong>
                  </div>
                  <div className="col-6">
                    <strong>Answer</strong>
                  </div>
                </div>
                <div className="col-6 d-flex border-y-solid pady-2 padx-2">
                  <div className="col-6">
                    <strong>Checklist</strong>
                  </div>
                  <div className="col-6">
                    <strong>Answer</strong>
                  </div>
                </div>
              </div>

              <div className="d-flex">
                <div
                  style={{ fontWeight: '400' }}
                  dangerouslySetInnerHTML={{ __html: tables[0] }}
                  className="col-6 d-flex border-r  pady-1 padx-2"
                ></div>

                {tables?.length > 1 && (
                  <div
                    style={{ fontWeight: '400' }}
                    dangerouslySetInnerHTML={{ __html: tables[1] }}
                    className="col-6 d-flex padx-2 pady-1"
                  ></div>
                )}
              </div>
            </div>
            {tables?.length > 2 && (
              <div className="report-view-pdf p-0">
                <Header data={prestartcheck} />

                <div className="d-flex">
                  <div className="col-6 d-flex border-el pady-2 padx-2">
                    <div className="col-6">
                      <strong>Checklist</strong>
                    </div>
                    <div className="col-6">
                      <strong>Answer</strong>
                    </div>
                  </div>
                  <div className="col-6 d-flex border-y-solid pady-2 padx-2">
                    <div className="col-6">
                      <strong>Checklist</strong>
                    </div>
                    <div className="col-6">
                      <strong>Answer</strong>
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <div
                    style={{ fontWeight: '400' }}
                    dangerouslySetInnerHTML={{ __html: tables[2] }}
                    className="col-6 d-flex border-r  pady-1 padx-2"
                  ></div>

                  {tables?.length > 3 && (
                    <div
                      style={{ fontWeight: '400' }}
                      className="col-6 d-flex padx-2 pady-1"
                      dangerouslySetInnerHTML={{ __html: tables[3] }}
                    ></div>
                  )}
                </div>
              </div>
            )}
            {tables?.length > 4 && (
              <div className="report-view-pdf p-0">
                <Header data={prestartcheck} />

                <div className="d-flex">
                  <div className="col-6 d-flex border-el pady-2 padx-2">
                    <div className="col-6">
                      <strong>Checklist</strong>
                    </div>
                    <div className="col-6">
                      <strong>Answer</strong>
                    </div>
                  </div>
                  <div className="col-6 d-flex border-y-solid pady-2 padx-2">
                    <div className="col-6">
                      <strong>Checklist</strong>
                    </div>
                    <div className="col-6">
                      <strong>Answer</strong>
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <div
                    style={{ fontWeight: '400' }}
                    dangerouslySetInnerHTML={{ __html: tables[4] }}
                    className="col-6 d-flex border-r  pady-1 padx-2"
                  ></div>

                  {tables?.length > 3 && (
                    <div
                      style={{ fontWeight: '400' }}
                      className="col-6 d-flex padx-2 pady-1"
                      dangerouslySetInnerHTML={{ __html: tables[5] }}
                    ></div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}{' '}
    </>
  );
};

export default Report1;
