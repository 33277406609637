import SunLight from "../../../../../assets/svgs/kpi-dashboard/sun-light.svg";
import HalfMoon from "../../../../../assets/svgs/kpi-dashboard/moon.svg";
import styles from "../../../SubComponent/ShiftTotals/ShiftTotals.module.css";
import Badge from "pages/KPIDashboard/Component/Badge/Badge";
import LinearPieChart from "pages/KPIDashboard/Component/LinearPieChart/LinearPieChart";
import { subtractAbsolute, getSign } from "pages/KPIDashboard/Utils/helper";
import Breakdown from "../../BreakDown/BreakDown";

const TotalSection = ({ isDayTotal, shiftTotalData }) => {
  const actual = () => {
    if (isDayTotal) {
      return shiftTotalData?.dayActual;
    } else {
      return shiftTotalData?.nightActual;
    }
  };

  const target = () => {
    if (isDayTotal) {
      return shiftTotalData?.dayTarget;
    } else {
      return shiftTotalData?.nightTarget;
    }
  };

  const shortFall = () => {
    return Math.max(target() - actual(), 0);
  };

  const above = () => {
    return Math.max(actual() - target(), 0);
  };

  // Function to generate dynamic linearChartData
  const getLinearChartData = () => {
    const fall = shortFall();
    const over = above();

    if (over > fall) {
      return [
        {
          title: "above",
          value: over,
          style: {
            background: "transparent linear-gradient(90deg, #87C2FF 0%, #C1DFFF 100%)",
            boxShadow: "#84C1FF26",
          },
        },
        {
          title: "actual",
          value: shiftTotalData?.actual,
          style: {
            background: "transparent linear-gradient(270deg, #C6EB78 0%, #85D813 100%)",
            boxShadow: "0px 0px 35px #C6EB784D",
          },
        },
      ];
    } else if (fall > over) {
      return [
        {
          title: "actual",
          value: shiftTotalData?.actual,
          style: {
            background: "transparent linear-gradient(270deg, #C6EB78 0%, #85D813 100%)",
            boxShadow: "0px 0px 35px #C6EB784D",
          },
        },
        {
          title: "short",
          value: fall,
          style: {
            background: "transparent linear-gradient(270deg, #FF6565 0%, #FF5353 100%)",
            boxShadow: "0px 0px 35px #EB5F5F26",
          },
        },
      ];
    } else {
      return [
        {
          title: "actual",
          value: shiftTotalData?.actual,
          style: {
            background: "transparent linear-gradient(270deg, #C6EB78 0%, #85D813 100%)",
            boxShadow: "0px 0px 35px #C6EB784D",
          },
        },
      ];
    }
  };




  return (
    <div className="py-2">
      <div className="d-flex align-items-center justify-content-between pb-4">
        <img
          style={{ height: "1.5rem", width: "1.5rem" }}
          src={isDayTotal ? SunLight : HalfMoon}
          alt="sun"
        />

        <div className={styles.dayTotalLabel}>
          {isDayTotal ? "Day" : "Night"} Totals
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <span className={styles.dayTotalActual}>{actual() || '-'} / </span>
          <span className={styles.dayTotalTarget}>{target() || '-'}</span>
        </div>
        <div>
          <Badge
            value={`${getSign(actual(), target())} ${subtractAbsolute(
              actual(),
              target()
            ) || 'N/A'}`}
            containerStyle={{
              font: "normal normal 600 9px/18px Open Sans",
              color: actual() < target() ? "#E62121" : actual() > target() ? "#3B6EFB" : '#585858',
              background: actual() < target() ? "#FCE5E9" : actual() < target() ? "#E5EFFA" : "#F0F0F0",
              width: "47px",
              height: "17px",
              borderRadius: "10px",
            }}
          />
        </div>
      </div>
      <div className=" pb-3">
        <LinearPieChart data={target() ? getLinearChartData() : undefined} />
      </div>
      <div className="d-flex flex-column" style={{ gap: "6px" }}>
        <Breakdown actual={actual()} shortFall={shortFall()} above={above()} />
      </div>
    </div>
  );
};

export default TotalSection;
