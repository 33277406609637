// import Header from "../Header";

// import { UserContext } from "../service/userContext";
import { Link } from "react-router-dom";
import "./landingPage.scss";
import AuthLayout from "../../components/AuthLayout";


const ComingSoon = () => {

  return (

    <div >
      <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: "90vh", fontSize: "25px", fontWeight: "bold" }}>

        <div>  Coming soon...</div> <br />
        <div>      <Link to="/landing-page">Go Back</Link></div>
      </div>

    </div >

  );
};

export default ComingSoon;
