import React from 'react';

export default function TextAreaField({ placeholder, required, label, value, isDisabled, id, style = {}, error, errorMessage, viewOnly, className, onChange, wrapperStyle = {} }) {
    return (
        <div style={{ minHeight: "3.5rem", height: "max-content", marginBottom: '1rem', ...wrapperStyle }} className="row form-group">
            {label && <div className="col-md-4">
                <label style={{
                    fontSize: "1.25rem",
                    fontWeight: '500',
                    color: "#4B515A",
                }}>
                    {label} {required && <span style={{ color: "#6A7C9A" }}>*</span>}
                </label>
            </div>}
            <div style={
                {
                    fontWeight: '600',
                    fontSize: "1.3rem",
                }
            } className={`col-md-${label ? 8 : 12} ` + className}>
                {viewOnly ? <span style={{ color: "#1A1A1A", width: '80%', wordWrap: "break-word", fontWeight: '600', }}>{value || "-"}</span> : <textarea
                    className='px-3 py-1'
                    placeholder={placeholder}
                    disabled={isDisabled}
                    value={value}
                    style={{
                        outline: 'none',
                        border: error
                            ? "1px solid red"
                            : "1px solid #E1E8F4",
                        width: "100%",
                        fontWeight: '600',
                        resize: "none",
                        ...style
                    }}
                    id={id}
                    onChange={(e) => onChange(e.target.value, e)}
                />}
                <div
                    className="d-flex align-items-center pt-1"
                    style={{ fontSize: 12, color: "#BA3938", height: error ? 15 : 0 }}
                >
                    {error ? errorMessage : ""}
                </div>
            </div>
        </div>
    )
}
