const Badge = ({ value, containerStyle, containerClass }) => {
  return (
    <div
      className={'px-2 ' + containerClass}
      style={{
        minWidth: "60px",
        width: 'min-content',
        // height: "24px",
        borderRadius: "30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...containerStyle,
      }}
    >
      <span>{value}</span>
    </div>
  );
};

export default Badge;
