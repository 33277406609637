export const TYPES={
  DAILY : 'daily',
  MONTHLY: 'monthly'
}

export const SHIFT_TYPE={
  DAY:'day',
  NIGHT:'night'
}

export const EQUIPMENT_TYPE = {
  EXCAVATOR :'Excavators',
  TRUCK:'Trucks'
}

export const DELAY_TYPE_ALIAS_HEADER = {
  ASSET:'Asset ID',
  RI : 'RI (h)',
  PI:'PI (h)',
  PM :'PM (h)',
  BM : 'BM (h)',
  OM :'OM (h)',
  AD :'AD (h)',
  WD :'WD (h)',
  PNP :'PNP (h)'
}

export const DELAY_TYPE={
  ASSET:'Asset ID',
  RI : 'Roster Impacted (RI)',
  PI:'Production Idle (PI)',
  PM :'Planned Maintenance (PM)',
  BM : 'Breakdown Maintenance (BM))',
  OM :'Opportune Maintenance (OM)',
  AD :'Accident Damage (AD)',
  WD :'Weather Delays (WD)',
  PNP :'Planned Non-Production(PN)'
  
}

export const DELAY_TYPE_COLOR_GRADIENT = [
  { id: "RI", colors: ["#2256E4", "#2256E4"] },
  { id: "PI", colors: ["#8EC3FF", "#8EC3FF"] },
  { id: "PM", colors: ["#B9DBFF", "#B9DBFF"] },
  { id: "BM", colors: ["#E7F2FF", "#E7F2FF"] },
  { id: "OM", colors: ["#FEC956", "#FEC956"] },
  { id: "AD", colors: ["#FE9201", "#FE9201"] },
  { id: "WD", colors: ["#FF3B2B", "#FF3B2B"] },
  { id: "PN", colors: ["#000000", "#000000"] },
];
