import Flyer from "pages/ShiftNotes/Component/Flyers/Flyer";
import { useShiftNotesContext } from "pages/ShiftNotes/Context/context";
import Styles from './ViewNotes.module.css'
const labelStyle = {
  font: "normal normal normal 14px/17px Open Sans",
  color: "#4B515A",
  width: "20%"
};

const valStyle = {
  font: "normal normal 600 14px/19px Open Sans",
  color: "#1A1A1A",
  marginBottom: "10px"
};

const ViewNotesComponent = ({ data }) => {
  return (
    <div className="container mt-4 px-5">
      {[
        { label: "Note Type", value: data?.noteType },
        { label: "Asset(s)", value: data?.assets?.join(", ") || "N/A" },
        { label: "Notes", value: data?.note }
      ].map(({ label, value }, index) => (
        <div key={index} className="d-flex pb-3">
          <div className="flex-shrink-0" style={labelStyle}>
            {label}
          </div>
          <div className="flex-grow-1" style={valStyle}>
            {label === "Notes" ? <p>{value}</p> : value}
          </div>
        </div>
      ))}
    </div>
  );
};

const ViewNotes = ({ data }) => {
  const { openFlyer, setOpenFlyer } = useShiftNotesContext();

  return (
    <>
      {openFlyer === "NOTES_VIEW" && (
        <Flyer
          isOpen={openFlyer === "NOTES_VIEW"}
          onClose={() => setOpenFlyer("")}
          title="Note Details"
          containerStyle={{
            zIndex: "100",
            right: 0,
            bottom: 0,
            top: "unset",
            height: "calc(100vh - 11.6rem)"
          }}
          headerClassName={"pt-3"}
          hideBottom={true}
          tabOptionsClassName={Styles.hideborderBottom}
          tabs={[
            {
              component: <ViewNotesComponent data={data} />
            }
          ]}
        />
      )}
    </>
  );
};

export default ViewNotes;
