const AssetDot =({bgColor})=>{
    return (
        <span
        style={{
          width: "8px",
          height: "8px",
          backgroundColor: bgColor,
          boxShadow: "0px 0px 25px #0277ff29",
          opacity: 1,
          borderRadius: "50%",
          display: "inline-block",
          marginRight: "5px",
        }}
      ></span>
    );
}

export default AssetDot