import moment from "moment";
import { toast } from "react-toastify";

export const convertSecondsToTime = (seconds) => {
  if (typeof seconds !== "number" || seconds < 0) {
    // console.error("Invalid seconds input. Please provide a non-negative number.");
    return "00h:00m"; // Default fallback time
  }

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  return `${String(hours).padStart(2, '0')}h:${String(minutes).padStart(2, '0')}m`;
};


export const processHourlyGraphCategories = (hourlyData) => {
  if (!hourlyData) return [];
  // Return the array directly using Object.keys
  return Object.keys(hourlyData);
};

const getDay = (dateString) => {
  if (!dateString) return "";
  return moment(dateString).format("DD");
};

export const processMonthlyGraphCategories = (monthlyData) => {
  if (!monthlyData) return [];
  // Extract the keys and map them to their day
  return Object.keys(monthlyData).map((dateKey) =>
    getDay(dateKey)
  );
};

export const mergeEquipmentData = (hourlyData) => {
  if (!hourlyData) return [];

  const equipmentMap = {};

  Object.values(hourlyData).forEach((hourData) => {
    hourData?.equipmentSpecific.forEach((equipment) => {
      const { equipmentName, totalDelay, totalByDelayTypes } = equipment;

      if (!equipmentMap[equipmentName]) {
        // Initialize the entry for unique equipmentName
        equipmentMap[equipmentName] = {
          equipmentName,
          totalDelay: 0,
          totalByDelayTypes: {
            PI: 0,
            RI: 0,
            PM: 0,
            BM: 0,
            OM: 0,
            AD: 0,
            WD: 0,
            PN: 0,
          },
        };
      }

      equipmentMap[equipmentName].totalDelay += totalDelay;
      Object.keys(totalByDelayTypes).forEach((key) => {
        equipmentMap[equipmentName].totalByDelayTypes[key] +=
          totalByDelayTypes[key];
      });
    });
  });
  return Object.values(equipmentMap);
};

export const calculatePercentage = (part, total) => {
  if (total === 0 || total === null || total === undefined) {
    return "0";
  }

  const percentage = (part / total) * 100;
  return `${percentage.toFixed(2)}`;
};

export const calculatePercentageDifference = (a, b) => {
  const difference = Math.abs(a - b); // Absolute difference between a and b
  const percentage = ((difference / b) * 100).toFixed(2); // Calculate percentage (relative to b)
  if (a < b) {
    return `${percentage}%`;
  } else if (a > b) {
    return `${percentage}%`;
  } else {
    return "0%";
  }
}


export const subtractAbsolute = (a, b) => {
  return Math.abs(a - b);
}


export const getSign = (actual, target) => {
  if (actual > target) {
    return "+";
  } else if (actual < target) {
    return "-";
  }
  return ''
};

export const processOverburdenGraphCategories = (data) => {
  if (!data) return [];
  // Extract the keys and map them to their day
  return Object.keys(data).map((dateKey) =>
    moment(dateKey).format("DD/MM")
  );
};


export const showToast = (isSuccess, msg) => {
  const options = {
    position: "bottom-right",
    autoClose: 6000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  };

  if (isSuccess) {
    toast.success(msg, options);
  } else {
    toast.error(msg, options);
  }
};