import React from "react";

const ConfirmStatusMultipleUsers = ({
  SetMultipleStatusChange,
  MultipleStatusChange,
  onSubmit,
  type
}) => {
  const submitStatusHandler = () => {
    SetMultipleStatusChange(false);
    onSubmit(type);
  };
  return (
    <div
      className={
        MultipleStatusChange
          ? "full_modal generate_new_pin_active"
          : "full_modal"
      }
      id="generate_new_pin"
    >
      <div className="generate_new_pin_box" style={{ width: "345px" }}>
        <div
          className="close_icon"
          onClick={() => SetMultipleStatusChange(false)}
        >
          <img src="assets/images/close.png" />
        </div>
        <h4 style={{ font: "normal normal normal 16px/22px Open Sans" }}>Are you sure you want to <span style={{ font: "normal normal bold 16px/22px Open Sans" }}>{type}</span> the selected user(s)?</h4>
        <div className="generate_new_pin_form">
          <div className="add_new_user_box_top_right">
            {/* <button
                  className="btn discard"
                  onClick={() => setStatusModal(false)}
                >
                  Cancel
                </button> */}
            <button className="btn save" onClick={submitStatusHandler} style={{ height: "40px", paddingTop: "0.6rem" }}>
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmStatusMultipleUsers;
