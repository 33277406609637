import DynamicSelectField from "../DynamicSelectField/DynamicSelectField";
const timeRanges = [
  { "value": 0, "label": "12:00 am" },
  { "value": 1800, "label": "12:30 am" },
  { "value": 3600, "label": "01:00 am" },
  { "value": 5400, "label": "01:30 am" },
  { "value": 7200, "label": "02:00 am" },
  { "value": 9000, "label": "02:30 am" },
  { "value": 10800, "label": "03:00 am" },
  { "value": 12600, "label": "03:30 am" },
  { "value": 14400, "label": "04:00 am" },
  { "value": 16200, "label": "04:30 am" },
  { "value": 18000, "label": "05:00 am" },
  { "value": 19800, "label": "05:30 am" },
  { "value": 21600, "label": "06:00 am" },
  { "value": 23400, "label": "06:30 am" },
  { "value": 25200, "label": "07:00 am" },
  { "value": 27000, "label": "07:30 am" },
  { "value": 28800, "label": "08:00 am" },
  { "value": 30600, "label": "08:30 am" },
  { "value": 32400, "label": "09:00 am" },
  { "value": 34200, "label": "09:30 am" },
  { "value": 36000, "label": "10:00 am" },
  { "value": 37800, "label": "10:30 am" },
  { "value": 39600, "label": "11:00 am" },
  { "value": 41400, "label": "11:30 am" },
  { "value": 43200, "label": "12:00 pm" },
  { "value": 45000, "label": "12:30 pm" },
  { "value": 46800, "label": "01:00 pm" },
  { "value": 48600, "label": "01:30 pm" },
  { "value": 50400, "label": "02:00 pm" },
  { "value": 52200, "label": "02:30 pm" },
  { "value": 54000, "label": "03:00 pm" },
  { "value": 55800, "label": "03:30 pm" },
  { "value": 57600, "label": "04:00 pm" },
  { "value": 59400, "label": "04:30 pm" },
  { "value": 61200, "label": "05:00 pm" },
  { "value": 63000, "label": "05:30 pm" },
  { "value": 64800, "label": "06:00 pm" },
  { "value": 66600, "label": "06:30 pm" },
  { "value": 68400, "label": "07:00 pm" },
  { "value": 70200, "label": "07:30 pm" },
  { "value": 72000, "label": "08:00 pm" },
  { "value": 73800, "label": "08:30 pm" },
  { "value": 75600, "label": "09:00 pm" },
  { "value": 77400, "label": "09:30 pm" },
  { "value": 79200, "label": "10:00 pm" },
  { "value": 81000, "label": "10:30 pm" },
  { "value": 82800, "label": "11:00 pm" },
  { "value": 84600, "label": "11:30 pm" },
  { "value": 86400, "label": "12:00 am" }
]

const FromToTimeSpan = ({
  label,
  onChange,
  required,
  wrapperClassName = "",
  wrapperStyle = {},
  labelStyle,
  valueStyle,
  style,
  startTime,
  endTime,
  error,
  errorMessage,
}) => {
  return (
    <>
    <div
      style={{
        minHeight: "3.4rem",
        height: "max-content",
        marginBottom: "0rem",
        ...wrapperStyle,
      }}
      className={"row " + wrapperClassName}
    >
      {label && (
        <div className="col-md-4 d-flex align-items-center pb-4">
          <label
            style={{
              fontSize: "1.25rem",
              fontWeight: "500",
              // fontFamily: "Open Sans",
              margin: 0,
              color: "#4B515A",
              ...labelStyle,

            }}
          >
            {label}
            {required && <span style={{ color: "#6A7C9A" }}>*</span>}
          </label>
        </div>
      )}

      <div className=" col-md-8 d-flex justify-content-between align-items-center">
        <DynamicSelectField
          wrapperStyle={{ width: '50%' }}
          placeholder={"00:00 am/pm"}
          options={timeRanges}
          value={startTime}
          onChange={(val) => onChange("startTime", val)}
          style={style}
        labelStyle={{ ...labelStyle, marginTop: "10px" }}
        valueStyle={valueStyle}
        />

        <div
          className="pb-4"
          style={{
            font: "normal normal 600 14px/19px Open Sans",
            color: "#6A7C9A",
          }}
        >
          to
        </div>

        <DynamicSelectField
          wrapperStyle={{ width: '50%' }}
          placeholder={"00:00 am/pm"}
          options={timeRanges}
          value={endTime}
          onChange={(val) => onChange("endTime", val)}
        />


      </div>
     
    </div>

     <div
          className="row"
          style={{ fontSize: 12, color: "#BA3938", height: error ? 15 : 0 }}
        >
          {error ? errorMessage : ""}
        </div>
        </>
  );
};

export default FromToTimeSpan;
