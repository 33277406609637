import BarChart from "pages/Dashboard/components/BarChart/BarChart";
import Styles from "../ExcavatorTruckHourlyResult/ExcavatorTruckHourlyResult.module.css";
import AssetDot from "pages/Dashboard/components/AssetDot";
import moment from "moment";
import { Status } from "pages/Dashboard/constant";
import { PiAsteriskSimpleBold } from "react-icons/pi";
import AssetBadge from "pages/Dashboard/components/AssetBadge";
import { FaCircleXmark } from "react-icons/fa6";
import { IoBanSharp } from "react-icons/io5";
import { ShiftStatusEnum } from "pages/Dashboard/constant";
import { dayDefaultHouryData, nightDefaultHouryData } from "../constant";
import { MdDoNotDisturbOn } from "react-icons/md";

const ExcavatorTruckHourlyResult = ({
  shiftStatus,
  exHourlyTruckResult,
  productiveHours,
  shiftType,
}) => {
  const statusAppearance = (status) => {
    const result = {};
    switch (status) {
      case Status.UNASSIGNED:
        result.truckBgColor = "#F4F6FD";
        result.icon = (
          <span
            style={{
              font: "normal normal 600 12px/24px Open Sans",
            }}
          >
            *
          </span>
        );

        break;

      case Status.UNSCHEDULED:
        result.icon = <IoBanSharp className="ml-1 mb-1" size={12} />;
        result.truckBgColor = "#F4F6FD";
        break;

      case Status.SHIFTENDED:
        result.truckBgColor = "#F0F0F0";
        result.icon = <FaCircleXmark className="ml-1 mb-1" size={12} />;
        break;

      case Status.REASSIGNED:
        result.label = "Reassigned";
        result.bgColor = "#D8E5FF";
        result.color = "#000000";
        result.icon = "";
        break;

      case Status.REMOVED:
        result.label = "Removed";
        result.bgColor = "#D8D8D8";
        result.color = "#2B2B2B";
        result.truckBgColor = "#F0F0F0";
        break;

      case Status.NOTOPERATING:
        result.icon = <MdDoNotDisturbOn color="red" size={12} />
        break;

      default:
        result.label = status;
        result.icon = "";
    }

    return result;
  };

  const firstLoad = (date) => {
    return date ? moment(date)
      .tz("Pacific/Auckland") // Set the timezone
      .format("HH:mm") : "-";
  };

  return (
    <div className="d-flex flex-wrap justify-content-between">
      <div
        className={Styles.exTruckHourlyCard}
        style={{
          background: "white",
        }}
      >
        <div
          style={{ backgroundColor: 'white' }}
          className={`pl-3 d-flex justify-content-between align-items-center ${Styles.exTruckHourlyCardHeader}`}
        >
          <div style={{ font: "normal normal 600 14px/19px Open Sans" }}>
            Trucks ({exHourlyTruckResult?.filter(x => x.status != Status.REMOVED).length})
          </div>
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center mr-3">
              <AssetDot bgColor={"#94DC28"} />
              <span
                style={{ font: "normal normal normal 12px/17px Open Sans" }}
              >
                Meeting Target
              </span>
            </div>
            <div className="d-flex align-items-center">
              <AssetDot
                className="d-flex align-items-center"
                bgColor={"#FF5858"}
              />
              <span
                style={{ font: "normal normal normal 12px/17px Open Sans" }}
              >
                Falling Behind
              </span>
            </div>
          </div>
        </div>
        {exHourlyTruckResult.map((truck, index) => (
          <div
            key={index}
            className="d-flex justify-content-between align-items-center pl-5 "
            style={{
              backgroundColor: statusAppearance(truck?.status).truckBgColor,
            }}
          >
            <div className={Styles.truckLeftSection}>
              <div className={Styles.truckEquipmentInfo}>
                {truck.truckName}{" "}
                <span>{statusAppearance(truck?.status).icon}</span>
                <div className={`pt-1 ${Styles.truckOperatorInfo}`}>
                  O:{" "}
                  {truck?.operatorName
                    ? truck?.operatorName
                    : "Unknown Operator"}
                </div>
                <div>
                  {" "}
                  {(truck?.status === Status.REASSIGNED ||
                    truck?.status === Status.REMOVED) && (
                      <AssetBadge
                        className="px-3 mt-3"
                        containerStyle={{ border: "none", padding: "3.5px 0px" }}
                        value={statusAppearance(truck?.status).label}
                        color={statusAppearance(truck?.status).color}
                        bgColor={statusAppearance(truck?.status).bgColor}
                        font={"normal normal normal 9px/12px Open Sans"}
                      />
                    )}{" "}
                </div>
              </div>

              <div className={Styles.truckDetails}>
                <div className={Styles.truckDetailItem}>
                  Total Loads:{" "}
                  <span className={Styles.truckDetailValue}>
                    <span
                      style={{ font: "normal normal bold 12px/22px Open Sans" }}
                    >
                      {truck.actualLoads ?? "-"}
                    </span>{" "}
                    /{" "}
                    <span
                      style={{ font: "normal normal 600 12px/22px Open Sans" }}
                    >
                      {truck.targetLoads ?? "-"}
                    </span>
                  </span>
                </div>

                <div className={Styles.truckDetailItem}>
                  Hourly Target:{" "}
                  <span className={Styles.truckDetailValue}>
                    {truck?.targetLoads
                      ? Math.round(truck?.targetLoads / productiveHours) +
                      " Loads"
                      : "-"}
                  </span>
                </div>
                <div className={Styles.truckDetailItem}>
                  First Load:{" "}
                  <span className={Styles.truckDetailValue}>
                    {firstLoad(truck.firstLoad)}
                  </span>
                </div>
              </div>

              {(truck?.status === Status.UNASSIGNED ||
                truck?.status === Status.UNSCHEDULED) && (
                  <div
                    style={{
                      font: "normal normal 600 10px/12px Open Sans",
                      color: "#4D4D4D",
                      paddingTop: "43px",
                    }}
                  >
                    *{" "}
                    {truck?.status === Status.UNASSIGNED
                      ? "Unassigned"
                      : "Unscheduled"}{" "}
                    Truck
                  </div>
                )}
            </div>

            {truck?.hourlyData &&
              (() => {
                const exHourlyChartData = Object.entries({
                  ...(shiftType == "day"
                    ? dayDefaultHouryData
                    : nightDefaultHouryData),
                  ...truck?.hourlyData,
                }).map(([hour, value]) => ({
                  xAxisData: hour, // Convert hour to "HH:00" format
                  yAxisData: value,
                  customColor:
                    shiftStatus === ShiftStatusEnum.ONGOING &&
                    hour ===
                    moment.tz("Pacific/Auckland").format("HH:00"),
                }));

                return (
                  <div className={Styles.truckChartContainer}>
                    <BarChart
                      maxBufferConstant={
                        truck?.status === Status.UNASSIGNED ||
                          truck?.status === Status.UNSCHEDULED
                          ? 0
                          : Math.round(
                            truck?.targetLoads / (productiveHours * 4)
                          )
                      }
                      chartData={exHourlyChartData}
                      yAxisLabel={"Loads"}
                      customColor={["#D6E7FF", "#69B4FF"]}
                      showTargetLine={
                        !(
                          truck?.status === Status.UNASSIGNED ||
                          truck?.status === Status.UNSCHEDULED
                        )
                      }
                      targetValue={
                        truck?.status === Status.UNASSIGNED ||
                          truck?.status === Status.UNSCHEDULED
                          ? 0
                          : Math.round(truck?.targetLoads / productiveHours)
                      }
                      gradient={
                        truck?.status === Status.UNASSIGNED ||
                          truck?.status === Status.UNSCHEDULED
                          ? ["#AAB7DB", "#7A88C6"]
                          : undefined
                      }
                    />
                  </div>
                );
              })()}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExcavatorTruckHourlyResult;
