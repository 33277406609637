import Flyer from "pages/DelayAndActivity/Component/Flyers/Flyer";
import EditActivityComponent from "./EditActivityComponent";
import { useEffect, useRef, useState } from "react";
import moment from "moment-timezone"
import { CallWithAuth } from "action/apiActions";
import { toast } from "react-toastify";
import { convertSecToISO, formateDataForSaving } from "pages/DelayAndActivity/utils/helper";
import { useActivityContext } from "../context/context";

const EditActivity = ({ data: originalData }) => {
  const {
    selectedDate,
    shiftType,
    activityTypeList,
    openFlyer,
    setOpenFlyer,
    refreshPage, setRefreshPage
  } = useActivityContext();

  const [formData, setFormData] = useState({
    activityType: "",
    startTime: "",
    endTime: "",
  });
  const initialFormData = useRef({
    activityType: "",
    activitySubType: "",
    startTime: "",
    endTime: "",
  })

  const [showError, setShowError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        initialFormData.current = {
          activityType: originalData.activityType
            ? {
              value: activityTypeList?.find((v) => originalData.activityType === v.label)?.value,
              label: originalData.activityType,
            }
            : "",
          startTime: originalData.startTime
            ? {
              value: moment(originalData?.startTime)
                .tz("Pacific/Auckland")
                .diff(moment(originalData?.startTime).tz("Pacific/Auckland").startOf('day'), 'seconds'),
              label: moment(originalData?.startTime).tz("Pacific/Auckland").format("hh:mm a"),
            }
            : "",
          endTime: originalData.endTime
            ? {
              value: moment(originalData?.endTime)
                .tz("Pacific/Auckland")
                .diff(moment(originalData?.endTime).tz("Pacific/Auckland").startOf('day'), 'seconds'),
              label: moment(originalData?.endTime).tz("Pacific/Auckland").format("hh:mm a"),
            }
            : "",
        };
        setFormData(initialFormData.current);
      } catch (error) {
        console.error("Error fetching activity sub types or processing data:", error);
      }
    };

    fetchData();
  }, []);


  const isFormValid = () => {

    const isActivityValid = (
      formData.activityType &&
      formData.startTime &&
      formData.endTime
    ) ? true : false;

    if (!isActivityValid) {
      setShowError(true);
      return false;
    }

    setShowError(false);
    return true;
  };

  const saveChanges = async () => {

    if(!isFormValid()) return

    // Validate activities first
    const formattedActivity = formateDataForSaving(formData);
    const startTime = convertSecToISO(formattedActivity.startTime, shiftType, selectedDate);
    const endTime = convertSecToISO(formattedActivity.endTime, shiftType, selectedDate);

    // Check if startTime is greater than endTime
    if (new Date(startTime) > new Date(endTime)) {
      toast.error("Error: Start time cannot be greater than end time.");
      return null;  // Mark invalid activities as null
    }

    const validatedData = {
      ...formattedActivity,
      startTime,
      endTime,
    };

    const reqBody = {
      activityId: originalData.activityId,
    };

    // Check for each field before adding to reqBody
    if (validatedData.activityType && validatedData.activityType !== initialFormData.current.activityType.value) {
      reqBody.activityType = validatedData.activityType;
    }

    if (validatedData.startTime && new Date(validatedData.startTime).getTime() !== new Date(originalData.startTime).getTime()) {
      reqBody.startTime = validatedData.startTime;
    }

    if (validatedData.endTime && new Date(validatedData.endTime).getTime() !== new Date(originalData.endTime).getTime()) {
      reqBody.endTime = validatedData.endTime;
    }

    // Check if reqBody has any changes to send
    if (Object.keys(reqBody).length === 1) { // Only contains activityId
      toast.error("No changes detected to save.");
      return; // Exit if no changes
    }

    try {
      const response = await CallWithAuth("PUT", '/api/activity', reqBody);
      if (response?.res?.status === 202) {
        toast.success("Activity edited successfully!");
        setOpenFlyer("");
        setRefreshPage(!refreshPage)
      } else {
        throw new Error('Failed to edit activity');
      }
    } catch (error) {
      toast.error('An error occurred while edit activities. Please try again.');
    }
  };


  return (
    <>
      {openFlyer == "ACTIVITY_EDIT" && (
        <Flyer
          isOpen={openFlyer == 'ACTIVITY_EDIT'}
          onClose={() => setOpenFlyer("")}
          title={"Edit Activity"}
          containerStyle={{
            zIndex: "100",
            right: 0,
            bottom: 0,
            top: "unset",
            height: "calc(100vh - 15.4rem)",
          }}
          headerComponent={true}
          tabs={[
            {
              title: "",
              rightActionButtons: [
                {
                  label: "Save Changes",
                  onClick: async () => {
                    await saveChanges();
                  },
                },
              ],

              component: (
                <EditActivityComponent
                showError={showError}
                  setFormData={setFormData}
                  formData={formData}
                />
              ),
            },
          ]}
        />
      )}
    </>
  );
};

export default EditActivity;
