import Flyer from "pages/ShiftNotes/Component/Flyers/Flyer";
import { useState } from "react";
import AddShiftNotesComponent from "./AddShiftNotesComponent";
import { formateDataForSaving } from "pages/ShiftNotes/utils/helper";
import { CallWithAuth } from "action/apiActions";
import { toast } from "react-toastify";
import { useShiftNotesContext } from "pages/ShiftNotes/Context/context";

const AddShiftNotes = () => {

  const { setOpenFlyer, openFlyer, selectedDate, shiftType, selectedShift, shiftOptions,setRefreshPage , refreshPage } =
    useShiftNotesContext();

  const initialFormData = {
    shift: shiftOptions[shiftType]?.find((shift) => shift.value == selectedShift?.[shiftType]),
    noteType: "Generic",
    assets: [],
    note: "",
    date: selectedDate,
    shiftType: shiftType
  };
  const [formData, setFormData] = useState(initialFormData);
  const [showError, setShowError] = useState(false);

  console.log(formData);
  

  const isFormValid = () => {
    if ( formData.noteType !== "Generic" && !formData.assets?.length) {
      setShowError(true);
      return false;
    }

      const isNotesValid = (
        formData.noteType &&
        formData.note &&
        formData.date && 
        formData.shiftType
      ) ? true : false;

    if (!isNotesValid) {
      setShowError(true);
      return false;
    }

    setShowError(false);
    return true;
  };

  const saveChanges = async () => {
    //Validate form data.
    if (!isFormValid()) {
      return;
    }

    // If all delays are valid, proceed with formatting the data
    const formattedData = {
      ...formateDataForSaving({ ...formData })
    };

    try {
      const reqBody = formattedData;
      const response = await CallWithAuth("POST", "/api/shiftNotes", reqBody);

      if (response?.res?.status === 201) {
        toast.success("Shift notes added successfully!");
        setFormData(initialFormData);
        setOpenFlyer("");
        setRefreshPage(!refreshPage)
      } else {
        throw new Error("Failed to add shift notes");
      }
    } catch (error) {
      toast.error(
        "An error occurred while adding shift notes. Please try again."
      );
    }
  };

  return (
    <>
      {openFlyer === "ADD_NOTES" && (
        <Flyer
          isOpen={openFlyer === "ADD_NOTES"}
          onClose={() => {
            setOpenFlyer("");
          }}
          title={"Add Notes"}
          containerStyle={{
            zIndex: "100",
            right: 0,
            bottom: 0,
            top: "unset",
            height: "calc(100vh - 11.6rem)",
          }}
          headerHeight="60px"
          headerClassName={"pt-3"}
          hideBorderBottom={true}

          tabs={[
            {
              title: "",
              rightActionButtons: [
                {
                  label: "Save",
                  onClick: async () => {
                    await saveChanges();
                  },
                },
              ],
              component: (
                <AddShiftNotesComponent
                  showError={showError}
                  formData={formData}
                  setFormData={setFormData}
                />
              ),
            },
          ]}
        />
      )}
    </>
  );
};

export default AddShiftNotes;
