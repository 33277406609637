import DynamicTable from "pages/DelayAndActivity/Component/Table/Table";
import { DelayShiftStatusType } from "pages/DelayAndActivity/utils/constant";
import { formatTimeRange } from "pages/DelayAndActivity/utils/helper";
import React, { useState } from "react";
import History from "../../../Component/History/History";
import EditDelay from "../EditDelay/EditDelay";
import ConfirmAlert from "pages/DelayAndActivity/Component/ConfirmAlert/ConfirmAlert";
import { useDelayContext } from "../context/context";
import { totalDelayDuration } from "pages/DelayAndActivity/utils/helper"


const confirmMessage = (delay, equipment) => <div
  className="d-flex flex-column"
  style={{ lineHeight: "2rem" }}
>
  <span>
    Are you sure you want to delete the following delay for{" "}
    <span style={{ font: "normal normal bold 14px/20px Open Sans", color: "#000000" }}>{equipment.name}</span>?
  </span>
  <span style={{ font: "normal normal 600 14px/22px Open Sans", color: "#000000" }}>
    {delay.delayType}, {delay.delaySubType}
  </span>
  <span style={{ font: "normal normal 600 14px/22px Open Sans", color: "#000000" }}>{formatTimeRange(delay)}</span>
</div>


function DelaysTable() {
  const {
    loading,
    openFlyer,
    setOpenFlyer,
    filteredResults,
    delayData,
    handleDeleteDelay
  } = useDelayContext()
  const [historyData, setHistoryData] = useState([]);
  const [editDelayData, setEditDelayData] = useState({})
  const [confirmModal, setConfirmModal] = useState({
    show: false,
    title: "",
    message: "",
    did: ""
  });



  const totalFilteredResult = ['excavators', 'trucks'].reduce((total, key) => {
    const items = filteredResults[key] || [];
    return total + items.reduce((acc, item) => acc + (item.delays ? item.delays.length : 0), 0);
  }, 0);


  const totalCount = ['excavators', 'trucks'].reduce((total, key) => {
    const items = delayData[key] || [];
    return total + items.reduce((acc, item) => acc + (item.delays ? item.delays.length : 0), 0);
  }, 0);

  const handleOpenHistory = (data) => {
    setHistoryData(data);
    setOpenFlyer("DELAY_HISTORY");
  };

  const handleOpenEdit = (data) => {
    setEditDelayData(data);
    setOpenFlyer("DELAY_EDIT");
  };




  const headers = [
    {
      label: "Delay Duration",
      key: "delayDuration",
      style: { width: "17.5%", paddingLeft: 12 },
    },
    {
      label: "Shift Status",
      key: "shiftStatus",
      style: { width: "7.5%", paddingLeft: 12 },
    },
    {
      label: "Delay Type",
      key: "delayType",
      style: { width: "20%", paddingLeft: 12 },
    },
    {
      label: "Delay Sub-Type",
      key: "delaySubType",
      style: { width: "30%", paddingLeft: 12 },
    },
    {
      label: "Reported By",
      key: "reportedBy",
      style: { width: "20%", paddingLeft: 12 },
    },
    {
      label: "Actions",
      key: "actionMenu",
      style: { width: "5%", paddingLeft: 12 },
    },
  ];


  const processedData = Object?.entries(filteredResults)?.map(([key, value]) => ({
    groupName: key,
    groupData: value?.map((equipment) => ({
      rowHeader: equipment.name + " - " + totalDelayDuration(equipment?.delays),
      rows: equipment?.delays?.map((delay, index) => ({
        style: {
          borderLeft: !delay.endTime ? "4px solid #EB5F5F" : "",
          backgroundColor: !delay.endTime ? "#FFF8F8" : "transparent",
        },
        value: {
          delayId: delay.delayId,
          delayDuration: formatTimeRange(delay),
          shiftStatus:
            delay.shiftStatus === DelayShiftStatusType.PAUSE
              ? "Paused"
              : "Ended",
          delayType: delay.delayType,
          delaySubType: delay.delaySubType,
          reportedBy: delay.reportedBy,
        },
        actionMenu: {
          options: [
            {
              name: "Edit",
              icon: "",
              disabled: !delay.endTime,
              onClick: () => {
                if (delay.endTime)
                  handleOpenEdit(delay);
              },
            },
            {
              name: "Delete",
              icon: "",
              onClick: () =>
                setConfirmModal({
                  show: true,
                  title: "Delete Delay",
                  message: confirmMessage(delay, equipment),
                  wantToDelete: true,
                  did: delay.delayId
                }),
            },
            {
              name: "Edit History",
              icon: "",
              onClick: () => handleOpenHistory(delay.history),
            },
          ],
        },
      })),
      actionMenu: {
        options: [
          {
            name: "Edit History",
            icon: "",
            onClick: () =>
              handleOpenHistory(
                equipment.history?.map((hist) => ({
                  updatedAt: hist.actionDate,
                  updatedBy: hist.actionBy,
                  changes: [
                    {
                      key: hist.action,
                      to: hist.delayType,
                    },
                    {
                      key: "Delay Duration",
                      to: formatTimeRange(hist),
                    },
                  ],
                }))
              ),
          },
          { name: "Download", icon: "", disabled: true, onClick: () => { } },
        ],
      },
    })),
  }));

  return (
    <>
      {openFlyer == "DELAY_EDIT" && <EditDelay data={editDelayData} />}

      {openFlyer == "DELAY_HISTORY" && (
        <History
          handleOnClose={() => setOpenFlyer("")}
          historyData={historyData}
        />
      )}

      {confirmModal.show && (
        <ConfirmAlert
          title={confirmModal.title}
          message={confirmModal.message}
          onClose={() => {
            setConfirmModal({
              show: false,
              title: "",
              message: "",
              sid: '',
              reportNo: ""
            });
          }}
          onConfirm={() => {
            handleDeleteDelay(confirmModal.did);
            setConfirmModal({
              show: false,
              title: "",
              message: "",
              did: ''
            });
          }}
        />
      )}

      <DynamicTable
        loading={loading}
        data={processedData}
        headers={headers}
        groupBadge={true}
        rowHeader={true}
        footer={`Displaying ${totalFilteredResult}/${totalCount} Delays`}
      />
    </>
  );
}

export default DelaysTable;
