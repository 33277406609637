import CircularPieChart from "pages/KPIDashboard/Component/CircularPieChart/CircularPieChart";
import styles from "../MTDTotals/MTDTotals.module.css";
import Badge from "pages/KPIDashboard/Component/Badge/Badge";
import Breakdown from "../BreakDown/BreakDown";
import { subtractAbsolute, getSign } from "pages/KPIDashboard/Utils/helper";

const MTDTotals = ({ title, data }) => {
  const shortFall = () => {
    return Math.max(data?.target - data?.actual, 0);
  };

  const above = () => {
    return Math.max(data?.actual - data?.target, 0);
  };

  const pieChartData = (() => {
    const fall = shortFall();
    const over = above();

    if (over > fall) {
      return [over, Math.max(data?.target - over, 0)];
    } else if (fall > over) {
      return [data?.target - fall, fall];
    } else {
      return [data?.actual];
    }
  })();
  const gradient = (() => {
    const fall = shortFall();
    const over = above();
    if (over > fall) {
      return [
        {
          id: "above",
          colors: ["#BEDDFF", "#89C3FF"]
        },
        { id: "actual", colors: ["#C6EB78", "#85D813"] },
      ]
    }
    if (fall > over) {
      return [
        { id: "actual", colors: ["#C6EB78", "#85D813"] },
        {
          id: "shortfall",
          colors: ["#FF8B4E", "#FF3E2B"]
        },

      ]
    }
    if (fall === over) {
      return [
        { id: "actual", colors: ["#C6EB78", "#85D813"] },
      ]
    }
  })();


  return (
    <div style={{ width: 235 }} className="d-flex flex-column px-4 py-4">
      <div className={`pb-2 ${styles.title}`}>{title}</div>
      <div
        className="mt-3 mb-4"
        style={{ border: "1px solid #ebebeb", opacity: "0.4" }}
      ></div>
      <div className={styles.header}>MTD Totals</div>
      <div className="d-flex justify-content-center">
        <CircularPieChart
          pieChartData={pieChartData}
          gradients={gradient}
          lineWidth={18}
          width={95}
          height={115}
        // containerStyle={{  }}
        />
      </div>

      <div className={`px-3 py-3 ${styles.target}`}>
        <div className={styles.actualLabel}>Actual / Target</div>
        <div className="d-flex align-items-center justify-content-between">
          <div className={`mr-4 ${styles.actualValue}`}>
            {data?.actual || "-"} / {data?.target || '-'}
          </div>
          <div>
            <Badge
              value={`${getSign(data?.actual, data?.target)} ${subtractAbsolute(
                data?.actual,
                data?.target
              ) || 'N/A'}`}
              containerStyle={{
                font: "normal normal 600 9px/18px Open Sans",
                color: data?.actual < data?.target ? "#E62121" : "#3B6EFB",
                background: data?.actual < data?.target ? "#FCE5E9" : data?.actual > data?.target ? "#E5EFFA" : "#F0F0F0",
                width: "47px",
                height: "17px",
                borderRadius: "10px",
              }}
            />
          </div>
        </div>
      </div>
      <div className="d-flex flex-column mt-3" style={{ gap: "6px" }}>
        <Breakdown
          target={data?.target}
          actual={data?.actual}
          shortFall={shortFall()}
          above={above()}
        />
      </div>
    </div>
  );
};

export default MTDTotals;
