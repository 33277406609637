import CircularPieChart from "pages/Dashboard/components/CircularPieChart";
import "../ExcavatorsSummary/Excavators.css";
import AssetBadge from "pages/Dashboard/components/AssetBadge";
import AssetDot from "pages/Dashboard/components/AssetDot";
import { useState } from "react";
import CustomTooltip from "pages/Dashboard/components/CustomTooltip/CustomTooltip";


function Excavators({ headerLabel, summaryData }) {
  const [activeTooltip, setActiveTooltip] = useState(null);
  const [tooltipDisabled, setTooltipDisabled] = useState(false);

  const total =
    summaryData?.meetingTarget?.length +
    summaryData?.fallingBehind?.length +
    summaryData?.notOperating?.length;

  const pieChartData = [
    summaryData?.meetingTarget?.length,
    summaryData?.fallingBehind?.length,
    summaryData?.notOperating?.length,
  ];

  // const handleMouseEnter = () => {
  //   if (!tooltipDisabled) {
  //     setShowTooltip(true);
  //   }
  // };

  // const handleMouseLeave = () => {
  //   if (!tooltipDisabled) {
  //     setShowTooltip(false);
  //   }
  // };

  // const handleClick = () => {
  //   setTooltipDisabled((prev) => !prev);
  //   if (tooltipDisabled) {
  //     setShowTooltip(false);
  //   }
  // };

  const handleMouseEnter = (tooltip) => {
    if (!tooltipDisabled) {
      setActiveTooltip(tooltip);
    }
  };

  const handleMouseLeave = () => {
    if (!tooltipDisabled) {
      setActiveTooltip(null);
    }
  };

  const handleClick = (tooltip) => {
    if (!summaryData?.[tooltip]?.length)
      return
    setTooltipDisabled((prev) => !prev);
    if (tooltipDisabled) {
      setActiveTooltip(null);
    } else {
      setActiveTooltip(tooltip);
    }
  };

  return (
    <div
      className="card-ex-summary"
      style={{ width: "24%", padding: "20px", height: 250 }}
    >
      <div
        className="d-flex justify-content-between align-items-cente"
        style={{ paddingBottom: "10px", borderBottom: "1px solid #f2f2f2" }}
      >
        <div className="ex-header-label">{headerLabel}</div>
        <AssetBadge
          containerStyle={{ border: "none" }}
          value={!summaryData ? "N/A" : `${total} Total`}
          color={!summaryData ? "#585858" : "#386DFF"}
          bgColor={!summaryData ? "#F0F0F0" : "#E8F2FD"}
          font={"normal normal 600 11px/15px Open Sans"}
          className={!summaryData ? "px-3 py-1" : ""}
        />
      </div>

      <div className="pt-4 d-flex justify-content-between">
        <CircularPieChart
          pieChartData={summaryData ? pieChartData : undefined}
        />
        <div
          className="flex-column justify-content-between"
          style={{ marginLeft: "20px", width: "100%" }}
        >
          {/* Meeting Target with Tooltip */}
          <div
            style={{ cursor: 'pointer' }}
            className="mb-3 d-flex justify-content-between align-items-center tooltip-parent"
            onMouseEnter={() => handleMouseEnter("meetingTarget")}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleClick("meetingTarget")}
          >
            <div className="ex-label d-flex align-items-center">
              <AssetDot bgColor={"#85D813"} />
              Meeting Target
            </div>
            <AssetBadge

              containerStyle={{ padding: "3.5px 9px" }}
              className={!summaryData ? "px-3 py-1" : "px-3"}
              value={summaryData?.meetingTarget?.length ?? "-"}
              color={"#000000"}
              bgColor={"#85D813"}
              font={"normal normal 600 12px/17px Open Sans"}
            />

            {summaryData?.meetingTarget?.length > 0 && (
              <CustomTooltip
                header={headerLabel}
                bgColor={"#85D813"}
                label={"Meeting Target"}
                showTooltip={activeTooltip === "meetingTarget"}
                data={summaryData?.meetingTarget?.join(", ")}
              />
            )}
          </div>

          <div
            style={{ cursor: 'pointer' }}
            className="mb-3 d-flex justify-content-between align-items-center tooltip-parent"
            onMouseEnter={() => handleMouseEnter("fallingBehind")}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleClick("fallingBehind")}
          >
            <div className="ex-label d-flex align-items-center">
              <AssetDot bgColor={"#FF5342"} />
              Falling Behind
            </div>
            <AssetBadge
              containerStyle={{ padding: "3.5px 9px" }}
              className={!summaryData ? "px-3 py-1" : "px-3"}
              value={summaryData?.fallingBehind?.length ?? "-"}
              color={"#FFFFFF"}
              bgColor={"#FF5342"}
              font={"normal normal 600 12px/17px Open Sans"}
            />
            {summaryData?.fallingBehind?.length > 0 && (
              <CustomTooltip
                header={headerLabel}
                bgColor={"#FF5342"}
                label={"Falling Behind"}
                showTooltip={activeTooltip === "fallingBehind"}
                data={summaryData?.fallingBehind?.join(", ")}
              />
            )}
          </div>

          <div
            style={{ cursor: 'pointer' }}
            className="mb-3 d-flex justify-content-between align-items-center tooltip-parent "
            onMouseEnter={() => handleMouseEnter("notOperating")}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleClick("notOperating")}
          >
            <div className="ex-label d-flex align-items-center">
              <AssetDot bgColor={"#CBCDCE"} />
              Not Operating
            </div>
            <AssetBadge
              containerStyle={{ padding: "3.5px 9px" }}
              className={!summaryData ? "px-3 py-1" : "px-3"}
              value={summaryData?.notOperating?.length ?? "-"}
              color={"#000000"}
              bgColor={"#EBEBEB"}
              font={"normal normal 600 12px/17px Open Sans"}
            />
            {summaryData?.notOperating?.length > 0 && (
              <CustomTooltip
                header={headerLabel}
                bgColor={"#EBEBEB"}
                label={"Not Operating"}
                showTooltip={activeTooltip === "notOperating"}
                data={summaryData?.notOperating?.join(", ")}
              />
            )}
          </div>

          <div style={{ padding: "8px 11px" }} className="card-standby">
            <div style={{ cursor: 'pointer' }} className="d-flex justify-content-between d-flex align-items-center tooltip-parent"
              onMouseEnter={() => handleMouseEnter("onStandBy")}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleClick("onStandBy")}>
              <div className="standby-label">On Standby</div>
              <div className="standby-val">{summaryData?.onStandBy?.length}</div>
              {summaryData?.onStandBy?.length > 0 && (
                <CustomTooltip
                  header={headerLabel}
                  bgColor={"#EBEBEB"}
                  label={"On Standby"}
                  showTooltip={activeTooltip === "onStandBy"}
                  data={summaryData?.onStandBy?.join(", ")}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Excavators;
