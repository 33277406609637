import AssetDot from "../AssetDot";
import Styles from "../CustomTooltip/CustomTooltip.module.css"

const CustomTooltip = ({ header, showTooltip, icon, bgColor, label, data, containerStyle = {} }) => {
  if (!showTooltip) return null;

  return (
    <div className={`px-3 ${Styles.customTooltip}`} style={{ left: 'calc(50% - 90px)', ...containerStyle }}>
      <div className={Styles.tooltipTitle} style={{ paddingBottom: "10px", borderBottom: "1px solid #f2f2f2" }}>
        {icon === undefined ? <AssetDot bgColor={bgColor} /> : icon} {label}
      </div>
      <div className={Styles.tooltipContent}>
        {header}
      </div>
      <div style={{ width: '100%' }} className={Styles.tooltipData}>
        {data}
      </div>
    </div>
  );
};

export default CustomTooltip;
