import "react-datepicker/dist/react-datepicker.css";

import {
  GEt_ALL_USER_LIST,
  DELETE_MULTIPLE_USERS,
  CHANGE_MULTIPLE_USER_STATUS
} from "../../action/apiPath";
import React, { useContext, useEffect, useState, useCallback, useMemo } from "react";
import { useSearchParams } from 'react-router-dom';

import AuthLayout from "../../components/AuthLayout";
import { CallWithAuth } from "../../action/apiActions";
import ConfirmStatusModal from "../../components/ConfirmStatusModal";
import ConfirmStatusMultipleUsers from "../../components/ConfirmStatusMultipleUsers";
import FilterBoard from "../../components/FilterBoard";
import GeneratePINModal from "../../components/GeneratePINModal";
import UserModal from "../../components/UserModal";
import { toast } from "react-toastify";
import AuthContext from "../../context/AuthProvider";
import UserTable from "./Components/UserTable";
import "./style.css";
import BulkAction from "./Components/BulkAction";
import { ConfirmSingleStatusPopup } from "./Components/ConfirmSingleStatusPopup";

const ManageUser = ({ ...props }) => {
  const { myRoles, userType, departments } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const [totalUsers, setTotalUsers] = useState(0)


  const [modals, setModals] = useState({
    type: "",
    openUserModal: false,
    OpenUserPopUpEditModal: false,
    GeneratePINState: false,
    OpenStatusModal: false,
    MultipleStatusChange: false,
    deleteModalOpen: { visible: false, type: "" },
  });

  const [filters, setFilters] = useState({
    searchUser: "",
    searchCrew: [],
    selectedEquipment: [],
    selectedUserType: [],
    selectedDepartmentType: [],
    date: "",
    isFilterApplied: false,
    isCleared: true,
    count: 0,
    sortName: null,
    sortAddedBy: null,
  });

  const [pagination, setPagination] = useState({
    page: 1,
    hasMore: true,
    userCount: 0,
    loading: false,
  });

  const [users, setUsers] = useState({
    userData: [],
    selectedUser: null,
    checkedUserList: [],
    showEllipsisPopUp: -1,
    threeDotsCall: false,
  });


  const [userAddUpdateType, setUserAddUpdateType] = useState("add");
  const [bulkActionType, setBulkActionType] = useState({})



  const allUserTypes = useMemo(() => [...userType.map(type => ({ label: type.name, value: type.code }))], [userType]);

  const statusList = [
    { value: "A", label: "Active" },
    { value: "I", label: "Inactive" },
    { value: "P", label: "Invited" },
  ];

  const onApplyFilter = useCallback(() => {
    const { selectedUserType, date, selectedDepartmentType, searchCrew, selectedEquipment, status } = filters;
    const filterCount = [selectedUserType, date, selectedDepartmentType, searchCrew, selectedEquipment, status].filter(Boolean).length;
    setPagination((prev) => ({ ...prev, loading: true }));
    getUserList(1, filters);
    setFilters((prev) => ({ ...prev, isFilterApplied: filterCount > 0, isCleared: false, }));
  }, [filters]);


  const clearFilters = () => {
    setFilters({
      searchUser: "",
      searchCrew: [],
      selectedEquipment: [],
      selectedUserType: [],
      selectedDepartmentType: [],
      date: null,
      isFilterApplied: false,
      isCleared: true,
      count: 0,
      sortName: '',
      sortAddedBy: null,
      sortStatus: '',
      status: [],
    });
  };

  const getUserList = (pages = pagination.page, currentFilters = filters) => {
    const { searchUser, sortStatus, selectedUserType, status, selectedEquipment, searchCrew, selectedDepartmentType, date, sortName, sortAddedBy } = currentFilters;
    const params = {
      search: searchUser,
      userType: selectedUserType ? selectedUserType.map(type => type.value) : [],
      equipmentLicence: selectedEquipment ? selectedEquipment.map(type => type.value) : [],
      crew: searchCrew ? searchCrew.map(type => type.value) : [],
      department: selectedDepartmentType ? selectedDepartmentType.map(type => type.value) : [],
      date: date,
      numberOfItems: 20,
      pageNumber: pages,
      sortName: sortName,
      sortAddedBy: sortAddedBy,
      status: status ? status.map(status => status?.value) : [],
      sortStatus: sortStatus || "",
    };
    // Convert non-string values to strings, handle empty, undefined, or null values
    Object.keys(params).forEach(key => {
      if (params[key] === '' || params[key] === undefined || params[key] === null || (Array.isArray(params[key]) && params[key].length === 0)) {
        delete params[key];
      } else if (typeof params[key] !== 'string' && !Array.isArray(params[key])) {
        params[key] = String(params[key]);
      } else if (Array.isArray(params[key])) {
        params[key] = params[key].map(item => String(item));
      }
    });

    setPagination({ page: pages, loading: true });
    const url = GEt_ALL_USER_LIST(params);

    CallWithAuth("GET", url).then((response) => {
      if (response.res.data.status === 200) {
        const list = pages === 1 ? response.res.data.data.result : [...users.userData, ...response.res.data.data.result];
        let updatedUserDetails = {};
        if (users?.selectedUser != null) {
          updatedUserDetails = list.find(user => user?._id === users?.selectedUser?._id);
        }
        setUsers((prev) => ({
          ...prev,
          userData: list,
          selectedUser: updatedUserDetails
        }));

        setPagination((prev) => ({
          ...prev,
          userCount: response.res.data.data.total,
          loading: false,
        }));
        setTotalUsers(response.res.data.data.total);
      } else {
        setPagination((prev) => ({ ...prev, loading: false }));
      }
    });
  };

  const handleCancelDelete = () => {
    setModals((prev) => ({ ...prev, deleteModalOpen: { visible: false, type: "" } }));
  };

  const handleConfirmDelete = async () => {
    const { type } = modals.deleteModalOpen;
    const { selectedUser } = users;

    if (type === "delete") {
      deleteMultipleAccount();
    } else {
      changeMultipleAccountStatus(selectedUser?.status === "A" ? "deactivate" : "activate", selectedUser?._id);
    }
    setModals((prev) => ({ ...prev, deleteModalOpen: { visible: false, type: "" } }));
  };

  const getUserDetails = (userData) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setUsers((prev) => ({
      ...prev,
      selectedUser: userData
    }));
  };


  const deleteMultipleAccount = async () => {
    if (users.checkedUserList.length > 0) {
      const payload = { id: users.checkedUserList?.length ? users.checkedUserList : [users?.selectedUser?._id] };
      const response = await CallWithAuth("POST", DELETE_MULTIPLE_USERS, payload);
      if (response?.res?.data?.status === 200) {
        toast.success("Selected accounts deleted successfully.", { position: "bottom-right", autoClose: 3000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, });
        getUserList(1, filters);
        var clist = document.getElementsByTagName("input");
        for (var i = 0; i < clist.length; ++i) {
          clist[i].checked = false;
        }
        setUsers((prev) => ({ ...prev, checkedUserList: [] }));
      }
      setModals((prev) => ({ ...prev, MultipleStatusChange: false }));
    }
  };

  const changeMultipleAccountStatus = async (type, ids) => {
    const { checkedUserList } = users;
    if (checkedUserList.length > 0 || ids) {
      const payload = { id: ids ? [ids] : checkedUserList, action: type };
      const response = await CallWithAuth("POST", CHANGE_MULTIPLE_USER_STATUS, payload);
      if (response?.res?.data?.status === 200) {
        toast.success(`Selected accounts ${type === "deactivate" ? "deactivated" : "activated"} successfully.`, { position: "bottom-right", autoClose: 3000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, });
        getUserList(1, filters);
        var clist = document.getElementsByTagName("input");
        for (var i = 0; i < clist.length; ++i) {
          clist[i].checked = false;
        }
        setUsers((prev) => ({ ...prev, checkedUserList: [] }));
      }
    }
  };


  const removeAllSelected = () => {
    setUsers((prev) => ({ ...prev, checkedUserList: [] }));
    document.querySelectorAll('input').forEach(input => input.checked = false);
  };


  const debounce = (func, delay = 1000) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };


  const handleChangeSearch = (value) => {
    setFilters((prev) => ({ ...prev, searchUser: value }));
  };


  const optimizedVersion = useCallback(debounce(handleChangeSearch), []);



  const actions = [
    { label: 'Activate', type: 'activate' },
    { label: 'Deactivate', type: 'deactivate' },
    { label: 'Delete', type: 'delete' },
  ];


  // useEffect(() => {
  //   document.body.classList.toggle("overlay", modals.openUserModal);
  // }, [modals.openUserModal]);

  useEffect(() => {
    const fetchUsers = (page = 1, newFilters = filters) => {
      setPagination(prev => ({ ...prev, loading: true }));
      getUserList(page, newFilters);
    };

    if (filters.searchUser || filters.isCleared) {
      fetchUsers();
    }
  }, [filters.searchUser, filters.isCleared]);

  useEffect(() => {
    const sortFilters = {
      ...filters,
      sortName: searchParams.get('sortName'),
      sortStatus: searchParams.get('sortStatus'),
      sortAddedBy: searchParams.get('sortAddedBy'),
      numberOfItems: 20,
    };
    setUsers((prev) => ({
      ...prev,
      checkedUserList: [],
      selectedUser: null
    }));
    setModals((prev) => ({
      ...prev,
      openUserModal: false
    }));
    setFilters(sortFilters);
    getUserList(1, sortFilters);
  }, [searchParams]);

  useEffect(() => {
    if (users.showEllipsisPopUp > -1) {
      setUsers(prev => ({ ...prev, showEllipsisPopUp: -1 }));
    }
  }, [pagination.loading]);

  useEffect(() => {
    getUserList(pagination.page);
  }, [pagination.page]);

  return (
    <AuthLayout pageName="User Management">
      <div className="right_sidebar_body manage-user-content mt-3">
        <div className="top_middle manage-user-content-header">
          <FilterBoard
            className={"manage-user-filter-modal"}
            headerClassName={"ml-4 pl-1 mr-2 px-3"}
            openModal={() => setModals(prev => ({ ...prev, openUserModal: true, type: "Add" }))}
            optimizedVersion={(v) => { optimizedVersion(v); setModals(prev => ({ ...prev, openUserModal: false })); setUsers(prev => ({ ...prev, selectedUser: null })) }}
            date={filters.date}
            setDate={(date) => setFilters(prev => ({ ...prev, date: date, count: (date ? (!prev.date?.length ? prev.count + 1 : prev.count) : filters?.count - 1), isCleared: false }))}
            searchCrew={filters.searchCrew}
            setSearchCrew={(crew) => setFilters(prev => ({ ...prev, searchCrew: crew, count: (crew?.length ? (!prev.searchCrew?.length ? prev.count + 1 : prev.count) : filters?.count - 1), isCleared: false }))}
            selectedEquipment={filters.selectedEquipment}
            setSelectedEquipment={(equipment) => setFilters(prev => ({ ...prev, selectedEquipment: equipment, count: (equipment?.length ? (!prev.selectedEquipment?.length ? prev.count + 1 : prev.count) : filters?.count - 1), isCleared: false }))}
            selectedUserType={filters.selectedUserType}
            setSelectedUserType={(userType) => setFilters(prev => ({ ...prev, selectedUserType: userType, count: (userType?.length ? (!prev.selectedUserType?.length ? prev.count + 1 : prev.count) : filters?.count - 1), isCleared: false }))}
            openFilter={modals.openFilter}
            setOpenFilter={(state) => setModals(prev => ({ ...prev, openFilter: state }))}
            userType={allUserTypes}
            departments={departments}
            statusList={statusList}
            onApplyFilter={onApplyFilter}
            onClear={() => { clearFilters() }}
            filter={filters}
            selectedDepartmentType={filters.selectedDepartmentType}
            setSelectedDepartmentType={(dept) => setFilters(prev => ({ ...prev, selectedDepartmentType: dept, count: (dept?.length ? (!prev.selectedDepartmentType?.length ? prev.count + 1 : prev.count) : filters?.count - 1), isCleared: false }))}
            setSelectedStatus={(status) => setFilters(prev => ({ ...prev, status: status, count: (status?.length ? (!prev.status?.length ? prev.count + 1 : prev.count) : filters?.count - 1), isCleared: false }))}
            selectedStatus={filters.status}
          />
          {users?.checkedUserList?.length > 0 && (
            <BulkAction users={users} actions={actions} setModals={setModals} setBulkActionType={setBulkActionType} removeAllSelected={removeAllSelected} />
          )}
        </div>
        <div className="table_manage_user" id="user-list-table">
          <UserTable
            totalUsers={totalUsers}
            users={users}
            userType={userType}
            getUserList={getUserList}
            setPagination={setPagination}
            getUserDetails={getUserDetails}
            pagination={pagination}
            setUsers={setUsers}
            setModals={setModals}
          />

          {modals?.openUserModal && (
            <UserModal
              filters={filters}
              modalType={modals?.type}
              setModals={setModals}
              closeModal={() => {
                setModals(prev => ({ ...prev, openUserModal: false, type: null }));
                setUsers(prev => ({ ...prev, selectedUser: null }));
              }}
              setLoading={(v) => { setPagination((prev) => ({ ...prev, loading: v })) }}
              getUserList={getUserList}
              userAddUpdateType={userAddUpdateType}
              selectedUser={users.selectedUser}
              setGeneratePINState={(state) => setModals(prev => ({ ...prev, GeneratePINState: state }))}
              setStatusModal={(state) => setModals(prev => ({ ...prev, OpenStatusModal: state }))}
              setAddUserModal={(state) => setModals(prev => ({ ...prev, openUserModal: state }))}
              userType={userType}
            />
          )}
        </div>
      </div>
      {modals.GeneratePINState && users.selectedUser && (
        <GeneratePINModal
          type="Generate"
          openPINModal={modals?.GeneratePINState}
          closeGeneratePIN={() => setModals(prev => ({ ...prev, GeneratePINState: false }))}
          selectedUser={users.selectedUser}
          userData={users.userData}
          setUserData={(data) => setUsers(prev => ({ ...prev, userData: data }))}
        />
      )}
      {modals.OpenStatusModal && users.selectedUser && (
        <ConfirmStatusModal
          openStatusModal={modals.OpenStatusModal}
          setStatusModal={(state) => setModals(prev => ({ ...prev, OpenStatusModal: state }))}
          selectedUser={users.selectedUser}
          userData={users.userData}
          setUserData={(data) => setUsers(prev => ({ ...prev, userData: data }))}
        />
      )}

      {modals.MultipleStatusChange && (
        <ConfirmStatusMultipleUsers
          type={bulkActionType?.type}
          MultipleStatusChange={modals.MultipleStatusChange}
          SetMultipleStatusChange={(state) => setModals(prev => ({ ...prev, MultipleStatusChange: state }))}
          onSubmit={bulkActionType?.type === 'delete' ? deleteMultipleAccount : changeMultipleAccountStatus}
        />
      )}
      {modals.deleteModalOpen?.visible && (
        <ConfirmSingleStatusPopup
          users={users}
          setUsers={setUsers}
          statusChange={modals.deleteModalOpen?.visible}
          type={modals.deleteModalOpen?.type === 'delete'
            ? 'delete'
            : (users.selectedUser?.status === 'A' ? 'DEACTIVATE' : 'ACTIVATE')}
          userName={`${users.selectedUser?.firstName} ${users.selectedUser?.lastName}`}
          confirmOnClick={() => {
            handleConfirmDelete();
          }}
          onClose={() => {
            handleCancelDelete();
            setUsers((prev) => ({
              ...prev,
              checkedUserList: prev.checkedUserList.filter(
                (userId) => userId !== users?.selectedUser?._id
              ),
            }));
          }}
        />
      )}

    </AuthLayout>
  );
};

export default ManageUser;
