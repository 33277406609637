import React from "react";
import { useState } from "react";
import "../../Dashboard.css";
import { ButtonGroup } from "react-bootstrap";
import AssetActionMenu from "pages/Dashboard/components/AssetActionMenu/AssetActionMenu";
import List from "../../../../assets/images/Dashboard/ActionMenuLabel/List.png";
import End from "../../../../assets/images/Dashboard/ActionMenuLabel/End.png";
import Download from "../../../../assets/images/Dashboard/ActionMenuLabel/Download.png";
import LegendsModal from "../LegendsModal/LegendsModal";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import EndShiftModal from "../EndShifts/EndShifts";
import SelectField from "pages/ShiftPlanning/components/GeneralFields/SelectField";
import ToggleButtons from "pages/Dashboard/components/ToggleButtons/ToggleButtons";
import PartialCloudy from ".././../../../assets/images/Dashboard/Weather/partialCloudy.png";
import DateTimeSelect from "pages/Dashboard/components/InputFields/DateTimeSelect/DateTimeSelect";

import CloudyNightLight from "../../../../assets/images/Dashboard/Weather/cloudyNightLight.png";
import HalfMoonLight from "../../../../assets/images/Dashboard/Weather/halfMoonLight.png";
import HeavyRainLight from "../../../../assets/images/Dashboard/Weather/heavyRainLight.png";
import MostlyCloudyLight from "../../../../assets/images/Dashboard/Weather/mostlyCloudyLight.png";
import SunLight from "../../../../assets/images/Dashboard/Weather/sunLight.png";
import { ShiftStatusEnum } from "pages/Dashboard/constant";

function Header({
  isExpanded,
  tabIdx,
  onTabChange,
  toggleSummary,
  onShiftTypeChange,
  onDateSelect,
  shiftType,
  toggleWeather,
  showWeather,
  weatherData,
  shiftOptions,
  isLoading,
  summaryData,
  onSelectShift,
  selectedDate,
  assetData,
  handleEndShift,
  showEndShiftModal,
  handleEndShiftOpenModal,
  handleShiftCloseModal,

}) {
  const radios = [
    { name: "Shift Totals", value: 1 },
    { name: "Hourly Results", value: 2 },
  ];

  const shiftTypes = [
    { name: "Day", value: 1 },
    { name: "Night", value: 2 },
  ];

  const [showLegendModal, setLegendShowModal] = useState(false);

  const handleLegendOpenModal = () => setLegendShowModal(true);
  const handleLegendCloseModal = () => setLegendShowModal(false);


  const getOptions = () => {
    const options = [];
    options.push({
      name: "Legend",
      icon: <img height={12} src={List} alt="List" />,
      onClick: (e) => handleLegendOpenModal(),
    });
    options.push({
      name: "End Shifts",
      disabled: summaryData?.shiftStatus !== ShiftStatusEnum.ONGOING ? true : false,
      icon: <img height={12} src={End} alt="End" />,
      onClick: (e) => summaryData?.shiftStatus === ShiftStatusEnum.ONGOING && handleEndShiftOpenModal(),
    });
    options.push({
      name: "Download",
      icon: <img height={12} src={Download} alt="Download" />,
      disabled: true,
      onClick: () => { },
    });

    return options;
  };

  const currentForeCast = {
    cloudCover: [weatherData?.dailyForecastData?.cloudCover[0]], // Extract the first value in an array
    temperature: [weatherData?.dailyForecastData?.temperature[0]], // Extract the first value in an array
  };

  const shiftStatusLabel = (shiftStatus) => {
    switch (shiftStatus) {
      case ShiftStatusEnum.ENDED:
        return "Ended";
      case ShiftStatusEnum.ONGOING:
        return "Ongoing";
      case ShiftStatusEnum.PUBLISHED:
        return "Published";
      default:
        return "No Records";
    }
  };

  const getIconForData = (t, forecast, iconSize) => {
    const timeC = new Date();
    timeC?.setHours(new Date()?.getHours() + t);
    const time = timeC?.getHours();
    if (time >= forecast?.cloudCover?.length || !forecast?.cloudCover)
      return (
        <img height={iconSize} src={MostlyCloudyLight} alt="full Cloudy" />
      );
    else if (forecast?.cloudCover[time] <= 20) {
      if (time > 7 && time < 19)
        return <img height={iconSize} src={SunLight} alt="sun" />;
      else return <img height={iconSize} src={HalfMoonLight} alt="moon" />;
    } else if (forecast?.cloudCover[time] <= 60) {
      if (time > 7 && time < 19)
        return (
          <img height={iconSize} src={MostlyCloudyLight} alt="sunny Cloudy" />
        );
      else
        return (
          <img height={iconSize} src={CloudyNightLight} alt="night Cloudy" />
        );
    } else if (forecast?.cloudCover[time] <= 90) {
      if (time > 7 && time < 19)
        return (
          <img height={iconSize} src={MostlyCloudyLight} alt="cloudy day" />
        );
      else
        return (
          <img height={iconSize} src={CloudyNightLight} alt="cloudy Night" />
        );
    } else
      return (
        <img height={iconSize} src={MostlyCloudyLight} alt="fully Cloudy" />
      );
  };

  return (
    <>
      <div>
        <div className=" d-flex justify-content-between align-items-center px-5 pt-3 pb-2">
          <div className="d-flex justify-content-between align-items-center">
            <DateTimeSelect
              value={selectedDate}
              onDateChange={onDateSelect} />
            <ToggleButtons
              selectedValue={shiftType == "day" ? 1 : 2}
              onOptionClick={(v) => onShiftTypeChange(v == 1 ? "day" : "night")}
              options={shiftTypes}
            />
          </div>

          <div className="d-flex justify-content-between align-items-center">
            {
              //!Dont Delete this is a Weatehr Component
            }
            {/* <button
              type="button"
              className="btn weatherBtn mr-2"
              onClick={toggleWeather}
              style={{
                backgroundColor: showWeather ? "#FAFAFA" : "#FFFFFF",
                border: "1px solid #F1F1F1",
                borderRadius: "15px",
                font: "normal normal 600 11px/15px Open Sans",
                color: "#386DFF",
              }}
            >
              <span className="pr-2">
                {getIconForData(0, currentForeCast, 18)}
              </span >
              {weatherData?.temp}°
            </button > */}

            <SelectField
              noPlaceHolder={true}
              options={shiftOptions?.[shiftType]}
              value="1"
              className={"mr-2"}
              isDisabled={!shiftOptions?.[shiftType]?.length || isLoading}
              style={{
                height: "30px",
                width: "160px",
                marginTop: 2.5,
                border: "1px solid #F1F1F1",
                borderRadius: "15px",
                paddingLeft: "10px",
                font: "normal normal 600 11px/14px Open Sans",
                color: "#000000",
                cursor:
                  !shiftOptions?.[shiftType]?.length || isLoading
                    ? "not-allowed"
                    : "pointer",
              }}
              onChange={(v) => onSelectShift(shiftType, v)}
              mainContainerStyle={{ marginBottom: "0px" }}
            />
            <AssetActionMenu
              // className={}
              options={getOptions()}
              optionsContainerClass="width:170px"
            />
          </div>
        </div>

        {/* Modal Component */}
        <LegendsModal
          show={showLegendModal}
          handleClose={handleLegendCloseModal}
        />
        <EndShiftModal
          assetData={assetData}
          show={showEndShiftModal}
          handleClose={handleShiftCloseModal}
          handleEndShift={handleEndShift}
        />
      </div >
      <div className="d-flex justify-content-between align-items-center px-5 pt-3 pb-0">
        <div className="shift-label">
          {`${summaryData?.reportNo ?? "#12345"} ${summaryData?.shiftType ?? "Day"} Shift`}{" "}
          <span className="shift-ongoing-label">
            ({shiftStatusLabel(summaryData?.shiftStatus)})
          </span>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <ToggleButtons
            selectedValue={tabIdx}
            onOptionClick={(v) => onTabChange(v)}
            options={radios}
          />
          <div
            className="pl-3 pb-1"
            onClick={toggleSummary} // Toggle on click
            style={{ cursor: "pointer" }}
          >
            {isExpanded ? (
              <IoIosArrowUp
                size={20}
                style={{ color: "#888", fontSize: "24px" }}
              />
            ) : (
              <IoIosArrowDown
                size={20}
                style={{ color: "#888", fontSize: "24px" }}
              />
            )}
            <span style={{ marginLeft: "10px" }}></span>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
