// Helper to format the search query
export const formatQuery = (query) => query.trim().toLowerCase().replace(/\s+/g, "");

// Helper to check if any field in a delay entry matches the query
export const delayMatchesQuery = (delay, query) => {
    return ["delayType", "reportedBy", "shiftStatus", "delaySubType"].some((field) =>
        delay[field]?.toLowerCase().replace(/\s+/g, "").includes(query)
    );
};

// Helper to filter data based on the item type (e.g., excavators or trucks)
export const filterData = (items, query) => {
    return items.filter((item) =>
        item?.addedBy.toLowerCase().replace(/\s+/g, "").includes(query)
       
    );
};
