import React from "react";
import { RiRadioButtonLine } from "react-icons/ri";
import { formatDate } from "../LoadTrackerTable/helpers";
import { useExcavatorLoadTrackerContext } from "../context/context";
import styles from "./LoadTracker.module.css";
import moment from "moment";

const DOT_HEIGHT = 20;
const MIN_LINE_HEIGHT = 52;

export const Dot = ({ Dotstyles, active }) => {
  const getColor = () => {
    if (active) return "#2A7B39";
    return "#1b499f";
  };
  const getBgColor = () => {
    if (active) return "#63E07A";
    return "#72a2fd";
  };
  return (
    <RiRadioButtonLine
      color={getColor()}
      min={DOT_HEIGHT}
      size={DOT_HEIGHT}
      style={{
        backgroundColor: getBgColor(),
        borderRadius: "50%",
        margin: "0 auto",
        padding: "0px",
        ...(Dotstyles ? Dotstyles : {}),
      }}
    />
  );
};

export const DotDescription = ({ label, detail }) => (
  <hgroup style={{ fontSize: "12px", height: DOT_HEIGHT + 2 }}>
    <div
      className="mt-2"
      style={{
        fontSize: "1rem",
        fontWeight: "600",
        lineHeight: 0.1,
        margin: 0,
      }}
    >
      {label}
    </div>
    <p
      style={{
        color: "gray",
        fontSize: "0.9rem",
        fontWeight: 600,
        height: "max-content",
        marginBottom: 0,
      }}
    >
      <em>{detail}</em>
    </p>
  </hgroup>
);

const Line = ({ active = false, height }) => {
  const color = active ? "#1b499f" : "#939292";
  const width = active ? "5px" : "3px";
  return (
    <div
      style={{
        backgroundColor: color,
        height: height,
        minHeight: height,
        margin: "0 auto",
        width: width,
      }}
    />
  );
};

const Tracker = () => {
  const { getExcavatorData } = useExcavatorLoadTrackerContext();
  const excavator = getExcavatorData();

  if (!excavator) {
    return <></>;
  }

  return (
    <div className={styles["tracker-container"]}>
      <section
        style={{
          display: "flex",
          flexDirection: "row",
          paddingBottom: 20,
          marginLeft: "6.3rem",
          marginTop: 40,
        }}
      >
        <section
          style={{
            marginLeft: 10,
            width: 30,
            marginRight: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {" "}
          {excavator.loads.map((load, index) => (
            <div key={index}>
              <Dot
                active={!moment(load.endTime).isValid()}
              />

              {index+1 !== excavator?.loads.length && (<Line
                active={load.endTime}
                height={Math.max(MIN_LINE_HEIGHT, 65)}
              />)}
            </div>
          ))}
        </section>

        <section
          style={{
            display: "flex",
            flexDirection: "column",
            gap: `${MIN_LINE_HEIGHT + 15}px`,
            width: "fit-content",
            marginTop: "5px"
          }}
        >
          {excavator.loads.map((load, index) => (
            <div key={index}>
              <DotDescription
                label={load?.truckName}
                detail={formatDate(load.endTime, "00:00 AM")}
                // status={excavator.status === 1}
              />
            </div>
          ))}
        </section>
      </section>
    </div>
  );
};

export default Tracker;
