import DynamicSelectField from "pages/DelayAndActivity/Component/GeneralFields/DynamicSelectField/DynamicSelectField";
import FromToTimeSpan from "pages/DelayAndActivity/Component/GeneralFields/FromToTimeSpan/FromToTimeSpan";
import { fetchDelaySubTypes } from "../utils/apiService";
import { useDelayContext } from "../context/context";
import { useEffect, useState } from "react";
import { showToast } from "pages/DelayAndActivity/utils/helper";

const EditDelayComponent = ({showError, formData, setFormData }) => {
  const {
    delayTypeList,
  } = useDelayContext();
  const [delaySubTypeList, setDelaySubTypeList] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchDelaySubTypes(formData?.delayType?.value)
        setDelaySubTypeList(data)
      } catch (error) {
        showToast(false, error.message)
      }
    }
    if (formData?.delayType)
      fetchData()
  }, [formData?.delayType])

  const onChange = (field, val) => {
    setFormData((prev) => ({ ...prev, [field]: val }))
  }

  return (
    <div className="px-5" >

      <DynamicSelectField
        label={"Delay Type"}
        placeholder={"Select Delay Type"}
        options={delayTypeList}
        value={formData?.delayType}
        onChange={async (val) => {
          onChange("delayType", val)
          onChange("delaySubType", "")
          try {
            const data = await fetchDelaySubTypes(val.value)
            setDelaySubTypeList(data)
          } catch (error) {
            showToast(false, error.message)
          }
        }}

        required
        error={showError && !formData?.delayType}
        errorMessage={"Please select a delay type"}
        style={{
          background: "#FFFFFF",
          border: "1px solid #E1E8F4",
          borderRadius: "4px",
          marginTop: "10px",
          font: "normal normal 600 14px/19px Open Sans"
        }}
        labelStyle={{
          font: "normal normal normal 14px/17px Open Sans",
          color: "#1A1A1A",
        }}
        valueStyle={{
          font: "normal normal 600 14px/19px Open Sans",
          color: "#1A1A1A"
        }}
      />

      <DynamicSelectField
        label={"Delay Sub-Type"}
        placeholder={"Select Delay Sub-Type"}
        options={delaySubTypeList}
        value={formData?.delaySubType}
        onChange={(val) => onChange("delaySubType", val)}
        required
        error={showError && !formData?.delaySubType}
        errorMessage={"Please select a delay sub type"}
        style={{
          background: "#FFFFFF",
          border: "1px solid #E1E8F4",
          borderRadius: "4px",
          marginTop: "10px",
        }}
        labelStyle={{
          font: "normal normal normal 14px/17px Open Sans",
          color: "#1A1A1A",
        }}
        valueStyle={{
          font: "normal normal 600 14px/19px Open Sans",
          color: "#1A1A1A"
        }}
      />

      <FromToTimeSpan
        label={"Delay Duration"}
        onChange={(key, val) => {
          onChange(key, val)
        }}
        labelStyle={{
          font: "normal normal normal 14px/17px Open Sans",
          color: "#1A1A1A",
          marginTop: "10px",
        }}
        valueStyle={{
          font: "normal normal 600 14px/19px Open Sans",
          color: "#1A1A1A"
        }}
        startTime={formData?.startTime}
        endTime={formData?.endTime}
        required
        error={showError && (!formData?.startTime || !formData?.endTime)  }
        errorMessage={"Please select a delay duration"}
      />
    </div>);
}

export default EditDelayComponent