import styles from "../ShiftSummaryReport.module.css";
import TextAreaField from "../../../Component/GeneralFields/TextAreaField";
import { useState } from "react";
import AddSign from "../../AddSign/AddSign";
import { SignatureAction } from "../../AddSign/SignatureAction";
import moment from "moment";

const SupervisorSection = ({
  signatureData,
  setSignatureData,
  handelSaveAndSign,
  commentData,
}) => {
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleDropdownOptions = async (action, sign) => {
    if (action === "edit") {
      setShowModal(true);
    }
    if (action === "delete") {
      setSignatureData({
        ...signatureData,
        signedName: "",
        signature: "",
        file: "",
      });
    }
  };

  const formatDate = (date) => {
    if (!date) return "";

    return moment(date).tz("Pacific/Auckland").format("hh:mm A - DD/MM/YYYY");
  };

  return (
    <div id="supervisor" style={{ background: "#FAFAFA" }} className="mb-4">
      <div className={`px-4 pt-4 pb-2 ${styles.supervisor}`}>
        Supervisor’s Use
      </div>
      <div className="d-flex align-items-center justify-content-between px-4 pb-1">
        <div className={styles.comments}>Comments</div>
        <div className={`px-4 pb-1 ${styles.signature} `}>Signature</div>
        <div className={styles.addSignatureBtn} onClick={handleShowModal}>
          Add
        </div>

        {showModal && (
          <>
            <div
              style={{
                position: "fixed",
                top: 327,
                left: 435,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0,0,0,0)",
                zIndex: 1000,
              }}
            >
              <AddSign
                closeModal={() => {
                  setShowModal(false);
                }}
                onChange={(v) => {
                  let temp = {
                    ...signatureData,
                    signature: v.file,
                    signedName: v.signedName,
                  };

                  if (v.f) {
                    temp["file"] = v.f;
                  }
                  setSignatureData(temp);
                }}
                props={{
                  signedName: signatureData?.signedName,
                  signature: signatureData?.signature,
                }}
              />
            </div>
          </>
        )}
      </div>
      <div className="d-flex align-items-center justify-content-between px-4 ">
        <textarea
          placeholder="Type..."
          value={signatureData?.comments}
          onChange={(e) => {
            setSignatureData({
              ...signatureData,
              comments: e.target.value,
            });
          }}
          style={{
            height: "8.5rem",
            maxHeight: "10rem",
            width: "62%",
            backgroundColor: "white",
            borderRadius: "4px",
            border: "1px solid #EBEBEB",
            padding: "0.85rem",
            marginBottom: "0.85rem",
            resize: "vertical",
            font: "normal normal normal 12px/17px Open Sans",
            color: "#000000",
          }}
        />
        <div
          className="d-flex py-3 "
          style={{ alignSelf: "flex-start", width: "33%" }}
        >
          {signatureData?.signature ? (
            <div
              className="d-flex w-100 justify-content-between"
              style={{ borderTop: "1px solid #EBEBEB" }}
            >
              <div>
                <embed
                  style={{ maxHeight: "40px", maxWidth: "170px" }}
                  // src={dataObj?.supervisor?.signature}
                  src={signatureData?.signature}
                  alt="sign"
                />
                <br />
                <div
                  style={{
                    font: "normal normal normal 12px/17px Open Sans",
                    color: "#000000",
                  }}
                >
                  {signatureData?.signedName}
                </div>
                <div
                  style={{
                    font: "normal normal normal 10px/14px Open Sans",
                    color: "#707070",
                    textTransform: "uppercase",
                  }}
                >
                  {formatDate(commentData?.signedAt)}
                </div>
              </div>

              <SignatureAction handleDropdownOptions={handleDropdownOptions} />
            </div>
          ) : (
            <div
              className="py-3 "
              style={{
                borderBottom: "1px solid #EBEBEB",
                borderTop: "1px solid #EBEBEB",
                font: "normal normal normal 10px/14px 'Open Sans', sans-serif",
                color: "#707070",
                width: "193px",
              }}
            >
              No signature saved
            </div>
          )}
        </div>
      </div>
      <div
        className="d-flex align-items-center justify-content-center pb-4 "
        style={{ width: "100%" }}
      >
        <div
          className={`${styles.saveBtn} ${
            !signatureData?.signature ? styles.disabled : ""
          }`}
          onClick={handelSaveAndSign}
        >
          Save
        </div>
      </div>
    </div>
  );
};

export default SupervisorSection;
