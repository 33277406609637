import React from "react";
import Styles from "./Header.module.css";
import DateTimeSelect from "pages/DelayAndActivity/Component/GeneralFields/DateTimeSelect/DateTimeSelect";
import ToggleButtons from "pages/DelayAndActivity/Component/ToggleButton/ToggleButton";
import SelectField from "pages/DelayAndActivity/Component/GeneralFields/SelectField/SelectField";
import Search from "pages/DelayAndActivity/Component/GeneralFields/Search/Search";
import Filter from "../Filter/Filter";
import AddActivity from "../AddActivity/AddActivity";
import { useActivityContext } from "../context/context";
import filter from "../../../../../assets/images/filter.png";

const SHIFT_TYPES = [
  { name: "Day", value: 1 },
  { name: "Night", value: 2 },
];

const Header = () => {
  const {
    setOpenFlyer,
    openFlyer,
    selectedDate,
    setSelectedDate,
    shiftType,
    setShiftType,
    shiftOptions,
    setSelectedShift,
    searchQuery,
    setSearchQuery,
    filters,
    loading,
    activityData,
    onApplyFilter
  } = useActivityContext()
  return (
    <div className={`d-flex justify-content-between align-items-center px-5 pt-4 pb-3 ${Styles.header} border-bottom`}>
      <div className="d-flex justify-content-between align-items-center">

        <div
          className={`px-3 py-2 ${Styles.addDelayBtn} ${(!shiftOptions?.[shiftType]?.length || loading || openFlyer) ? Styles.disabled : ''}`}
          onClick={() => setOpenFlyer("ADD_ACTIVITY")}
        >
          Add Activities
        </div >

        {openFlyer == "ADD_ACTIVITY" && < AddActivity />}

        <DateTimeSelect
          value={selectedDate}
          onDateChange={(date) => {
            setSelectedDate(date);
            setOpenFlyer("");
          }}
          className={`${Styles.dateTimeSelect} ml-5`}
          iconColor="#6B7B8E"
          containerStyle={{ border: "1px solid #E1E8F4" }}
        />

        <ToggleButtons
          selectedValue={shiftType === "day" ? 1 : 2}
          onOptionClick={(v) => {
            setShiftType(v === 1 ? "day" : "night")
            setOpenFlyer("");
          }}
          style={{ height: 27.5 }}
          options={SHIFT_TYPES}
        />

        <SelectField
          noPlaceHolder={true}
          options={shiftOptions?.[shiftType]}
          value="1"
          className="ml-3"
          isDisabled={!shiftOptions?.[shiftType]?.length || loading}
          style={getSelectFieldStyle(loading, shiftOptions?.[shiftType])}
          onChange={(v) => {
            setSelectedShift((prev) => ({ ...prev, [shiftType]: v }))
            setOpenFlyer("");
          }}
          mainContainerStyle={{ marginBottom: "0px" }}
        />
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <Search query={searchQuery} onChange={(value) => {
          setSearchQuery(value)
          onApplyFilter(filters, value, activityData)
        }} />

        <div
          style={{
            userSelect: 'none',
            backgroundColor: openFlyer == "ACTIVITY_FILTER" ||
              (filters.activityTypes.length > 0 ||
                filters.equipments.length > 0 ||
                filters.startTime ||
                filters.endTime) ? "#E2ECFC" : "transparent"
          }}
          className={Styles.filterBtn}
          onClick={() => setOpenFlyer("ACTIVITY_FILTER")}
        >
          <img src={filter} alt="filter" />
          <span>Filter</span>
        </div>

        {openFlyer == "ACTIVITY_FILTER" && <Filter />}
      </div>
    </div>
  );
};

const getSelectFieldStyle = (isLoading, options) => ({
  height: "30px",
  width: "160px",
  marginTop: 2.5,
  border: "1px solid #F1F1F1",
  borderRadius: "15px",
  paddingLeft: "10px",
  font: "normal normal 600 11px/14px Open Sans",
  color: "#000000",
  cursor: !options?.length || isLoading ? "not-allowed" : "pointer",
});

export default Header;
