import React from 'react'
import LoadTrackerTable from '../LoadTrackerTable/LoadTrackerTable'
import LoadTracker from '../LoadTracker/LoadTracker'

const LoadTrackerLayout = () => {
    return (
        <div className='d-flex flex-row w-100 position-relative' style={{ height: 'calc(100% - 9rem)'}}>
            <LoadTrackerTable />
            <LoadTracker />
        </div>
    )
}

export default LoadTrackerLayout