export const InitialFilters = {
  equipments: [],
  delayTypes: [],
  activityTypes: [],
  startTime: "",
  endTime: ""
};

export const DelayShiftStatusType = {
  PAUSE: "PAUSE",
  ENDED: "ENDED"
}
