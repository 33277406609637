export const API_METHODS = {
  GET: "get",
  POST: "post",
  PUT: "put",
  PATCH: "patch",
  DELETE: "delete",
};

export const ZONE_DATA = {
  ZONE1: { color: "#138BAA", name: "zone1", label: "Zone 1" },
  ZONE2: { color: "#FAF265", name: "zone2", label: "Zone 2" },
  ZONE3: { color: "#4C4B66", name: "zone3", label: "Zone 3" },
  ZONE4: { color: "#FFC850", name: "zone4", label: "Zone 4" },
  ZONE5: { color: "#00C48C", name: "zone5", label: "Zone 5" },
  ZONE6: { color: "#6C63FF", name: "zone6", label: "Zone 6" },
};

export const NZSTEEL_API_BASE_URL: string = process.env.REACT_APP_NZSTEEL_API!;

export const ENDPOINTS = {
  login: "auth/login",
  forgotPassword: "auth/forgot-password",
  resetPassword: "users/reset-password",
  getZones: "zone/",
  getDailyZones: "jobs/get-zones",
  getActiveOperators: "users/active-operators",
  logout: "auth/logout",
  editHazards: "zone/edit-hazard",
  getTrucks: "trucks",
  changeZoneStatus: "zone/change-status",
  getAllUsers: "users/users-list",
  addUser: "users/add-user",
  resendInvitation: "users/resend-invitation",
  cancelInvitation: "users/cancel-invitation",
  editUser: "users/edit-user",
  deactivateDeleteUsers: "users/deactivate-delete",
  getDepartments: "departments/",
  getLicenses: "users/licenses",
  getNewToken: "auth/refresh-token",
  setTimer: (zone: number, reminderTime: Date) =>
    `zone/set-timer?zone=${zone}&timer=${reminderTime}`,
  getWaterConsumption: "water-usage",
  changePassword: "users/change-password",
  authChangePassword: "auth/change-password",
  dismissAlert: (zone: number) => `zone/dismiss-alert?zone=${zone}`,
  getJobs: "jobs/jobs-list",
  getJob: "jobs/get-job",
  getJobDailyReport: "reports/jobs-daily-report",
  getZonesReport: "reports/zone-daily-report",
  getSingleJob: "jobs/single-job",
  getRefills: "waterRefill/",
  validateToken: "auth/validate-token",
  generatePin: "users/generate-pin",
  getPrestartChecks: "prestart/",
  getsummarypdf: "prestart/summary-pdf",
  getImage: "prestart/image",
  getReportPdf: "prestart/report-pdf",
  signPrestartCheck: "prestart/sign",
  getCheckPoints: "checkpoints/",

  // ---------- DMU ----------- //
  getBulldozers: process.env.REACT_APP_GET_BULLDOZERS!,
  calculatePushes: process.env.REACT_APP_PUSHES_SUMMARY!,
  calculatePushesForTimeline: process.env.REACT_APP_PUSHES_TIMELINE!,
  calculateTimeUseProgression: process.env.REACT_APP_CONTACT_NEARBY_TIMELINE!,
  calculateTimelineSpeed: process.env.REACT_APP_SPEED_TIMELINE!,
  getSummaryPushes: process.env.REACT_APP_PUSHES_SUMMARY!,
  getSummaryContactNearby: process.env.REACT_APP_CONTACT_NEARBY_SUMMARY!,
  getSummarySpeed: process.env.REACT_APP_SPEED_SUMMARY!,
  getNZsteelMineTucks: "trucks",
  getEngineHours: process.env.REACT_APP_ENGINE_HOURS_SUMMARY!,
  getIdleTime: process.env.REACT_APP_IDLE_TIME_SUMMARY!,
  updateEngineHours: process.env.REACT_APP_ENGINE_HOURS_UPDATE!,
  getTrafficLight: process.env.REACT_APP_TRAFFIC_LIGHT_STATUS!,
  getTonnage: process.env.REACT_APP_TONNAGE!,
  calculateEngineHoursForTimeline: process.env.REACT_APP_ENGINE_HOURS_TIMELINE!,
  calculateIdleTimeForTimeline: process.env.REACT_APP_IDLE_TIME_TIMELINE!,
  getTonnageForTimeline: process.env.REACT_APP_TONNAGE_TIMELINE!,
};

export const headerText = [
  "User Name",
  "Status",
  "User Type",
  "Email",
  "Department",
  "Equipment License",
  "PIN No",
  "Added by",
  "Actions",
];

export const headerText2 = [
  "Asset ID",
  "Department",
  "Status",
  "Ref No.",
  "Operator",
  "Login/Engin On",
  "Check Time",
  "Failed Checks",
  "Engine Hours",
  "Supervisor's Signature",
];
export const headerText3 = ["", "Checklist", "Answer"];
export const UserType = {
  admin: { value: "Admin", name: "Super Admin" },
  supervisor: { value: "Supervisor", name: "Supervisor" },
  operator: { value: "Operator", name: "Operator" },
};
export const UserStatus = {
  invited: "Invited",
  inactive: "Inactive",
  active: "Active",
};

export const sections = {
  200: "Material Movements",
  300: "Maintenance",
  400: "DMU Dashboard",
  500: "KPIs",
  600: "Pre-start Checks",
  700: "Manage Users",
  800: "Asset Tracking",
};

export const subSections = {
  100: [],
  200: [],
  300: ["Workshop", "Engine Hours"],
  400: [],
  500: [],
  600: [],
  700: [],
  800: ["Live Tracking", "Trips History"],
};

export const links = {
  200: "/material-movements",
  300: "/maintenance",
  400: "/dmu",
  500: "/kpi",
  600: "/pre-start-check",
  700: "/user-management",
  800: "/asset-tracking",
};

export const desiredOrder = [500, 800, 400, 200, 600, 300, 700];

export const DepartmentNames = {
  200: "Material Movements",
  300: "Maintenance",
  400: "Dry Mining Unit (DMU)",
  500: "KPI Dashboard",
  600: "Pre-start Checks",
  700: "Manage Users",
  800: "Asset Tracking",
};

export const userType = {
  Supervisor: "Supervisor",
  Admin: "Super Admin",
  Operator: "Operator",
};

export const STATUS_DATA = {
  ZONE1: { color: "#00C48C", name: "passed", label: "Passed" },
  ZONE2: { color: "#E3402F", name: "failed", label: "Failed" },
  ZONE3: { color: "#325D88", name: "incomplete", label: "Incomplete" },
};
