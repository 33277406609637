import {
  DELETE_SHIFT_PLAN,
  DUPLICATE_SHIFT_PLAN,
  GET_CREW,
  GET_DEPARTMENTS,
  GET_MINES,
  PUBLISH_SHIFT_PLAN,
  SHIFT_LISTING_DETAILS,
  SHIFT_LISTING_LOAD_COUNT,
} from "../action/apiPath";
import { Link, useNavigate } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import './ShiftListing.css'
import AuthLayout from "../components/AuthLayout";
import { CallWithAuth } from "../action/apiActions";
import { Helmet } from "react-helmet";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingScreen from "../components/LoadingScreen";
import moment from "moment";
import { toast } from "react-toastify";
import { useRef } from "react";
import GetStatusCell from "../components/GetStatusCell";
import ShiftPlanFilter from "./ShiftPlanning/SubComponents/ShiftPlanFilter/ShiftPlanFilter";
import { InitialFilters, ShiftStatus } from "./ShiftPlanning/utils/contants";
import ActionMenu from "./ShiftPlanning/components/ActionMenu/ActionMenu";
import { formateDataForSaving } from "./ShiftPlanning/utils/helpers/PublishShift";
import ConfirmAlert from "./ShiftPlanning/components/ConfirmAlert/ConfirmAlert";

const Loader = () => (
  <ThreeDots
    height="20"
    width="20"
    radius="9"
    color="#000"
    ariaLabel="three-dots-loading"
    wrapperStyle={{}}
    wrapperClassName=""
    visible={true}
  />
);

const ShiftListing = () => {
  const pagesRef = useRef(0);
  const [shiftList, setShiftList] = useState("");
  const [loading, setLoading] = useState(true);
  const [userCount, setUserCount] = useState(0);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const isDataFetching = useRef({});
  const [confirmModal, setConfirmModal] = useState({
    show: false,
    title: "",
    message: "",
    sid: "",
    reportNo: ""
  })
  const [openFilter, setOpenFilter] = useState(false);
  const [totalShifts, setTotalShifts] = useState(0)
  const shiftListRef = useRef([]);
  const [resetList, setResetList] = useState(false);
  const [minesList, setMinesList] = useState([]);
  const [departmentsList, setDepartmentsList] = useState([]);
  const myInterval = useRef([]);
  const [crewTypesList, setCrewTypesList] = useState([]);
  const [loadsFetching, setLoadsFetching] = useState(false);
  const [query, setQuery] = useState('');
  const [filterObject, setFilterObject] = useState(InitialFilters);

  const navigate = useNavigate();

  useEffect(() => {
    getCrew();
    getDepartment();
    getMine();

    const tableArea = document.getElementById("shift-list-table");
    const contextMenuHandler = (e) => {
      e.preventDefault();
    };

    tableArea?.addEventListener("contextmenu", contextMenuHandler);

    return () => {
      tableArea?.removeEventListener("contextmenu", contextMenuHandler);
      clearInterval(myInterval.current);
    };
  }, []);

  useEffect(() => {
    if (shiftList?.length > 0) {
      shiftList?.filter((data) => data.openTab === true)?.length > 0
        ? document
          .querySelector(".top_header")
          .classList.add("overlay-active-header-inner-dropdown2")
        : document
          .querySelector(".top_header")
          .classList.remove("overlay-active-header-inner-dropdown2");
    }
    document.querySelector(".overlay-active-header-inner-dropdown2") &&
      document
        .querySelector(".overlay-active-header-inner-dropdown2")
        .addEventListener("click", function () {
          handelClosePopUp();
        });
  }, [shiftList]);

  const getCrew = async () => {
    const response = await CallWithAuth("GET", GET_CREW);
    if (response.res.data.status === 200) {
      setCrewTypesList(response.res.data.data);
    }
  };

  const getDepartment = async () => {
    const response = await CallWithAuth("GET", GET_DEPARTMENTS);
    if (response.res.data.status === 200) {
      setDepartmentsList(response.res.data.data);
    }
  };

  const getMine = async () => {
    const response = await CallWithAuth("GET", GET_MINES);
    if (response.res.data.status === 200) {
      setMinesList(response.res.data.data);
    }
  };

  const fetchActualTargetLoads = async (_id) => {
    const response = await CallWithAuth("GET", SHIFT_LISTING_LOAD_COUNT(_id));

    return {
      _id,
      data:
        response?.res?.data?.status == 200
          ? response?.res?.data?.data
          : {
            excavatorTargetLoads: 0,
            excavatorActualLoads: 0,
            truckActualLoads: 0,
            truckTargetLoads: 0,
          },
    };
  };

  const accumulatedLoads = async (shiftLists) => {
    try {
      const promises = shiftLists.map((item) =>
        fetchActualTargetLoads(item?._id)
      );
      const values = await Promise.all(promises);
      const result = values.reduce((obj, item) => {
        obj[item?._id] = item?.data;
        return obj;
      }, {});

      return result;
    } catch (error) {
      console.log({ error });
    }
  };

  const fetchData = async (payload, pages) => {
    isDataFetching.current[pages] = true;
    if (localStorage.getItem("accessToken")) {
      let response = null;
      try {
        response = await CallWithAuth("POST", SHIFT_LISTING_DETAILS, payload);
      } catch (e) { }

      if (response?.res?.data?.status === 200) {
        if (pagesRef.current < pages) {
          pagesRef.current = pages;
          shiftListRef.current = [
            ...shiftListRef.current,
            ...response.res.data.data,
          ];
        } else {
          shiftListRef.current = shiftListRef.current.map((s) => {
            const value = response.res.data.data?.find((ns) => ns._id == s._id);
            if (value) return { ...value, openTab: s.openTab ? true : false };
            return s;
          });
        }

        setUserCount(response?.res?.data?.data?.total);
        setShiftList(shiftListRef.current);
        setTotalShifts(response?.res?.data?.totalShifts)
        setLoading(false);
        setLoadsFetching(true);

        const shiftLoads = await accumulatedLoads(shiftListRef.current);

        /* TODO - [Bugfix/IMOS-190] 
        While being navigated from the shift plan page to this component, the excavatorTargetLoads and truckTargetLoads,
        are being updated to 0 when the shiftLists are being mapped to shiftLoads in the below code 
        the fetchActualTargetLoads called internally in accumulatedLoads returns 0 for them, making them get temporarily updated to 0 eventhough 
        shiftListRef has the correct values on initial load. The below mapping strategy is updating the value to 0.

        This fix should not be necessary when the logic to update the shift list is refactored. Please remember to get back to this fix then.
        */
        const udpatedShiftData = shiftListRef?.current?.map((shift) => ({
          ...shift,
          ...{
            ...shiftLoads?.[shift?._id]
            // excavatorTargetLoads: shift.excavatorTargetLoads,
            // truckTargetLoads: shift.truckTargetLoads,
          },
        }));

        setShiftList(udpatedShiftData);
        setLoadsFetching(false);
      } else {
        setLoadsFetching(false);
        setLoading(false);
      }
    }
    isDataFetching.current[pages] = false;
  };

  const loadShiftData = async (pages = page) => {
    setLoading(true);
    let payload = {
      PAGE_NUMBER: pages,
      NUMBER_OF_ITEMS: 20,
      filter: true,
      search: true,
      date: filterObject.date,
      crew: filterObject.crew,
      shift: filterObject.shift,
      department: filterObject.department,
      mine: filterObject.mine,
      status: filterObject.status,
      query: query,
    };
    setPage(pages);
    await fetchData(payload, pages);
    myInterval.current = [
      ...myInterval.current,
      setInterval(async () => {
        if (!isDataFetching.current[pages]) fetchData(payload, pages);
      }, 45000),
    ];
  };

  const handelClosePopUp = () => {
    const list = [...shiftListRef.current];
    list.forEach((res) => (res.openTab = false));
    shiftListRef.current = list;
    setShiftList(list);
  };

  const handleClick = (e, userValue, index = 0, exact_id) => {
    const list = [...shiftListRef.current];
    let fulls = [];
    list.forEach((res) => {
      res = {
        ...res,
        openTab: false,
      };
      fulls.push(res);
    });
    changetab(exact_id);
  };

  const changetab = (id) => {
    let list = [...shiftListRef.current];
    let fulls = [];
    list.forEach((res, idx) => {
      if (res._id === id) {
        res = {
          ...res,
          openTab: true,
        };
      } else {
        res = {
          ...res,
          openTab: false,
        };
      }
      fulls.push(res);
    });
    shiftListRef.current = fulls;
    setShiftList(fulls);
  };

  const fetchMoreData = () => {
    if (shiftList.length >= userCount) {
      setHasMore(false);
      return;
    }
    loadShiftData(page + 1);
  };

  const callDownload = (data) => {
    let filenmae = "";

    let list = [...shiftListRef.current];
    const selectedData = list.find((item) => item._id == data._id);
    const indexing = list.findIndex((item) => item._id == data._id);

    changetab(indexing);
    let headers = [
      "Report #,Shift,Mine,Department,Crew,Status,Excavators (Loads) Target,Excavators (Loads) Actual,Trucks (Loads) Target,Trucks (Loads) Actual,Events & Delays,Shift Date & Time,Supervisor",
    ];

    let temp_obj = {};
    let listArr = [];

    if (selectedData && selectedData._id) {
      filenmae = selectedData.reportNo ? selectedData.reportNo : "" + ".csv";

      let shiftDate = `${selectedData.shiftDate
        ? moment(selectedData.shiftDate).format("DD/MM/YYYY")
        : ""
        } ${selectedData.startTime ? selectedData.startTime : ""} ${selectedData.endTime ? selectedData.endTime : ""
        }`;
      listArr.push([
        selectedData.reportNo ? selectedData.reportNo : "",
        selectedData.shift ? selectedData.shift : "",
        selectedData.mine ? selectedData.mine : "",
        selectedData.department ? selectedData.department : "",
        selectedData.crew ? selectedData.crew : "",
        selectedData.status && selectedData.status === "P"
          ? "Published"
          : selectedData.status && selectedData.status === "D"
            ? "Draft"
            : selectedData.status && selectedData.status === "O"
              ? "Ongoing"
              : "Ended",
        selectedData.excavatorTargetLoads
          ? selectedData.excavatorTargetLoads
          : 0,
        selectedData.status === "E"
          ? selectedData.excavatorActualLoads
            ? selectedData.excavatorActualLoads
            : 0
          : "-",
        selectedData.truckTargetLoads ? selectedData.truckTargetLoads : 0,
        selectedData.status === "E"
          ? selectedData.truckActualLoads
            ? selectedData.truckActualLoads
            : 0
          : "-",
        selectedData.status &&
          (selectedData.status === "E" || selectedData.status === "O")
          ? selectedData.event
          : "-",
        shiftDate,
        selectedData.supervisorName ? selectedData.supervisorName : "",
      ]);
    }

    //Call download Func
    downloadFile({
      data: [...headers, ...listArr].join("\n"),
      fileName: filenmae,
      fileType: "text/csv",
    });
  };

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();

    let list = [...shiftListRef.current];
    list.forEach((res) => {
      res = {
        ...res,
        openTab: false,
      };
    });
    setShiftList(list);
  };

  useEffect(() => {
    pagesRef.current = 0;
    shiftListRef.current = [];
    loadShiftData(1);
    return () => {
      myInterval.current.forEach((x) => {
        clearInterval(x);
      });
      myInterval.current = [];
    };
  }, [
    query,
    resetList,
    filterObject,
  ]);

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const publishDraftPlan = async (reportNo, sid) => {
    try {
      setLoading(true)
      const response = await CallWithAuth("POST", "api/shift-planning/draft/publish", { reportNo, sid });
      if (response?.res?.data?.status === 200) {
        setResetList(!resetList);
        toast.success(
          "Shift Published successfully.",
          {
            position: "bottom-right",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      } else {
        toast.error(
          response?.res?.data?.errors
            ? response?.res?.data?.errors
            : "Error occured while Publishing shift.",
          {
            position: "bottom-right",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );

      }
    } catch (error) {
      toast.error(
        "Error occured while Publishing shift.",
        {
          position: "bottom-right",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );

    }
    setLoading(false)
  }

  const convertPublishedToDraftPlan = async (reportNo, sid) => {
    try {
      setLoading(true)
      const response = await CallWithAuth("POST", "api/shift-planning/published/to-draft", { reportNo, sid });
      if (response?.res?.data?.status === 200) {
        setResetList(!resetList);
        toast.success(
          "Shift Converted successfully.",
          {
            position: "bottom-right",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );

      } else {
        toast.error(
          response?.res?.data?.errors
            ? response?.res?.data?.errors
            : "Error occured while converting shift.",
          {
            position: "bottom-right",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } catch (error) {
      toast.error(
        "Error occured while converting shift.",
        {
          position: "bottom-right",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
    setLoading(false)
  }

  const deleteDraftPlan = async (reportNo) => {
    try {
      setLoading(true)
      const response = await CallWithAuth("POST", "api/shift-planning/draft/delete", { reportNo });
      if (response?.res?.data?.status === 200) {
        setResetList(!resetList);
        toast.success(
          "Shift Deleted successfully.",
          {
            position: "bottom-right",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );

      } else {
        toast.error(
          response?.res?.data?.errors
            ? response?.res?.data?.errors
            : "Error occured while Publishing shift.",
          {
            position: "bottom-right",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } catch (error) {
      toast.error(
        "Error occured while Publishing shift.",
        {
          position: "bottom-right",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
    setLoading(false)
  }

  const handleSearch = useCallback(debounce((val) => {
    setQuery(val);
  }), []);

  const handleApplyFilter = (filters) => {
    setFilterObject(formateDataForSaving(filters));
  }

  const handleClearAllFilter = () => {
    setFilterObject(InitialFilters);
  }

  const getOptions = (userValue) => {

    const getPastDate = (date) => {
      if (moment(date).format("L") === moment().format("L")) {
        return true;
      } else {
        const diff = new Date().getTime() - new Date(date).getTime();
        if (diff > 0) {

          return false;
        } else {
          return true;
        }
      }
    };
    const options = [];
    if (userValue?.status === ShiftStatus.DRAFT) { //Draft Options

      if (moment().add(30, 'minutes').isBefore(moment(userValue?.shiftEndTimeUTC))) {
        options.push({
          name: 'Publish',
          onClick: () => setConfirmModal({
            show: true,
            title: "Publish Plan",
            message: <div className="d-flex flex-column" style={{ lineHeight: "2rem" }}>
              <span> Are you sure you want to publish the following plan?</span>
              <span style={{ fontWeight: '700' }}> {userValue.reportNo} (Crew {userValue.crew}), {moment(userValue.createdAt).format("DD/MM/YYYY")}, {userValue.shift} Shift</span>
            </div>,
            wantToPublish: true,
            sid: userValue._id,
            reportNo: userValue.reportNo
          })
        })
      }
    }
    if (userValue?.status !== ShiftStatus.ENDED) { //Published and OnGoing Options
      options.push({
        name: 'Edit',
        onClick: () => window.location.href = `/shift-management/shift-planning/add-daily-plan?id=${userValue?._id}`
      })

    }
    options.push({
      name: 'Download PDF',
      onClick: () => { },
      disabled: true,
    });
    options.push({
      name: 'Download CSV',
      onClick: () => { },
      disabled: true,
    });
    if (userValue?.status === ShiftStatus.DRAFT) { //Draft Options
      options.push({
        name: 'Delete',
        onClick: () => setConfirmModal({
          show: true,
          title: "Delete Plan",
          message: <div className="d-flex flex-column" style={{ lineHeight: "2rem" }}>
            <span>Are you sure you want to delete the following plan?</span>
            <span style={{ fontWeight: '700' }}>
              {userValue.reportNo} (Crew {userValue.crew}), {moment(userValue.createdAt).format("DD/MM/YYYY")}, {userValue.shift} Shift
            </span>
          </div>,
          wantToDelete: true,
          sid: userValue._id,
          reportNo: userValue.reportNo
        })
      })
    }
    if (userValue?.status === ShiftStatus.PUSBLISHED) {
      options.push({
        name: 'Revert to Draft',
        onClick: () => setConfirmModal({
          show: true,
          title: " Revert to Draft",
          message: <div className="d-flex flex-column" style={{ lineHeight: "2rem" }}>
            <span> Are you sure you want to revert the following plan to draft?</span>
            <span style={{ fontWeight: '700' }}>
              {userValue.reportNo} (Crew {userValue.crew}), {moment(userValue.createdAt).format("DD/MM/YYYY")}, {userValue.shift} Shift
            </span>
          </div>,
          wantToRevert: true,
          sid: userValue._id,
          reportNo: userValue.reportNo
        })
      })
    }
    return options;
  }

  return (
    <AuthLayout pageName="Shift Planning">

      <div
        style={{
          background: "white", padding: "1rem 0rem 0rem 0rem", height: '100%', width: "100%", display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Helmet>
          <title>Shift List | iMOS</title>,
        </Helmet>
        {confirmModal.show && (
          <ConfirmAlert
            title={confirmModal.title}
            message={confirmModal.message}
            onClose={() => {
              setConfirmModal({
                show: false,
                title: "",
                message: "",
                sid: '',
                reportNo: ""
              });
            }}
            onConfirm={() => {
              if (confirmModal.wantToPublish)
                publishDraftPlan(parseInt(confirmModal.reportNo), confirmModal.sid)
              else if (confirmModal.wantToDelete)
                deleteDraftPlan(parseInt(confirmModal.reportNo))
              else
                convertPublishedToDraftPlan(parseInt(confirmModal.reportNo), confirmModal.sid)
              setConfirmModal({
                show: false,
                title: "",
                message: "",
                sid: '',
                reportNo: ""
              });
            }}
          />
        )}
        <div style={{ padding: "0rem 2rem", height: "calc(100% - 4rem)", width: '100%' }}>
          <div className="top_middle shit_top" style={{ marginBottom: "1.5rem" }}>
            <ul className="px-3 ">
              <li className="active">
                <a href="#">Daily</a>
              </li>
            </ul>
          </div>
          <div
            className="top_middle shift_top_middle"
            style={{ marginBottom: "1.5rem" }}
          >
            <div className="daily_button mx-3">
              <Link
                to="add-daily-plan"
              >
                Add daily plan
              </Link>
            </div>
            <ShiftPlanFilter
              onApply={handleApplyFilter}
              onClear={handleClearAllFilter}
              onSearch={handleSearch}
              query={query}
              filterObj={filterObject}
              minesList={minesList}
              departmentsList={departmentsList}
              crewTypesList={crewTypesList}
            />
          </div>
          <div
            className="table_main table_shift_plan table_shift_plan_listing"
            id="shift-list-table"
            style={{
              borderLeft: "none",
              borderRadius: "0px",
              borderRight: "none",
              height: 'calc(100% - 9.3rem)',
              overflow: 'hidden',
            }}
          >
            <InfiniteScroll
              className="shift-listing-infinite-scroll"
              dataLength={shiftList?.length}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={loading && <LoadingScreen />}
              height={'100%'}
            >
              <table cellPadding="0" cellSpacing="0">
                <thead style={{ background: "white" }}>
                  <tr>
                    <th
                      align="left"
                      valign="top"
                      rowSpan="2"
                      style={{ width: "5%" }}
                    >
                      Ref No
                    </th>
                    <th
                      align="left"
                      valign="top"
                      rowSpan="2"
                      style={{ width: "5%" }}
                    >
                      Shift
                    </th>
                    <th
                      align="left"
                      valign="top"
                      rowSpan="2"
                      style={{ width: "5%" }}
                    >
                      Crew
                    </th>
                    <th
                      align="left"
                      valign="top"
                      rowSpan="2"
                      style={{ width: "10%" }}
                    >
                      Status
                    </th>
                    <th
                      align="left"
                      valign="top"
                      rowSpan="2"
                      style={{ width: "10%" }}
                    >
                      <div>
                        <div>Excavators BCMs/Tonnes</div>
                        <div>(Actuals / Targets)</div>
                      </div>
                    </th>
                    <th
                      align="left"
                      valign="top"
                      rowSpan="2"
                      style={{ width: "10%" }}
                    >
                      <div>
                        <div>Truck Loads</div>
                        <div>(Actuals / Targets)</div>
                      </div>
                    </th>
                    <th
                      align="left"
                      valign="top"
                      rowSpan="2"
                      style={{ width: "10%" }}
                    >
                      <div>
                        <div>Drill Meters</div>
                        <div>(Actuals / Targets)</div>
                      </div>
                    </th>
                    <th
                      align="left"
                      valign="top"
                      rowSpan="2"
                      style={{ width: "15%" }}
                    >
                      Shift Date & Time
                    </th>
                    <th
                      align="left"
                      valign="top"
                      rowSpan="2"
                      style={{ width: "15%" }}
                    >
                      Created By
                    </th>
                    <th
                      align="left"
                      valign="top"
                      rowSpan="2"
                      style={{ width: "5%" }}
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {shiftList?.length > 0 &&
                    shiftList.map(
                      (userValue, userIndex) =>
                        userValue &&
                        userValue._id && (
                          <tr
                            className={
                              userValue?.openTab &&
                                userValue.status &&
                                userValue.status === "O"
                                ? "left_green active"
                                : userValue?.openTab
                                  ? "active"
                                  : userValue.status && userValue.status === "O"
                                    ? "left_green"
                                    : ""
                            }
                            key={userIndex}
                          >
                            {userValue?._id && (
                              <td
                                align="left"
                                valign="top"
                                onClick={() =>
                                  navigate(`add-daily-plan?id=${userValue._id}`)
                                }
                              >
                                {userValue.reportNo ? userValue.reportNo : ""}
                              </td>
                            )}
                            <td
                              align="left"
                              valign="top"
                              onClick={() =>
                                navigate(`add-daily-plan?id=${userValue._id}`)
                              }
                            >
                              {userValue.shift ? userValue.shift : ""}
                            </td>
                            <td
                              align="left"
                              valign="top"
                              onClick={() =>
                                navigate(`add-daily-plan?id=${userValue._id}`)
                              }
                            >
                              {userValue.crew ? userValue.crew : ""}
                            </td>
                            {userValue.status ? (
                              <td
                                align="left"
                                valign="top"
                                onClick={() =>
                                  navigate(`add-daily-plan?id=${userValue._id}`)
                                }
                              >
                                <GetStatusCell status={userValue.status} />
                              </td>
                            ) : (
                              <td
                                align="left"
                                valign="top"
                                onClick={() =>
                                  navigate(`add-daily-plan?id=${userValue._id}`)
                                }
                              >
                                -
                              </td>
                            )}
                            <td
                              align="left"
                              valign="top"
                              style={
                                userValue.excavatorActualLoads
                                  ? userValue.excavatorActualLoads >=
                                    userValue.excavatorTargetLoads
                                    ? {
                                      borderLeft: "2px solid #3AC49F",
                                      background: "#F0FCF5",
                                    }
                                    : {
                                      borderLeft: "2px solid #EB5F5F",
                                      background: "#FFEDED",
                                    }
                                  : {
                                    borderLeft: "2px solid #E1E8F4",
                                    background: "#FAFBFD",
                                  }
                              }
                              onClick={() =>
                                navigate(`add-daily-plan?id=${userValue._id}`)
                              }
                            >
                              <div style={{ display: "flex", gap: "10px" }}>
                                <div>
                                  {loadsFetching ? (
                                    <Loader />
                                  ) : userValue.excavatorActualLoads ? (
                                    userValue.excavatorActualLoads
                                  ) : (
                                    "-"
                                  )}
                                </div>
                                {" / "}
                                <div>
                                  {loadsFetching ? (
                                    <Loader />
                                  ) : userValue.excavatorTargetLoads ? (
                                    userValue.excavatorTargetLoads
                                  ) : (
                                    "-"
                                  )}
                                </div>
                              </div>
                            </td>
                            <td
                              align="left"
                              valign="top"
                              style={
                                userValue.truckActualLoads
                                  ? userValue.truckActualLoads >=
                                    userValue.truckTargetLoads
                                    ? {
                                      borderLeft: "2px solid #3AC49F",
                                      background: "#F0FCF5",
                                    }
                                    : {
                                      borderLeft: "2px solid #EB5F5F",
                                      background: "#FFEDED",
                                    }
                                  : {
                                    borderLeft: "2px solid #E1E8F4",
                                    background: "#FAFBFD",
                                  }
                              }
                              onClick={() =>
                                navigate(`add-daily-plan?id=${userValue._id}`)
                              }
                            >
                              <div style={{ display: "flex", gap: "10px" }}>
                                <div>
                                  {loadsFetching ? (
                                    <Loader />
                                  ) : userValue.truckActualLoads ? (
                                    userValue.truckActualLoads
                                  ) : (
                                    "-"
                                  )}
                                </div>
                                {" / "}
                                <div>
                                  {loadsFetching ? (
                                    <Loader />
                                  ) : userValue.truckTargetLoads ? (
                                    userValue.truckTargetLoads
                                  ) : (
                                    "-"
                                  )}
                                </div>
                              </div>
                            </td>
                            <td
                              align="left"
                              valign="top"
                              style={{
                                borderLeft: "2px solid #E1E8F4",
                                background: "#FAFBFD",
                              }}
                              onClick={() =>
                                navigate(`add-daily-plan?id=${userValue._id}`)
                              }
                            >
                              - / -
                            </td>
                            <td
                              align="left"
                              valign="top"
                              onClick={() =>
                                navigate(`add-daily-plan?id=${userValue._id}`)
                              }
                            >
                              {userValue.shiftDate
                                ? moment(userValue.shiftDate).format("DD/MM/YYYY")
                                : ""}
                              , {userValue.startTime ? userValue.startTime : ""} -{" "}
                              {userValue.endTime ? userValue.endTime : ""}
                            </td>
                            <td
                              align="left"
                              valign="top"
                              onClick={() =>
                                navigate(`add-daily-plan?id=${userValue._id}`)
                              }
                            >
                              {userValue.supervisorName
                                ? userValue.supervisorName
                                : ""} ({moment(userValue.createdAt).format("DD/MM/YYYY")})
                            </td>
                            <td
                              align="center"
                              valign="top"
                              onClick={(e) =>
                                handleClick(
                                  e,
                                  userValue,
                                  userIndex,
                                  userValue._id
                                )
                              }
                              onDoubleClick={handelClosePopUp}
                            >
                              <ActionMenu isOpen={userValue?.openTab} onClick={(e) => changetab(userIndex, e)} options={getOptions(userValue)} />
                            </td>
                          </tr>
                        )
                    )}
                  {shiftList && shiftList?.length === 0 && "No Data found"}
                </tbody>
              </table>
            </InfiniteScroll>
          </div>
        </div>
        <div className="shiftlisting-footer">
          Displaying {shiftList.length}/{totalShifts} Shift Plans
        </div>
      </div>
    </AuthLayout>
  );
};

export default ShiftListing;
