import Search from 'pages/ShiftPlanning/components/GeneralFields/Search';
import SelectField from 'pages/ShiftPlanning/components/GeneralFields/SelectField';
import { HiDownload } from "react-icons/hi";
const MODULE_START_YEAR = 2024

const Header = ({ searchTerm, handleSearchChange, year, handleYearChange, yearRange }) => {
    const years = Array.from({ length: (yearRange?.maxYear ?? MODULE_START_YEAR) - (yearRange?.minYear ?? MODULE_START_YEAR) + 1 }, (_, i) => ({ value: (yearRange?.minYear ?? 2020) + i, label: String((yearRange?.minYear ?? 2020) + i) }));
    return (
        <div className='d-flex justify-content-between py-4'>
            <div className='d-flex align-items-center'>
                <Search containerStyle={{ height: 30, marginRight: 5 }} query={searchTerm} onChange={handleSearchChange} />
                <SelectField
                    noPlaceHolder={true}
                    options={years}
                    value={year}
                    onChange={handleYearChange}
                    style={{
                        height: "30px",
                        width: "100px",
                        borderRadius: "15px",
                        backgroundColor: "white",
                        paddingLeft: "10px",
                        border: "1px solid #E1E8F4",
                        color: "#6A7C9A",
                        fontSize: "12px",
                        fontWeight: "600",
                    }}
                    mainContainerStyle={{ minHeight: 30, marginBottom: 0 }}
                />

            </div>
            <div>
                <div className='d-flex'>
                    <div style={{ color: '#576B8D', fontSize: 13 }}>Displaying data for Jan to Dec, {year}</div>
                    <div className='px-2 ml-3 d-flex align-items-center justify-content-center' style={{ backgroundColor: "#07273F", borderRadius: '50%', }}>
                        <HiDownload color='white' />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
