import { useContext, useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { CgClose, CgSearch } from 'react-icons/cg';
import { FiDownload } from 'react-icons/fi';
import { MdOutlineFilterList } from 'react-icons/md';

import { formatEpochTimeToNZ } from '../../../utils/helper';
import { DumpPointsContext } from '../DumpPointsContext';
import FilterModal from './FilterModal';
import { generateDumpEventsPDF } from '../Download';
import { CSVLink } from 'react-csv';

export default function DumpPointDetailModal(props) {
  const {
    dumpEvents,
    selectedDumpPoint,
    setSelectedDumpPoint,
    shiftChoice,
    setShiftChoice,
    setSelectedDate,
    selectedDate
  } = useContext(DumpPointsContext);
  const [selectFilter, setSelectFilter] = useState({
    materials: [],
    operators: [],
    trucks: [],
  });
  const [queryFilter, setQueryFilter] = useState();
  const [filteredDumpEvents, setFilteredDumpEvents] = useState(dumpEvents);
  const [allOperators, setAllOperators] = useState([]);

  const getCSVData = (selectedDumpPoint) => {
    const data = selectedDumpPoint?.allLoads
      .filter((truck) => {
        const truckMatched =
          selectFilter.trucks.length === 0 ||
          selectFilter.trucks.some(
            (filterTruck) =>
              filterTruck.label.replace(/\s+/g, '') == truck.name
          );

        const remainingMatched = truck.loads.some(
          ({ material, operator }) => {
            const materialMatched =
              selectFilter.materials.length === 0 ||
              selectFilter.materials.some(
                (filterMaterial) => filterMaterial.label == material
              );

            const operatorMatched =
              selectFilter.operators.length === 0 ||
              selectFilter.operators.some(
                (filterOperator) => filterOperator.label == operator
              );

            return materialMatched && operatorMatched;
          }
        );

        return truckMatched && remainingMatched;
      }).reduce((prev, truck) => [...prev, ...truck?.loads.map(load => ({
        Truck: truck?.name,
        Material: load.material,
        Location: load.lat + " , " + load.lng,
        Operator: load.operator || '-',
        Timestamp: load?.timestamp !== 0
          ? formatEpochTimeToNZ(load?.timestamp).replace(',', ' _')
          : '-',
      }
      ))], []);
    return data;
  }

  useEffect(() => {
    if (queryFilter) {
      setFilteredDumpEvents(
        dumpEvents.filter((dumpEvent) =>
          dumpEvent.name.toLowerCase()?.includes(queryFilter.toLowerCase())
        )
      );
    } else {
      setFilteredDumpEvents(dumpEvents);
    }
  }, [dumpEvents, queryFilter]);

  const [isSelectedUnknown, setIsSelectedUnknown] = useState(false);
  useEffect(() => {
    if (Object.keys(selectedDumpPoint).length === 0) {
      if (filteredDumpEvents.length > 0) {
        setSelectedDumpPoint(filteredDumpEvents[0]);
      }
      return;
    }
    setIsSelectedUnknown(selectedDumpPoint?.name === 'Unknown');
  }, [selectedDumpPoint]);

  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  useEffect(() => {

    const filterCount = Object.values(selectFilter).reduce(
      (accumulator, currentValue) =>
        currentValue.length !== 0 ? accumulator + 1 : accumulator,
      0
    );
    setIsFilterApplied(filterCount ? true : false);
  }, [selectFilter]);

  return (
    <div
      style={{
        top: '50px',
        left: '20px',
        position: 'absolute',
        height: 'calc(100% - 140px)',
        width: 1590,
        display: 'flex',
        flexDirection: 'row',
        zIndex: 2,
      }}
    >
      <div
        style={{
          background: ' #FFFFFF 0% 0% no-repeat padding-box',
          borderRadius: '10px',
          boxShadow: '0px 3px 6px #00000029',
          height: '100%',
          width: 1066,
        }}
      >
        <div
          style={{
            background: 'rgb(253 253 253) 0% 0% no-repeat padding-box',
            padding: '15px 20px 0px 28px',
            borderRadius: '10px 10px 0px 0px',
          }}
          className="d-flex justify-content-between"
        >
          <div className="d-flex align-items-center">
            <Image
              src={require('../../../assets/images/geofence.png')}
              height={28}
              width={51.2}
            />
            <span
              style={{
                font: 'normal normal 600 17px/22px Open Sans',
                marginLeft: '5px',
              }}
            >
              DUMP POINTS DETAILS{' '}
              <span
                style={{
                  font: 'normal normal 600 14px/16px Open Sans',
                  color: '#707070',
                }}
              >
                - LOADS BREAKDOWN
              </span>
            </span>
          </div>
          <CgClose
            onClick={props.close}
            size={25}
            style={{ cursor: 'pointer' }}
            className="px-1 mb-3"
          />
        </div>

        <div className="d-flex" style={{ height: 'calc(100% - 57px)' }}>
          <div
            style={{
              width: 350,
              height: '100%',
              background: ' #F3F4F6 0% 0% no-repeat padding-box',
              boxShadow: '0px 3px 6px #00000029',
              borderRadius: ' 0px 0px 0px 10px',
              padding: '10px 28px',
              borderTop: '2px solid #DDE5F3',
            }}
          >
            <div className='d-flex align-items-center'>
              <div style={{ width: '70%', }}><input type="date" style={{ height: 35 }} className='form-control' value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)} /></div>

              <div
                className="m-3 d-flex"
                style={{ backgroundColor: 'rgb(240,244,251)', borderRadius: '15px', boxShadow: '0px 0px 1px grey' }}
              >

                <div
                  style={{
                    backgroundColor:
                      shiftChoice == "0" ? 'rgb(203,220,248)' : 'rgb(240,244,251)',
                    color: shiftChoice == "0" ? 'black' : '#96a4bb',

                    borderWidth: '0px',
                    cursor: 'pointer',
                    fontSize: '1rem',
                    fontWeight: '600',
                    padding: '0px 1.3rem',
                    borderRadius: '15px'
                  }}
                  key={0}
                  name="radio"
                  value={0}
                  onClick={(e) => setShiftChoice("0")}
                >
                  Day
                </div>
                <div
                  style={{
                    backgroundColor:
                      shiftChoice == "1" ? 'rgb(20,36,62)' : 'rgb(241,244,251)',

                    color: shiftChoice == "1" ? 'white' : '#96a4bb',

                    borderWidth: '0px',
                    cursor: 'pointer',
                    fontSize: '1rem',
                    fontWeight: '600',
                    padding: '0px 1rem',
                    borderRadius: '15px',
                  }}
                  key={1}
                  name="radio"
                  value={1}
                  onClick={(e) => setShiftChoice('1')}
                >
                  Night
                </div>

              </div>
            </div>

            <div
              className="px-2 my-2 d-flex align-items-center"
              style={{
                height: 35,
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                border: '1px solid #DDE5F3',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '3px',
                width: '100%',
                padding: 8,
              }}
            >
              <CgSearch color="gray" size={12} style={{ margin: '0 7px' }} />
              <input
                className="shadow-none"
                style={{
                  border: 'none',
                  font: 'normal normal normal 14px/19px Open Sans',
                }}
                placeholder="Search for a dump point"
                type="text"
                value={queryFilter}
                onChange={(e) => setQueryFilter(e.target.value)}
              />
            </div>

            <div
              className="mt-3"
              style={{
                backgroundColor: 'white',
                height: 'calc(100% - 120px)',
                border: ' 1px solid #DDE5F3',
                borderRadius: 5,
                width: '100%',
              }}
            >
              <tr
                className="d-flex py-3"
                style={{
                  background: ' #F2F7FF 0% 0% no-repeat padding-box',
                  borderRadius: '4px 4px 0px 0px',
                  font: 'normal normal 600 13px/17px Open Sans',
                  paddingLeft: '8px',
                  paddingRight: '8px',
                }}
              >
                <td
                  style={{
                    width: '10%',
                  }}
                />
                <td style={{ width: '50%' }}>Dump Point</td>
                <td style={{ width: '20%' }}>Trucks</td>
                <td style={{ width: '20%' }}>Loads</td>
              </tr>

              <div
                style={{
                  padding: '15px 8px 50px',
                  overflowY: 'auto',
                  height: 'calc(100% - 40px)',
                }}
                className="d-flex flex-column"
              >
                {filteredDumpEvents.map((dumpEvent, index) => {
                  const isUnknown = dumpEvent.name === 'Unknown';
                  if (!isUnknown && dumpEvent.totalLoads === 0) return <></>;

                  return (
                    <tr
                      className="d-flex my-2 align-items-center"
                      key={index}
                      style={{
                        background:
                          selectedDumpPoint?._id === dumpEvent._id
                            ? '#D9E5FC66 0% 0% no-repeat padding-box'
                            : '#FFFFFF66 0% 0% no-repeat padding-box',
                        border:
                          selectedDumpPoint?._id === dumpEvent._id
                            ? '2px solid #346FD2'
                            : '2px solid #DDE5F3',
                        borderRadius: 5,
                        fontSize: '13px',
                        width: '100%',
                      }}
                      onClick={() => setSelectedDumpPoint(dumpEvent)}
                    >
                      <td
                        style={{
                          backgroundColor: isUnknown
                            ? '#FF6E6E'
                            : 'transparent',
                          height: '100%',
                          width: '6%',
                          borderTopLeftRadius: 5,
                          borderBottomLeftRadius: 5,
                        }}
                      />
                      <td style={{ width: '4%' }} />
                      <td
                        className="py-2"
                        style={{ width: '50%', lineHeight: '150%' }}
                      >
                        {dumpEvent.name}
                      </td>
                      <td style={{ height: 'min-content', width: '20%' }}>
                        {dumpEvent.totalTrucks}
                      </td>
                      <td style={{ height: 'min-content', width: '20%' }}>
                        {dumpEvent.totalLoads}
                      </td>
                    </tr>
                  );
                })}
              </div>
            </div>
          </div>

          {selectedDumpPoint && Object.keys(selectedDumpPoint).length !== 0 ? (
            <div
              id="dumpPoint-details"
              style={{
                width: '715px',
                padding: '15px 16px',
                borderTop: '2px solid #DDE5F3',
              }}
            >
              <div className="d-flex justify-content-between align-items-center">
                <div style={{ font: 'normal normal bold 16px/18px Open Sans' }}>
                  <span
                    style={{ font: 'normal normal bold 16px/18px Open Sans' }}
                  >
                    {selectedDumpPoint?.name}
                  </span>
                  <div
                    className="my-2"
                    style={{
                      font: 'normal normal normal 14px/18px Open Sans',
                      color: '#707070',
                    }}
                  >
                    Description:{' '}
                    <span
                      style={{
                        font: 'normal normal 600 14px/18px Open Sans',
                        color: '#1A1A1A',
                      }}
                    >
                      {selectedDumpPoint?.description}
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <MdOutlineFilterList
                    color={isFilterApplied ? 'red' : 'black'}
                    style={{
                      // color: 'black',
                      backgroundColor: '#F2F7FF',
                      cursor: 'pointer',
                      borderRadius: '50%',
                      padding: '.4rem',
                      width: '2.3rem',
                      height: '2.3rem',
                      border: '1.5px solid rgb(225,232,244)',
                      marginRight: 10,
                    }}
                    onClick={() => {
                      setIsFilterModalOpen(true);
                    }}
                  />
                  <CSVLink
                    // style={{ margiTop: -25 }}
                    data={getCSVData(selectedDumpPoint)}
                    filename={"Dump-Point-" + selectedDumpPoint?.name}
                    target="_blank"
                  >
                    <FiDownload
                      // onClick={() => {
                      //   let newShiftList = [];
                      //   shift?.forEach(({ operatorLoads, ...shift
                      //   }) => {
                      //     newShiftList?.push(
                      //       ...operatorLoads?.map((operatorLoad) => ({
                      //         ...shift,
                      //         operatorLoads: [operatorLoad],
                      //       }))
                      //     );
                      //   });
                      //   setTemporaryShift(filteredShift);
                      //   setFilteredShift(newShiftList);
                      // }}
                      style={{
                        backgroundColor: '#F2F7FF',
                        color: 'black',
                        cursor: 'pointer',
                        borderRadius: '50%',
                        padding: '.4rem',
                        width: '2.3rem',
                        height: '2.3rem',

                        border: '1.5px solid rgb(225,232,244)',
                      }}
                    />
                  </CSVLink>
                  {/* <FiDownload
                    onClick={() =>
                      generateDumpEventsPDF(
                        selectedDumpPoint?.name,
                        'dumpPoint-details',
                        'dumpPoint-details-table'
                      )
                    }
                    style={{
                      
                    }}
                  /> */}
                </div>
              </div>
              <div
                className="mt-1"
                id="dumpPoint-details-table"
                style={{
                  backgroundColor: 'white',
                  height: 'calc(100% - 60px)',
                  border: ' 1px solid #DDE5F3',
                  borderRadius: 5,
                  width: '100%',
                  overflowY: 'auto',
                }}
              >
                <div
                  className="d-flex align-items-center  justify-content-between p-3"
                  style={{
                    background: ' #F2F7FF 0% 0% no-repeat padding-box',
                    borderRadius: '4px 4px 0px 0px',
                    font: 'normal normal 600 13px/17px Open Sans',
                  }}
                >
                  <div style={{ width: isSelectedUnknown ? '15%' : '25%' }}>
                    Truck
                  </div>
                  <div style={{ width: isSelectedUnknown ? '15%' : '25%' }}>
                    Material
                  </div>
                  {isSelectedUnknown ? (
                    <div style={{ width: '30%' }}>Location</div>
                  ) : (
                    <></>
                  )}
                  <div style={{ width: isSelectedUnknown ? '20%' : '25%' }}>
                    Operator
                  </div>
                  <div style={{ width: isSelectedUnknown ? '20%' : '25%' }}>
                    Timestamp
                  </div>
                </div>

                {selectedDumpPoint?.allLoads
                  .filter((truck) => {
                    const truckMatched =
                      selectFilter.trucks.length === 0 ||
                      selectFilter.trucks.some(
                        (filterTruck) =>
                          filterTruck.label.replace(/\s+/g, '') == truck.name
                      );

                    const remainingMatched = truck.loads.some(
                      ({ material, operator }) => {
                        const materialMatched =
                          selectFilter.materials.length === 0 ||
                          selectFilter.materials.some(
                            (filterMaterial) => filterMaterial.label == material
                          );

                        const operatorMatched =
                          selectFilter.operators.length === 0 ||
                          selectFilter.operators.some(
                            (filterOperator) => filterOperator.label == operator
                          );

                        return materialMatched && operatorMatched;
                      }
                    );

                    return truckMatched && remainingMatched;
                  })
                  .map((truck, truckIndex) => {
                    return (
                      <div style={{ paddingTop: '15px' }}>
                        <span
                          className="px-2 py-1 mx-4"
                          key={truckIndex}
                          style={{
                            background: '#707070',
                            width: 'max-content',
                            padding: '3px',
                            borderRadius: '5px',
                            color: 'white',

                            font: 'normal normal normal 12px/17px Open Sans',
                          }}
                        >
                          {truck.name}
                        </span>

                        {truck.loads.map((load, index) => {
                          return (
                            <div
                              className="d-flex p-3"
                              key={index}
                              style={{
                                width: '100%',
                                font: 'normal normal normal 13px/17px Open Sans',
                                borderBottom: '1px solid rgb(221, 229, 243)',
                              }}
                            >
                              <div
                                style={{
                                  width: isSelectedUnknown ? '15%' : '25%',
                                }}
                              />
                              <div
                                style={{
                                  width: isSelectedUnknown ? '15%' : '25%',
                                }}
                              >
                                {load.material || '-'}
                              </div>
                              {isSelectedUnknown ? (
                                <div style={{ width: '30%' }}>
                                  {load.lat}, {load.lng}
                                </div>
                              ) : (
                                <></>
                              )}
                              <div
                                style={{
                                  width: isSelectedUnknown ? '20%' : '25%',
                                }}
                              >
                                {load.operator || '-'}
                              </div>
                              <div
                                style={{
                                  width: isSelectedUnknown ? '20%' : '25%',
                                }}
                              >
                                {load.timestamp !== 0
                                  ? formatEpochTimeToNZ(load.timestamp)
                                  : '-'}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '67.5%' }}>Please select a truck to show its dump details.</div>}
        </div>
      </div>

      {isFilterModalOpen && (
        <FilterModal
          onApply={(filterVariable) => {
            setIsFilterApplied(true);
            setSelectFilter(filterVariable);
            setIsFilterModalOpen(false);
          }}
          onCancel={() => setIsFilterModalOpen(false)}
          selectFilter={selectFilter}
        />
      )}
    </div>
  );
}
