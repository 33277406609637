import React, { useContext, useEffect, useState } from "react";
import { CallWithAuth } from "../action/apiActions";
import { GET_PIN, ADD_USER, UPDATE_USER, GET_CREW } from "../action/apiPath";
import { toast } from "react-toastify";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import AuthContext from "../context/AuthProvider";
import DynamicSelectField from "../pages/ShiftPlanning/components/DynamicSelectField/DynamicSelectField";
import InputField from "./InputField";
import RadioButton from "./RadioButton/index.js";
import ActionButton from "./Button/index.js";
import "./styles.css";
import CountryCodeSelector from "./Country Code/CountryCode.js";
import CountryCodeSelect from "./Country Code/CountryCode.js";
import { Phone } from "@mui/icons-material";
import PhoneNum from "./PhoneNum/PhoneNum.js";
import PinInput from "./PIN/PinInput.js";
import ReactSwitch from "react-switch";
import { ToggleSwitch } from "./ToggleSwitch/ToggleSwitch";
import ActionMenu from "../pages/ShiftPlanning/components/ActionMenu/ActionMenu.jsx";
import crossImg from '../assets/images/cross.svg'
import moment from "moment";
import TextInputField from "../pages/ShiftPlanning/components/GeneralFields/TextInputField.jsx";


const equipmentLicenseOptions = [
  { label: "Excavator", value: "Excavator" },
  { label: "Truck", value: "Truck" },
];

const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
const pinRegex = /^\d{4}$/;

const UserModal = ({ userAddUpdateType, selectedUser, setAddUserModal, filters, getUserList, closeModal, setModals, setLoading, modalType }) => {
  const { departments, userType } = useContext(AuthContext);
  const [formData, setFormData] = useState({});
  const [userTypeValue, setUserTypeValue] = useState({});
  const [equipmentLicenseValue, setEquipmentLicenseValue] = useState([]);
  const [crewTypesList, setCrewTypesList] = useState([]);
  const [countryCode, setCountryCode] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');


  const getOptions = (user) => {
    const userType = user.userType;

    const isOperator = (userType === 200);
    const isMechanic = (userType);
    const isTraineeOperator = (userType === 100);

    const handleDeleteUser = (type) => {
      setModals((prev) => ({ ...prev, deleteModalOpen: { visible: true, type } }));
    };

    const baseOptions = [{
      name: "Delete",
      onClick: () => handleDeleteUser("delete"),
    }];

    if ((isOperator || isMechanic) && user?.status !== "P") {
      baseOptions.push({
        name: "Generate New Pin",
        onClick: () => {
          setModals(prev => ({ ...prev, GeneratePINState: true }));
        }
      });
    }

    return baseOptions;
  };



  useEffect(() => {
    const fetchCrew = async () => {
      try {
        const response = await CallWithAuth("GET", GET_CREW);
        if (response?.res?.data?.status === 200) {
          setCrewTypesList(response?.res?.data?.data);
        }
      } catch (error) {
        toast.error("Failed to fetch crew types");
      }
    };
    fetchCrew();
  }, []);

  useEffect(() => {
    if (modalType === "View" || modalType === "Edit") {
      setFormData({ ...selectedUser, userType: selectedUser?.userType?.[0], equipmentLicense: selectedUser?.equipmentLicense });
    } else {
      setFormData({ firstName: "", lastName: "" });

    }
  }, [modalType, selectedUser]);

  useEffect(() => {
    if (userAddUpdateType === "Edit" && selectedUser) {
      const updatedFormData = { ...selectedUser };

      const mappedLicenses = selectedUser?.equipmentLicense?.map(data =>
        equipmentLicenseOptions.find(item => item?.value === data)
      );
      setEquipmentLicenseValue(mappedLicenses || []);
      setFormData(updatedFormData);
    }
  }, [userAddUpdateType, selectedUser]);

  const isTraineeOperator = formData?.userType === 100 || false;
  const isOperator = formData?.userType === 200 || false;
  const isSupervisor = formData?.userType === 300 || false;
  const isActingSupervisor = formData?.userType === 400 || false;
  const isSuperAdmin = formData?.userType === 500 || false;
  const isMaintenanceSupervisor = formData?.userType === 600 || false;
  const isMechanic = formData?.userType === 700 || false;
  const isTechnicalServices = formData?.userType === 800 || false;

  const handleInputChange = (field, value) => {
    setFormData(prev => ({ ...prev, [field]: value, isError: false }));
  };

  const handleSubmit = async () => {
    setLoading(true);

    if (!userTypeValue) {
      setLoading(false);
      return toast.error("Please select at least one user type");
    }

    const { firstName, lastName, department, pin, crewType, equipmentLicense, email, userType } = formData;
    const handleError = (message) => {
      setLoading(false);
      return toast.error(message);
    };

    if (!firstName) return handleError("First name is required");
    if (!userType) return handleError("Please Select a User Type");
    if (!lastName) return handleError("Last name is required");
    if (!department) return handleError("Please select a Department");
    if (!crewType) return handleError("Please select at least one crew");

    if (isOperator || isMechanic || (isSupervisor && modalType !== "View" && modalType !== "Edit")) {
      if (!pin) return handleError("Please generate the PIN");
      if (!pinRegex.test(pin)) return handleError("Please generate a valid 4-digit PIN");
      if (!equipmentLicense?.length) return handleError("Please select at least one license type");
    }

    if (isSupervisor || isActingSupervisor || isMaintenanceSupervisor || isTechnicalServices || isSuperAdmin) {
      if (!email) return handleError("Email is required");
      if (!emailRegex.test(email)) return handleError("Invalid email address");
      formData.email = email?.trim().toLowerCase();
    }
    if (isActingSupervisor) {
      if (!equipmentLicense?.length) return handleError("Please select at least one license type");
    }

    formData.firstName = firstName?.trim();
    formData.lastName = lastName?.trim();

    try {
      const apiPath = (modalType === "Edit" && selectedUser) ? UPDATE_USER(selectedUser?._id) : ADD_USER;
      const response = await CallWithAuth(modalType === "Edit" ? "PUT" : "POST", apiPath, formData);
      toast.success("Data added successfully");
      setAddUserModal(false);
      getUserList(1, filters);
      closeModal();
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };



  const generateNewPin = () => {
    getPinInfo();
  };

  const getPinInfo = async () => {
    console.log('piinnnnnnn');
    const response = await CallWithAuth("GET", GET_PIN);
    if (response?.res?.data?.status === 200) {
      setFormData({
        ...formData,
        pin: response?.res?.data?.data,
      });
      console.log('pin apo callllll');
    }
  };
  const checkLength = (e) => {
    if (e?.target?.value?.length > e?.target?.maxLength) {
      e.target.value = e?.target?.value?.slice(0, e?.target?.maxLength);
    }
  };


  const crewOptions = crewTypesList?.map(crew => ({ value: crew?.CrewType, label: crew?.CrewType }))
  const handleStatusChange = (checked) => {
    setFormData(prev => ({ ...prev, status: checked ? "A" : "I" }));
  };

  const handleUserAction = (user, type) => {
    setModals((prev) => ({ ...prev, deleteModalOpen: { visible: true, type } }));
  };

  const toggleEllipsis = (e) => {
    e.stopPropagation();
    // setUsers((prev) => ({ ...prev, showEllipsisPopUp: prev.showEllipsisPopUp === rowIndex ? -1 : rowIndex }));
  };



  return (
    <div id="add_new_user" className="user_popup">
      <div className="add-user-modal">
        <div className={`user-modal-top pb-0 ${modalType === "View" ? "" : ""}`} style={{ padding: modalType === "View" ? "2rem 2rem" : "2rem 3.125rem" }}>
          {modalType === "View" ? (
            <>
              <ActionButton
                actionType="discard"
                className="btn"
                onClick={() => setModals((prev) => ({
                  ...prev,
                  type: "Edit"
                }))}>
                Edit</ActionButton>
              <div className="d-flex align-items-center mr-0 pr-0">
                {formData?.status !== "P" && (
                  <ToggleSwitch
                    checked={formData?.status === "A"}
                    onChange={() => handleUserAction(formData, formData?.status === "A" ? "deactivate" : "activate")}
                    labelClassName="col-form-label"
                  />
                )}
                <ActionMenu
                  className="mx-3"
                  optionsContainerClass="mr-2 mt-2"
                  optionClass="pl-4 pr-2"
                  isOpen={formData?.showEllipsisPopUp}
                  onClick={() => setFormData((prev) => ({
                    ...prev,
                    showEllipsisPopUp: !prev?.showEllipsisPopUp
                  }))}
                  options={getOptions(formData)}
                />
                <div className="d-flex mr-0 pr-0" style={{ width: "max-content" }}>
                  <img src={crossImg} onClick={closeModal} style={{
                    cursor: 'pointer'
                  }} />
                </div>
              </div>
            </>
          ) : (
            <>
              <h3 style={{ font: "normal normal bold 16px/22px Open Sans" }}>
                {modalType === "Edit" ? "Edit" : "Add"} User
              </h3>
              <div className="d-flex px-0">
                <ActionButton actionType="discard" className="btn my-2 pl-0" onClick={closeModal}>Discard</ActionButton>
                <ActionButton className="btn my-2 ml-1 pr-0" onClick={handleSubmit}>
                  {userAddUpdateType === "edit" ? "Edit" : "Save"}
                </ActionButton>
              </div>
            </>
          )}
        </div>
        {modalType === "View" && <hr style={{ border: "none", borderTop: "1px solid #ccc", width: "100%", marginTop: "2rem", padding: "0" }} />}
        <div className="add_new_user_box_middle" style={{ padding: modalType === "View" ? "0rem 3.125rem" : "3.125rem 3.125rem", }}>
          <DynamicSelectField
            label="User Type"
            options={userType?.map(option => ({ label: option?.name, value: option?.code }))}
            value={userType?.find(option => option?.code === formData?.userType)
              ? { label: userType?.find(option => option?.code === formData?.userType)?.name, value: userType?.find(option => option?.code === formData?.userType)?.code }
              : null}
            onChange={(v) => setFormData(prev => ({ ...prev, userType: v?.value }))}
            isSearchable
            required
            viewOnly={modalType === "View"}
            isDisabled={modalType === "View"}

          />
          <TextInputField
            label="First Name"
            value={formData?.firstName}
            onChange={(value) => setFormData(prev => ({ ...prev, firstName: value }))}
            required
            viewOnly={modalType === "View"}
            isDisabled={modalType === "View"}

          />
          <TextInputField
            label="Last Name"
            value={formData?.lastName}
            onChange={(value) => setFormData(prev => ({ ...prev, lastName: value }))}
            required
            viewOnly={modalType === "View"}
            isDisabled={modalType === "View"}

          />
          {(isActingSupervisor || isMaintenanceSupervisor || isTechnicalServices || isSupervisor || isSuperAdmin) && (
            <>
              <TextInputField
                label="Email"
                value={formData?.email}
                onChange={(value) => setFormData(prev => ({ ...prev, email: value }))}
                required
                viewOnly={modalType === "View"}
                isDisabled={modalType === "View"}

              />

              <PhoneNum
                showCountryCode
                label="Phone No"
                labelClassName="px-0"
                value={formData?.phoneNo}
                onChange={(v) => setFormData(prev => ({ ...prev, phoneNo: v }))}
                editOnly={modalType === "Edit"}
                modalType={modalType}
                isDisabled={modalType === "View"}
                conatinerClassName={`${modalType === "View" ? "mb-3" : ""}`}
                labelStyle={{ font: `normal normal 500 1.25rem/19px Open Sans`, color: "#4b515a" }}
              />
            </>
          )}
          {(modalType === "Edit" || modalType === "View") && formData?.status !== "P" && (
            <ToggleSwitch
              label="Status"
              checked={formData?.status === "A"}
              onChange={handleStatusChange}
              labelClassName="col-form-label"
              viewOnly={modalType === "View"}
              containerClassName="mb-3"
              labelStyle={{ font: `normal normal 500 1.25rem/19px Open Sans`, color: "#4b515a" }}
            />
          )}
          <DynamicSelectField
            label="Department"
            options={departments?.map(dept => ({ value: dept?.DepartmentType, label: dept?.DepartmentType }))}
            value={departments?.find(option => option?.DepartmentType === formData?.department)
              ? { label: departments?.find(option => option?.DepartmentType === formData?.department)?.DepartmentType, value: departments?.find(option => option?.DepartmentType === formData?.department)?.DepartmentType }
              : null}
            onChange={(v) => setFormData(prev => ({ ...prev, department: v?.value }))}
            isSearchable
            viewOnly={modalType === "View"}
            isDisabled={modalType === "View"}
            required

          />
          <DynamicSelectField
            label="Crew"
            options={crewOptions}
            value={crewTypesList.find(option => option.CrewType === formData.crewType)
              ? { label: crewTypesList.find(option => option.CrewType === formData.crewType).CrewType, value: crewTypesList.find(option => option.CrewType === formData.crewType).CrewType }
              : null}
            onChange={(v) => setFormData(prev => ({ ...prev, crewType: v?.value }))}
            isSearchable
            viewOnly={modalType === "View"}
            isDisabled={modalType === "View"}
            required
          />
          {(isOperator || isMechanic || isActingSupervisor || isMaintenanceSupervisor || isTechnicalServices || isSupervisor || isSuperAdmin) &&
            <DynamicSelectField
              placeholder=""
              label="Equipment License"
              options={equipmentLicenseOptions}
              value={equipmentLicenseOptions?.filter(option => formData?.equipmentLicense?.includes(option?.value))}
              onChange={(selectedOptions) => setFormData(prev => ({ ...prev, equipmentLicense: selectedOptions.map(option => option.value) }))}
              viewOnly={modalType === "View"}
              isDisabled={modalType === "View"}
              required={isActingSupervisor || isOperator}
              isMulti
              selectAll
            />
          }
          {(isOperator || isMechanic || (isSupervisor && modalType !== "View" && modalType !== "Edit")) && (
            <PinInput
              required
              label="PIN No"
              pin={formData?.pin}
              onPinChange={(v) => setFormData(prev => ({ ...prev, pin: v }))}
              onGeneratePin={generateNewPin}
              viewOnly={modalType === "View"}
              labelStyle={{ font: `normal normal 500 1.25rem/19px Open Sans`, color: "#4b515a" }}
            />
          )}
          {modalType === "View" && (
            <TextInputField
              label="Added By"
              value={`${formData?.addedBy} ,  ${formData?.createdAt ? moment(formData?.createdAt).format('DD/MM/YYYY') : "N/A"}`}
              viewOnly
              isDisabled

            />
          )}
        </div>
      </div>
    </div>
  );
};

export default UserModal;