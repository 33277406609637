import { CallWithAuth, CallWithAuthFormData } from "action/apiActions";
import { SHIFT_SUMMARY_REPORT, SHIFT_SUMMARY_REPORT_PDF,SIGN_SHIFT_SUMMARY } from "action/apiPath";
import axios from "axios";
import moment from "moment";


// Cache object to store data and timestamps
const cache = {};
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

const getFromCache = (key) => {
  const cachedData = cache[key];
  if (cachedData && Date.now() - cachedData.timestamp < CACHE_DURATION) {
    return cachedData.data;
  }
  return null;
};

const setCache = (key, data) => {
  cache[key] = {
    data,
    timestamp: Date.now(),
  };
};

export const fetchEquipments = async () => {
  const cacheKey = "equipments";
  const cachedData = getFromCache(cacheKey);
  if (cachedData) return cachedData;

  try {
    const response = await CallWithAuth("GET", "api/equipments");
    if (response.res.status === 201) {
      const data = response.res.data.data;
      const equipmentList =
        Object.keys(data)
          .reduce((prev, curr) => [...prev, ...(data[curr] || [])], [])
          .map((d) => ({ ...d, value: d._id, label: d.Unit })) || [];
      if (equipmentList) setCache(cacheKey, equipmentList);
      return equipmentList;
    } else {
      throw new Error("Error fetching equipments");
    }
  } catch (error) {
    throw new Error(`Error fetching equipments: ${error.message}`);
  }
};

//Fetch shift summary data.
export const fetchShiftSummaryReport = async (shiftPlanId, equipmentId) => {
  try {
    const response = await CallWithAuth(
      "GET",
      SHIFT_SUMMARY_REPORT(shiftPlanId, equipmentId)
    );
    if (response.res.status === 200) {
      return response?.res?.data?.data;
    } else {
      throw new Error("Error occurred while fetching shift summary report");
    }
  } catch (error) {
    throw new Error(`Error occurred while fetching shift summary: ${error.message}`);
  }
};

//generate pdf and download in the local system.
export const generateShiftSummaryPdf = async (
  selectedDate,
  shiftType,
  equipmentName,
  reportNumber
) => {
  try {
    const requestData = {
      date: moment(selectedDate).format("YYYY-MM-DD"),
      shiftType: shiftType,
      equipmentName: equipmentName,
    };

    const headers = {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      "Content-Type": "multipart/form-data",
    };

    const responseType = "arraybuffer"; // Ensure response type is arraybuffer for PDF

    const pdfResponse = await axios.post(
      SHIFT_SUMMARY_REPORT_PDF,
      requestData,
      { headers, responseType }
    );

    if (pdfResponse.status === 200) {
      const blob = new Blob([pdfResponse?.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);

      // Create a link element for download
      const link = document.createElement("a");
      link.href = url;
      link.download = `Summary-Report_${equipmentName}_${reportNumber}.pdf`;

      document.body.appendChild(link);
      link.click();
      link.remove();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 1000);
    } else {
      throw new Error("An error occurred while generating pdf");
    }
  } catch (error) {
    throw new Error("An error occurred while generating pdf",error.message);
  }
};

export const SignShiftSummary= async(formData)=>{
  try{
    const response = await CallWithAuthFormData(
      "POST",
      SIGN_SHIFT_SUMMARY,
      formData,
    );

    if(response?.res?.status===200){
      return true
    }
    else{
      throw new Error(`Error occurred while signing shift summary`);
    }

  } catch (error){
    throw new Error(`Error occurred while signing shift summary: ${error.message}`);
  }
}


