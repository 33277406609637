import React, { useContext } from 'react';
import './style.css';
import DumpCycleTracker from './DumpCycleTracker/DumpCycleTracker';
import LoadingScreen from '../../components/LoadingScreen';
import DashboardContext from './Context/DashboardContext';

export default function DashboardComponent() {
    const { loading, } = useContext(DashboardContext)

    return loading
        ? <LoadingScreen />
        : <DumpCycleTracker />;
};
