import React from 'react'
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage'
import TextInputField from '../../../components/GeneralFields/TextInputField'
import OperatorSelect from '../../../components/OperatorSelect/OperatorSelect'
import DynamicSelectField from '../../../components/DynamicSelectField/DynamicSelectField'
import DateTimeSelect from '../../../components/GeneralFields/DateTimeSelect/DateTimeSelect'
import moment from 'moment'
import { DateTimeFormats } from '../../../utils/contants'
import { getDurationBetweenMomentsInHHMM } from '../../../utils/helper'
import TextAreaField from '../../../components/GeneralFields/TextAreaField'
import { useAddDailyPlan } from '../../../Context/context'

const TruckPlanFields = ({ isMandateErrorOpen, setIsMandateErrorOpen, viewOnly, formData, showError, handleFieldChange, excavatorAssignedPlan }) => {
    const {
        activityDescriptions,
        generalShiftPlanDetailsFormData
    } = useAddDailyPlan();

    return (
        <div>
            {isMandateErrorOpen && !viewOnly && <ErrorMessage message={"Please make sure all the required fields marked with an asterisk are filled correctly before you save."} onClose={() => setIsMandateErrorOpen(false)} />}

            <TextInputField
                viewOnly={viewOnly}
                label="Plant ID"
                required={true}
                isDisabled={true}
                placeholder="HT000"
                value={formData?.plantId}
            />

            <TextInputField
                viewOnly={viewOnly}
                label="Category"
                required={true}
                isDisabled={true}
                placeholder="Enter Category"
                value={formData?.category}
            />

            <OperatorSelect
                viewOnly={viewOnly}
                label="Operator"
                required={true}
                initialCrew={generalShiftPlanDetailsFormData?.crew?.label}
                isMulti={false}
                // userTypes={[]}
                equipmentType={"Truck"}
                value={formData?.operator}
                error={showError && !formData?.operator}
                errorMessage={"Please select a Operator"}
                onChange={(v) => handleFieldChange('operator', v)}
            />

            <OperatorSelect
                viewOnly={viewOnly}
                label="Assistant Operator"
                required={false}
                initialCrew={generalShiftPlanDetailsFormData?.crew?.label}
                clearSelection={true}
                isMulti={false}
                equipmentType={"Truck"}
                // userTypes={[]}
                placeholder={"-"}
                value={formData?.assistantOperator}
                onChange={(v) => handleFieldChange('assistantOperator', v)}
            />

            <OperatorSelect
                viewOnly={viewOnly}
                label="Trainee"
                required={false}
                initialCrew={generalShiftPlanDetailsFormData?.crew?.label}
                userTypes={[100]}
                clearSelection={true}
                placeholder={"-"}
                isMulti={false}
                value={formData?.trainee}
                onChange={(v) => handleFieldChange('trainee', v)}
            />

            <DynamicSelectField
                options={[]}
                viewOnly={viewOnly}
                label="Material"
                required={true}
                isDisabled={true}
                placeholder="Enter Material"
                value={formData?.material}
            />

            <DynamicSelectField
                options={[]}
                viewOnly={viewOnly}
                label="Source Area"
                required={true}
                isDisabled={true}
                placeholder="Enter Source Area"
                value={formData?.sourceArea}
            />


            <DynamicSelectField
                options={[]}
                isMulti={true}
                viewOnly={viewOnly}
                label="Dump Area"
                required={true}
                isDisabled={true}
                placeholder="Enter Dump Area"
                value={formData?.dumpArea}
            />

            <TextInputField
                viewOnly={viewOnly}
                label="Loading Machine"
                required={true}
                isDisabled={true}
                placeholder="Select Loading Machine"
                value={formData?.loadingMachine?.label}
            />

            <TextInputField
                viewOnly={viewOnly}
                label="Target Loads"
                isDisabled={true}
                required={true}
                placeholder="Enter Target Loads"
                value={formData?.targetLoads}
            />

            <DateTimeSelect
                required
                minDate={new Date(moment(excavatorAssignedPlan?.startTime, DateTimeFormats.DATE_TIME_U).toISOString())}
                minTime={
                    moment(excavatorAssignedPlan?.startTime, DateTimeFormats.DATE_TIME_U).format('YYYY-MM-DD') !==
                        moment(formData?.startTime, DateTimeFormats.DATE_TIME_U).format('YYYY-MM-DD')
                        ? new Date(moment().startOf('day').toISOString())
                        : new Date(moment(excavatorAssignedPlan?.startTime, DateTimeFormats.DATE_TIME_U).toISOString())}

                maxDate={new Date(moment(formData?.endTime, DateTimeFormats.DATE_TIME_U).subtract(15, 'minutes').toISOString())}
                maxTime={
                    moment(formData?.startTime, DateTimeFormats.DATE_TIME_U).format('YYYY-MM-DD') !==
                        moment(formData?.endTime, DateTimeFormats.DATE_TIME_U).format('YYYY-MM-DD')
                        ? new Date(moment().endOf('day').toISOString())
                        : new Date(moment(formData?.endTime, DateTimeFormats.DATE_TIME_U).subtract(15, 'minutes').toISOString())
                }
                viewOnly={viewOnly}
                label="Shift Start"
                value={new Date(moment(formData?.startTime, DateTimeFormats.DATE_TIME_U).toISOString())}
                onChange={(v) => {
                    const newStartTime = moment(v).format(DateTimeFormats.DATE_TIME_U);
                    const newEndTime = formData?.endTime;
                    // if (moment(newEndTime, DateTimeFormats.DATE_TIME_U).diff(moment(newStartTime, DateTimeFormats.DATE_TIME_U), 'minutes') >= 15) {
                    handleFieldChange('startTime', newStartTime);
                    // } else {
                    //     // Handle error or notification about minimum difference requirement
                    // }
                }}
                error={showError && !formData?.startTime}
                errorMessage="Please select shift plan start time"
            />

            <DateTimeSelect
                required
                minDate={new Date(moment(formData?.startTime, DateTimeFormats.DATE_TIME_U).add(10, 'minutes').toISOString())}
                minTime={
                    moment(
                        Math.max(
                            moment(formData.startTime, DateTimeFormats.DATE_TIME_U).add(10, 'minutes').valueOf(),
                            Date.now() + 600000
                        )
                    ).format('YYYY-MM-DD') !== moment(formData.endTime, DateTimeFormats.DATE_TIME_U).format('YYYY-MM-DD')
                        ? moment().startOf('day').valueOf()
                        : Math.max(
                            moment(formData.startTime, DateTimeFormats.DATE_TIME_U).add(10, 'minutes').valueOf(),
                            Date.now() + 600000
                        )
                }
                maxTime={
                    moment(excavatorAssignedPlan?.endTime, DateTimeFormats.DATE_TIME_U).format('YYYY-MM-DD') !==
                        moment(formData?.endTime, DateTimeFormats.DATE_TIME_U).format('YYYY-MM-DD')
                        ? new Date(moment().endOf('day').toISOString())
                        : new Date(moment(excavatorAssignedPlan?.endTime, DateTimeFormats.DATE_TIME_U).toISOString())}
                maxDate={new Date(moment(excavatorAssignedPlan?.endTime, DateTimeFormats.DATE_TIME_U).toISOString())}
                label="Shift End"
                viewOnly={viewOnly}
                value={new Date(moment(formData?.endTime, DateTimeFormats.DATE_TIME_U).toISOString())}
                onChange={(v) => {
                    const newEndTime = moment(v).format(DateTimeFormats.DATE_TIME_U);
                    const newStartTime = formData?.startTime;

                    // if (moment(newEndTime, DateTimeFormats.DATE_TIME_U).diff(moment(newStartTime, DateTimeFormats.DATE_TIME_U), 'minutes') >= 15) {
                    handleFieldChange('endTime', newEndTime);
                    // } else {
                    //     // Handle error or notification about minimum difference requirement
                    // }
                }}
                error={showError && !formData?.endTime}
                errorMessage="Please select shift Plan end time"
            />


            <TextInputField
                viewOnly={viewOnly}
                label="Shift Duration"
                placeholder={"00:00 hrs"}
                required={true}
                isDisabled={true}
                value={getDurationBetweenMomentsInHHMM(moment(formData?.startTime, DateTimeFormats.DATE_TIME_U), moment(formData?.endTime, DateTimeFormats.DATE_TIME_U))}
            />


            <DynamicSelectField
                required
                isDisabled={true}
                viewOnly={viewOnly}
                label="Productive Hours"
                value={formData?.productiveHours}
                options={Array.from({ length: 9 }, (_, index) => ({ value: (index + 1), label: (index + 1).toString() }))}
                error={showError && !formData?.productiveHours}
                errorMessage={"Please select Productive Hours"}
                onChange={(v) => handleFieldChange('productiveHours', v)}
            />

            <DynamicSelectField
                options={activityDescriptions?.filter((ad) => ad.equipType == "Truck").map((ad) => ({ value: ad._id, label: ad.activityDescription }))}
                viewOnly={viewOnly}
                label="Activity Description"
                required={true}
                error={!formData?.activityDescription && showError}
                isSearchable={true}
                placeholder="Enter Activity Description"
                errorMessage={"Please select Activity Description"}
                value={formData?.activityDescription}
                onChange={(v) => handleFieldChange('activityDescription', v)}
            />

            <TextAreaField
                viewOnly={viewOnly}
                label={"Known Hazards"}
                placeholder={"-"}
                style={{ height: 75 }}
                value={formData?.knownHazards}
                onChange={(v) => handleFieldChange('knownHazards', v)}
            />

            <TextAreaField
                viewOnly={viewOnly}
                label={"Start of Shift Note to Operator"}
                style={{ height: 75 }}
                placeholder={"-"}
                value={formData?.startOfShiftNoteToOperator}
                onChange={(v) => handleFieldChange('startOfShiftNoteToOperator', v)}
            />
        </div>
    )
}

export default TruckPlanFields