// Helper to format the search query
export const formatQuery = (query) => query.trim().toLowerCase().replace(/\s+/g, "");

// Helper to check if any field in a activity entry matches the query
export const activityMatchesQuery = (activity, query) => {
    return ["activityType", "reportedBy"].some((field) =>
        activity[field]?.toLowerCase().replace(/\s+/g, "").includes(query)
    );
};

// Helper to filter data based on the item type (e.g., excavators or trucks)
export const filterData = (items, query) => {
    return items.filter((item) =>
        item.name.toLowerCase().replace(/\s+/g, "").includes(query) ||
        item.activities.some((activity) => activityMatchesQuery(activity, query))
    );
};
