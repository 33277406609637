import { CallWithAuth } from "action/apiActions";
import { GET_EQUIMENTS, GET_DELAY_TYPE, GET_DELAY_SUB_TYPE, DELETE_DELAY } from "action/apiPath";

// Cache object to store data and timestamps
const cache = {};
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

const getFromCache = (key) => {
    const cachedData = cache[key];
    if (cachedData && (Date.now() - cachedData.timestamp) < CACHE_DURATION) {
        return cachedData.data;
    }
    return null;
};

const setCache = (key, data) => {
    cache[key] = {
        data,
        timestamp: Date.now(),
    };
};

export const fetchEquipments = async () => {
    const cacheKey = 'equipments';
    const cachedData = getFromCache(cacheKey);
    if (cachedData) return cachedData;

    try {
        const response = await CallWithAuth("GET", "api/equipments");
        if (response.res.status === 201) {
            const data = response.res.data.data;
            const equipmentList = Object.keys(data).reduce((prev, curr) =>
                [...prev, ...(data[curr] || [])],
                []
            ).map(d => ({ ...d, value: d._id, label: d.Unit })) || [];
            if (equipmentList)
                setCache(cacheKey, equipmentList);
            return equipmentList;
        } else {
            throw new Error('Error fetching equipments');
        }
    } catch (error) {
        throw new Error(`Error fetching equipments: ${error.message}`);
    }
};

export const fetchDelayTypes = async () => {
    const cacheKey = 'delayTypes';
    const cachedData = getFromCache(cacheKey);
    if (cachedData) return cachedData;

    try {
        const response = await CallWithAuth("GET", GET_DELAY_TYPE);
        if (response.res.status === 200) {
            const delayTypes = response.res.data || [];
            if (delayTypes)
                setCache(cacheKey, delayTypes);
            return delayTypes;
        } else {
            throw new Error('Error fetching delay types');
        }
    } catch (error) {
        throw new Error(`Error fetching delay types: ${error.message}`);
    }
};

export const fetchDelaySubTypes = async (typeId) => {
    const cacheKey = `subTypes_${typeId}`;
    const cachedData = getFromCache(cacheKey);
    if (cachedData) return cachedData?.map((subType) => ({ label: subType.delaySubType, value: subType._id }));

    try {
        const response = await CallWithAuth("GET", GET_DELAY_SUB_TYPE(typeId));
        if (response.res.status === 200) {
            const subTypes = response.res.data || [];
            if (subTypes)
                setCache(cacheKey, subTypes);
            return subTypes?.map((subType) => ({ label: subType.delaySubType, value: subType._id }));
        } else {
            throw new Error('Error fetching sub types');
        }
    } catch (error) {
        throw new Error(`Error fetching sub types: ${error.message}`);
    }
};

export const deleteDelay = async (delayId) => {
    try {
        const response = await CallWithAuth("DELETE", DELETE_DELAY(delayId));
        if (response.res.status === 202) {
            return { success: true, message: "Delay Deleted successfully" };
        } else {
            return { success: false, message: "Error occurred while Deleting delay" };
        }
    } catch (error) {
        return { success: false, message: `Error occurred: ${error.message}` };
    }
};
