import ReactEcharts from "echarts-for-react";
import { convertSecondsToTime } from "pages/KPIDashboard/Utils/helper";

const LineChart = ({
  data,
  style = {},
  categories,
  yAxisLabel = "",
  gridOptions = {},
  handlePointClick,
  formatter = (v) => v,
  isChartClickable = false,
}) => {

  const onEvents = {
    click: handlePointClick,
  };

  const option = {
    tooltip: {
      trigger: "axis",
      formatter: (params) => {
        return "";
      },
    },
    xAxis: {
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      nameTextStyle: {
        fontFamily: "Open Sans",
        fontWeight: "bold",
        fontSize: "12px",
        color: "#728BB5",
      },
      axisPointer: {
        type: "shadow",
      },
      axisLabel: {
        font: "normal normal normal 10px/14px Open Sans",
        color: "#808080",
        margin: 25,
        clickable: true,
      },
      setInterval: 1,
      data: categories ? categories : ["DD/MM", "DD/MM", "DD/MM", "DD/MM"],
    },
    yAxis: {
      name: yAxisLabel,
      nameLocation: "middle",
      nameGap: 55,
      nameTextStyle: {
        font: "normal normal normal 7px/9px Open Sans",
        color: "#6B6B6B",
        padding: [0, 0, 10, 15],
      },
      type: "value",
      axisLine: { show: false },
      axisTick: { show: false },
      axisLabel: {
        font: "normal normal normal 10px/14px Open Sans",
        color: "#808080",
        formatter, // Add the label for the Y-axis here
      },
      splitLine: {
        lineStyle: {
          type: "dashed",
          width: 1.5,
          color: "#DBDBDB",
        },
      },
    },
    grid: {
      left: "7%",
      right: "5%",
      bottom: "10%",
      containLabel: true,
      ...gridOptions
    },
    //   series: [
    //   {
    //     type: "line",
    //     smooth: true,
    //     symbolSize: isChartClickable ? 5 : 0, // Conditional symbol size
    //     symbol: isChartClickable ? "emptyCircle" : "none", // Conditional symbol
    //     lineStyle: {
    //       color: "#5178E0",
    //       width: 2,
    //     },
    //     data: data
    //      ? data
    //     : [],
    //   },

    // ],
    series: data?.map((series, index) => ({
      name: `Line ${index + 1}`,
      type: "line",
      smooth: true,
      animation: false,
      symbolSize: isChartClickable ? 7.5 : 0,
      symbol: isChartClickable ? "emptyCircle" : "none",
      lineStyle: {
        color: ["#5178E0", "#87D919"][index],
        width: 2,
      },
      data: series,

    })),

  };

  return (
    <div
      className="graphContainer"
      style={{ position: "relative", ...style }}
    >
      <ReactEcharts
        height={325}
        width={450}
        option={option}
        onEvents={onEvents}
      />
    </div>
  );
};

export default LineChart;
