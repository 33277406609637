import styles from "./LoadTracker.module.css";
import Header from "./Header";
import Tracker from "./Tracker";
import { useExcavatorLoadTrackerContext } from "../context/context";

const LoadTracker = () => {
  const { getExcavatorData } = useExcavatorLoadTrackerContext();
  const excavator = getExcavatorData();

  return (
      <div className={styles.container}>
        <div className={styles.header}>
          <p>Load Tracker - {excavator?.excavatorName || "-"}</p>
        </div>

        <div className={styles.body}>
          <Header />
          <Tracker />
        </div>
      </div>
  );
};

export default LoadTracker;
