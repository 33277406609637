import { useEffect, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import Styles from "../EndShifts/EndShift.module.css";

const EndShiftModal = ({ show, handleClose, assetData, handleEndShift }) => {
  const [selectedEquipmentIds, setSelectedEquipmentIds] = useState([]);

  // if (!show) return null;
  useEffect(() => {
    if (!show) {
      setSelectedEquipmentIds([]); // Clear the selected checkboxes
    }
  }, [show]);

  if (!show) return null;

  const excavators = assetData?.excavatorAssignments?.filter(
    (exc) => exc.status === "ASSIGNED" || exc.status === "REASSIGNED"
  );

  const exTrucks = assetData?.excavatorAssignments.flatMap((excavator) =>
    excavator.truckAssignments
      .filter(
        (truck) => truck.status === "ASSIGNED" || truck.status === "REASSIGNED"
      )
      .map((truck) => ({
        ...truck,
        excavatorName: excavator.excavatorName,
      }))
  );

  const handleCheckboxChange = (equipmentId) => {
    setSelectedEquipmentIds((prevSelected) =>
      prevSelected.includes(equipmentId)
        ? prevSelected.filter((id) => id !== equipmentId)
        : [...prevSelected, equipmentId]
    );
  };

  const handleSelectAll = (items) => {
    const itemIds = items.map((item) => item.equipmentId);
    setSelectedEquipmentIds((prevSelected) =>
      itemIds.every((id) => prevSelected.includes(id))
        ? prevSelected.filter((id) => !itemIds.includes(id))
        : [
            ...prevSelected,
            ...itemIds.filter((id) => !prevSelected.includes(id)),
          ]
    );
  };

  return (
    <div
      className="modal fade show"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className={`modal-content px-5 ${Styles.endShiftModal}`}>
          <div className={`modal-header ${Styles.header}`}>
            <div className={`modal-title pl-3 ${Styles.title}`}>End Shifts</div>
            <div
              onClick={handleClose}
              style={{ cursor: "pointer" }}
              className={Styles.closeIcon}
            >
              <IoCloseSharp color="black" fontSize={17.5} />
            </div>
          </div>

          <div className="pt-2 row">
            {/* Excavators Section */}
            <div className="col-6">
              <div className={`pl-3 ${Styles.exHeader}`}>
                Excavators ({excavators?.length ? excavators?.length :"No Excavators"})
              </div>
              {excavators?.length>0 && ( <div className="d-flex align-items-center form-check">
                <input
                 className={Styles.userCheckbox}
                  type="checkbox"
                  onChange={() => handleSelectAll(excavators)}
                  checked={excavators.every((exc) =>
                    selectedEquipmentIds.includes(exc.equipmentId)
                  )}
                />
                <div
                  style={{ height: 30 }}
                  className={`pl-3 d-flex align-items-center form-check-label ${Styles.selectAll}`}
                  for="selectAllExcavators"
                >
                  Select all
                </div>
              </div> )}
          
              {excavators.map((exc) => (
                <div
                  key={exc.equipmentId}
                  className="d-flex align-items-center form-check"
                >
                  <input
                    type="checkbox"
                    className={Styles.userCheckbox}
                    onChange={() => handleCheckboxChange(exc.equipmentId)}
                    checked={selectedEquipmentIds.includes(exc.equipmentId)}
                  />
                  <div style={{ height: 30 }}  className={`pl-3 d-flex align-items-center form-check-label ${Styles.data} `}>
                    {exc.excavatorName}
                  </div>
                </div>
              ))}
            </div>

            {/* Trucks Section */}
          
              <div className="col-6">
              <div className={`pl-3 ${Styles.exHeader}`}>
                Trucks ({exTrucks?.length ? exTrucks?.length : "No trucks" })
              </div>
              {exTrucks?.length >0 && (  <div className="d-flex align-items-center form-check">
                <input
                  type="checkbox"
                  className={Styles.userCheckbox}
                  onChange={() => handleSelectAll(exTrucks)}
                  checked={exTrucks.every((truck) =>
                    selectedEquipmentIds.includes(truck.equipmentId)
                  )}
                />
                <div
                  style={{ height: 30 }}
                  className={`pl-3 d-flex align-items-center form-check-label ${Styles.selectAll}`}
                >
                  Select all
                </div>
              </div>)}
              {exTrucks?.map((truck) => (
                <div
                  key={truck.equipmentId}
                  className="d-flex align-items-center form-check"
                >
                  <input
                    type="checkbox"
                    className={Styles.userCheckbox}
                    onChange={() => handleCheckboxChange(truck.equipmentId)}
                    checked={selectedEquipmentIds.includes(truck.equipmentId)}
                  />
                  <div style={{ height: 30 }}  className={`pl-3 d-flex align-items-center form-check-label ${Styles.data} `}>
                    {truck.truckName} ({truck.excavatorName})
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="d-flex justify-content-center">
            {/* <div className={`${Styles.endShiftBtn} ${selectedEquipmentIds.length ?  ${endShiftBtn_disabled} : {}}  my-5`} onClick={() => handleEndShift(selectedEquipmentIds)}>
              End Shift
            </div> */}
            <div
              className={`${Styles.endShiftBtn} ${
                !selectedEquipmentIds.length ? Styles.endShiftBtn_disabled : ""
              } my-5`}
              onClick={() => handleEndShift(selectedEquipmentIds)}
            >
              End Shift
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EndShiftModal;
