import Styles from "./AddShiftNotes.module.css";
import { useShiftNotesContext } from "pages/ShiftNotes/Context/context";
import DynamicSelectField from "pages/ShiftNotes/Component/GeneralFields/DynamicSelectField/DynamicSelectField";
import SelectField from "pages/ShiftNotes/Component/GeneralFields/SelectField/SelectField";
import TextAreaField from "pages/ShiftNotes/Component/GeneralFields/TextAreaField";
import RadioSelect from "pages/ShiftNotes/Component/GeneralFields/RadioSelect";
import DatePicker from "../../Component/GeneralFields/DatePicker/DatePicker";
import useAuth from "hooks/useAuth";
import moment from "moment";
import { useState } from "react";
import { CallWithAuthFormData } from "action/apiActions";

const AddShiftComponent = ({ showError, setFormData, formData }) => {

  const { loading, equipmentList, shiftOptions: originalShiftOptions } =
    useShiftNotesContext();
  const [shiftOptions, setShiftOptions] = useState(originalShiftOptions || [{ day: [], night: [] }]);
  const { auth } = useAuth()

  const fetchShiftsData = async (date) => {
    try {

      const shiftResponse = await CallWithAuthFormData(
        "POST",
        "/api/getShiftsForDashboard?new=true",
        {
          userId: auth?.userData?._id,
          date: moment(date).format("YYYY-MM-DD"),
        },
      );

      const shiftsData = shiftResponse?.res?.data?.data || {};
      setShiftOptions({
        day: shiftsData.day,
        night: shiftsData.night,
      });
      setFormData((prev) => ({
        ...prev,
        shift: shiftsData[prev?.shiftType]?.[0],
      }));
    } catch (error) {
      console.error("Error fetching shift data:", error);
    }
  };

  const handleChange = (field, value) => {
    setFormData(prevState => ({
      ...prevState,
      [field]: value
    }));
  };

  return (
    <div style={{ minHeight: "100%" }} className="d-flex flex-column pt-3">
      <div style={{ borderBottom: "1px solid #e1e8f4" }} className="px-5">
        <DatePicker
          required
          label="Date"
          dateOnly={true}
          value={formData.date}
          onChange={(v) => {
            handleChange("date", v)
            fetchShiftsData(v)
          }}
          error={showError && !formData?.date}
          errorMessage="Please select shift start time"
          id="shiftStart"
        />

        <RadioSelect
          required
          label="Shift"
          value={formData.shiftType ? formData.shiftType : 'day'}
          options={[
            { value: "day", label: "Day" },
            { value: "night", label: "Night" },
          ]}
          onChange={(v) => handleChange("shiftType", v)}
          id="shift"
        />

        <DynamicSelectField
          label={"Shift Ref No"}
          placeholder={"Select Shift Ref No"}
          options={shiftOptions?.[formData.shiftType]}
          value={formData?.shift}
          isDisabled={!shiftOptions?.[formData.shiftType]?.length || loading}
          style={getSelectFieldStyle(loading, shiftOptions?.[formData.shiftType])}
          onChange={(v) => handleChange("shift", v)}
          mainContainerStyle={{ marginBottom: "0px" }}
          error={showError && !formData?.shift}
          errorMessage="Please select a shift"
        />

        <RadioSelect
          label="Note Type"
          value={formData.noteType}
          options={[
            { value: "Generic", label: "Generic" },
            { value: "Asset Specific", label: "Asset Specific" },
          ]}
          onChange={(v) => {
            handleChange("noteType", v)
            handleChange("assets", [])
          }}
            error={showError && !formData?.noteType}
          errorMessage="Please select a note type"
          id="note"
        />

        <DynamicSelectField
          isSearchable={true}
          isMulti={true}
          label="Select Asset(s)"
          placeholder={formData?.noteType === "Generic" ? "" : "Select Assets"}
          options={equipmentList}
          value={formData?.assets}
          required
          onChange={(val) => handleChange("assets", val)}
          isDisabled={formData?.noteType === "Generic"}
          error={showError && !formData.assets?.length && formData?.noteType !== "Generic"}
          errorMessage={"Please select a asset"}
        />
      </div>
      <div style={{ background: "#FAFBFD", flexGrow: 1 }} className="px-5 py-4 d-flex flex-column">
        <div className="mb-2" style={{ color: 'balck', font: "normal normal bold 14px/17px Open Sans" }}>Notes:</div>
        <TextAreaField
          wrapperStyle={{ flexGrow: 1 }}
          style={{ height: '100%' }}
          value={formData.Note}
          onChange={(v) => handleChange("note", v)}
          error={showError && !formData?.note }
          errorMessage={"Please enter a note"}
        />
      </div>
    </div>
  );
};

const getSelectFieldStyle = (isLoading, options) => ({
  minHeight: "3.25rem",
  height: "max-content",
  width: "100%",
  marginTop: 2.5,
  border: "1px solid #F1F1F1",
  paddingLeft: "10px",
  font: "normal normal normal 14px/17px Open Sans",
  color: "#1A1A1A",
  cursor: !options?.length || isLoading ? "not-allowed" : "pointer",
});

export default AddShiftComponent;
