import { useShiftNotesContext } from "pages/ShiftNotes/Context/context";
import DynamicSelectField from "pages/ShiftNotes/Component/GeneralFields/DynamicSelectField/DynamicSelectField";
import TextAreaField from "pages/ShiftNotes/Component/GeneralFields/TextAreaField";
import RadioSelect from "pages/ShiftNotes/Component/GeneralFields/RadioSelect";

const EditShiftNotesComponent = ({ showError, setFormData, formData }) => {
  const { equipmentList } = useShiftNotesContext();

  const handleChange = (field, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  return (
    <div style={{ minHeight: "100%" }} className="d-flex flex-column pt-2">
      <div style={{ borderBottom: "1px solid #e1e8f4" }} className="px-5">
        <RadioSelect
          label="Note Type"
          value={formData.noteType}
          options={[
            { value: "Generic", label: "Generic" },
            { value: "Asset Specific", label: "Asset Specific" },
          ]}
          onChange={(v) => {
            handleChange("noteType", v);
            handleChange("assets", []);
          }}
          id="note"
          error={showError && !formData?.noteType}
          errorMessage="Please select a note type"
        />

        <DynamicSelectField
          isSearchable={true}
          isMulti={true}
          label="Select Asset(s)"
          placeholder={formData.noteType == "Generic" ? "" : "Select Assets"}
          options={equipmentList}
          value={formData?.assets}
          required
          onChange={(val) => handleChange("assets", val)}
          isDisabled={formData?.noteType === "Generic" ? true : false}
          error={showError && !formData.assets?.length && formData?.noteType !== "Generic"}
          errorMessage={"Please select a asset"}
        />
      </div>
      <div
        style={{ background: "#FAFBFD", flexGrow: 1 }}
        className="px-5 py-4 d-flex flex-column"
      >
        <div
          className="mb-2"
          style={{
            color: "balck",
            font: "normal normal bold 14px/17px Open Sans",
          }}
        >
          Notes:
        </div>
        <TextAreaField
          wrapperStyle={{ flexGrow: 1 }}
          style={{ height: "100%" }}
          value={formData.note}
          onChange={(v) => handleChange("note", v)}
          error={showError && !formData?.note}
          errorMessage={"Please enter a note"}
        />
      </div>
    </div>
  );
};

export default EditShiftNotesComponent;
