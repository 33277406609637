export const ShiftStatusEnum = {
    ONGOING: 'O',
    PUBLISHED: 'P',
    ENDED: 'E',
  };

export const ModuleStartDate={
    DATE:"2024-11-14" //YYYY-MM-DD
}

export const ShiftHistoryHeader={
 PLANT_ID:"Plant ID",
 FIRST_LOAD:"First Load",
 LAST_LOAD:"Last Load",
 CURRENT_OPERATOR:"Current Operator",
 LOGIN_SHIFT_START:"Login (Shift Start)",
 LOGOUT_SHIFT_END:"Logout (Shift End)",
 ACTIONS:"Actions"
}

export const EquipmentType={
  EXCAVATOR : "Excavator",
  TRUCK:"Truck"
}