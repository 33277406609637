// @ts-nocheck
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import * as toast from './NZMine/components/toast';

import CategoryCard from './Components/CategoryWidget/CategoryCard';
import moment from 'moment';
import { capitalize, entries, forEach, isEmpty, values } from 'lodash';
import {
  addRow,
  getCategoryName,
  getDeptname,
  getFailedCategories,
  getLocalTime,
} from './helper';
import {
  initialPrestartcheckDefectCount,
  InitialPrestartcheckDefectCountType,
  ROW_HEIGHT,
  DefectColor,
} from './helper/constants';
import { arrayofPreCheckEvaluationResult } from './NZMine/utils/helper';
import { PreCheckEvaluationResult, SortBy } from './models/enums';
import {
  DepartmentWithoutRestriction,
  ExtendedPrestartCheck,
  License,
  PreStartCheck,
} from './NZMine/utils/types';
import {
  CategoryData,
  PreCheckEvaluationResultToChecksMap,
  PrestartCountForCategory,
  SummaryModalProps,
} from './helper/types';
import DisplayCategoryDefectsSummary from './displayCategoryDefectsSummary';
import { API_METHODS, ENDPOINTS } from './NZMine/utils/constants';
import Logo from './assets/favicon.png'

const Header = ({ selectedDate }) => {
  return (
    <div className="d-flex pady-2 padx-2  justify-content-between align-items-center">
      <div className="col-8 d-flex align-items-end ">
        <img style={{ width: 100 }} src={Logo} alt='iMOS' />
      </div>
      <div className="col-4 d-flex justify-content-end  align-items-end">
        {moment().format('YYYY-MM-DD') == selectedDate
          ? moment().format('hh:mm A')
          : ''}{' '}
        {selectedDate?.substring(8, 10)}/{selectedDate?.substring(5, 7)}/
        {selectedDate?.substring(0, 4)}
      </div>
    </div>
  );
};

const Summary: FunctionComponent<SummaryModalProps> = (props) => {
  const { ApiHandler, departments, licenses } = useContext(UserContext);

  const getDepartMentsName = (deptArray, depts, licenses) => {
    let actualLength = 0;
    const departmentsWithLiscences = depts?.map((dept) => {
      const lIndex = licenses.findIndex(
        (license) => license?.departmentCode === dept?.code,
      );
      if (lIndex !== -1) {
        actualLength++;
        if (deptArray?.includes(dept?.code)) {
          return dept?.name;
        }
      }
    });

    const departmentsList = departmentsWithLiscences?.filter(
      (d) => d != undefined,
    );

    if (deptArray?.length == actualLength) {
      return 'All Departments';
    }
    const deptNames = departmentsList?.join(', ');
    return deptNames;
  };

  const getCategoriesName = (
    categoryArray: number[],
    licenses: License[],
  ): string => {
    const filteredData: License[] = licenses.filter(
      (l) => categoryArray?.includes(l?.code),
    );
    const categories: string[] = filteredData?.map((liscs) => {
      return liscs?.name;
    });
    const categoryList: string[] = categories?.filter((d) => d !== undefined);
    if (categoryArray?.length === licenses?.length) {
      return 'All Categories';
    }
    const categoryNames: string = categoryList?.join(', ');
    return categoryNames;
  };

  const search: URLSearchParams = new URLSearchParams(document.location.search);

  const [prestartChecksData, setPrestartChecksData] = useState<
    ExtendedPrestartCheck[]
  >([]);
  const [tables, setTables] = useState([]);

  const statusType = search.get('status')
    ? search.get('status')?.split(',')
    : props.status;

  const deptType: number[] = search.get('dept')
    ? search
      .get('dept')
      .split(',')
      .map((item) => Number(item))
    : props.dept;

  const categoryType: number[] = search.get('category')
    ? search
      .get('category')
      .split(',')
      .map((item) => Number(item))
    : props.category;

  const searchQuery: string | null = search.get('search')
    ? search.get('search')
    : props.searchq;

  const dateQuery: string = search.get('date')
    ? search.get('date')
    : props.selectedDate;
  const sortByQuery = search.get('sortBy') ?? props.sortBy;
  const [loading, setLoading] = useState<boolean>(false);
  const [summary, setSummary] = useState<InitialPrestartcheckDefectCountType>({
    ...initialPrestartcheckDefectCount,
  });

  const [categoryData, setCategoryData] = useState<CategoryData[]>([]);

  const [tablesData, setTablesData] =
    useState<PreCheckEvaluationResultToChecksMap>({});

  const handleChange = async (
    prestartChecks: PreStartCheck[],
    source?: boolean,
  ) => {
    const prestartCountForCategory: PrestartCountForCategory[] = [];

    const summaryCounts: InitialPrestartcheckDefectCountType = {
      ...initialPrestartcheckDefectCount,
    };

    const updatedPrestartchecks: ExtendedPrestartCheck[] = prestartChecks.map(
      (prestartcheck) => {
        summaryCounts[prestartcheck.status] += 1;
        const categoryName = getCategoryName(
          prestartcheck.truck?.type,
          licenses,
        );

        const categoryIndex: number = prestartCountForCategory.findIndex(
          (category) => category.name === categoryName,
        );
        // If the category doesn't exist, create it and add to prestartCountForCategory
        if (categoryIndex === -1) {
          prestartCountForCategory.push({
            name: categoryName,
            data: { ...initialPrestartcheckDefectCount },
          });
        }

        // Update data in prestartCountForCategory
        const updatedCategoryIndex: number = prestartCountForCategory.findIndex(
          (cat) => cat.name === categoryName,
        );
        prestartCountForCategory[updatedCategoryIndex].data[
          prestartcheck.status
        ]++;

        const prestartcheckDepartment: DepartmentWithoutRestriction =
          getDeptname(prestartcheck?.truck?.type, departments, licenses);
        const defectCategories: string = getFailedCategories(
          prestartcheck.checkpoints,
        );
        return {
          ...prestartcheck,
          assetID: prestartcheck.truck?.name,
          failedChecks: defectCategories,
          supervisorSignature: prestartcheck.supervisor?.signedName,
          department: prestartcheckDepartment.name,
          deptCode: prestartcheckDepartment.code,
          category: getCategoryName(prestartcheck.truck?.type, licenses),
        };
      },
    );
    const updatedCategoryData: CategoryData[] = prestartCountForCategory.map(
      (category) => ({
        ...category,
        data: values(category.data),
      }),
    );

    setSummary(summaryCounts);

    setCategoryData(updatedCategoryData);
    if (!source) {
      setPrestartChecksData(updatedPrestartchecks);
    }
    setLoading(false);
  };

  const fetch = async (): Promise<void> => {
    try {
      setLoading(true);

      // const response = await ApiHandler({
      //   endPoint: ENDPOINTS.getPrestartChecks,
      //   method: API_METHODS.PUT,
      //   reqParam: {
      //     date: dateQuery,
      //   },
      // });
      // await handleChange(response.data);
    } catch (err) {
      console.log(err);

      toast.error(err?.message);
      setLoading(false);
    }
  };

  const filterStatusData = (
    data: ExtendedPrestartCheck[],
    statusType: PreCheckEvaluationResult,
  ): ExtendedPrestartCheck[] => {
    if (!isEmpty(statusType)) {
      const filteredData = data.filter((d) => statusType?.includes(d.status));
      return filteredData;
    } else {
      return data;
    }
  };

  const filterDeptData = (
    data: ExtendedPrestartCheck[],
    deptType: number[],
  ): ExtendedPrestartCheck[] => {
    if (!isEmpty(deptType)) {
      const filteredData = data.filter((d) => deptType?.includes(d?.deptCode));
      return filteredData;
    } else {
      return data;
    }
  };

  const filterCategoryData = (
    data: ExtendedPrestartCheck[],
    categoryType: number[],
  ): ExtendedPrestartCheck[] => {
    if (!isEmpty(categoryType)) {
      const filteredData = data.filter(
        (d) => categoryType?.includes(d?.truck?.type),
      );
      return filteredData;
    } else {
      return data;
    }
  };

  const filteredData = (
    status: PreCheckEvaluationResult,
  ): ExtendedPrestartCheck[] => {
    return filterCategoryData(
      filterDeptData(filterStatusData(prestartChecksData, status), deptType),
      categoryType,
    ).filter(
      (prestartCheck) =>
        !searchQuery ||
        prestartCheck.assetID.includes(searchQuery) ||
        `${prestartCheck.operator?.first_name} ${prestartCheck.operator?.last_name}`
          .toLowerCase()
          .includes(searchQuery.toLowerCase()),
    );
  };

  useEffect(() => {
    const statusTypes: PreCheckEvaluationResult[] =
      arrayofPreCheckEvaluationResult;
    const updatedData: PreCheckEvaluationResultToChecksMap = {};
    statusTypes.forEach((status) => {
      if (statusType?.includes(status)) {
        const statusFilteredfData: ExtendedPrestartCheck[] =
          filteredData(status);
        updatedData[status] = statusFilteredfData;
      }
    });
    setTablesData(updatedData);
    const filterData: ExtendedPrestartCheck[] = filteredData(statusType);
    const applyFilter = async (
      filterData: ExtendedPrestartCheck[],
    ): Promise<void> => {
      await handleChange(filterData, true);
    };
    applyFilter(filterData);
  }, [prestartChecksData]);

  const generateTables = ({ preData, maxHeight = 400 }) => {
    const remaining: PreCheckEvaluationResultToChecksMap = {
      [PreCheckEvaluationResult.CATEGORY_A_FAILURE]: [],
      [PreCheckEvaluationResult.CATEGORY_B_FAILURE]: [],
      [PreCheckEvaluationResult.CATEGORY_C_FAILURE]: [],
      [PreCheckEvaluationResult.PASS]: [],
      [PreCheckEvaluationResult.INCOMPLETE]: [],
    };
    let tableHtml: string = '<div class = "py-2 padx-2">'; // initialize HTML content
    let currentHeight: number = 0; // Track the current height of the page
    forEach(entries(preData), ([prestartCheckStatus, prestartchecks]) => {
      if (preData[prestartCheckStatus]?.length) {
        tableHtml += `<div class="table_manage_prestart mb-3;"> ${currentHeight + ROW_HEIGHT < maxHeight
          ? ` <span style="font-size: 14px; font-weight: 600; display: inline-block; margin-bottom: 1rem; border-bottom: 2px solid ${DefectColor[prestartCheckStatus]
          };"> ${prestartCheckStatus?.toUpperCase()} </span> `
          : ' '
          }<table>`;

        if (currentHeight + ROW_HEIGHT < maxHeight) {
          tableHtml += '<thead class="users_header">';
          tableHtml +=
            '<tr style="border-top: 2px solid #707070; border-bottom: 2px solid #707070;" align="left" valign="top" class="users_header">';
          tableHtml += '<th>Ref No.</th>';
          tableHtml += '<th colspan="2">Operator</th>';
          tableHtml += '<th>Check time</th>';
          tableHtml += '<th>Failed checks</th>';
          tableHtml += '<th>Engine hours</th>';
          tableHtml += '<th>Sup. Signature</th>';
          tableHtml += '</tr>';
          tableHtml += '</thead>';
          currentHeight += ROW_HEIGHT;
        }

        let department: string = '';
        let category: string = '';
        forEach(prestartchecks, (prestartCheck) => {
          if (
            sortByQuery === SortBy.DEPARTMENT &&
            department !== prestartCheck.department
          ) {
            ({ tableHtml, currentHeight } = addRow(
              tableHtml,
              `<td colspan="10"><strong>${prestartCheck.department}</strong></td>`,
              currentHeight,
              maxHeight,
            ));
            department = prestartCheck.department;
          }
          // checking if the category is already displayed.
          if (category !== prestartCheck.category) {
            ({ tableHtml, currentHeight } = addRow(
              tableHtml,
              `<td style="color : #707070" colspan="10"><i>${prestartCheck.category}</i></td>`,
              currentHeight,
              maxHeight,
            ));
            category = prestartCheck.category;
          }

          ({ tableHtml, currentHeight } = addRow(
            tableHtml,
            `<td colspan="10"><strong>${prestartCheck.assetID}</strong></td>`,
            currentHeight,
            maxHeight,
          ));
          if (currentHeight + ROW_HEIGHT < maxHeight) {
            tableHtml += `<tr align="left" valign="top">`;
            tableHtml += `<td >${prestartCheck.refNo ? prestartCheck.refNo : '-'
              }</td>`;
            tableHtml += `<td colspan="2">${prestartCheck.operator?.first_name} ${prestartCheck.operator?.last_name}</td>`;
            tableHtml += `<td >${prestartCheck.status !== 'incomplete'
              ? getLocalTime(prestartCheck.checkTime)
              : '-'
              }</td>`;
            tableHtml += `<td>${prestartCheck.failedChecks}</td>`;
            tableHtml += `<td>${prestartCheck.engineHours === 0
              ? '00000'
              : prestartCheck.engineHours
              } hrs</td>`;
            tableHtml += `<td>${prestartCheck.supervisorSignature
              ? prestartCheck.supervisorSignature
              : prestartCheck.status === 'incomplete'
                ? 'N/A'
                : '-'
              }</td>`;
            tableHtml += '</tr>';
            currentHeight += ROW_HEIGHT;
          } else {
            remaining[prestartCheckStatus]?.push(prestartCheck);
          }
        });

        tableHtml += '</table></div>';
      }
    });
    tableHtml += '</div>';

    return { tableHtml, remaining };
  };

  useEffect(() => {
    const localTablesVariable = [];
    let remainingData = tablesData;

    while (
      !isEmpty(remainingData[PreCheckEvaluationResult.CATEGORY_A_FAILURE]) ||
      !isEmpty(remainingData[PreCheckEvaluationResult.CATEGORY_B_FAILURE]) ||
      !isEmpty(remainingData[PreCheckEvaluationResult.CATEGORY_C_FAILURE]) ||
      !isEmpty(remainingData[PreCheckEvaluationResult.PASS]) ||
      !isEmpty(remainingData[PreCheckEvaluationResult.INCOMPLETE])
    ) {
      const { tableHtml, remaining } = generateTables({
        preData: remainingData,
        maxHeight: localTablesVariable?.length === 0 ? 210 : 660,
      });
      localTablesVariable.push(tableHtml);
      setTables(localTablesVariable);
      remainingData = remaining;
    }
  }, [tablesData]);

  useEffect(() => {
    fetch();
  }, [props.selectedDate]);

  return (
    <>
      {loading ? (
        <div>Loading....</div>
      ) : (
        <div id="print-report" className="content-loaded">
          <div style={{ position: 'relative' }} className="report-view-pdf p-0">
            <Header selectedDate={dateQuery} />

            <div
              className=" py-2 padx-2 "
              style={{ fontSize: '16px', fontWeight: '600' }}
            >
              {' '}
              Pre-start Checks
            </div>

            <div style={{ backgroundColor: '#FFF' }} className=" mb-3">
              {/* <div
                style={{ backgroundColor: '#FAFAFA' }}
                className="d-flex py-2 padx-2 border-y "
              >
                <div className=" col-12 d-flex flex-column">
                  <div
                    style={{
                      fontSize: '13.5px',
                      borderBottom: '1px solid #EBEBEB',
                    }}
                    className="py-2 mb-2 d-flex"
                  >
                    <strong className="">Specifications </strong>
                  </div>
                  <div className=" d-flex">
                    <strong className="col-2">Department:</strong>
                    <div className="col-10">
                      <strong>
                        {getDepartMentsName(deptType, departments, licenses)}
                      </strong>
                    </div>
                  </div>
                  <div className=" d-flex">
                    <strong className="col-2">Category: </strong>
                    <div className="col-6">
                      <strong>
                        {getCategoriesName(categoryType, licenses)}
                      </strong>
                    </div>
                  </div>
                  <div className=" d-flex">
                    <strong className="col-2">Status:</strong>
                    <div className="col-6">
                      <strong>
                        {statusType?.length === 0 ||
                        statusType?.length ===
                          arrayofPreCheckEvaluationResult.length
                          ? 'All'
                          : statusType?.map((status, index) => (
                              <span key={status}>
                                {capitalize(status)}
                                <span>
                                  {index + 1 === statusType?.length ? '' : ', '}
                                </span>
                              </span>
                            ))}{' '}
                      </strong>
                    </div>
                  </div>
                  <div className=" d-flex">
                    <strong className="col-2">Assets:</strong>
                    <div className="col-6">
                      <strong>{searchQuery ? searchQuery : 'All'} </strong>
                    </div>
                  </div>
                  <div className="d-flex">
                    <strong className="col-2">Operators:</strong>
                    <div className="col-6">
                      <strong>{searchQuery ? searchQuery : 'All'} </strong>
                    </div>
                  </div>
                </div>
              </div> */}

              <div
                className="rounded m-4 border p-2 "
                style={{ borderColor: '#EBEBEB' }}
              >
                <div
                  className="mt-2 fw-bold "
                  style={{ fontSize: '12px', fontWeight: '600' }}
                >
                  Summary
                </div>
                {/* <DisplayCategoryDefectsSummary summary={summary} /> */}
                {/* <CategoryCard data={categoryData} /> */}
              </div>

              <div className="  mt-3  padx-2 ">
                <div style={{ fontSize: '14px', fontWeight: '600' }}>
                  Details
                </div>
              </div>
              <div dangerouslySetInnerHTML={{ __html: tables[0] }}></div>
            </div>

            <div
              style={{ position: 'absolute ', bottom: 10, right: 10 }}
              className="d-flex "
            >
              Page: 1/{tables?.length}
            </div>
          </div>

          {tables?.map((table, index) => {
            if (index > 0) {
              return (
                <div
                  key={index}
                  style={{ position: 'relative' }}
                  className="report-view-pdf p-0"
                >
                  <Header selectedDate={dateQuery} />
                  <div style={{ backgroundColor: '#FFF' }} className=" mb-3">
                    <div
                      dangerouslySetInnerHTML={{ __html: tables[index] }}
                    ></div>
                  </div>
                  <div
                    style={{ position: 'absolute ', bottom: 10, right: 10 }}
                    className="d-flex "
                  >
                    Page: {index + 1}/{tables?.length}
                  </div>
                </div>
              );
            }
          })}

          {search.get('date') && (
            <div
              style={{ position: 'relative' }}
              className="report-view-pdf p-0"
            >
              <Header selectedDate={dateQuery} />
              <div style={{ backgroundColor: '#FFF' }} className=" mb-3">
                <div
                  dangerouslySetInnerHTML={{
                    __html: tables[tables?.length - 1],
                  }}
                ></div>
              </div>
              <div
                style={{ position: 'absolute ', bottom: 10, right: 10 }}
                className="d-flex "
              >
                Page: {tables?.length}/{tables?.length}
              </div>
            </div>
          )}
        </div>
      )}{' '}
    </>
  );
};

export default Summary;
