import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendarAlt } from 'react-icons/fa';
import styles from './DateTimeSelect.module.css';
import { IoIosArrowDown } from 'react-icons/io';

const DateTimeSelect = ({
    value,
    placeholder = "DD/MM/YYYY",
    dateFormat = "yyyy/MM/dd",
    onDateChange,
    containerStyle = {},
    className = "",
    iconColor = "#1a73e8",
}) => {


    const handleChange = (date) => {
        if (onDateChange) {
            onDateChange(new Date(date));
        }
    };

    return (
        <div className={styles.datePickerContainer + ` ${className}`} style={containerStyle}>
            <FaCalendarAlt className={styles.calendarIcon} style={{ color: iconColor }} />
            <DatePicker
                selected={value}
                onChange={handleChange}
                dateFormat={dateFormat}
                placeholderText={placeholder}
                className={styles.customInput}
            />
            <IoIosArrowDown className={styles.dropdownIcon} />
        </div>
    );
};

export default DateTimeSelect;
