import { CallWithAuth, CallWithAuthFormData } from "action/apiActions";
import axios from "axios";
import useAuth from "hooks/useAuth";
import moment from "moment";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchEquipments } from "../utils/apiService";
import { GET_SHIFT_HISTORY } from "action/apiPath";
import { ModuleStartDate, ShiftStatusEnum } from "../utils/constant";

const ShiftHistoryContext = createContext();

export const ShiftHistoryProvider = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const [selectedDate, setSelectedDate] = useState(
    queryParams.get("date")
      ? moment
        .tz(queryParams.get("date"), "YYYY-MM-DD", "Pacific/Auckland")
        .toDate()
      : new Date()
  );

  const [selectedShift, setSelectedShift] = useState({
    day: undefined,
    night: undefined,
  });
  const [shiftType, setShiftType] = useState(
    queryParams.get("type") ? queryParams.get("type") : "day"
  );
  const [shiftOptions, setShiftOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const { auth } = useAuth();
  const timeoutRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [shiftHistoryData, setShiftHistoryData] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [equipmentList, setEquipmentList] = useState([]);
  const searchQueryRef = useRef("");
  const [refreshPage, setRefreshPage] = useState(false);
  const [summaryPreCondition, setSummaryPreCondition] = useState(false);

  useEffect(() => {
    const dateFromQuery = queryParams.get("date");
    const shiftTypeFromQuery = queryParams.get("type");
    const formattedSelectedDate = moment(selectedDate).format("YYYY-MM-DD");

    if (dateFromQuery && formattedSelectedDate !== dateFromQuery) {
      const newSelectedDate = moment
        .tz(dateFromQuery, "YYYY-MM-DD", "Pacific/Auckland")
        .toDate();
      setSelectedDate(newSelectedDate);
    }
    if (shiftTypeFromQuery && shiftType !== shiftTypeFromQuery) {
      setShiftType(shiftTypeFromQuery);
    }
  }, [location.search]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [equipmentList] = await Promise.all([fetchEquipments()]);
        setEquipmentList(equipmentList);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const cktkn = source.token;

    // Helper function to navigate with query parameters
    const navigateWithDate = () => {
      queryParams.set("date", moment(selectedDate).format("YYYY-MM-DD"));
      navigate(`${location.pathname}?${queryParams.toString()}`, {
        replace: true,
      });
    };

    // Helper function to fetch shifts data
    const fetchShiftsData = async () => {
      try {
        setLoading(true);
        navigateWithDate(); // Update the navigation with the selected date

        const shiftResponse = await CallWithAuthFormData(
          "POST",
          "/api/getShiftsForDashboard",
          {
            userId: auth?.userData?._id,
            date: moment(selectedDate).format("YYYY-MM-DD"),
          },
          cktkn
        );

        const shiftsData = shiftResponse?.res?.data?.data || {};
        processShiftData(shiftsData);
      } catch (error) {
        handleFetchError(error);
      }
    };

    // Helper function to process fetched shift data
    const processShiftData = (shiftsData) => {
      setShiftOptions({
        day: shiftsData.day,
        night: shiftsData.night,
      });

      setSelectedShift({
        day: shiftsData?.day?.[0]?.value,
        night: shiftsData?.night?.[0]?.value,
      });

      // Check if the selected shift type is valid
      if (!shiftsData[shiftType]?.[0]?.value) {
        setShiftHistoryData([]);
        setFilteredResults([]);
      }

      setLoading(false); // Ensure loading is false after processing
    };

    // Helper function to handle fetch errors
    const handleFetchError = (error) => {
      console.error("Error fetching shift data:", error);
      setLoading(false); // Ensure loading is false on error
    };

    // Fetch shifts data if user is authenticated and a date is selected
    if (auth?.userData?._id && selectedDate) {
      fetchShiftsData();
    }

    // Cleanup function
    return () => {
      source.cancel("Operation canceled by the user.");
    };
  }, [selectedDate, auth]);


  const preCondition = (shiftStatus) => {
    if (
      shiftStatus &&
      shiftStatus === ShiftStatusEnum.ENDED &&
      moment(selectedDate).isAfter(ModuleStartDate.DATE, "day")
    ) {
      setSummaryPreCondition(true)
    }
    else {
      setSummaryPreCondition(false)
    }

  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    const cktkn = source.token;
    // Helper function to fetch data
    const fetchData = async (dontLoad = false) => {
      if (!dontLoad) setLoading(true);
      try {
        const response = await CallWithAuth("POST", GET_SHIFT_HISTORY, {
          shiftPlanId: selectedShift[shiftType],
        },
          cktkn
        );

        const data = response?.res?.data;

        if (!dontLoad && data) setLoading(false);
        if (response?.res.status && response.status) {
          setShiftHistoryData(data);
          setFilteredResults(data?.historyData);
          preCondition(data.shiftStatus)
        }

        //setAutoRefreshTimeout();
        // If successful, start the timeout for auto-refresh
      } catch (error) {
        handleError(error, dontLoad);
      }
    };

    // Helper function for error handling and retry logic
    const handleError = (error, dontLoad) => {
      if (!dontLoad) setLoading(false);
      console.error("Error fetching performance data:", error);

      // Set retry with timeout on error
      setAutoRefreshTimeout();
    };

    // Helper function to manage auto-refresh
    const setAutoRefreshTimeout = () => {
      timeoutRef.current = setTimeout(() => {
        if (selectedShift[shiftType]) fetchData(true);
      }, 5000);
    };

    // Initial Navigation
    if (shiftType) {
      queryParams.set("type", shiftType);
      navigate(`${location.pathname}?${queryParams.toString()}`, {
        replace: true,
      });
    }

    // Initial Data Fetch
    if (selectedShift[shiftType]) fetchData();
    else {
      setShiftHistoryData([]);
      setFilteredResults([]);
    }

    // Cleanup on unmount
    return () => {
      source.cancel("Operation canceled by the user.");
      clearTimeout(timeoutRef.current);
    };
  }, [selectedShift, shiftType, refreshPage]);


  const searchShiftHistoryData = (searchQuery) => {
    const historyData = shiftHistoryData?.historyData
    setSearchQuery(searchQuery);
    if (!searchQuery.trim()) {
      setFilteredResults(historyData);
      return;
    }

    const lowerQuery = searchQuery?.toLowerCase().replace(/\s+/g, "");

    const results = historyData?.filter((item) => {
      const nameMatch = item.name
        .toLowerCase()
        .replace(/\s+/g, "")
        .includes(lowerQuery);

      const operatorMatch = item.operatorLoads.some((load) =>
        load.operatorName.toLowerCase().replace(/\s+/g, "").includes(lowerQuery)
      );

      return nameMatch || operatorMatch;
    });

    setFilteredResults(results);
  };

  return (
    <ShiftHistoryContext.Provider
      value={{
        loading,
        setLoading,
        selectedDate,
        setSelectedDate,
        selectedShift,
        setSelectedShift,
        shiftType,
        setShiftType,
        searchQuery,
        setSearchQuery,
        searchShiftHistoryData,
        filteredResults,
        equipmentList,
        shiftOptions,
        shiftHistoryData,
        setRefreshPage,
        refreshPage,
        summaryPreCondition
      }}
    >
      {children}
    </ShiftHistoryContext.Provider>
  );
};

export const useShiftHistoryContext = () => {
  return useContext(ShiftHistoryContext);
};
