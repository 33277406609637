//@ts-nocheck

import { v4 as uuidv4 } from "uuid";

import { isEmpty, includes } from "lodash";
import { capitaliseFirstLetter } from "../NZMine/utils/helper";
import { messages } from "../NZMine/utils/messages";
import { PreCheckAnswer } from "../NZMine/utils/model";
import {
  CheckpointDetail,
  CheckpointQuestion,
  Department,
  ExtendedPrestartCheck,
  License,
  PreStartCheck,
  PrestartCheckForTable,
  SortedData,
} from "../NZMine/utils/types";
import { PreCheckEvaluationResult, SortBy } from "../models/enums";
import {
  CategoryMapping,
  DefectColor,
  PreCheckEvaluationResultLabels,
  ROW_HEIGHT,
} from "./constants";
import {
  SortedDataByCategory,
  SortedDataByDepartment,
  TableRow,
} from "./types";
import { HTMLAttributes } from "react";
import MESSAGES from "../NZMine/constants/messages";

export const getCategoryName = (
  truckType: number,
  licenses: License[]
): string => {
  const license = licenses.find((license) => license.code === truckType);
  if (license) {
    return license.name;
  } else {
    return messages.error.LICENSE_NOT_FOUND;
  }
};

export const getDeptname = (
  truckType: number,
  departments: Department[],
  licenses: License[]
): { name: string; code: number } => {
  const license: License | undefined = licenses.find(
    (license) => license.code === truckType
  );
  if (!license) return { name: "-", code: 0 };
  const dept: Department | undefined = departments.find(
    (dept) => license.departmentCode === dept.code
  );
  if (dept) {
    return { name: dept.name, code: dept.code };
  } else {
    return { name: "-", code: 0 };
  }
};

const hasDefectsInCheckpoints = (
  checkpoints: CheckpointQuestion[]
): boolean => {
  return checkpoints.some(
    (checkpoint) => checkpoint.answer === PreCheckAnswer.DEFECT_FOUND
  );
};

export const getFailedCategories = (checkpoints?: CheckpointDetail[]) => {
  const defectsCategories: string[] | undefined = checkpoints?.reduce(
    (acc: string[], categoryAndCheckpoints: CheckpointDetail) => {
      if (hasDefectsInCheckpoints(categoryAndCheckpoints.checkpoints)) {
        acc.push(CategoryMapping[categoryAndCheckpoints.category]);
      }
      return acc;
    },
    []
  );
  if (isEmpty(defectsCategories)) return "-";
  return defectsCategories!.join(", ");
};

export const isSamePrestartCheckAndNotIncomplete = (
  selectedPreStart: PreStartCheck,
  prestartCheck: PreStartCheck
): boolean => {
  return (
    JSON.stringify(selectedPreStart) === JSON.stringify(prestartCheck) &&
    prestartCheck.status !== PreCheckEvaluationResult.INCOMPLETE
  );
};

export const isPrestartSelected = (
  selectedPrestartId: string | undefined,
  prestartCheck: PreStartCheck
): boolean =>
  selectedPrestartId ? selectedPrestartId === prestartCheck._id : false;

export const isPrestartComplete = (prestartCheck: PreStartCheck): boolean =>
  prestartCheck.status !== PreCheckEvaluationResult.INCOMPLETE;

export const getStyleOfSelectedRow = (
  isSelected: boolean,
  prestartCheck: ExtendedPrestartCheck
): HTMLAttributes<HTMLTableRowElement>["style"] => {
  if (isSelected && isPrestartComplete(prestartCheck)) {
    return {
      backgroundColor: "#05795F0D",
      borderTop: "2px solid #EDEDED ",
      borderBottom: "2px solid #EDEDED ",
    };
  } else
    return {
      backgroundColor: "#fff",

      borderBottom: "2px solid #EDEDED ",
    };
};

export const getStyleOfCellByStatus = (
  prestartCheck: ExtendedPrestartCheck
): HTMLAttributes<HTMLTableCellElement>["style"] => ({
  color: DefectColor[prestartCheck.status],
});

export const getSupervisorSignature = (
  prestartCheck: PrestartCheckForTable
): string => {
  if (prestartCheck.supervisorSignature)
    return prestartCheck.supervisorSignature;
  else if (isPrestartComplete(prestartCheck)) return "-";
  else return "N/A";
};

export const getStatusName = (prestartCheck: PrestartCheckForTable): string => {
  if (
    prestartCheck.status === PreCheckEvaluationResult.INCOMPLETE ||
    prestartCheck.status === PreCheckEvaluationResult.PASS
  ) {
    return capitaliseFirstLetter(prestartCheck.status);
  } else
    return `${PreCheckEvaluationResultLabels[prestartCheck.status]} ${
      MESSAGES.PRESTART.FAILURE
    }`;
};

export const formatTimestampToTime = (
  timestamp: string,
  defaultValue = "00:00 AM"
) => {
  if (isNaN(Date.parse(timestamp))) return defaultValue;
  const date = new Date(timestamp);
  return date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
};
export const getLocalTime = (time: string): string => {
  const timeString: string = formatTimestampToTime(time);
  const lastTwoCharacters: string = timeString.slice(-2); // Get the last two characters
  const modifiedTime: string =
    timeString.slice(0, -2) + lastTwoCharacters.toLowerCase(); // Convert the last two characters to lowercase and replace them
  return modifiedTime;
};

export const generateRandomId = () => {
  return uuidv4();
};

export const getSortedPrestartChecks = (
  data: ExtendedPrestartCheck[]
): SortedData => {
  const sortedData: SortedData = {};
  data.forEach((prestartCheck) => {
    if (!sortedData[prestartCheck?.department]) {
      sortedData[prestartCheck?.department] = {};
    }
    if (!sortedData[prestartCheck?.department][prestartCheck?.category]) {
      sortedData[prestartCheck?.department][prestartCheck?.category] = {};
    }
    if (
      !sortedData[prestartCheck?.department][prestartCheck?.category][
        prestartCheck?.truck?.name
      ]
    ) {
      sortedData[prestartCheck?.department][prestartCheck?.category][
        prestartCheck?.truck?.name
      ] = [];
    }
    sortedData[prestartCheck?.department][prestartCheck?.category][
      prestartCheck?.truck?.name
    ].push(prestartCheck);
  });
  return sortedData;
};

export const addRow = (
  tableHtml: string,
  content: string,
  currentHeight: number,
  maxHeight: number
): TableRow => {
  if (currentHeight + ROW_HEIGHT < maxHeight) {
    tableHtml += `<tr align="left" valign="top">`;
    tableHtml += content;
    tableHtml += "</tr>";
    currentHeight += ROW_HEIGHT;
  }
  return { tableHtml, currentHeight };
};

export const getSortedData = (
  sortBy: SortBy,
  prestartChecks: ExtendedPrestartCheck[]
): SortedDataByDepartment | SortedDataByCategory | undefined => {
  if (sortBy === SortBy.DEPARTMENT) {
    const sortedData: SortedDataByDepartment = {};
    prestartChecks.forEach((prestartCheck) => {
      if (!sortedData[prestartCheck.department]) {
        sortedData[prestartCheck.department] = {};
      }
      if (!sortedData[prestartCheck.department][prestartCheck.category]) {
        sortedData[prestartCheck.department][prestartCheck.category] = [];
      }
      sortedData[prestartCheck.department][prestartCheck.category].push(
        prestartCheck
      );
    });

    return sortedData;
  } else if (sortBy === SortBy.CATEGORY) {
    const sortedData: SortedDataByCategory = {};
    prestartChecks.forEach((prestartCheck) => {
      if (!sortedData[prestartCheck.category]) {
        sortedData[prestartCheck.category] = [];
      }
      sortedData[prestartCheck.category].push(prestartCheck);
    });
    return sortedData;
  }
};

export const isWithinMaxHeight = (
  currentHeight: number,
  maxHeight: number
): boolean => {
  return currentHeight + ROW_HEIGHT < maxHeight;
};

export const includesPassOrIncomplete = (
  name: PreCheckEvaluationResult
): boolean => {
  const validResults: PreCheckEvaluationResult[] = [
    PreCheckEvaluationResult.PASS,
    PreCheckEvaluationResult.INCOMPLETE,
  ];
  return includes(validResults, name);
};

export const tempPreProcessing = async (obj) => {
  return {
    _id: obj._id,
    status: obj.status,
    truck: {
      _id: obj.equipment?._id,
      name: obj.equipment?.Unit,
      type:
        obj.equipment?.EquipmentType == "Excavator"
          ? 2
          : obj.equipment?.EquipmentType == "Truck"
          ? 6
          : 0,
    },
    operator: {
      _id: obj.operator?._id,
      first_name: obj.operator?.firstName,
      last_name: obj.operator?.lastName,
      signature: obj.operatorSignature,
    },
    loginTime: obj.loginTime,
    engineHours: obj.engineHours,
    odometers: obj.odometers,
    checkpoints: obj.checkpoints,
    checkTime: obj.checkTime,
    refNo: obj.refNo,
    extraNote: {
      question: obj.extraNote?.question || "",
      note: obj.extraNote?.note || "",
      imagePaths: obj.extraNote?.imagePaths || [],
    },
    supervisor: obj.supervisor,
    __v: obj.__v,
  };
};
