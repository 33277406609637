import { useExcavatorLoadTrackerContext } from "../context/context";
import { generatePdf, getCSVData } from "./helpers";
import Searchbar from "pages/Maintenance/Component/Searchbar/Searchbar";
import Datepicker from "pages/Maintenance/Component/Datepicker/Datepicker";
import DayNightSwitch from "pages/Maintenance/Component/DayNightSwitch/DayNightSwitch";
import ExportButton from "pages/Maintenance/Component/ExportButton/ExportButton";
import Legend from "pages/Maintenance/Component/Legend/Legend";
import EquipmentButton from "pages/Maintenance/Component/EquipmentButton/EquipmentButton";

const Header = () => {
  const {
    searchQuery,
    setSearchQuery,
    selectedDate,
    setSelectedDate,
    shiftType,
    setShiftType,
    getExcavators,
    selectedExcavator,
    setSelectedExcavator,
    mainContainer,
    getExcavatorData,
  } = useExcavatorLoadTrackerContext();

  const excavators = getExcavators() ?? [], excavator = getExcavatorData();

  return (
    <div className="d-flex justify-content-between align-items-center p-5 border-bottom">
      <div className="d-flex justify-content-start align-items-center">
        {excavators.map((e, idx) => (
          <EquipmentButton
            isSelected={idx === selectedExcavator}
            label={e.excavatorName}
            onClick={() => setSelectedExcavator(idx)}
            key={idx}
          />
        ))}
      </div>

      <div className="d-flex justify-content-end align-items-center">
        <Searchbar search={searchQuery} onChange={(e) => setSearchQuery(e)} />
        <Datepicker date={selectedDate} onChange={(e) => setSelectedDate(e)} />
        <DayNightSwitch value={shiftType} onChange={(e) => setShiftType(e)} />
        <ExportButton
          onClick={() => generatePdf(mainContainer, excavator)}
          fileName={"Dump_Cycle_Tracker_Data " + excavator?.excavatorName}
          data={excavator ? getCSVData(excavator) : []}
        />
        {/* <Legend /> */}
      </div>
    </div>
  );
};

export default Header;
