import React from 'react'

const Searchbar = ({ search, onChange }) => {
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                border: "1px solid #dde5f3",
                padding: "4px 12px",
                borderRadius: "40px",
                width: "15rem",
                height: "2.5rem",
                backgroundColor: "white",
                marginRight: "0.8rem",
            }}
        >
            <input
                type="search"
                className="shadow-none"
                value={search}
                placeholder="Search"
                style={{
                    border: "none",
                    font: "normal normal normal 1rem Open Sans",
                    width: "125px",
                }}
                onChange={(e) => onChange(e.target.value)}
            />
            <i
                style={{ fontSize: "13px" }}
                className="fa-regular fa-magnifying-glass"
            ></i>
        </div>
    )
}

export default Searchbar;
