import { arrayofPreCheckEvaluationResult } from "../NZMine/utils/helper";
import { Category, PreCheckEvaluationResult } from "../models/enums";
export const ROW_HEIGHT = 30;

export const CategoryMapping = {
  [Category.CATEGORY_A]: "A",
  [Category.CATEGORY_B]: "B",
  [Category.CATEGORY_C]: "C",
};

export const initialPrestartcheckDefectCount = {
  [PreCheckEvaluationResult.CATEGORY_A_FAILURE]: 0,
  [PreCheckEvaluationResult.CATEGORY_B_FAILURE]: 0,
  [PreCheckEvaluationResult.CATEGORY_C_FAILURE]: 0,
  [PreCheckEvaluationResult.PASS]: 0,
  [PreCheckEvaluationResult.INCOMPLETE]: 0,
};

export const initialPrestartCheckList = {
  [PreCheckEvaluationResult.CATEGORY_A_FAILURE]: [],
  [PreCheckEvaluationResult.CATEGORY_B_FAILURE]: [],
  [PreCheckEvaluationResult.CATEGORY_C_FAILURE]: [],
  [PreCheckEvaluationResult.PASS]: [],
  [PreCheckEvaluationResult.INCOMPLETE]: [],
};

export const PreCheckEvaluationResultLabels = {
  [PreCheckEvaluationResult.CATEGORY_A_FAILURE]: "Cat A",
  [PreCheckEvaluationResult.CATEGORY_B_FAILURE]: "Cat B",
  [PreCheckEvaluationResult.CATEGORY_C_FAILURE]: "Cat C",
  [PreCheckEvaluationResult.PASS]: PreCheckEvaluationResult.PASS,
  [PreCheckEvaluationResult.INCOMPLETE]: PreCheckEvaluationResult.INCOMPLETE,
};

export const prestartcheckLabelsWithoutIncomplete = {
  [PreCheckEvaluationResult.CATEGORY_A_FAILURE]: "Cat A",
  [PreCheckEvaluationResult.CATEGORY_B_FAILURE]: "Cat B",
  [PreCheckEvaluationResult.CATEGORY_C_FAILURE]: "Cat C",
  [PreCheckEvaluationResult.PASS]: PreCheckEvaluationResult.PASS,
};

export type InitialPrestartcheckDefectCountType =
  typeof initialPrestartcheckDefectCount;

export const DefectColor = {
  [PreCheckEvaluationResult.CATEGORY_A_FAILURE]: "#F44242",
  [PreCheckEvaluationResult.CATEGORY_B_FAILURE]: "#F8A200",
  [PreCheckEvaluationResult.CATEGORY_C_FAILURE]: "#4C1A90",
  [PreCheckEvaluationResult.PASS]: "#3EA884",
  [PreCheckEvaluationResult.INCOMPLETE]: "#6F7070",
};

export const PreCheckEvaluationResultCounts =
  arrayofPreCheckEvaluationResult.map((status: any) => ({
    name: status,
    value: 0,
  }));

  