import { useState } from "react";
import Styles from "../../Component/NavBar/NavBar.module.css";
import clsx from "clsx";

const NavBar = ({ onTabChange }) => {
  const options = [{ label: "Dump Cycle Tracker", value: "dump" }, { label: "Excavator Load Tracker", value: "excavator" }];
  const [activeOption, setActiveOption] = useState("dump");

  return (
    <nav>
      <ul className={Styles.menu}>
        {options.map((option, index) => (
          <li
            key={index}
            className={clsx(
              Styles.menuItem,
              option.value === activeOption && Styles.active
            )}
            onClick={() => {
              setActiveOption(option.value)
              if (onTabChange)
                onTabChange(option.value)
            }}
            style={{ cursor: "pointer" }}
          >
            {option.label}
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default NavBar