import React, { useEffect, useState } from "react";
import Flyer from "../../components/Flyers/Flyer";
import ShiftPlan from "./Sections/ShiftPlan";
import { AssetCategories } from "../../utils/contants";
import ConfirmAlert from "../../components/ConfirmAlert/ConfirmAlert";
import HistoryWithPlans from "../HistoryTab/HistoryWithPlans";
import { detectOverlaps, fetchOperatorShiftAssignments, findOperatorInAssignments, getInitialPlanData, validateFormData } from "./utils/helper";
import { useAddDailyPlan } from "../../Context/context";

const ExcavatorShiftDetails = ({
  singleAssignedExcavatorDetailsFormData,
  onSaveExcavatorDetails,
  notEditable,
  isOpen,
  onClose
}) => {
  const {
    canEditShift,
    generalShiftPlanDetailsFormData,
    assignedExcavatorDetailsFormData,
    activityDescriptions
  } = useAddDailyPlan();
  const [formData, setFormData] = useState(getInitialPlanData(0, singleAssignedExcavatorDetailsFormData, { activityDescriptions }));
  const [showError, setShowError] = useState(false);
  const [viewOnly, setViewOnly] = useState(singleAssignedExcavatorDetailsFormData?.operator || notEditable);
  const [currPlan, setCurrPlan] = useState(singleAssignedExcavatorDetailsFormData?.shiftPlans?.length - 1);
  const [isExtraFlyerOpen, setIsExtraFlyerOpen] = useState(false);
  const [expandAll, setExpandAll] = useState(false);
  const [operatorChangeConfirmationPopup, setOperatorChangeConfirmationPopup] = useState(false);
  const [overlappingDetails, setOverlappingDetails] = useState({
    operator: {
      locations: [],
      found: false
    },
    assistantOperator: {
      locations: [],
      found: false
    },
    trainee: {
      locations: [],
      found: false
    }
  })
  const handleAddPlan = () => {
    const newPlanIndex = singleAssignedExcavatorDetailsFormData?.shiftPlans?.length || 0;
    setFormData(getInitialPlanData(newPlanIndex, singleAssignedExcavatorDetailsFormData, { activityDescriptions }));
    setIsExtraFlyerOpen(true);
    setCurrPlan(newPlanIndex);
  };

  const [search, setSearch] = useState({
    query: "",
    count: 0,
  });

  const handleOnClear = () => {
    setSearch({
      query: "",
      count: 0,
    });
  };

  useEffect(() => {
    setFormData(getInitialPlanData(0, singleAssignedExcavatorDetailsFormData, { activityDescriptions }));
    setIsExtraFlyerOpen(false)
  }, [singleAssignedExcavatorDetailsFormData])

  const handleSave = async () => {
    if (!validateFormData(formData)) {
      setShowError(true);
      return;
    }
    setShowError(false);

    const { plantId, category, ...dataToSave } = formData;
    if (await fetchOperatorShiftAssignments(formData, generalShiftPlanDetailsFormData)) {
      const newLocation = { exIdx: assignedExcavatorDetailsFormData?.findIndex((ex => ex.equipment == singleAssignedExcavatorDetailsFormData?.equipment)) }

      const operatorOverlapDetails = findOperatorInAssignments(assignedExcavatorDetailsFormData, formData?.operator?.value, formData?.startTime, formData?.endTime, newLocation, 'operator');

      const assistantOperatorOverlapDetails = findOperatorInAssignments(assignedExcavatorDetailsFormData, formData?.assistantOperator?.value, formData?.startTime, formData?.endTime, newLocation, 'assistantOperator');

      const traineeOverlapDetails = findOperatorInAssignments(assignedExcavatorDetailsFormData, formData?.trainee?.value, formData?.startTime, formData?.endTime, newLocation, 'trainee');

      if (operatorOverlapDetails.found || assistantOperatorOverlapDetails.found || traineeOverlapDetails.found) {
        setOverlappingDetails({
          operator: operatorOverlapDetails,
          assistantOperator: assistantOperatorOverlapDetails,
          trainee: traineeOverlapDetails,
        })
        setOperatorChangeConfirmationPopup(true)
      } else {
        onSaveExcavatorDetails(dataToSave, currPlan, isExtraFlyerOpen);
        setIsExtraFlyerOpen(false)
      }
    }
  };

  const handleEditMultiPlan = (planIndex) => {
    setFormData(getInitialPlanData(planIndex, singleAssignedExcavatorDetailsFormData, { activityDescriptions }));
    setIsExtraFlyerOpen(true);
    setCurrPlan(planIndex);
  };

  const generateTabs = () => {
    const isSinglePlan = singleAssignedExcavatorDetailsFormData.shiftPlans.length === 1;

    return [
      {
        title: "Shift Plan",
        leftActionButtons: [
          !notEditable && viewOnly && isSinglePlan && canEditShift && { label: "Edit", style: { border: "1px solid #186FD9" }, onClick: () => setViewOnly(false) },
          !notEditable && viewOnly && canEditShift && { label: "Add Plan", style: { backgroundColor: "transparent", color: "#186FD9", border: "1px solid #E1E8F4" }, onClick: () => handleAddPlan() }
        ].filter(Boolean),
        rightActionButtons: [
          !notEditable && !viewOnly && isSinglePlan && canEditShift && { label: "Save", onClick: handleSave },
          !isSinglePlan && { label: expandAll ? "Collapse All" : "Expand All", style: { backgroundColor: "transparent", color: "#186FD9", border: "1px solid #E1E8F4" }, onClick: () => setExpandAll(prev => prev === 0 ? true : !prev) }
        ].filter(Boolean),
        component: (
          <ShiftPlan
            // materialSelected={singleAssignedExcavatorDetailsFormData?.material?.label}
            canEditShift={canEditShift}
            shiftDetailsFormData={generalShiftPlanDetailsFormData}
            setFormData={setFormData}
            multi={!isSinglePlan}
            isHaulTrucksSelectionDisabled={true}
            formData={isExtraFlyerOpen && isSinglePlan ? getInitialPlanData(0, singleAssignedExcavatorDetailsFormData, { activityDescriptions }) : !isSinglePlan ? singleAssignedExcavatorDetailsFormData : formData}
            showError={showError && !isExtraFlyerOpen}
            viewOnly={viewOnly || isExtraFlyerOpen || !canEditShift}
            expandAll={expandAll}
            timingsOverlapping={detectOverlaps(singleAssignedExcavatorDetailsFormData?.shiftPlans)}
            handleEditMultiPlan={handleEditMultiPlan}
          />
        ),
      },
      { title: "History", component: <HistoryWithPlans search={search} setSearch={setSearch} exId={singleAssignedExcavatorDetailsFormData?.equipment} shiftId={generalShiftPlanDetailsFormData?.newShiftId} equipType={AssetCategories.EXCAVATORS} equipId={singleAssignedExcavatorDetailsFormData.equipment} /> },
    ];
  };

  const extraFlyerTabs = [
    {
      title: "Shift Plan",
      rightActionButtons: !notEditable ? [
        {
          label: "Save", onClick: handleSave
        }
      ] : [],
      component: (
        <ShiftPlan
          generalShiftPlanDetailsFormData={generalShiftPlanDetailsFormData}
          setFormData={setFormData}
          formData={formData}
          lastSavedExcavatorDetails={singleAssignedExcavatorDetailsFormData?.shiftPlans[currPlan]}
          showError={showError}
        />
      ),
    },
  ];

  const operatorAssignmentWarning = () => {

    const getCommaSeparatedLocations = (locations) => locations.map(location => location.type === 'excavator' ? location?.ExPlantId : location?.truckPlantId).join(', ');

    const commaSeparatedLocationsOperator = getCommaSeparatedLocations(overlappingDetails.operator.locations);
    const commaSeparatedLocationsAssistantOperator = getCommaSeparatedLocations(overlappingDetails.assistantOperator.locations);
    const commaSeparatedLocationsTrainee = getCommaSeparatedLocations(overlappingDetails.trainee.locations);

    return (
      <div>
        {overlappingDetails.operator.found && <div>
          Operator <span style={{ fontWeight: 'bold' }}>{formData?.operator?.label}</span> has already been assigned to <span style={{ fontWeight: 'bold' }}>{commaSeparatedLocationsOperator}</span>
        </div>}

        {overlappingDetails.assistantOperator.found && <div>
          Assistant Operator <span style={{ fontWeight: 'bold' }}>{formData?.assistantOperator?.label}</span> has already been assigned to <span style={{ fontWeight: 'bold' }}>{commaSeparatedLocationsAssistantOperator}</span>
        </div>}

        {overlappingDetails.trainee.found && <div>
          Trainee <span style={{ fontWeight: 'bold' }}>{formData?.trainee?.label}</span> has already been assigned to <span style={{ fontWeight: 'bold' }}>{commaSeparatedLocationsTrainee}</span>.
        </div>}

        Are you sure you want to reassign them to <span style={{ fontWeight: 'bold' }}>{formData?.plantId}</span>?

        <div className="my-2">
          <span style={{ fontWeight: 'bold' }}>Note:</span> Please make sure you assign a new operator/Trainee to <span style={{ fontWeight: 'bold' }}>
            {Array.from(new Set([
              ...(commaSeparatedLocationsOperator ? commaSeparatedLocationsOperator?.split(', ') : []),
              ...(commaSeparatedLocationsAssistantOperator ? commaSeparatedLocationsAssistantOperator?.split(', ') : []),
              ...(commaSeparatedLocationsOperator ? commaSeparatedLocationsOperator?.split(', ') : [])
            ])).join(', ')}
          </span> before you publish the plan.
        </div>
      </div>
    );
  }

  return (
    <>
      {operatorChangeConfirmationPopup && canEditShift && (
        <ConfirmAlert
          title={<div style={{ fontSize: 18, fontWeight: "bold", color: "#c13e3d" }}>Attention!</div>}
          message={operatorAssignmentWarning()}
          onClose={() => setOperatorChangeConfirmationPopup(false)}
          onConfirm={() => {
            const { plantId, category, ...dataToSave } = formData;
            onSaveExcavatorDetails(dataToSave, currPlan, singleAssignedExcavatorDetailsFormData.shiftPlans.length > 1, overlappingDetails);
            setOperatorChangeConfirmationPopup(false)
            setIsExtraFlyerOpen(false)
          }}
        />
      )}
      <Flyer
        isOpen={isOpen}
        onClose={() => !detectOverlaps(singleAssignedExcavatorDetailsFormData?.shiftPlans) && onClose()}
        style={{ backgroundColor: isExtraFlyerOpen ? "#F8FAFD" : "white", opacity: !operatorChangeConfirmationPopup ? 1 : 0 }}
        title={`${singleAssignedExcavatorDetailsFormData?.plantId} - Shift Plan`}
        tabs={generateTabs()}
        search={true}
        searchString={search.query}
        searchCount={search.count}
        onSearchInput={(val) => {
          setSearch({ ...search, query: val });
        }}
        handleOnClear={handleOnClear}
      />
      <Flyer
        style={{ position: "relative", right: 620 }}
        removeBorderRadius={true}
        isOpen={isExtraFlyerOpen && canEditShift}
        onClose={() => {
          if (singleAssignedExcavatorDetailsFormData.shiftPlans.length === 1) {
            setFormData(getInitialPlanData(0, singleAssignedExcavatorDetailsFormData, { activityDescriptions }));
          }
          if (currPlan == singleAssignedExcavatorDetailsFormData?.shiftPlans?.length)
            setCurrPlan(currPlan - 1);
          setIsExtraFlyerOpen(false);
        }}
        title={`${singleAssignedExcavatorDetailsFormData?.plantId} - Add Plan ${formData?.plan}`}
        tabs={extraFlyerTabs}
      />
    </>
  );
};

export default ExcavatorShiftDetails;
