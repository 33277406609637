import AuthLayout from "../../components/AuthLayout";
import Header from "../ShiftHistory/SubComponent/Header/Header";
import { ShiftHistoryProvider } from "./Context/context";
import ShiftHistoryTable from "./SubComponent/ShiftHistoryTable/ShiftHistoryTable"

const ShiftHistory = () => {
  return (
    <AuthLayout pageName="Shift History">
      <div
        className={`right_sidebar_body d-flex flex-column`}
        id="main"
        style={{ backgroundColor: "#FFFFFF" }}
      >
        <ShiftHistoryProvider>
          <Header />
          <ShiftHistoryTable/>
        </ShiftHistoryProvider>
      </div>
    </AuthLayout>
  );
};

export default ShiftHistory;
