import DynamicTable from "pages/DelayAndActivity/Component/Table/Table";
import { formatTimeRange, totalDelayDuration } from "pages/DelayAndActivity/utils/helper";
import React, { useState } from "react";
import History from "../../../Component/History/History";
import EditActivity from "../EditActivity/EditActivity";
import ConfirmAlert from "pages/DelayAndActivity/Component/ConfirmAlert/ConfirmAlert";
import { useActivityContext } from "../context/context";


const confirmMessage = (activity, equipment) => <div
  className="d-flex flex-column"
  style={{ lineHeight: "2rem" }}
>
  <span>
    Are you sure you want to delete the following activity for{" "}
    <span style={{ font: "normal normal bold 14px/20px Open Sans", color: "#000000" }}>{equipment.name}</span>?
  </span>
  <span style={{ font: "normal normal 600 14px/22px Open Sans", color: "#000000" }}>
    {activity.activityType}
  </span>
  <span style={{ font: "normal normal 600 14px/22px Open Sans", color: "#000000" }}>{formatTimeRange(activity)}</span>
</div>


function ActivitiesTable() {
  const {
    loading,
    openFlyer,
    setOpenFlyer,
    filteredResults,
    activityData,
    handleDeleteActivity,
  } = useActivityContext()
  const [historyData, setHistoryData] = useState([]);
  const [editActivityData, setEditActivityData] = useState({})
  const [confirmModal, setConfirmModal] = useState({
    show: false,
    title: "",
    message: "",
    aid: ""
  });



  const totalFilteredResult = ['excavators', 'trucks'].reduce((total, key) => {
    const items = filteredResults[key] || [];
    return total + items.reduce((acc, item) => acc + (item.activities ? item.activities.length : 0), 0);
  }, 0);


  const totalCount = ['excavators', 'trucks'].reduce((total, key) => {
    const items = activityData[key] || [];
    return total + items.reduce((acc, item) => acc + (item.activities ? item.activities.length : 0), 0);
  }, 0);

  const handleOpenHistory = (data) => {
    setHistoryData(data);
    setOpenFlyer("ACTIVITY_HISTORY");
  };

  const handleOpenEdit = (data) => {
    setEditActivityData(data);
    setOpenFlyer("ACTIVITY_EDIT");
  };

  const headers = [
    {
      label: "Activity Duration",
      key: "activityDuration",
      style: { width: "17.5%", paddingLeft: 12 }
    },
    {
      label: "Activity Type",
      key: "activityType",
      style: { width: "20%", paddingLeft: 12 }
    },
    {
      label: "Reported By",
      key: "reportedBy",
      style: { width: "57.5%", paddingLeft: 12 }
    },
    {
      label: "Actions",
      key: "actionMenu",
      style: { width: "5%", paddingLeft: 12 }
    },
  ];

  const processedData = Object.entries(filteredResults).map(([key, value]) => ({
    groupName: key,
    groupData: value?.map((equipment) => ({
      rowHeader: equipment.name + " - " + totalDelayDuration(equipment?.activities),
      rows: equipment?.activities?.map((activity, index) => ({
        style: {
          borderLeft: !activity.endTime ? "4px solid #42C7AE" : "",
          backgroundColor: !activity.endTime ? "#F5FFFC" : "transparent",
        },
        value: {
          activityId: activity.activityId,
          activityDuration: formatTimeRange(activity),
          activityType: activity.activityType,
          reportedBy: activity.reportedBy

        },
        actionMenu: {
          options: [
            {
              name: "Edit",
              icon: "",
              onClick: () => {
                handleOpenEdit(activity);
              },
            },
            {
              name: "Delete",
              icon: "",
              onClick: () =>
                setConfirmModal({
                  show: true,
                  title: "Delete Activity",
                  message: confirmMessage(activity, equipment),
                  wantToDelete: true,
                  aid: activity.activityId
                }),
            },
            {
              name: "Edit History",
              icon: "",
              onClick: () => handleOpenHistory(activity.history),
            },
          ],
        }
      })),
      actionMenu: {
        options: [
          {
            name: "Edit History",
            icon: "",
            onClick: () =>
              handleOpenHistory(
                equipment.history?.map((hist) => ({
                  updatedAt: hist.actionTime,
                  updatedBy: hist.actionBy,
                  changes: [
                    {
                      key: hist.action,
                      to: hist.activityType,
                    },
                    {
                      key: "Activity Duration",
                      to: formatTimeRange(hist),
                    },
                  ],
                }))
              ),
          },
          { name: "Download", icon: "", disabled: true },
        ],
      }
    }))
  }))

  return (
    <>
      {openFlyer == "ACTIVITY_EDIT" && <EditActivity data={editActivityData} />}

      {openFlyer == "ACTIVITY_HISTORY" && (
        <History
          handleOnClose={() => setOpenFlyer("")}
          historyData={historyData}
        />
      )}

      {confirmModal.show && (
        <ConfirmAlert
          title={confirmModal.title}
          message={confirmModal.message}
          onClose={() => {
            setConfirmModal({
              show: false,
              title: "",
              message: "",
              aid: '',
            });
          }}
          onConfirm={() => {
            handleDeleteActivity(confirmModal.aid);
            setConfirmModal({
              show: false,
              title: "",
              message: "",
              aid: ''
            });
          }}
        />
      )}

      <DynamicTable
        loading={loading}
        data={processedData}
        headers={headers}
        groupBadge={true}
        rowHeader={true}
        footer={`Displaying ${totalFilteredResult}/${totalCount} Activities`}
      />
    </>
  );
}

export default ActivitiesTable;
