import moment from "moment";
import { CallWithAuth } from "../../../../../action/apiActions";
import { GET_OPERATOR_SHIFT_ASSIGNMENTS } from "../../../../../action/apiPath";
import { DateTimeFormats } from "../../../utils/contants";
import { toast } from "react-toastify";

export const fetchOperatorShiftAssignments = async (formData, generalShiftPlanDetailsFormData) => {
    try {
        const response = await CallWithAuth(
            "GET",
            `${GET_OPERATOR_SHIFT_ASSIGNMENTS}/${formData?.operator?.value}?startTime=${moment(formData?.startTime, DateTimeFormats.DATE_TIME_U).toISOString()}&endTime=${moment(formData?.endTime, DateTimeFormats.DATE_TIME_U).toISOString()}`
        );
        const { status, data } = response.res.data;
        if (status === 200) {
            if (data?.length && data?.[0]?.shift != generalShiftPlanDetailsFormData.newShiftId) {
                toast.error("Operator already assigned to a different shift", { position: "bottom-right", autoClose: 3000 });
                return false;
            }
            return true;
        }
        throw new Error(`Error fetching operator's other shift details: ${data.message}`);
    } catch (error) {
        throw new Error(`Error fetching operator's other shift details: ${error.message}`);
    }
};

export const findOperatorInAssignments = (assignments, operatorId, startTime, endTime, newLocation, type) => {
    if (!operatorId) {
        return { found: false, locations: [] };
    }

    const locations = [];

    assignments.forEach((assignment, excavatorIndex) => {
        assignment?.shiftPlans.forEach((shiftPlan) => {
            const isOperatorInShiftPlan = shiftPlan?.[type]?.value === operatorId &&
                moment(shiftPlan.startTime, DateTimeFormats.DATE_TIME_U) <= moment(endTime, DateTimeFormats.DATE_TIME_U) &&
                moment(startTime, DateTimeFormats.DATE_TIME_U) <= moment(shiftPlan.endTime, DateTimeFormats.DATE_TIME_U) && moment() < moment(shiftPlan.endTime, DateTimeFormats.DATE_TIME_U);
            if (isOperatorInShiftPlan) {
                locations.push({ type: 'excavator', exPlantId: assignment?.plantId, plan: shiftPlan.plan });
            }

            shiftPlan.haulTrucks.forEach((truck, haulTruckIndex) => {
                const isOperatorInTruck = truck?.[type]?.value === operatorId &&
                    moment(truck.startTime, DateTimeFormats.DATE_TIME_U) <= moment(endTime, DateTimeFormats.DATE_TIME_U) &&
                    moment(startTime, DateTimeFormats.DATE_TIME_U) <= moment(truck.endTime, DateTimeFormats.DATE_TIME_U) && moment() < moment(truck.endTime, DateTimeFormats.DATE_TIME_U);
                if (isOperatorInTruck) {
                    if (newLocation.exIdx != excavatorIndex || haulTruckIndex != newLocation.truckIdx)
                        locations.push({ type: 'truck', exPlantId: assignment?.plantId, plan: shiftPlan.plan, truckPlantId: truck.plantId });
                }
            });
        });
    });

    return { found: locations.length > 0, locations };
};

export const validate = (data) => (
    !!data?.plantId &&
    !!data?.category &&
    !!data?.operator &&
    !!data?.material &&
    !!data?.sourceArea &&
    !!data?.dumpArea?.length &&
    !!data?.startTime &&
    !!data?.endTime &&
    !!data?.productiveHours &&
    !!data?.activityDescription
);

export const getShiftPlanData = (planIndex, truckIdx, singleAssignedExcavatorDetailsFormData, fetchedData) => {
    const haulTruckData = singleAssignedExcavatorDetailsFormData?.shiftPlans[planIndex]?.haulTrucks[truckIdx] || {};
    const previousPlanData = singleAssignedExcavatorDetailsFormData?.shiftPlans[planIndex - 1] || {};

    return {
        plan: singleAssignedExcavatorDetailsFormData?.shiftPlans[planIndex]?.plan,
        plantId: haulTruckData?.plantId,
        category: haulTruckData?.category,
        bcmPerLoad: haulTruckData?.bcmPerLoad,
        operator: haulTruckData?.operator || null,
        assistantOperator: haulTruckData?.assistantOperator || null,
        trainee: haulTruckData?.trainee || null,
        material: haulTruckData?.material || null,
        sourceArea: haulTruckData?.sourceArea || null,
        dumpArea: haulTruckData?.dumpArea || [],
        loadingMachine: {
            value: singleAssignedExcavatorDetailsFormData?.plantId,
            label: singleAssignedExcavatorDetailsFormData?.plantId,
        },
        targetLoads: haulTruckData?.targetLoads || previousPlanData?.targetLoads,
        startTime: haulTruckData?.startTime || previousPlanData?.startTime,
        endTime: haulTruckData?.endTime || previousPlanData?.endTime,
        shiftDuration: haulTruckData?.shiftDuration || null,
        productiveHours: haulTruckData?.productiveHours || null,
        activityDescription: haulTruckData?.activityDescription || { value: fetchedData?.activityDescriptions?.filter(ad => ad?.equipType == "Truck")?.find((ad) => ad.activityCode == "T1")._id, label: "T1-Hauling (default code)" },
        knownHazards: haulTruckData?.knownHazards || null,
        startOfShiftNoteToOperator: haulTruckData?.startOfShiftNoteToOperator || null,
    };
};



export const getShiftPlans = (singleAssignedExcavatorDetailsFormData, equipmentId) => {
    return singleAssignedExcavatorDetailsFormData?.shiftPlans?.reduce((accumulatedArray, currentShiftPlan) => {
        const haulTruckDetails = currentShiftPlan?.haulTrucks?.find((ht) => ht.equipment == equipmentId)
        const removedHaulTruckDetails = currentShiftPlan?.removedHaulTrucks?.find((ht) => ht.equipment == equipmentId)
        if (!haulTruckDetails && !removedHaulTruckDetails) return accumulatedArray

        const planDetails = {
            ...(haulTruckDetails || removedHaulTruckDetails),
            loadingMachine: {
                value: singleAssignedExcavatorDetailsFormData?.equipment,
                label: singleAssignedExcavatorDetailsFormData?.plantId
            },
            plan: currentShiftPlan?.plan,
            planStartTime: currentShiftPlan?.startTime,
            planEndTime: currentShiftPlan?.endTime,
            status: currentShiftPlan.status
        };

        return [...accumulatedArray, planDetails];
    }, []);
};