import React from "react";
import styles from "./ConfirmAlert.module.css";

const ConfirmAlert = ({ onConfirm, onClose, message, title, containerStyle = {}, modalStyle = {} }) => {
  document.body.style.height = "100vh";
  document.body.style.overflow = "hidden";
  return (
    <div style={{ ...containerStyle }} className={styles.container}>
      <div style={{ ...modalStyle }} className={styles.modal}>
        <div className={styles.top}>
          <img
            src="assets/images/close.png"
            style={{ cursor: "pointer" }}
            onClick={() => {
              document.body.style.height = "100%";
              document.body.style.overflow = "auto";
              onClose();
            }}
          />
        </div>
        <div className={styles.mid}>
          {title && <div className={styles.title}>{title}</div>}
          {message && <div className={styles.message}>{message}</div>}
        </div>
        <div className={styles.bottom}>
          <div className={styles.btn} onClick={() => {
            document.body.style.height = "100%";
            document.body.style.overflow = "auto";
            onConfirm()
          }}>
            Confirm
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmAlert;
