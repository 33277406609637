import { PieChart } from 'react-minimal-pie-chart';

const CircularPieChart = ({ pieChartData }) => {
  const data = pieChartData ? [
    { value: pieChartData[0], color: 'url(#greenGradient)' },
    { value: pieChartData[1], color: 'url(#redGradient)' },
    { value: pieChartData[2], color: 'url(#grayGradient)' },
  ] : [
    { value: 100, color: 'url(#grayGradient)' },
  ];


  return (
    <div style={{ width: '200px', height: '120px' }}>
      <svg width="0" height="0">
        <defs>
          <linearGradient id="greenGradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#CDEE81" />
            <stop offset="100%" stopColor="#75C506" />
          </linearGradient>
          <linearGradient id="redGradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#FEA186" />
            <stop offset="100%" stopColor="#FE5846" />
          </linearGradient>
          <linearGradient id="grayGradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#EFEFEF" />
            <stop offset="100%" stopColor="#C2C0B9" />
          </linearGradient>
        </defs>
      </svg>

      <PieChart
        data={data}
        lineWidth={22}
        radius={50}
        startAngle={-90}
      />
    </div>
  );
};

export default CircularPieChart;
