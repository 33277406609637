import { useShiftHistoryContext } from "pages/ShiftHistory/Context/context";
// import historyData from "../../DummyData";
import {
  dateFormatted,
  showToast,
  transformEquipmentData,
} from "pages/ShiftHistory/utils/helper";
import DynamicTable from "pages/ShiftHistory/Component/Table/Table";
import ShiftHistoryModal from "../ShiftHistoryTimeline/ShiftHistoryModal";
import { useEffect, useRef, useState } from "react";
import ShiftSummaryReport from "../ShiftSummaryReport/ShiftSummaryReport";
import { fetchShiftSummaryReport } from "pages/ShiftHistory/utils/apiService";
import { CSVLink } from "react-csv";
import { RiDownloadLine } from "react-icons/ri";
import { Riple } from "react-loading-indicators";
import { EquipmentType, ShiftHistoryHeader } from "pages/ShiftHistory/utils/constant";

function ShiftHistoryTable() {
  const {
    loading,
    selectedShift,
    shiftType,
    filteredResults,
    shiftHistoryData,
    summaryPreCondition
  } = useShiftHistoryContext();

  const [selectedUnit, setSelectedUnit] = useState();
  const [reportLoading, setReportLoading] = useState(false)
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const handleSummaryCloseModal = () => setShowSummaryModal(false);
  const [shiftSummary, setShiftSummary] = useState();
  const [isTruck, setIsTruck] = useState();
  const csvLinkRef = useRef();
  const [csvData, setCSVData] = useState(null);
  const [csvFilename, setCSVFilename] = useState(null);
  const [selectedEquipmentId, setSelectedEquipmentId] = useState("")

  const getHistorySummaryResponse = async (isTruck, equipmentId) => {
    setReportLoading(true)
    setIsTruck(isTruck);
    setSelectedEquipmentId(equipmentId)
    //allow modal to open

    try {
      const summaryResponse = await fetchShiftSummaryReport(
        selectedShift[shiftType],
        equipmentId
      );
      if (summaryResponse) {
        setShowSummaryModal(true);
        setShiftSummary(summaryResponse);
      }
    } catch (error) {
      showToast(false, error.message);
    }
    setReportLoading(false)
  };

  const headerStyle = {
    font: "normal normal 600 12px/17px Open Sans",
    color: "#707070",
    paddingLeft: 12,
  };

  const tBodyStyle = {
    font: "normal normal 600 12px/18px Open Sans",
    color: "#28354D",
  };

  const totalAssets = shiftHistoryData?.historyData?.length ?? 0;
  const filteredAsset = filteredResults.length > 0 ? filteredResults.length : 0;

  const transformedData = transformEquipmentData(filteredResults);

  const handleDownloadCSV = (unit) => {
    const data = getSingleCSVData(unit);
    const filename = `${unit.name}`;
    setCSVData(data);
    setCSVFilename(filename);
  };

  useEffect(() => {
    if (csvData && csvFilename) {
      csvLinkRef.current.link.click();
    }
  }, [csvData, csvFilename]);

  const getSingleCSVData = (unit) =>
    unit.operatorLoads.map((operatorLoad) => ({
      name: unit.name,
      firstLoad: dateFormatted(operatorLoad.firstLoad),
      lastLoad: dateFormatted(operatorLoad.lastLoad),
      operatorName: operatorLoad.operatorName,
      loginTime: dateFormatted(operatorLoad.loginTime),
      logoutTime: dateFormatted(operatorLoad.logoutTime),
    }));

  const headers = [
    {
      label: ShiftHistoryHeader.PLANT_ID,
      key: "plantID",
      style: { width: "12%", ...headerStyle },
    },
    {
      label: ShiftHistoryHeader.FIRST_LOAD,
      key: "firstLoad",
      style: { width: "12%", ...headerStyle },
    },
    {
      label: ShiftHistoryHeader.LAST_LOAD,
      key: "lastLoad",
      style: { width: "12%", ...headerStyle },
    },
    {
      label: ShiftHistoryHeader.CURRENT_OPERATOR,
      key: "currentOperator",
      style: { width: "17%", ...headerStyle },
    },
    {
      label: ShiftHistoryHeader.LOGIN_SHIFT_START,
      key: "shiftStart",
      style: { width: "12%", ...headerStyle },
    },
    {
      label: ShiftHistoryHeader.LOGOUT_SHIFT_END,
      key: "shiftEnd",
      style: { width: "23%", ...headerStyle },
    },
    {
      label: "",
      key: "viewSummary",
      style: { width: "19%", ...headerStyle },
    },
    {
      label: ShiftHistoryHeader.ACTIONS,
      key: "actionMenu",
      style: { width: "2%", ...headerStyle },
    },
  ];




  const processedData = transformedData
    ? Object.entries(transformedData).map(([key, value]) => ({
      groupName: key,
      groupData: value.map((equipment) => {
        const { name, operatorLoads = [], type, id } = equipment;
        const loads = operatorLoads[0] || {}; // Handle missing loads
        const shiftStart = dateFormatted(loads.loginTime);
        const shiftEnd = dateFormatted(loads.logoutTime);
        const firstLoad = dateFormatted(loads.firstLoad);
        const lastLoad = dateFormatted(loads.lastLoad);

        return {
          rowHeader: name || "-",
          rows: [
            {
              style: {},
              value: {
                Id: loads._id || "-",
                plantID: name || "-",
                firstLoad,
                lastLoad,
                currentOperator: loads.operatorName || "-",
                shiftStart,
                shiftEnd,
                ...(summaryPreCondition && {
                  viewSummary: (
                    <span
                      id={`equipment_${(name || "").replace(/\s+/g, "")}`}
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent the event from bubbling to <tr>
                        getHistorySummaryResponse(
                          type === EquipmentType.EXCAVATOR ? false : true,
                          id
                        );
                      }}
                    >
                      View Shift Summary
                    </span>
                  ),
                }),
              },
              valueStyle: {
                plantID: tBodyStyle,
                firstLoad: tBodyStyle,
                lastLoad: {
                  font: "normal normal 600 12px/18px Open Sans",
                  color: "#28354D",
                  opacity: "0.74",
                },
                currentOperator: tBodyStyle,
                shiftStart: tBodyStyle,
                shiftEnd: tBodyStyle,
                viewSummary: {
                  textDecoration: "underline",
                  font: "normal normal 600 12px/18px Open Sans",
                  color: "#005AFF",
                },
              },
              onClick: () => setSelectedUnit({ ...equipment, shiftType }),
              actionMenu: {
                options: [
                  {
                    name: "Download CSV",
                    icon: <RiDownloadLine color={"#005AFF"} size={17} />,
                    onClick: () => handleDownloadCSV(equipment),
                  },
                ],
              },
            },
          ],
        };
      }),
    }))
    : [];

  return (
    <>
      {reportLoading && <div
        style={{ height: "100%", width: "100%", position: 'fixed', left: 0, opacity: .25, backgroundColor: "black", zIndex: 1000 }}
        className="d-flex align-items-center justify-content-center"
      >
        <Riple color="#317bcc" size="medium" text="" textColor="" />
      </div>}

      <DynamicTable
        id="shift-history"
        loading={loading}
        data={processedData}
        headers={headers}
        groupBadge={true}
        rowHeader={false}
        footer={`Displaying ${filteredAsset}/${totalAssets} assets`}
      />

      {selectedUnit && (
        <ShiftHistoryModal
          selectedUnit={selectedUnit}
          setSelectedUnit={setSelectedUnit}
        />
      )}

      <ShiftSummaryReport
        show={showSummaryModal}
        isTruck={isTruck}
        closeModal={handleSummaryCloseModal}
        data={shiftSummary}
        selectedEquipmentId={selectedEquipmentId}
      />
      {csvData && csvFilename && (
        <CSVLink data={csvData} filename={csvFilename} ref={csvLinkRef} />
      )}
    </>
  );
}

export default ShiftHistoryTable;
