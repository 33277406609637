import ColorDot from "pages/KPIDashboard/Component/ColorDot/ColorDot";
import Style from "../Delays.module.css";
import { convertSecondsToTime } from "pages/KPIDashboard/Utils/helper";
import { DELAY_TYPE } from "pages/KPIDashboard/Utils/constant";

const DelayTypes = ({ delayTypesData }) => {
  return (
    <div className="d-flex flex-column mt-2" style={{ gap: "4px" }}>
      <div className="d-flex align-items-center justify-content-between">
        <div className={Style.legendLabels}>
          {" "}
          <span>
            <ColorDot
              bgColor={"#2256E4"}
              containerStyle={{
                opacity: "1",
                boxShadow: "0px 0px 25px #0277FF29",
              }}
            />
          </span>{" "}
          {DELAY_TYPE.RI}
        </div>
        <div className={Style.legendValues}>{convertSecondsToTime(delayTypesData?.RI)}</div>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <div className={Style.legendLabels}>
          {" "}
          <span>
            <ColorDot
              bgColor={"#8EC3FF"}
              containerStyle={{
                opacity: "1",
              }}
            />
          </span>
          {DELAY_TYPE.PI}
        </div>
        <div className={Style.legendValues}>{convertSecondsToTime(delayTypesData?.PI)}</div>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <div className={Style.legendLabels}>
          <span>
            <ColorDot
              bgColor={"#B9DBFF"}
              containerStyle={{
                opacity: "1",
              }}
            />
          </span>
          {DELAY_TYPE.PM}
        </div>
        <div className={Style.legendValues}>{convertSecondsToTime(delayTypesData?.PM)}</div>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <div className={Style.legendLabels}>
          <span>
            <ColorDot
              bgColor={"#E7F2FF"}
              containerStyle={{
                opacity: "1",
              }}
            />
          </span>
          {DELAY_TYPE.BM}
        </div>
        <div className={Style.legendValues}>{convertSecondsToTime(delayTypesData?.BM)}</div>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <div className={Style.legendLabels}>
          {" "}
          <span>
            <ColorDot
              bgColor={"#FEC956"}
              containerStyle={{
                opacity: "1",
              }}
            />
          </span>
          {DELAY_TYPE.OM}
        </div>
        <div className={Style.legendValues}>{convertSecondsToTime(delayTypesData?.OM)}</div>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <div className={Style.legendLabels}>
          {" "}
          <span>
            <ColorDot
              bgColor={"#FE9201"}
              containerStyle={{
                opacity: "1",
              }}
            />
          </span>
          {DELAY_TYPE.AD}
        </div>
        <div className={Style.legendValues}>{convertSecondsToTime(delayTypesData?.AD)}</div>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <div className={Style.legendLabels}>
          {" "}
          <span>
            <ColorDot
              bgColor={"#FF3B2B"}
              containerStyle={{
                opacity: "1",
              }}
            />
          </span>
          {DELAY_TYPE.WD}
        </div>
        <div className={Style.legendValues}>{convertSecondsToTime(delayTypesData?.WD)}</div>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <div className={Style.legendLabels}>
          {" "}
          <span>
            <ColorDot
              bgColor={"#000000"}
              containerStyle={{
                opacity: "1",
              }}
            />
          </span>
          {DELAY_TYPE.PNP}
        </div>
        <div className={Style.legendValues}>{convertSecondsToTime(delayTypesData?.PN)}</div>
      </div>
    </div>
  )
}
export default DelayTypes




