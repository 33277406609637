import React from 'react'

const Datepicker = ({ date, onChange }) => {
    return (
        <div>
            <input
                type="date"
                className="form-control date-picker"
                value={date}
                style={{
                    borderRadius: "50%",
                    marginRight: "0.8rem",
                }}
                placeholder="DD/MM/YYYY"
                onChange={(e) => onChange(e.target.value)}
            />
        </div>
    )
}

export default Datepicker