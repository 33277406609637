import moment from "moment";
import styles from "../ShiftSummaryReport.module.css";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";

const HeaderSection = ({ summary, isTruck, materialType }) => {

  const isTargetReached = (actual, target) => {
    if (actual >= target) return true;
    return false;
  };

  const remaining = Math.max(0, (summary?.target || 0) - (summary?.actual || 0));

  return (
    <>
      <div className="pb-3 d-flex align-items-center justify-content-between w-100">
        <div className="modal-title">
          <span className={styles.titleSummary}>Shift Summary</span> -{" "}
          <span className={styles.titleSummary}>#{summary?.reportNo}</span>{" "}
          <span className={styles.titleShift}>({summary?.shiftType})</span>
        </div>
        <div>
          <span className={styles.titleShift}>{summary?.plantId}</span> -
          <span className={styles.titleDate}>
            {moment(summary?.date).format("DD/MM/YYYY")}
          </span>
        </div>
      </div>
      <div
        className={`d-flex align-items-center justify-content-between w-100 px-3 py-4 ${styles.reportStatus}`}
        style={{
          background: isTargetReached(summary?.actual, summary?.target)
            ? "#E8FDE7"
            : "#FFDDDD",
        }}
      >

        <div className={styles.reportLabel}>
          {isTargetReached(summary?.actual, summary?.target)
            ? "Target reached"
            : " Target not reached"}{" "}
        </div>
        <div>
          <span className="pr-1"> {isTargetReached(summary?.actual, summary?.target) ? <FaArrowUp color="#049A5F" /> : <FaArrowDown color="#DD3238" />} </span>
          <span className={styles.totalLabel}>{summary?.actual}</span> /
          <span className={styles.targetLabel}> {summary?.target} {isTruck ? "Loads" : (materialType == "Coal" ? "Tonnes" : "BCM")} Completed</span> |{" "}
          <span className={styles.remainingLabel}>{remaining} {isTruck ? "Loads" : (materialType == "Coal" ? "Tonnes" : "BCM")} Remaining</span>
        </div>
      </div>
    </>
  );
};

export default HeaderSection;
