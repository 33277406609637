import { ButtonGroup } from 'react-bootstrap';

function ToggleButtons({
    options,
    selectedValue,
    onOptionClick,
    activeColor = '#386DFF',
    inactiveColor = '#6B7B8E',
    backgroundColor = '#FAFBFD',
    activeBackgroundColor = '#FFFFFF',
    borderRadius = '20px',
    style = {},
    containerClassName = ""
}) {
    return (
        <div className={"ml-3 " + containerClassName} style={{ display: 'flex', backgroundColor: backgroundColor, borderRadius, border: "1px solid #E1E8F4" }}>
            {options.map((option, idx) => (
                <div
                    key={idx}
                    className='px-4'
                    style={{
                        backgroundColor:
                            selectedValue === option.value
                                ? activeBackgroundColor
                                : "transparent",
                        color: selectedValue === option.value ? activeColor : inactiveColor,
                        border: `1px solid ${selectedValue === option.value ? activeColor : 'transparent'
                            }`,
                        borderRadius,
                        fontSize: 12,
                        // fontWeight: selectedValue === option.value ? "600" : "400",
                        fontWeight: 600,
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        userSelect: 'none',
                        justifyContent: 'center',
                        height: 30,
                        ...style
                    }}
                    onClick={() => onOptionClick(option.value)}
                >
                    {option.name || option.content}
                </div>
            ))}
        </div>
    );
}

export default ToggleButtons;
