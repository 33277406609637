import AuthLayout from "../../components/AuthLayout";
import Styles from "../DelayAndActivity/DelayAndActivity.module.css";
import NavBar from "./Component/NavBar/NavBar";
import Delays from "./SubComponent/Delays";
import Activity from "./SubComponent/Activity";
import { useState } from "react";
import { DelayProvider } from "./SubComponent/Delays/context/context";
import { ActivityProvider } from "./SubComponent/Activity/context/context";

const DelayAndActivity = () => {
  const [selectedTab, setSelectedTab] = useState("delays");

  return (
    <AuthLayout pageName="Delays & Activities">
      <div
        className={`right_sidebar_body d-flex flex-column ${Styles.main}`}
        id="main"
      >
        <div className={`mb-0 px-5 py-1 mt-5 border-bottom`}>
          <NavBar onTabChange={(type) => setSelectedTab(type)} />
        </div>

        {selectedTab === "delays" ? (
          <DelayProvider>
            <Delays />
          </DelayProvider>
        ) : (
          <ActivityProvider>
            <Activity />
          </ActivityProvider>
        )}
      </div>
    </AuthLayout>
  );
};

export default DelayAndActivity;
