import Flyer from "pages/DelayAndActivity/Component/Flyers/Flyer";
import { useState } from "react";
import Styles from "../Filter/Filter.module.css";
import { InitialFilters } from "../../../utils/constant";
import FilterComponent from "./FilterComponent";
import { useActivityContext } from "../context/context";


const Filter = () => {
  const {
    setOpenFlyer,
    openFlyer,
    filters: originalFilters,
    setFilters: setOriginalFilters,
    onApplyFilter,
    searchQuery,
    activityData
  } = useActivityContext()

  const [filters, setFilters] = useState(originalFilters)

  const handleOnChange = (key, val) => {
    const tempFilters = { ...filters };
    tempFilters[key] = val;
    setFilters(tempFilters);
  };

  return (
    <>
      {openFlyer == "ACTIVITY_FILTER" && (
        <Flyer
          isOpen={openFlyer == "ACTIVITY_FILTER"}
          onClose={() => setOpenFlyer("")}
          title={"Filters"}
          containerStyle={{
            zIndex: "100",
            right: 0,
            bottom: 0,
            top: "unset",
            height: "calc(100vh - 15.4rem)",
          }}
          tabOptionsClassName={Styles.borderBottom}
          tabs={[
            {
              title: "",
              rightActionButtons: [
                {
                  label: "Apply",
                  onClick: () => {
                    onApplyFilter(filters, searchQuery, activityData);
                    setOriginalFilters(filters)
                    setOpenFlyer("");
                  },
                },
              ],
              leftActionButtons: [
                {

                  label: "Clear All",
                  onClick: () => {
                    setOriginalFilters(InitialFilters)
                    onApplyFilter(InitialFilters, searchQuery, activityData, true)
                    setOpenFlyer("");
                  },
                  outline: true,
                },
              ],
              component: (
                <FilterComponent
                  filters={filters}
                  onChange={handleOnChange}
                />
              ),
            },
          ]}
        />
      )}
    </>
  );
};

export default Filter;
