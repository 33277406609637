import Flyer from "pages/DelayAndActivity/Component/Flyers/Flyer";
import { useState } from "react";
import AddDelayComponent from "./AddDelayComponent";
import Style from "../AddDelay/AddDelay.module.css";
import {
  convertSecToISO,
  formateDataForSaving,
} from "pages/DelayAndActivity/utils/helper";
import { CallWithAuth } from "action/apiActions";
import { toast } from "react-toastify";
import { useDelayContext } from "../context/context";

const AddDelay = () => {
  const initialFormData = {
    equipments: undefined,
    delays: [
      {
        delayType: "",
        delaySubType: "",
        startTime: "",
        endTime: "",
      },
    ],
  };
  const { setOpenFlyer, selectedShift, openFlyer, selectedDate, shiftType,refreshPage, setRefreshPage } =
    useDelayContext();
  const [formData, setFormData] = useState(initialFormData);
  const [showError, setShowError] = useState(false);

  const isFormValid = () => {
    if (!formData.equipments?.length) {
      setShowError(true);
      return false;
    }

    const isDelayValid = formData.delays?.every((delay) => {
      return (
        delay.delaySubType &&
        delay.delayType &&
        delay.startTime &&
        delay.endTime
      );
    });

    if (!isDelayValid) {
      setShowError(true);
      return false;
    }

    setShowError(false);
    return true;
  };

  const saveChanges = async () => {
    //Validate form data.
    if (!isFormValid()) {
      return;
    }
    // Validate delays first
    const validatedDelays = formData.delays?.map((delay) => {
      const formattedDelay = formateDataForSaving(delay);
      const startTime = convertSecToISO(
        formattedDelay.startTime,
        shiftType,
        selectedDate
      );
      const endTime = convertSecToISO(
        formattedDelay.endTime,
        shiftType,
        selectedDate
      );

      // Check if startTime is greater than endTime
      if (new Date(startTime) > new Date(endTime)) {
        toast.error("Error: Start time cannot be greater than end time.");
        return null; // Mark invalid delays as null
      }

      return {
        ...formattedDelay,
        startTime,
        endTime,
      };
    });

    // If any delay is invalid, stop the function
    if (validatedDelays?.includes(null)) {
      return; // Prevent API call
    }

    // If all delays are valid, proceed with formatting the data
    const formattedData = {
      ...formateDataForSaving({ ...formData, shift: selectedShift[shiftType] }),
      delays: validatedDelays,
    };

    try {
      const reqBody = formattedData;
      const response = await CallWithAuth("POST", "/api/delay", reqBody);

      if (response?.res?.status === 201) {
        toast.success("Delays added successfully!");
        setFormData(initialFormData);
        setOpenFlyer("");
        setRefreshPage(!refreshPage)
      } else {
        throw new Error("Failed to add delays");
      }
    } catch (error) {
      toast.error("An error occurred while adding delays. Please try again.");
    }
  };

  return (
    <>
      {openFlyer === "ADD_DELAY" && (
        <Flyer
          isOpen={openFlyer === "ADD_DELAY"}
          onClose={() => {
            setOpenFlyer("");
          }}
          title={"Add Delay"}
          containerStyle={{
            zIndex: "100",
            right: 0,
            bottom: 0,
            top: "unset",
            height: "calc(100vh - 15.4rem)",
          }}
          tabOptionsClassName={Style.flyer}
          tabs={[
            {
              title: "",
              rightActionButtons: [
                {
                  label: "Save",
                  onClick: async () => {
                    await saveChanges();
                  },
                },
              ],
              component: (
                <AddDelayComponent
                  showError={showError}
                  formData={formData}
                  setFormData={setFormData}
                />
              ),
            },
          ]}
        />
      )}
    </>
  );
};

export default AddDelay;
