import { IoBanSharp } from "react-icons/io5";

const Legend = ({ background, shadow, borderRadius, icon, label, info, style = {} }) => {
  const styles = {
    label: {
      font: "normal normal normal 12px/22px Open Sans",
      color: "#1A1A1A",
    },
    info: {
      font: "normal normal normal 10px/22px Open Sans",
      color: "#585858",
    },
  };


  return (<>


    <div className="d-flex align-items-center my-1">
      <div
        style={{
          width: "20px",
          height: icon === undefined ? "6px" : "max-content",
          borderRadius: icon === undefined ? borderRadius : "",
          opacity: "1",
          marginRight: "10px",
          background: icon === undefined ? background : "",
          boxShadow: icon === undefined ? shadow : "",
          display: 'flex',
          alignItems: "start",
          ...style
        }}
      >{icon}</div>
      <div>
        <div style={{ ...styles.label }}>{label}</div>
      </div>
    </div>
    {info !== undefined && <div style={{ marginLeft: 30, ...styles.info }}>{info}</div>}
  </>
  );
};

export default Legend;
