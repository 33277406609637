import Badge from "pages/KPIDashboard/Component/Badge/Badge";
import Style from "../Delays.module.css";
import DelayTypes from "../DelayTypes/DelayTypes";
import { convertSecondsToTime } from "pages/KPIDashboard/Utils/helper";
import { IoCloseSharp } from "react-icons/io5";
import { EQUIPMENT_TYPE, TYPES } from "pages/KPIDashboard/Utils/constant";
import moment from "moment";

const DelaysTypeSection = ({
  coordinates,
  cardContent,
  setShowDetails,
  handleCloseCard,
  equipmentType,
  type,
}) => {


  const styleDiv = {
    position: "absolute",
    marginTop: "-29rem",
    left: coordinates.x / 2 + 500 + "px",
    padding: "1rem",
    border: "0.063rem solid var(--unnamed-color-e1e8f4)",
    border: "0.063rem solid #E1E8F4",
    opacity: "1",
    zIndex: 1000,
  };

  const handleViewDetailsClick = () => {
    setShowDetails(true);
  };

  return (
    <div
      className="pb-4"
      style={{
        width: "290px",
        height: "305px",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 0px 45px #A3A3A326",
        borderRadius: "15px",
        ...styleDiv,
      }}
    >
      <div className="d-flex align-items-center justify-content-between">
        <div class={Style.DailySectionTitle}>
          {equipmentType === 1
            ? EQUIPMENT_TYPE.TRUCK
            : EQUIPMENT_TYPE.EXCAVATOR}
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            {" "}
            <Badge
              value={
                type === TYPES.DAILY
                  ? `${cardContent?.time}`
                  : moment(cardContent?.time).format("DD-MM-YYYY")
              }
              containerClass="py-2"
              containerStyle={{
                font: "normal normal 600 11px/15px Open Sans",
                color: "#386DFF",
                background: "#F0F6FD",
                borderRadius: 15,
                width: "98px",
              }}
            />
          </div>
          <div onClick={handleCloseCard} style={{ cursor: "pointer" }}>
            <IoCloseSharp color="black" fontSize={17.5} />
          </div>
        </div>

      </div>
      <div
        className="mx-2 mt-3 mb-4"
        style={{ border: "1px solid #ebebeb", opacity: "0.4" }}
      ></div>
      <div className="d-flex align-items-center justify-content-between">
        <div className={Style.totalDelayLabel}>Total Delay Duration</div>
        <div className={Style.totalDelayValue}>
          {convertSecondsToTime(cardContent?.totalDelay)}
        </div>
      </div>
      <div className={Style.byTypeLabel}>By type</div>
      <DelayTypes delayTypesData={cardContent?.totalByDelayTypes} />

      <div
        className={`pt-2 ${Style.viewDetails}`}
        onClick={handleViewDetailsClick}
      >
        View Details
      </div>
    </div>
  );
};

export default DelaysTypeSection;
