import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchEquipments, fetchDelayTypes, deleteDelay } from '../utils/apiService';
import { filterData, formatQuery } from '../utils/helpers/searchHelper';
import { filterEquipments, isEmptyFilter } from '../utils/helpers/filterHelper';
import { InitialFilters } from 'pages/DelayAndActivity/utils/constant';
import { showToast } from 'pages/DelayAndActivity/utils/helper';
import { CallWithAuth, CallWithAuthFormData } from 'action/apiActions';
import axios from 'axios';
import moment from 'moment-timezone'
import useAuth from 'hooks/useAuth';
const DelayContext = createContext();


export const DelayProvider = ({ children }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const [selectedDate, setSelectedDate] = useState(
        queryParams.get("date")
            ? moment.tz(queryParams.get("date"), "YYYY-MM-DD", "Pacific/Auckland").toDate()
            : new Date()
    );
    const [selectedShift, setSelectedShift] = useState({ day: undefined, night: undefined });
    const [shiftType, setShiftType] = useState(queryParams.get("type") ? queryParams.get("type") : 'day');
    const [shiftOptions, setShiftOptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const { auth } = useAuth();
    const timeoutRef = useRef(null);
    const [searchQuery, setSearchQuery] = useState('')
    const [delayData, setDelayData] = useState([]);
    const [filteredResults, setFilteredResults] = useState([]);
    const [openFlyer, setOpenFlyer] = useState("");
    const [filters, setFilters] = useState(InitialFilters)

    const [equipmentList, setEquipmentList] = useState([])
    const [delayTypeList, setDelayTypeList] = useState([]);
    const filtersRef = useRef(InitialFilters);
    const searchQueryRef = useRef('')
    const [refreshPage, setRefreshPage] = useState(false)

    useEffect(() => {
        const dateFromQuery = queryParams.get("date");
        const shiftTypeFromQuery = queryParams.get("type")
        const formattedSelectedDate = moment(selectedDate).format("YYYY-MM-DD");

        if (dateFromQuery && formattedSelectedDate !== dateFromQuery) {
            const newSelectedDate = moment.tz(dateFromQuery, "YYYY-MM-DD", "Pacific/Auckland").toDate();
            setSelectedDate(newSelectedDate);
        }
        if (shiftTypeFromQuery && shiftType !== shiftTypeFromQuery) {
            setShiftType(shiftTypeFromQuery);
        }
    }, [location.search]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [equipmentList, delayTypeList] = await Promise.all([
                    fetchEquipments(),
                    fetchDelayTypes()
                ]);
                setDelayTypeList(delayTypeList?.map((delayType) => ({ label: delayType.delayType, value: delayType._id })));
                setEquipmentList(equipmentList);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, []);


    useEffect(() => {
        const source = axios.CancelToken.source();
        const cktkn = source.token;

        // Helper function to navigate with query parameters
        const navigateWithDate = () => {
            queryParams.set("date", moment(selectedDate).format("YYYY-MM-DD"));
            navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true });
        };

        // Helper function to fetch shifts data
        const fetchShiftsData = async () => {
            try {
                setLoading(true);
                navigateWithDate(); // Update the navigation with the selected date

                const shiftResponse = await CallWithAuthFormData(
                    "POST",
                    "/api/getShiftsForDashboard?new=true",
                    {
                        userId: auth?.userData?._id,
                        date: moment(selectedDate).format("YYYY-MM-DD"),
                    },
                    {},
                    cktkn
                );

                const shiftsData = shiftResponse?.res?.data?.data || {};
                processShiftData(shiftsData);
            } catch (error) {
                handleFetchError(error);
            }
        };

        // Helper function to process fetched shift data
        const processShiftData = (shiftsData) => {
            setShiftOptions({
                day: shiftsData.day,
                night: shiftsData.night,
            });

            setSelectedShift({
                day: shiftsData?.day?.[0]?.value,
                night: shiftsData?.night?.[0]?.value,
            });

            // Check if the selected shift type is valid
            if (!shiftsData[shiftType]?.[0]?.value) {
                setDelayData([]);
                setFilteredResults([]);
            }

            setLoading(false); // Ensure loading is false after processing
        };

        // Helper function to handle fetch errors
        const handleFetchError = (error) => {
            console.error("Error fetching shift data:", error);
            setLoading(false); // Ensure loading is false on error
        };

        // Fetch shifts data if user is authenticated and a date is selected
        if (auth?.userData?._id && selectedDate) {
            fetchShiftsData();
        }

        // Cleanup function
        return () => {
            source.cancel('Operation canceled by the user.');
        };
    }, [selectedDate, auth]);

    useEffect(() => {
        const source = axios.CancelToken.source();

        // Helper function to fetch data
        const fetchData = async (dontLoad = false) => {
            if (!dontLoad) setLoading(true);
            try {
                const shiftResponse = await CallWithAuth(
                    "GET",
                    `/api/delay?shiftId=${selectedShift[shiftType]}`,
                    {},
                    source.token
                );
                const data = shiftResponse?.res?.data;

                if (!dontLoad && data) setLoading(false);
                if (shiftResponse?.res.status) {
                    setDelayData(data);
                    onApplyFilter(filtersRef.current, searchQueryRef.current, data, false);
                }
                setAutoRefreshTimeout();
                // If successful, start the timeout for auto-refresh

            } catch (error) {
                handleError(error, dontLoad);
            }
        };

        // Helper function for error handling and retry logic
        const handleError = (error, dontLoad) => {
            if (!dontLoad) setLoading(false);
            console.error("Error fetching performance data:", error);

            // Set retry with timeout on error
            setAutoRefreshTimeout();
        };

        // Helper function to manage auto-refresh
        const setAutoRefreshTimeout = () => {
            timeoutRef.current = setTimeout(() => {
                if (selectedShift[shiftType]) fetchData(true);
            }, 5000);
        };

        // Initial Navigation
        if (shiftType) {
            queryParams.set("type", shiftType);
            navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true });
        }

        // Initial Data Fetch
        if (selectedShift[shiftType]) fetchData();
        else {
            setDelayData([]);
            setFilteredResults([]);
        }

        // Cleanup on unmount
        return () => {
            source.cancel("Operation canceled by the user.");
            clearTimeout(timeoutRef.current);
        };
    }, [selectedShift, shiftType,refreshPage]);

    const handleDeleteDelay = async (delayId) => {
        const result = await deleteDelay(delayId);
        if(result.success) setRefreshPage(!refreshPage)
        showToast(result.success, result.message);
    };

    const searchDelayData = (query, data) => {

        if (!query.trim()) {
            // Show all data if the query is empty or undefined
            setFilteredResults(data);
            return;
        }

        const formattedQuery = formatQuery(query);

        // Filter excavators and trucks based on the formatted query
        const filteredData = {
            excavators: filterData(data.excavators, formattedQuery),
            trucks: filterData(data.trucks, formattedQuery),
        };

        setFilteredResults(filteredData);
    };

    const onApplyFilter = (filters, searchQuery, data, wantToClear = false) => {
        filtersRef.current = filters;
        searchQueryRef.current = searchQuery;

        if (wantToClear) {
            searchDelayData(searchQuery, data);
            return;
        }

        const { equipments = [], delayTypes = [], startTime, endTime } = filters;

        // Return the original data if no filters are applied
        if (isEmptyFilter(equipments, delayTypes, startTime, endTime)) {
            searchDelayData(searchQuery, data);
            return;
        }

        const filteredData = {
            excavators: filterEquipments(data?.excavators, equipments, delayTypes, startTime, endTime),
            trucks: filterEquipments(data?.trucks, equipments, delayTypes, startTime, endTime),
        };

        searchDelayData(searchQuery, filteredData);
    };

    return (
        <DelayContext.Provider value={{
            loading,
            setLoading,
            selectedDate,
            setSelectedDate,
            selectedShift,
            setSelectedShift,
            shiftType,
            setShiftType,
            openFlyer,
            setOpenFlyer,
            filters,
            setFilters,
            searchQuery,
            setSearchQuery,
            onApplyFilter,
            searchDelayData,
            handleDeleteDelay,
            filteredResults,
            equipmentList,
            delayTypeList,
            shiftOptions,
            delayData,
            refreshPage, 
            setRefreshPage

        }}>
            {children}
        </DelayContext.Provider>
    );
};

export const useDelayContext = () => {
    return useContext(DelayContext);
};