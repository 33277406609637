import moment from "moment-timezone";

// Helper function to check if filters are empty
export const isEmptyFilter = (equipments, noteType, startTime, endTime) => {
  return equipments.length === 0 && !noteType && !startTime && !endTime;
};

// Filter equipment based on the provided filters


export const filterEquipments = (
    items,
    equipmentFilter,
    noteTypeFilter,
    startTime,
    endTime
  ) => {
    return items?.filter((item) => {
      const startSec = getSecondsFromStartOfDay(item.addedOn);
      const addedOnTimeMatches =
        startSec >= (startTime || 0) && startSec <= (endTime || 86400);
  
      const equipmentMatches =
        equipmentFilter.length === 0 ||
        item?.assets.some((asset) =>
          equipmentFilter.some((eq) => eq.label === asset)
        );
  
      const noteTypeMatches =
        !noteTypeFilter || item.noteType === noteTypeFilter;
  
      return equipmentMatches && noteTypeMatches && addedOnTimeMatches;
    });
  };


// export const filterNotes = (note, noteTypeFilter, startTime, endTime) => {
//   const noteMatches = !noteTypeFilter || note.noteType === noteTypeFilter;

//   const startSec = getSecondsFromStartOfDay(note.addedOn); 
//   const addedOnTimeMatches =
//     startSec >= (startTime || 0) && startSec <= (endTime || 86400);

//   return noteMatches && addedOnTimeMatches ? note : {};
// };

// Get seconds from the start of the day for a given time
export const getSecondsFromStartOfDay = (time) => {
  return moment(time)
    .tz("Pacific/Auckland")
    .diff(moment(time).tz("Pacific/Auckland").startOf("day"), "seconds");
};
