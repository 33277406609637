// import styles from "../TruckProgressBar/TruckProgressBar.css"

const AssetProgressBar = ({ width = 115, value, max, arrowDirection, thresholdNeeded = true, arrowNeeded = true, containerStyle = {}, progressContainerStyle = {}, shadowColor = true }) => {

  const thresholdPercent = thresholdNeeded ? 80 : 0;
  const percentV = thresholdNeeded ? ((value / max) * thresholdPercent) : 100;
  const fillWidth = `${percentV}%`;
  const fillColor = value >= max ? 'transparent linear-gradient(270deg, #C6EB78 0%, #85D813 100%)' : 'transparent linear-gradient(270deg, #FF6565 0%, #FF5353 100%)';
  const shadowColorX = value >= max ? "#85D8134D" : "#EB5F5F4D"

  //todo ask prem how to manage it.
  const styles = {
    progressBar: {
      position: 'relative',
      height: '12px',
      borderRadius: '15px',
      backgroundColor: '#e0e0e0',
      overflow: 'hidden',
      width: width,
      opacity: 1,
    },

    fill: {
      height: '100%',
      position: 'absolute',
      left: '0',
      top: '0',
      borderRadius: '10px',
    },
    thresholdLine: {
      position: 'absolute',
      top: '0',
      bottom: '0',

      backgroundColor: 'black',
      zIndex: 1,
    },
    arrow: {
      fontFamily: 'Open Sans',
      position: 'absolute',
      left: '50%',
      top: '27.5%',
      fontSize: '20px',
      color: 'black',
      zIndex: 2,
    }
  };

  return (
    <div style={{ ...styles.progressBar, ...containerStyle, boxShadow: shadowColor ? ("0px 0px 35px " + shadowColorX) : "none" }}>
      {arrowNeeded && (
        <>
          {arrowDirection === 'right' && (
            <div style={{
              ...styles.arrow,
              transform: "translate(-50%, -50%)"
            }}>
              →
            </div>
          )}
          {arrowDirection === 'left' && (
            <div style={{
              ...styles.arrow,
              transform: "translate(-50%, -50%)"
            }}>
              ←
            </div>
          )}
        </>
      )}
      <div
        style={{
          ...styles.fill,
          width: fillWidth,
          background: fillColor,
          ...progressContainerStyle
        }}
      />
      <div
        style={{
          ...styles.thresholdLine,
          left: `${thresholdPercent}%`,
          width: thresholdNeeded ? '2px' : '0px'
        }}
      />


    </div >
  );
};

export default AssetProgressBar