import ColorDot from "pages/KPIDashboard/Component/ColorDot/ColorDot";
import Style from "./MTDGraphs.module.css";
import LineChart from "pages/KPIDashboard/Component/LineChart/LineChart";
import { processOverburdenGraphCategories } from "pages/KPIDashboard/Utils/helper";

const MTDGraphs = ({ data, style, title, unit }) => {

  // const data = {
  //     xAxisData: [
  //       '01/01', '02/01', '03/01', '04/01', '05/01', '06/01', '07/01', '08/01','09/01',
  //     ],
  //     seriesData: [
  //       [0, 10, 40, 0, 20, 10, 30, 40,35], // Data for Line 1
  //       [10, 0, 20, 30, 10, 20, 40, 50,35], // Data for Line 2
  //     ],
  //   };

  // const getCategory = () => {
  //   return processOverburdenGraphCategories(data);
  // };

  const getSeriesData = () => {
    if (!data) return [];
    const actualData = [];
    const targetData = [];

    Object.keys(data).forEach((date) => {
      const { actual, target } = data[date];
      actualData.push(actual);
      targetData.push(target);
    });

    return [actualData, targetData];
  }

  return (
    <div style={style || { width: 500 }} className={` ${Style.container}`}>
      <div className="px-4 pt-4 d-flex align-items-center justify-content-between mx-4">
        <div className={Style.title}>
          {title}<span className={Style.mtd}> (MTD)</span>{" "}
        </div>

        <div className=" d-flex align-items-center justify-content-between">
          <div className="mr-3">
            <span>
              {" "}
              <ColorDot
                bgColor={"#416BE0"}
                containerStyle={{ opacity: "1" }}
              />
            </span>{" "}
            <span className={Style.legend}>Planned</span>
          </div>
          <div>
            <span>
              {" "}
              <ColorDot
                bgColor={"#85D813"}
                containerStyle={{ opacity: "1" }}
              />
            </span>
            <span className={Style.legend}>Actual</span>
          </div>
        </div>
      </div>
      <div
        className="mx-5 mt-3 mb-4"
        style={{ border: "1px solid #ebebeb", opacity: "0.4" }}
      ></div>
      <div className="px-3">
        <LineChart style={{ bottom: '30px' }}
          yAxisLabel={unit}
          gridOptions={{
            // left: "3%",
            top: '17.5%',
            bottom: '7.5%'
          }}
          data={getSeriesData()} categories={processOverburdenGraphCategories(data)} />
      </div>
    </div >
  );
};

export default MTDGraphs;
