import React from 'react'
import Button from '../../../../components/Buttons'

const EquipmentButton = ({ isSelected = true, onHold = false, alert = false, onClick, label = 'EXC75' }) => {
    const removeSpaces = (val) => {
        if (val) {
            return val.replaceAll(/\s/g, '');
        } else {
            return '';
        }
    };

    return (
        <div>
            <Button
                onClick={onClick}
                label={removeSpaces(label)}
                hold={onHold}
                alert={alert}
                style={{
                    margin: "0 4px",
                    border: "1px solid " + (isSelected ? "#7da4db" : "#C1D3EF"),
                    backgroundColor: isSelected ? "#E2ECFC" : "transparent",
                }}
            />
        </div>
    )
}

export default EquipmentButton;
