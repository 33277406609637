import React from "react";

export default function SelectField({
  required,
  label,
  value,
  options,
  isDisabled,
  id,
  style = {},
  placeholder,
  error,
  errorMessage,
  className,
  onChange,
  mainContainerStyle,
  viewOnly,
  noPlaceHolder = false
}) {
  return (
    <div className="row form-group" style={{ minHeight: 37, ...mainContainerStyle }}>
      {label && (
        <div className="col-md-4">
          <label>
            {label} {required && <span style={{ color: "#6A7C9A" }}>*</span>}
          </label>
        </div>
      )}
      <div className={`col-md-${label ? 8 : 12} ` + className}>
        {viewOnly ? <span style={{ fontWeight: '600', fontSize: 16, color: "#1A1A1A" }}>{value}</span> : <select
          disabled={isDisabled}
          value={value}
          style={{
            cursor: "pointer",
            userSelect: "none",
            border: error ? "1px solid red" : "",
            ...style,
          }}
          id={id}
          onChange={(e) => onChange(e.target.value)}
        >
          {!noPlaceHolder && <option value=""> {placeholder || "Select" + label}</option>}
          {options?.map((option, index) => (
            <option value={option.value} key={index}>
              {option.label}
            </option>
          ))}
        </select>}
        {error && <div
          className="d-flex align-items-center pt-1"
          style={{ fontSize: 12, color: "#BA3938", height: error ? 15 : 0 }}
        >
          {errorMessage ?? ""}
        </div>}
      </div>
    </div>
  );
}

