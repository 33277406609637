import { useEffect, useRef, useState } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import styles from './Tabs.module.css';

const Tabs = ({ activeTab, setActiveTab, tabs, showVerticalJumpTo }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef(null);
    const tabRefs = useRef([]); // Ref array for horizontal tabs

    useEffect(() => {
        if (!showVerticalJumpTo) return;

        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuRef, showVerticalJumpTo]);

    const handleVerticalOptionClick = (index) => {
        setIsMenuOpen(false);
        setActiveTab(index);
        // Scroll to the corresponding horizontal tab
        tabRefs.current[index]?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    };

    return (
        <div className="d-flex align-items-end">
            <div style={{ overflowX: 'auto' }} className="shift_tab_list mb-0">
                <ul>
                    {tabs.map((tab, index) => (
                        <li
                            className={activeTab === index ? "active" : ""}
                            onClick={(e) => {
                                e.preventDefault();
                                setActiveTab(index);
                                // Scroll to the corresponding tab
                                tabRefs.current[index]?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                            }}
                            ref={(el) => (tabRefs.current[index] = el)} // Assign ref to each tab
                            key={index}
                        >
                            <a href="#">{tab.title}</a>
                        </li>
                    ))}
                </ul>
            </div>

            {showVerticalJumpTo && (
                <div onClick={() => setIsMenuOpen(!isMenuOpen)} className="py-2 pr-2 d-flex align-items-center justify-content-center" style={isMenuOpen ? { backgroundColor: "#B6C7E2", borderRadius: 5, cursor: "pointer" } : { cursor: "pointer" }}>
                    <RxHamburgerMenu size={20} className="ml-2" />
                </div>
            )}

            {isMenuOpen && showVerticalJumpTo && (
                <div ref={menuRef} style={{
                    position: 'absolute',
                    border: '1px solid #e1e8f4',
                    borderRadius: '4px',
                    boxShadow: '1px 3px 4px #00000029',
                    zIndex: 100,
                    right: '35px',
                    top: '115px',
                    padding: '4px 0px',
                }} className={`d-flex flex-column align-items-start bg-white`}>
                    {tabs.map((tab, index) => (
                        <div
                            className={styles.option}
                            key={index}
                            style={{
                                minWidth: '40px',
                                textAlign: 'start',
                                color: '#1a1a1a',
                                fontSize: '12px',
                                userSelect: 'none',
                                padding: '0 20px',
                                fontWeight: 'normal',
                                cursor: 'pointer',
                                ...(activeTab === index ? { backgroundColor: "#bacbeb", fontWeight: '600' } : {})
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleVerticalOptionClick(index); // Use the new click handler
                            }}
                        >
                            {tab.title}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Tabs;
