import React from 'react'

export default function TextInputField({ inputClass, wrapperClassName, labelStyle, placeholder, required, type = "text", label, value, isDisabled, id, style = {}, error, errorMessage, className, onChange, viewOnly, containerStyle = {}, textStyle = {} }) {
    return (
        <div style={{ minHeight: "3.5rem", display: 'flex', alignItems: 'center', marginBottom: '1rem', ...containerStyle }} className={`${wrapperClassName} row form-group`}>
            {label && <div className="col-md-4">
                <label style={{
                    ...labelStyle,
                    fontSize: "1.25rem",
                    color: "#4B515A"
                }}>
                    {label} {required && <span style={{ color: "#4B515A" }}>*</span>}
                </label>
            </div>}
            <div style={{ borderRadius: '.25rem' }} className={`col-md-${label ? 8 : 12} ` + (className || "")}>
                {viewOnly ? <span className='pl-1' style={{
                    color: "#1A1A1A",

                    fontSize: "1.3rem",
                    height: "3.25rem",
                    fontWeight: '600',
                    ...textStyle
                }}>{value || "-"}</span> : <input
                    placeholder={placeholder}
                    type={type}
                    disabled={isDisabled}
                    value={value}
                    style={{
                        border: error
                            ? "1px solid red"
                            : "",
                        fontWeight: '600',
                        // fontFamily: 'Open Sans',
                        fontSize: "1.3rem",
                        height: "3.25rem",
                        ...style
                    }}
                    id={id}
                    onChange={(e) => onChange(e.target.value, e)}
                    className={inputClass}
                />}
                <div
                    className="d-flex align-items-center pt-1"
                    style={{ fontSize: 12, color: "#BA3938", height: error ? 15 : 0 }}
                >
                    {error ? errorMessage : ""}
                </div>

            </div>
        </div>
    )
}
