import Styles from "../Header/Header.module.css";
import ActionMenu from "pages/ShiftHistory/Component/ActionMenu/ActionMenu";
import DateTimeSelect from "pages/ShiftHistory/Component/GeneralFields/DateTimeSelect/DateTimeSelect";
import Search from "pages/ShiftHistory/Component/GeneralFields/Search/Search";
import SelectField from "pages/ShiftHistory/Component/GeneralFields/SelectField/SelectField";
import ToggleButtons from "pages/ShiftHistory/Component/ToggleButton/ToggleButton";
import { useShiftHistoryContext } from "pages/ShiftHistory/Context/context";
import { dateFormatted } from "pages/ShiftHistory/utils/helper";
import { RiDownloadLine } from "react-icons/ri";
import { CSVLink } from "react-csv";
import { useRef, useState } from "react";

const SHIFT_TYPES = [
  { name: "Day", value: 1 },
  { name: "Night", value: 2 },
];

const Header = () => {
  const {
    selectedDate,
    setSelectedDate,
    shiftType,
    setShiftType,
    shiftOptions,
    setSelectedShift,
    searchQuery,
    setSearchQuery,
    loading,
    shiftHistoryData,
    filteredResults,
    searchShiftHistoryData,
  } = useShiftHistoryContext();

  const [csvData, setCSVData] = useState([]);

  const csvLinkRef = useRef(null); // Create a reference for the CSVLink

  const handleDownloadCSV = (shift) => {
    const data = getCSVData(shift);
    if (data.length === 0) return;

    setCSVData(data);
    // Trigger the download after the state has been updated
    setTimeout(() => {
      csvLinkRef.current.link.click();
    }, 0);
  };

  const getCSVData = (shift) =>
    shift?.flatMap((unit) =>
      unit.operatorLoads.map((operatorLoad) => ({
        name: unit.name,
        firstLoad: dateFormatted(operatorLoad.firstLoad),
        lastLoad: dateFormatted(operatorLoad.lastLoad),
        operatorName: operatorLoad.operatorName,
        loginTime: dateFormatted(operatorLoad.loginTime),
        logoutTime: dateFormatted(operatorLoad.logoutTime),
      }))
    );

  const optionMenu = [
    {
      name: "Download CSV",
      icon: <RiDownloadLine color={"#005AFF"} size={17} />,
      onClick: () => handleDownloadCSV(filteredResults),
    },
  ];

  return (
    <div 
      // style={{ paddingTop: 30 }}
      className={`d-flex justify-content-between align-items-center pt-5 pb-3 ${Styles.header} border-bottom`}
    >
      <div className="d-flex justify-content-between align-items-center">
        <CSVLink
          ref={csvLinkRef}
          data={csvData}
          filename="Shift-History.csv"
          style={{ display: "none" }}
        />

        <DateTimeSelect
          value={selectedDate}
          onDateChange={(date) => {
            setSelectedDate(date);
          }}
          className={`${Styles.dateTimeSelect} ml-5`}
          iconColor="#6B7B8E"
          containerStyle={{
            border: "1px solid #E1E8F4",
          }}
        />

        <ToggleButtons
          selectedValue={shiftType === "day" ? 1 : 2}
          onOptionClick={(v) => {
            setShiftType(v === 1 ? "day" : "night");
          }}
          style={{ height: 27.5 }}
          options={SHIFT_TYPES}
        />
      </div>

      <div className="d-flex justify-content-between align-items-center pr-5">
        <Search
          query={searchQuery}
          onChange={(value) => searchShiftHistoryData(value)}
        />
        <SelectField
          noPlaceHolder={true}
          options={shiftOptions?.[shiftType]}
          value="1"
          className="ml-3"
          isDisabled={!shiftOptions?.[shiftType]?.length || loading}
          style={getSelectFieldStyle(loading, shiftOptions?.[shiftType])}
          onChange={(v) => {
            setSelectedShift((prev) => ({ ...prev, [shiftType]: v }));
          }}
          mainContainerStyle={{ marginBottom: "0px" }}
        />

        <ActionMenu
          options={optionMenu}
          optionsContainerStyle={{
            font: "normal normal normal 12px/18px Open Sans",
            color: "#1A1A1A",
            right: "102.5%",
          }}
          className="ml-3"
        />
      </div>
    </div>
  );
};

const getSelectFieldStyle = (isLoading, options) => ({
  height: "30px",
  width: "160px",
  marginTop: 2.5,
  border: "1px solid #F1F1F1",
  borderRadius: "15px",
  paddingLeft: "10px",
  font: "normal normal 600 11px/14px Open Sans",
  color: "#000000",
  cursor: !options?.length || isLoading ? "not-allowed" : "pointer",
});

export default Header;
