import AssetProgressBar from 'pages/Dashboard/components/AssetProgressBar/AssetProgressBar';
import styles from "../ExcavatorsTruckDetails/ExcavatorsTruckDetails.module.css"
import AssetBadge from 'pages/Dashboard/components/AssetBadge';
import { ShiftStatusEnum, Status } from "pages/Dashboard/constant"
import { IoBanSharp } from 'react-icons/io5';
import { FaCircleXmark } from 'react-icons/fa6';
import moment from 'moment';
import { MdDoNotDisturbOn } from 'react-icons/md';
const ExcavatorsTruckDetail = ({ truckAssignments, productiveHours }) => {

  const truckAppearance = (status) => {
    const result = {}
    switch (status) {
      case Status.ASSIGNED:
        break;

      case Status.REASSIGNED:
        result.label = 'Reassigned'
        result.color = '#000000'
        result.truckBgColor = '#fffff'
        result.icon = ''
        break;

      case Status.REMOVED:
        result.label = 'Removed'
        result.bgColor = '#D8D8D8'
        result.color = '#2B2B2B'
        result.truckBgColor = '#F0F0F0'
        break;

      case Status.UNASSIGNED:
        result.label = ''
        result.truckBgColor = '#F4F6FD'
        result.icon = <span
          style={{
            font: "normal normal 600 12px/24px Open Sans"
          }}>*</span>
        break;

      case Status.UNSCHEDULED:
        result.truckBgColor = '#F4F6FD'
        result.icon = <IoBanSharp className="ml-1 mb-1" size={12} />
        break;

      case Status.SHIFTENDED:
        result.truckBgColor = '#F0F0F0'
        result.icon = <FaCircleXmark className="ml-1 mb-1" size={12} />
        break;

      case Status.NOTOPERATING:
        result.icon = <MdDoNotDisturbOn className="ml-1 mb-1" color="red" size={12} />
        break;

      default:
        result.truckBgColor = "#ffffff"
        result.label = status
    }

    return result;
  }

  return (
    truckAssignments.map((truck, index) => (
      <div
        key={index}
        className={styles.truckCardDetail}
        style={{
          backgroundColor: truckAppearance(truck?.status).truckBgColor,
          borderRadius: index === truckAssignments.length - 1 ? '0 0 15px 15px' : 'None'
        }}
      >
        <div className={styles.excTruckDetails}>
          <div style={{ width: 200 }} className={styles.truckId}>
            {truck?.truckName}  <span>{truckAppearance(truck?.status).icon}</span>
          </div>
          <AssetProgressBar
            width={"100%"}
            value={truck?.actualLoads}
            max={truck?.targetLoads}
            arrowDirection={(
              (truck.actualLoads < truck.targetLoads && (truck.hourlyData[
                moment().isBefore(moment(truck?.shiftEndTime))
                  ? moment().tz("Pacific/Auckland").subtract(1, 'hour').format("HH:00")
                  : moment(truck?.shiftEndTime).tz("Pacific/Auckland").subtract(1, 'hour').format("HH:00")
              ] ?? 0) < Math.round(truck?.targetLoads / productiveHours))
                ? "left"
                : "right"
            )}
            shadowColor={(truck?.status === Status.UNASSIGNED || truck?.status === Status.UNSCHEDULED) ? false : true}
            thresholdNeeded={(truck?.status === Status.UNASSIGNED || truck?.status === Status.UNSCHEDULED) ? false : true}
            arrowNeeded={(truck?.status === Status.UNASSIGNED || truck?.status === Status.UNSCHEDULED) ? false : true}
            progressContainerStyle={
              truck?.status === Status.UNASSIGNED || truck?.status === Status.UNSCHEDULED
                ? { background: 'transparent linear-gradient(270deg, #AAB7DB 0%, #7A88C6 100%)' }
                : {}
            }
          />
          <span style={{ width: 200, textAlign: "right" }}>
            <span className={styles.loadsText}>{truck?.actualLoads}</span>
            {truck?.targetLoads != undefined ? ` / ${truck.targetLoads}` : ""} Loads
          </span>
        </div>
        <div className={styles.operatorName}>O: {truck?.operatorName ? truck?.operatorName : "Unknown Operator"}</div>

        {(truck?.status === Status.REMOVED || truck?.status === Status.REASSIGNED) && (
          <AssetBadge
            className="px-3 mt-3"
            containerStyle={{ border: 'none', padding: "3.5px 0px" }}
            value={truckAppearance(truck?.status).label}
            color={truckAppearance(truck?.status).color}
            bgColor={truckAppearance(truck?.status).bgColor}
            font={"normal normal normal 9px/12px Open Sans"}
          />
        )}
      </div>
    ))

  );
};

export default ExcavatorsTruckDetail