import AuthLayout from "../../components/AuthLayout";
import Header from "pages/KPIDashboard/SubComponent/Header/Header";
import Dashboard from "pages/KPIDashboard/SubComponent/Dashboard/Dashboard"
import { KPIDashboardProvider } from "./Context/Context";


const KPIDashboard = () => {
  return (
    <AuthLayout pageName="KPI Dashboard">
      <div className={`right_sidebar_body d-flex flex-column`} style={{height: "100%"}} id="main">
      <KPIDashboardProvider>
        <Header />
        <Dashboard/>
      </KPIDashboardProvider>
      </div>
    </AuthLayout>
  );
};

export default KPIDashboard;
