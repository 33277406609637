import { useState } from "react";
import styles from "../ShiftSummaryReport.module.css";
import { TimeFormatter } from "pages/ShiftHistory/utils/helper";
import moment from "moment";
import { IoCloseSharp } from "react-icons/io5";

export const ViewSignature = ({ data, close }) => {
  const formatDate = (date) => {
    if (!date) return "-";
    return moment(date).tz("Pacific/Auckland").format("hh:mm A - DD/MM/YYYY");
  };
  return (
    <div
      className={`modal fade show`}
      style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      tabIndex="-1"
      role="dialog"
    >
      <div
        className={`modal-dialog modal-dialog-centered`}
        style={{ width: "465px", height: "436px" }}
        role="document"
      >
        <div
          className={`modal-content px-5`}
          style={{
            background: "#FFFFFF",
            boxShadow: "0px 0px 45px #A3A3A329",
            borderRadius: "15px",
            maxHeight: "110vh",
          }}
        >
          <div
            className={`modal-header d-flex align-items-center justify-content-between px-0`}
            style={{ border: "none", padding: "25px 0 15px 0" }}
          >
            <div
              style={{
                font: "normal normal 600 14px/14px Open Sans",
                color: "#000000",
                width: "90%",
              }}
            >
              {" "}
              Signature & Notes
            </div>
            <div>
              {" "}
              <div onClick={close} style={{ cursor: "pointer" }}>
                <IoCloseSharp color="black" fontSize={17.5} />
              </div>
            </div>
          </div>
          <div
            style={{ borderBottom: "1px solid #f2f2f2", opacity: "0.7" }}
          ></div>
          <div className={`modal-body p-0 pt-4 pb-5 `}>
            <div>
              <div
                className="pb-4"
                style={{
                  font: "normal normal 600 12px/17px Open Sans",
                  color: "#000000",
                }}
              >
                Signature
              </div>
              <div>
                <embed
                  style={{ maxHeight: "60px", maxWidth: "170px" }}
                  src={data?.signature}
                  alt="sign"
                />
                <br />
              </div>
              <div
                style={{
                  font: "normal normal normal 12px/17px Open Sans",
                  color: "#000000",
                }}
              >
                {data?.operatorName}
              </div>
              <div
                style={{
                  font: "normal normal normal 10px/14px Open Sans",
                  color: "#707070",
                  textTransform: "uppercase",
                }}
              >
                {formatDate(data?.endTime)}
              </div>
              <div
                className="pt-4"
                style={{ borderBottom: "1px solid #f2f2f2", opacity: "0.7" }}
              ></div>
            </div>

            <div className="pt-4">
              <div
                className="pb-3"
                style={{
                  font: "normal normal 600 12px/17px Open Sans",
                  color: "#000000",
                }}
              >
                Notes
              </div>
              <div
                style={{
                  font: "normal normal normal 12px/17px Open Sans",
                  color: "#000000",
                }}
              >
                {data?.notes}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const OperatorSection = ({ operators }) => {
  const [viewSignatureOpen, setViewSignatureOpen] = useState(false);
  const [signatureData, setSignatureData] = useState({});
  const handelViewOpenModal = (data) => {
    setSignatureData(data);
    setViewSignatureOpen(true);
  };
  const handelViewCloseModal = () => setViewSignatureOpen(false);

  return (
    <div id="operator">
      {viewSignatureOpen && (
        <ViewSignature data={signatureData} close={handelViewCloseModal} />
      )}
      <div className={`py-4 ${styles.singleHeader}`}>Operator</div>
      <div id="operator-data" className="pb-3">
        {/* Header Row */}
        <div
          className={`d-flex align-items-center justify-content-between px-3 py-4 ${styles.operatorData}`}
        >
          <div className={styles.operatorWidth20}>Name</div>
          <div className={styles.operatorWidth40}>Start & End Time</div>
          <div className={styles.operatorWidth20}>Signature & Notes</div>
        </div>
        {/* Dynamic Data Rows */}
        {operators.map((op, index) => (
          op.endTime ? <div
            key={index}
            className="d-flex align-items-center justify-content-between px-3 py-3"
            style={{
              font: "normal normal 600 11px/11px Open Sans",
              color: "#050505",
            }}
          >
            <div className={styles.operatorWidth20}>{op.operatorName}</div>

            <div className={styles.operatorWidth40}>
              {TimeFormatter(op.startTime, op.endTime)}
            </div>
            <div className={styles.viewOperator}>
              {op.signature ? (
                <span onClick={() => handelViewOpenModal(op)}>View</span>
              ) : (
                ""
              )}
            </div>
          </div>
            : <></>
        ))}
      </div>
    </div>
  );
};

export default OperatorSection;
